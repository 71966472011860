import React, { useState, useEffect } from "react";
import { Badge, Image, Menu } from "antd";
import {
  LogoWrapper,
  MenuIconWrap,
  MenuItem,
  Navigation,
  SidebarContainer,
  SidebarMenu,
  SubMenu,
  SideMenuGroup,
  Spacer,
  MenuDivider,
  SidebarTopMenu,
  SidebarDivider,
} from "./TheLayout.styles";
import {
  BenchmarkSvg,
  ApplicationsSvg,
  ListingsSvg,
  MessagesSvg,
  InvoicesSvg,
  TenantsSvg,
  MyAccountSvg,
  SettingsSvg,
  AvenewLogo,
  SupportSvg,
  DiscountSvg,
  SettingSvg,
} from "../../assets/svgs";
import { useLocation, useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { StyledLeftSideMenu } from "./header/TheHeader.styles";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import AddApartmentModal from "../addApartmentModal/AddApartmentModal";
import AddOrgModal from "../addApartmentModal/AddOrgModal";
import Listings from "../listings/Listings";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrg, getOrg } from "../../store/reducers/user.reducer";
import { slugify, _get, _set, upperCase } from "../../utils/fn";

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const routeOrg = "/property/:id";
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [visibleScreen, setVisible] = useState("");
  const [allOrg, setAllOrg] = useState([]);

  const { user } = useSelector((state) => state.user);
  const { allNotificatios } = useSelector((state) => state.user);
  const { allOrganization } = useSelector((state) => state.user);
  const [firstOrgName, setFirstOrgName] = useState("View All");
  const [routeOrgSide, setRouteOrgSide] = useState(
    `/property/${localStorage.getItem("toSave")}`
  );
  // const routeOrgSide = `/property/${localStorage.getItem("org_id")}`;
  useEffect(() => {
    if (localStorage.getItem("viewAll")) {
      setRouteOrgSide("/listings");
    } else if (!localStorage.getItem("viewAll")) {
      setRouteOrgSide(`/property/${localStorage.getItem("toSave")}`);
    }
  }, [
    firstOrgName,
    location,
    // localStorage.getItem("viewAll"),
    localStorage.getItem("toSave"),
    allOrganization,
  ]);

  const onClickFun = () => {
    console.log("click");
  };

  // Menu option for side bar
  const menu = [
    // {
    //   name: "Benchmark",
    //   key: "benchmark",
    //   component: MenuItem,
    //   icon: BenchmarkSvg,
    //   to: "/",
    // },
    // {
    //   name: "Applications",
    //   key: "applications",
    //   component: MenuItem,
    //   icon: ApplicationsSvg,
    //   to: "/applications",
    // },
    {
      name: "Applications",
      key: "applications",
      component: MenuItem,
      icon: ApplicationsSvg,
      to: "/application",
    },
    {
      name: "Listings & Units",
      key: "listings",
      component: MenuItem,
      icon: ListingsSvg,
      to: routeOrgSide,
    },
    {
      name: "Messages",
      key: "messages",
      component: MenuItem,
      icon: MessagesSvg,
      to: "/messages",
    },
    {
      name: "Transactions",
      key: "transactions",
      component: MenuItem,
      icon: InvoicesSvg,
      to: "/transactions",
    },
    {
      name: "Tenants",
      key: "tenants",
      component: MenuItem,
      icon: TenantsSvg,
      to: "/tenants",
    },
    // {
    //   name: "Discount Code",
    //   key: "discount",
    //   component: MenuItem,
    //   icon: DiscountSvg,
    //   to: "/discount-code",
    // },
    {
      name: "My Account",
      key: "my-account",
      component: MenuItem,
      icon: MyAccountSvg,
      to: "/my-account",
    },
    {
      name: "Support",
      key: "support",
      component: MenuItem,
      icon: SupportSvg,
      to: "/support",
    },
    {
      name: "Settings",
      key: "setting",
      component: MenuItem,
      icon: SettingSvg,
      to: "/setting",
    },
  ];

  // Menu option for view all or particular organization
  const menuTwo = [
    {
      name: firstOrgName.charAt(0).toUpperCase() + firstOrgName.slice(1),
      key: "org",
      component: SubMenu,
      icon: MdKeyboardArrowDown,
      children: [
        {
          name: `View All`,
          // icon: BsPlus,
          key: "view-all",
          component: SidebarDivider,
          // to: "/listings",
          toSave: true,
          // onClick: onClickFun,
        },
        {
          name: `Add New Org`,
          icon: BsPlus,
          key: "add-new-org",
          component: MenuItem,
          // to: "/dashboard",
          onClick: onClickFun,
          className: "add__org",
        },
      ],
    },
  ];
  const menuTwoOne = [
    {
      name: firstOrgName.charAt(0).toUpperCase() + firstOrgName.slice(1),
      key: "org",
      component: SubMenu,
      icon: MdKeyboardArrowDown,
      children: [
        {
          name: `Add New Org`,
          icon: BsPlus,
          key: "add-new-org",
          component: MenuItem,
          // to: "/dashboard",
          onClick: onClickFun,
          className: "add__org",
        },
      ],
    },
  ];

  // Split the value or a name w.r.t length of a string
  const slpitFunc = (value) => {
    if (value?.length > 15) {
      return value.slice(0, 15) + "...";
    } else {
      return value;
    }
  };

  const [topMenu, setTopMenu] = useState(menu);

  const [orgList, setOrgList] = useState(
    allOrg.length > 1 ? menuTwo : menuTwoOne
  );

  useEffect(() => {
    topMenu.map((value) => {
      if (value?.name === "Listings & Units") {
        value.to = routeOrgSide;
      } else {
      }
    });
  }, [routeOrgSide]);

  const [openKeys, setOpenKeys] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [addNew, setAddNew] = useState(false);

  //
  const findKey = (menu, parent = "dashboard") => {
    for (const item of menu) {
      if (item.children) {
        findKey(item.children, `${parent}.${item.key}`);
      } else {
        if (item.to === location.pathname) {
          let path = parent.split(".");
          setActiveKey(item.key);
          setOpenKeys(path.slice(1));
          // break;
        } else if (
          item.to === "/application" &&
          location.pathname === "/applications"
        ) {
          let path = parent.split(".");
          setActiveKey("applications");
          setOpenKeys(path.slice(1));
          break;
        }
      }
    }
    return [];
  };

  useEffect(() => {
    findKey(topMenu, topMenu[0].key);
  }, [location]);

  useEffect(() => {
    findKey(
      allOrg.length > 1 ? menuTwo : menuTwoOne,
      (allOrg.length > 1 ? menuTwo : menuTwoOne)[0].key
    );
  }, [location]);

  useEffect(() => {
    dispatch(getAllOrg());
  }, []);

  useEffect(() => {
    setAllOrg(Object.values(allOrganization));
  }, [allOrganization]);

  useEffect(() => {
    orgList.map((value) => {
      value.name = upperCase(firstOrgName);
    });
  }, [orgList, firstOrgName]);

  useEffect(() => {
    if (allOrg?.length && !localStorage.getItem("org_id")) {
      localStorage.setItem("org_id", allOrg[0]?.id);
      localStorage.setItem("toSave", allOrg[0]?.id);
    } else {
      // localStorage.removeItem("org_id");
    }
  }, [allOrg]);

  // useEffect(() => {
  //   if (allOrganization.lenght) {
  //     localStorage.setItem("org_id", allOrganization[0]?.id);
  //   }
  // }, [allOrganization]);

  // To set value id in localstorage when event triggered
  const addNewhendler = (value) => {
    setAddNew(true);
    if (!value.Error) {
      localStorage.removeItem("viewAll");
      localStorage.removeItem("application");
      setFirstOrgName(value.org_name);
      navigate(`/property/${value.id}`);
      localStorage.setItem("toSave", value.id);
      localStorage.setItem("org_id", value.id);
    }
  };

  // To navigate to addNeworganization Modal
  function getMenuLink(key) {
    switch (key) {
      case "add-new-org":
        return { onClick: () => setModalState(true) };
      default:
        return null;
    }
  }

  // Switch icon conditionally
  const sliderIcon = (key) => {
    switch (key) {
      case "dashboard":
        return ApplicationsSvg;
      case "org":
        return MdKeyboardArrowDown;
      case "add-new-org":
        return BsPlus;
      case "focus":
        return ApplicationsSvg;
      default:
        return null;
    }
  };

  useEffect(() => {
    const createMenu = ({ children, key, name, to, className, toSave }) => {
      let menuLink = getMenuLink(key);
      return {
        name: name,
        key: `${key}`,
        component:
          key === "org"
            ? SubMenu
            : key === "add-new-org"
            ? MenuItem
            : key === "team-devider"
            ? SidebarDivider
            : MenuItem,
        className: key === "add-new-org" ? "add__org" : "",
        // component: getComponentForMenu(
        //   key === "team-devider"
        //     ? "devider"
        //     : children && children.length
        //     ? "menu"
        //     : key === "org"
        //     ? "submenu"
        //     : "menu"
        // ),
        icon: sliderIcon(key),
        ...(to ? { to } : {}),
        ...(menuLink ? { ...menuLink } : {}),
        ...(toSave ? { toSave } : {}),
        ...(children && children.length
          ? { children: children.map((child) => createMenu(child)) }
          : {}),
      };
    };

    const mergeTeamMenu = (mainMenu, orgs) => {
      if (mainMenu.key === "org") {
        const connectMenu = { ...mainMenu };

        const mergeTeamData =
          orgs && orgs.length
            ? orgs.map((data, idx) => {
                return {
                  name: slpitFunc(data?.org_name),
                  key: `${slugify(data?.org_name || "org_")}`,
                  // component: MenuItem,
                  // to: routeOrg.replace(":id", data?.id),
                  toSave: data?.id,
                  // divider: true,
                };
              })
            : [];
        if (mergeTeamData.length) {
          const teamChildren = _get(mainMenu, "children", []);
          const teamLength = mergeTeamData.length;
          _set(mergeTeamData, `${teamLength}`, {
            // name: "devider",
            key: "team-devider",
          });
          teamChildren?.forEach((tc, idx) => {
            _set(mergeTeamData, `${teamLength + idx + 1}`, tc);
          });

          const modifiedConnectedMenu = {
            ...connectMenu,
            children: [
              ...mergeTeamData.map((connectChild) => {
                return {
                  ...connectChild,
                  // children: mergeTeamData,
                };
              }),
            ],
          };
          return modifiedConnectedMenu;
        }
      }
      return mainMenu;
    };

    let rebuildMenu = [];

    if (menuTwo && allOrg) {
      menuTwo &&
        (allOrg.length > 1 ? menuTwo : menuTwoOne).map((data, idx) => {
          _set(rebuildMenu, idx, createMenu(mergeTeamMenu(data, allOrg)));
        });

      setOrgList(() => {
        let oldMenu = [...rebuildMenu];
        return oldMenu;
      });
    }

    return () => {};
  }, [allOrg]);

  useEffect(() => {
    if (localStorage.getItem("org_id")) {
      const value = Object.values(allOrganization);
      value?.map((item) => {
        if (
          item.id === localStorage.getItem("org_id") &&
          location.pathname != "/listings" &&
          !localStorage.getItem("viewAll")
        ) {
          setFirstOrgName(slpitFunc(item?.org_name));
        } else if (location.pathname === "/listings") {
          setFirstOrgName("View All");
        } else if (localStorage.getItem("viewAll")) {
          setFirstOrgName("View All");
        }
      });
    }
  }, [allOrganization, firstOrgName, params]);

  const firstOrgNameFun = (name) => {
    var a = [];
    allOrg?.forEach(function (obj) {
      a.push(obj?.org_name);
    });
    if (
      a.includes(name) &&
      location.pathname != "/listings" &&
      !localStorage.getItem("viewAll")
    ) {
      setFirstOrgName(slpitFunc(name));
    } else if (location.pathname === "/listings") {
      setFirstOrgName("View All");
    }
  };

  useEffect(() => {
    if (firstOrgName === "View All") {
      setFirstOrgName("View All");
      // localStorage.removeItem("org_id");
      localStorage.setItem("viewAll", "viewAll");
    }
  }, [firstOrgName, location, localStorage.getItem("viewAll")]);

  function onOpenChange(keys, children) {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  }

  const renderMenu = (menu, className = "") => {
    return menu.map(
      (
        {
          name,
          key,
          to,
          onClick,
          component: Item,
          icon: Icon,
          children,
          toSave,
          className: className,
          divider,
        },
        idx
      ) => {
        return (
          <>
            <Item
              key={key}
              className={className}
              icon={
                Icon && (
                  <MenuIconWrap>
                    <Icon />
                  </MenuIconWrap>
                )
              }
              title={children && name}
              level={idx + 1}
            >
              {children ? (
                renderMenu(children, `sub-children ${key}`)
              ) : to ? (
                <>
                  {/* <Link to={to} onClick={() => localStorage.setItem("orgName", name)}> */}
                  {key === "messages" ? (
                    <Badge count={allNotificatios?.notifications?.length}>
                      <Link
                        to={to}
                        onClick={() => {
                          setVisible(name);
                          firstOrgNameFun(name);
                        }}
                      >
                        {upperCase(name)}
                      </Link>
                    </Badge>
                  ) : (
                    <Link
                      to={to}
                      onClick={() => {
                        setVisible(name);
                        firstOrgNameFun(name);
                      }}
                    >
                      {upperCase(name)}
                    </Link>
                  )}
                </>
              ) : onClick ? (
                <Link
                  to={window.location.pathname}
                  onClick={() => setModalState(true)}
                >
                  {name}
                </Link>
              ) : toSave ? (
                <>
                  {location.pathname.includes("property") ||
                  location.pathname.includes("listings") ? (
                    <Link
                      to={toSave === true ? "/listings" : `/property/${toSave}`}
                      onClick={() => setLocalId(toSave, name)}
                    >
                      {upperCase(name)}
                    </Link>
                  ) : (
                    <Link
                      to={window.location.pathname}
                      onClick={() => setLocalId(toSave, name)}
                    >
                      {upperCase(name)}
                    </Link>
                  )}
                </>
              ) : (
                name
              )}
            </Item>
          </>
        );
      }
    );
  };

  // To set ID in local storage
  const setLocalId = (id, name) => {
    if (id && name != "View All") {
      localStorage.setItem("toSave", id);
      localStorage.setItem("org_id", id);
      setFirstOrgName(name);
      localStorage.removeItem("viewAll");
      localStorage.removeItem("application");
      setRouteOrgSide(`/property/${id}`);
      if (location.pathname.includes("property")) {
        navigate(routeOrg.replace(":id", id));
        routeOrg.replace(":id", id);
        navigate(`/property/${id}`);
        setRouteOrgSide(`/property/${id}`);
        localStorage.removeItem("viewAll");
        localStorage.removeItem("application");
      }
    } else {
      setRouteOrgSide(`/listings`);
      setFirstOrgName(name);
      localStorage.setItem("viewAll", "viewAll");
      localStorage.removeItem("application");
    }
  };

  return (
    <>
      <SidebarContainer className="left-panel">
        <Navigation>
          <SideMenuGroup>
            <StyledLeftSideMenu>
              {/* <span className="avenew__title__sidebar">avenew</span> */}
              <Link to="/" className="nav-link avenew__title__sidebar">
                <AvenewLogo />
              </Link>
            </StyledLeftSideMenu>
            <SidebarTopMenu
              triggerSubMenuAction="click"
              mode="horizontal"
              openKeys={openKeys}
              selectedKeys={activeKey}
              onOpenChange={onOpenChange}
            >
              {renderMenu(orgList)}
            </SidebarTopMenu>
            <SidebarMenu
              mode="inline"
              openKeys={openKeys}
              selectedKeys={activeKey}
              onOpenChange={onOpenChange}
            >
              {renderMenu(topMenu)}
            </SidebarMenu>
          </SideMenuGroup>
        </Navigation>
      </SidebarContainer>
      <AddOrgModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
        addNewhendler={addNewhendler}
      />
      <Listings displayShow={false} name={visibleScreen} />
    </>
  );
}

export default Sidebar;
