import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonWrap, CurrentStagWrap, StatusButton } from "./ApplicationsStyle";
import ReactQuill from "react-quill";
import { AtchBox } from "../messages/messagesStyle";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useLocation } from "react-router";
import { HiDownload } from "react-icons/hi";
import { SendLeaseWrapTop } from "./SendLease";

function LeaseSigned({ selectedApplication, onMsgApplicant }) {
  const { width } = useWindowSize();
  const location = useLocation();

  // To dowload the signed lease from applicant
  const download = (e, name) => {
    const link = document.createElement("a");
    // link.setAttribute("download", "images.jfif");
    link.href = e;

    document.body.appendChild(link);
    link.click();
  };

  const [stringSort, setStringSort] = useState(8);

  // To set string sorted according to screen
  useEffect(() => {
    if (width < 768) {
      setStringSort(8);
    } else {
      setStringSort(30);
    }
  }, [width, location.pathname]);

  // Lease agreement Name split function w.r.t length
  const slpitFunc = (value) => {
    if (value?.length > 28) {
      return value.slice(0, stringSort) + "..." + value.slice(-8);
    } else {
      return value;
    }
  };

  //
  const exportHTML = () => {
    var header =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
      "xmlns:w='urn:schemas-microsoft-com:office:word' " +
      "xmlns='http://www.w3.org/TR/REC-html40'>" +
      "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
    var footer = "</body></html>";
    var sourceHTML =
      header + document.getElementById("source-html").innerHTML + footer;
    // downloadPdf(sourceHTML);

    var source =
      "data:application/pdf;charset=utf-8," + encodeURIComponent(sourceHTML);
    var fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = "document.doc";
    fileDownload.click();
    document.body.removeChild(fileDownload);
  };

  // const downloadPdf = (text) => {
  //   var header =
  //     "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
  //     "xmlns:w='urn:schemas-microsoft-com:office:word' " +
  //     "xmlns='http://www.w3.org/TR/REC-html40'>" +
  //     "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
  //   var footer = "</body></html>";
  //   var sourceHTML =
  //     header + document.getElementById("source-html").innerHTML + footer;
  //   doc.text(sourceHTML, 1, 1);
  //   doc.save("a4.pdf");
  // };

  return (
    <>
      <CurrentStagWrap>
        <h2 style={{ color: "red" }}>Coming soon in Beta</h2>
      </CurrentStagWrap>
      <SendLeaseWrap>
        {selectedApplication?.leaseAgreementDoc ? (
          <AtchBox
            style={{ marginTop: "0", padding: "10px" }}
            onClick={() =>
              download(
                selectedApplication?.leaseAgreementDoc.path,
                selectedApplication?.leaseAgreementDoc.name
              )
            }
          >
            <HiDownload
              style={{ fontSize: "24px", marginLeft: "10px" }}
              className="dow__svg"
            />
            <h5>{slpitFunc(selectedApplication?.leaseAgreementDoc.name)}</h5>
          </AtchBox>
        ) : (
          ""
        )}
        <ReactQuill
          id="source-html"
          value={selectedApplication?.leaseAgreementText}
          readOnly={true}
          theme={"bubble"}
        />
        {selectedApplication?.sign &&
        selectedApplication?.signFormat === "drawSign" ? (
          <div
            style={{ width: "100%", justifyContent: "end", display: "flex" }}
          >
            <img src={selectedApplication?.sign?.path} />
          </div>
        ) : selectedApplication?.sign &&
          selectedApplication?.signFormat === "uploadSign" ? (
          <div
            style={{
              width: "15%",
              margin: "auto",
            }}
          >
            <img
              style={{
                width: "100%",
              }}
              src={selectedApplication?.sign?.path}
            />
          </div>
        ) : (
          ""
        )}
        <ButtonWrap padding="30px 10px">
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
              <StatusButton
                className="status__button"
                bgColor={"#4160AC"}
                onClick={() => onMsgApplicant(selectedApplication)}
              >
                <span>Message Applicant</span>
              </StatusButton>
            </Col>
            <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
              <StatusButton className="status__button" bgColor={"#F67C36"}>
                <span>Approve Lease</span>
              </StatusButton>
            </Col>

            <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
              <StatusButton className="status__button" bgColor={"#2B353F"}>
                <span>Deny Lease</span>
              </StatusButton>
            </Col>
          </Row>
        </ButtonWrap>
        <SendLeaseWrapTop />
      </SendLeaseWrap>
    </>
  );
}

export default LeaseSigned;

const ObjWrap = styled.div`
  padding: 10px;
  display: flex;
  svg {
    cursor: pointer;
  }
`;

const SendLeaseWrap = styled.div`
  position: relative;
`;
