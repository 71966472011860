import {
  Badge,
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Popover,
  Row,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "../../ui/Layout/Container";
import { StyledAvatar } from "../applications/ApplicationsStyle";
import HeaderSearch from "../layout/header/HeaderSearch";
import {
  AttachmentSvg,
  AvenewImg,
  MsgFilterSvg,
  NoMessage,
  UserSvgIcon,
} from "../../assets/svgs";
import {
  AtchBox,
  AttachMent,
  ChatBox,
  ChatBoxMsg,
  FilterBtn,
  FilterBtnPopover,
  LeftMessage,
  MessagerBox,
  MessagerList,
  MessagesWrap,
  MsgBtn,
  NoMessageWrap,
  NoMessageWrapMain,
  OptionContent,
  RightMessage,
  SendEmail,
  TextBox,
  TopMessageBar,
} from "./messagesStyle";
import { UserAvatar } from "../../ui/Avatar";
import { CgFileDocument } from "react-icons/cg";
import { AddNewApr } from "../listings/Listingstyle";
import { BiSend } from "react-icons/bi";
import { FiPaperclip } from "react-icons/fi";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  createMessage,
  getAllChats,
  getMessages,
  getMessagesUnit,
  getOrgChats,
} from "../../store/reducers/user.reducer";
import { useLocation, useNavigate, useParams } from "react-router";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import moment from "moment";
import { ImagePreview } from "../ImagePreview";
import { Capitalize, imagePath, upperCase } from "../../utils/fn";
import { createSearchParams, useSearchParams } from "react-router-dom";
import io from "socket.io-client";
import { IoIosArrowDown } from "react-icons/io";
import FuzzySearch from "fuzzy-search";
import { MdCancel } from "react-icons/md";
import { TableOneWrap } from "../tenants/TableOne";

// const endPoint = "http://localhost:4000";
var socket;

function Messages() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const attachmentType = ["image/svg+xml", "application/pdf"];
  const [socketConnected, setSocketConnected] = useState(false);
  const upload = React.useRef(null);
  const [state, setState] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [avatarWidth, setAvatarWidth] = useState(60);
  const [selectedChat, setSelectedChat] = useState({});
  const [lastMessage, setLastMessage] = useState({});
  const [adminChats, setAdminChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [unitMessages, setUnitMessages] = useState([]);
  const [sortMessages, setSortMessages] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [newSortMess, setNewSortMess] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedChatId, setSelectedChatId] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [applicationData, setapplicationData] = useState({});
  const [isChatLoading, setIsChatloading] = useState(false);
  const location = useLocation();
  const { width } = useWindowSize();
  const { Option } = Select;
  const Search = Input.Search;
  const [searchParams] = useSearchParams();
  const plainOptions = ["Property", "Unit", "Name"];

  const [selectedFilter, setSelectedFilter] = useState(plainOptions);

  const onChangeFilter = (checkedValues) => {
    setSelectedFilter(checkedValues);
  };

  useEffect(() => {
    if (localStorage.getItem("viewAll")) {
    } else {
      localStorage.setItem("application", true);
    }
  }, [params, localStorage.getItem("toSave")]);

  useEffect(() => {
    if (localStorage.getItem("application")) {
      localStorage.removeItem("viewAll");
    }
  }, [params, localStorage.getItem("viewAll")]);

  // To download upon click
  const download = (e, name) => {
    const link = document.createElement("a");
    // link.setAttribute("download", "images.jfif");
    link.href = e;

    document.body.appendChild(link);
    link.click();
  };

  // Socket connection for real time data transfer
  useEffect(() => {
    socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.emit("setup", user?.id);
    socket.on("connected", () => setSocketConnected(true));
  }, [user]);

  // Set selected chat w.r.t Id
  // useEffect(() => {
  //   if (selectedChatId && adminChats?.length) {
  //     const selectedId = adminChats.filter((item) => {
  //       return item["id"] === selectedChatId;
  //     });
  //     setSelectedChat(selectedId[0]);
  //   }
  // }, [selectedChatId, adminChats]);

  // To set chat Id to locat storage
  useEffect(() => {
    if (selectedChat && Object.keys(selectedChat || {}).length) {
      // localStorage.setItem("chat_id", selectedChat.id);
      localStorage.setItem("prospect_user_id", selectedChat?.prospectUser?.id);
    }
  }, [selectedChat]);

  useEffect(() => {
    if (adminChats?.length) {
      if (localStorage.getItem("chat_id")) {
      } else {
        localStorage.setItem("chat_id", adminChats[0].id);
      }
    }
  }, [adminChats, selectedChat, localStorage.getItem("chat_id")]);

  // Loading admin side chats
  useEffect(() => {
    if (localStorage.getItem("org_id") && localStorage.getItem("user_id")) {
      if (localStorage.getItem("viewAll")) {
        setIsloading(true);
        dispatch(getAllChats()).then(({ payload }) => {
          setAdminChats(payload?.chats);
          setIsloading(false);
        });
      } else {
        setIsloading(true);
        dispatch(getOrgChats()).then(({ payload }) => {
          setIsloading(false);
          if (payload?.chats?.length) {
            localStorage.setItem("chat_id", payload?.chats[0].id);
            setMessages([]);
            setAdminChats(payload?.chats);
          } else {
            setAdminChats([]);
          }
        });
      }
    }
  }, [localStorage.getItem("viewAll"), localStorage.getItem("org_id")]);

  useEffect(() => {
    if (localStorage.getItem("chat_id")) {
      setIsChatloading(true);
      dispatch(getMessages()).then(({ payload }) => {
        setMessages(payload.messages);
        setapplicationData(payload.application);
        socket.emit("join chat", localStorage.getItem("chat_id"));
      });
    }
  }, [localStorage.getItem("chat_id")]);

  // useEffect(() => {
  //   if (localStorage.getItem("prospect_user_id")) {
  //     dispatch(getMessagesUnit()).then(({ payload }) => {
  //       const val = payload?.result.sort(function (a, b) {
  //         return (
  //           new Date(b.latestMessage.created_at) -
  //           new Date(a.latestMessage.created_at)
  //         );
  //       });
  //       setUnitMessages(val);
  //       setSelectedUnit(val[0]?.id);
  //       localStorage.setItem("chat_id", val[0]?.id);
  //     });
  //   }
  // }, [localStorage.getItem("prospect_user_id")]);

  useEffect(() => {
    if (socketConnected) {
      socket.on("message recieved", (newMessage) => {
        if (
          selectedChat &&
          localStorage.getItem("chat_id") === newMessage?.chat?.id &&
          user.id != newMessage?.chat?.latestMessage.sender
        ) {
          setMessages([...newMessage.message, ...messages]);
        }
      });
    }
  });

  useEffect(() => {
    if (messages?.length) {
      setIsChatloading(false);
      const val = messages.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      setSortMessages(val);
    } else {
      setIsChatloading(false);
      setSortMessages([]);
      setNewSortMess([]);
    }
  }, [messages]);

  useEffect(() => {
    if (adminChats?.length) {
      if (localStorage.getItem("chat_id")) {
        const filteredData = adminChats?.filter((item) => {
          return item.id === localStorage.getItem("chat_id");
        });
        setInputValue("");
        setSelectedChat(filteredData[0]);
      } else {
        const val = adminChats.sort(function (a, b) {
          return (
            new Date(b.latestMessage.created_at) -
            new Date(a.latestMessage.created_at)
          );
        });
        setInputValue("");
        setSelectedChat(val[0]);
      }
    }
  }, [adminChats, localStorage.getItem("chat_id")]);

  useEffect(() => {
    if (Object.keys(lastMessage)?.length && adminChats?.length) {
      let arr = [...adminChats];
      let abc = adminChats?.filter((item) => {
        return item["id"] === lastMessage.chat_id;
      });
      if (abc?.length) {
        abc[0].latestMessage.message = lastMessage.message;
        abc[0].latestMessage.created_at = lastMessage.created_at;
        adminChats.map((value, id) => {
          if (value.id === abc[0].id) {
            arr[id] = abc[0];
          }
        });
        setAdminChats([...arr]);
      }
    } else {
      setAdminChats([]);
    }
  }, [lastMessage]);

  // OnClick submit function
  const onFinish = () => {
    if (inputValue?.length && !state.length) {
      const value = {};
      value.chat_id = selectedChat?.id;
      value.replyTo = "";
      value.sender = user?.id;
      value.receiver = selectedChat?.prospect_id;
      value.message = inputValue;
      value.messageType = "Message";
      value.attachment_URL = "";

      setSortMessages((val) => [value, ...val]);
      socket.emit("typing", localStorage.getItem("chat_id"));
      dispatch(createMessage(value)).then(({ payload }) => {
        setLastMessage(payload.message[0]);
        socket.emit("new message", payload);
        socket.emit("stop typing", localStorage.getItem("chat_id"));
        socket.emit("send notification", payload.notification);
      });
      setInputValue("");
    } else if (state.length) {
      const value = {};
      value.chat_id = selectedChat?.id;
      value.replyTo = "";
      value.sender = user?.id;
      value.message = inputValue;
      value.receiver = selectedChat?.prospect_id;
      value.messageType = "Attachment";
      value.attachments = state;

      setSortMessages((val) => [value, ...val]);
      socket.emit("typing", localStorage.getItem("chat_id"));
      dispatch(createMessage(value)).then(({ payload }) => {
        socket.emit("new message", payload);
        socket.emit("stop typing", localStorage.getItem("chat_id"));
        socket.emit("send notification", payload.notification);
        setState([]);
        setLastMessage(payload.message[0]);
      });
      setInputValue("");
    }
  };

  // To set avatar width w.r.t screen width
  useEffect(() => {
    if (width < 1550) {
      setAvatarWidth(56);
    } else {
      setAvatarWidth(60);
    }
  }, [width, location.pathname]);

  // Reflecting typing message while typing
  const getInputValue = (e) => {
    setInputValue(e.target.value);
    if (e.target.value.length) {
      socket.emit("typing", localStorage.getItem("chat_id"));
    }
  };

  // To stop typing message with settimeout
  useEffect(() => {
    const handler = setTimeout(() => {
      socket.emit("stop typing", localStorage.getItem("chat_id"));
    }, 800);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  // Setting typing to true when socket is connected
  useEffect(() => {
    if (socketConnected) {
      socket.on("typing", (value) => {
        if (value === localStorage.getItem("chat_id")) {
          setIsTyping(true);
        }
      });
    }
  });

  // Stopping typing message when connected to socket
  useEffect(() => {
    if (socketConnected) {
      socket.on("stop typing", () => {
        setIsTyping(false);
      });
    }
  });

  // To get the current time
  const getTime = (date) => {
    const ms = new Date(date);
    let newTime = ms.toLocaleTimeString("en-US");
    let hour = newTime.split(":")[0];
    let amPm = newTime.split(" ")[1];
    let seconds = newTime.split(":")[2].replace(amPm, "");

    let noAmPm = newTime.replace(amPm, "");
    let noAmPmSeconds = newTime.replace(":" + seconds, "");
    let noSeconds = newTime.replace(":" + seconds, " ");

    if (parseInt(hour) < 9) {
      newTime = "0" + newTime;
      noAmPm = "0" + noAmPm;
      noSeconds = "0" + noSeconds;
      noAmPmSeconds = "0" + noAmPmSeconds;
    }

    return noAmPmSeconds;
  };

  const MAX_LENGTH = 4;

  // Onchange handler for file upload
  const fileChangeHandler = async (event) => {
    // const imgType =
    if (Array.from(event.target.files).length > MAX_LENGTH) {
      event.preventDefault();
      alert(`Cannot upload files more than ${MAX_LENGTH}`);
      return;
    }
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setState((s) => [
        ...s,
        {
          file: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
          ext: img.type,
        },
      ]);
    };

    Array(...event.target.files).length &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          return await newFun(file);
        })
      ));
  };

  //onchange handler to
  const fileChangeHandlerDrag = async (files) => {
    if (Array.from(files).length > MAX_LENGTH) {
      alert(`Cannot upload files more than ${MAX_LENGTH}`);
      files.preventDefault();
      return;
    }

    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    await Promise.all(
      Array(...files).map(async (img) => {
        const convertImg = await convertToBase64(img);
        setState((s) => [
          ...s,
          {
            file: convertImg,
            path: URL.createObjectURL(img),
            name: img.name,
            ext: img.type,
          },
        ]);
      })
    );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      fileChangeHandlerDrag(files);
    }
  };

  const drop = React.useRef(null);

  // React.useEffect(() => {
  //   drop.current.addEventListener("dragover", handleDragOver);
  //   drop.current.addEventListener("drop", handleDrop);
  // }, []);

  useEffect(() => {
    if (sortMessages?.length) {
      const arr = [];
      let arr2 = [];
      sortMessages.map((item) => {
        if (item.attachment_URL) {
          if (arr2?.length) {
            const lastArr = arr2[arr2.length - 1];
            if (lastArr.sender != item.sender) {
              arr.push(arr2);
              arr2 = [];
            }
          }
          arr2.push(item);
        } else {
          if (arr2?.length) {
            arr.push(arr2);
            arr2 = [];
          }
          arr.push(item);
        }
      });
      setNewSortMess(arr);
    }
  }, [sortMessages]);

  useEffect(() => {
    if (searchParams.get("chatId")) {
      setSelectedChatId(searchParams.get("chatId"));
    }
  }, []);

  // Function to open sider
  const msgOpenSider = (e) => {
    e.preventDefault();
    let x = document.getElementsByClassName("msgOpenSider");
    if (x.length > 0) {
      document.body.classList.remove("msgOpenSider");
    } else {
      document.body.classList.add("msgOpenSider");
    }
  };

  // Function to open message sider
  const openSiderDivider = (e) => {
    e.preventDefault();
    let x = document.getElementsByClassName("msgOpenSider");
    document.body.classList.toggle("msgOpenSider");
  };

  const style = {
    height: 40,
    width: 40,
    lineHeight: "40px",
    borderRadius: "50%",
    backgroundColor: "#4160AC90",
    color: "#fff",
    textAlign: "center",
    fontSize: 40,
  };
  const [stringSort, setStringSort] = useState(8);

  // String sort w.r.t screen width
  useEffect(() => {
    if (width < 768) {
      setStringSort(8);
    } else {
      setStringSort(20);
    }
  }, [width, location.pathname]);

  // Slipt function upon exceeding length 28
  const slpitFunc = (value) => {
    if (value?.length > 28) {
      return value.slice(0, stringSort) + "..." + value.slice(-8);
    } else {
      return value;
    }
  };

  // for change unit (select unit)
  const selectChange = (value) => {
    setSelectedUnit(value);
    localStorage.setItem("chat_id", value);
    const sele = unitMessages?.filter((item) => {
      return item["id"] === value;
    });
    setSelectedChat(sele[0]);
  };
  const [searchBarText, setSearchBarText] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const searcher = new FuzzySearch(
    adminChats,
    [
      "prospectUser.firstName",
      "prospectUser.lastName",
      "prospectUser.email",
      "latestMessage",
      "unitDetails.unit_name",
    ],
    {
      caseSensitive: true,
    }
  );

  const onSearch = (e) => {
    setSearchBarText(e.target.value);
    const result = searcher.search(e.target.value);
    setSearchResult(result);
  };

  const emitEmpty = () => {
    setSearchBarText("");
    setSearchResult([]);
  };

  const suffix = <MdCancel onClick={() => emitEmpty()} />;

  // To navigate to Application details page
  const navigateParam = (id) => {
    navigate({
      pathname: `/applications`,
      search: createSearchParams({
        unitId: id,
      }).toString(),
    });
  };

  // Get message time and date (Date formate)
  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day} ${month}, ${year}`;
  }

  // Get date converter
  const dateConverter = (dateValue, month) => {
    var date = new Date(dateValue);

    return formatDate(date);
  };

  return (
    <MessagesWrap>
      {isLoading ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : adminChats?.length ? (
        <>
          <MsgWrap>
            <Container
              paddingSm="40px 40px 0 265px"
              padding="50px 50px 0 330px"
            >
              <Row gutter={[5, 5]}>
                <Col xs={0} sm={0} md={8} lg={11} xl={7} xxl={8}>
                  <LeftWrap>
                    <div className="message__box">
                      <TableOneWrap
                        style={{ width: "100%" }}
                        searchHeight="35px"
                        sHeight="45px"
                        boxHeight="0"
                      >
                        <Search
                          ref={(ele) => searchBarText === ele}
                          suffix={suffix}
                          onChange={onSearch}
                          placeholder="Search..."
                          value={searchBarText}
                          onPressEnter={onSearch}
                        />
                      </TableOneWrap>
                      <Popover
                        placement="bottom"
                        trigger="click"
                        // onClick={(event) => {
                        //   event.stopPropagation();
                        // }}
                        content={
                          <FilterBtnPopover>
                            <Checkbox.Group
                              options={plainOptions}
                              defaultValue={["Property", "Unit", "Name"]}
                              onChange={onChangeFilter}
                            />
                          </FilterBtnPopover>
                        }
                      >
                        <FilterBtn>
                          <MsgFilterSvg />
                        </FilterBtn>
                      </Popover>
                    </div>
                    <MessagerWrap>
                      {searchResult?.length || searchBarText?.length ? (
                        <>
                          {[...searchResult]?.map((item, id) => (
                            <>
                              <AdminMessage
                                key={id}
                                background={
                                  selectedChat.id === item.id
                                    ? "#ECF2FF"
                                    : "#ffffff"
                                }
                                onClick={() => {
                                  setSelectedUnit("");
                                  setInputValue("");

                                  setState([]);
                                  setSelectedChat(item);
                                  localStorage.setItem("chat_id", item.id);
                                }}
                              >
                                <UserAvatar
                                  className="userName__icon"
                                  size={avatarWidth}
                                  src={item?.prospectUser?.image?.path}
                                  fullName={`${
                                    item.prospectUser.firstName || ""
                                  } ${item.prospectUser.lastName || ""}`}
                                />
                                <MessageName>
                                  {selectedFilter?.includes("Name") && (
                                    <h2>
                                      {upperCase(item.prospectUser.firstName)}{" "}
                                      {upperCase(item.prospectUser.lastName)}
                                      <Badge
                                        count={item?.unseenMessageCount}
                                      ></Badge>
                                      {/* <Divider type="vertical" /> {upperCase(item.unit_name)} */}
                                    </h2>
                                  )}

                                  <h3>
                                    {selectedFilter?.includes("Property")
                                      ? upperCase(item?.unitDetails?.unit_name)
                                      : ""}{" "}
                                    {selectedFilter?.includes("Unit")
                                      ? `- ${upperCase(
                                          item?.unitDetails?.unit_name
                                        )}`
                                      : ""}
                                  </h3>

                                  {item?.latestMessage?.message ? (
                                    <p>
                                      {slpitFunc(item?.latestMessage?.message)}
                                    </p>
                                  ) : (
                                    <p>Chat Joined</p>
                                  )}
                                </MessageName>
                              </AdminMessage>
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          {[...adminChats]?.map((item, id) => (
                            <>
                              <AdminMessage
                                key={id}
                                background={
                                  selectedChat?.id === item.id
                                    ? "#ECF2FF"
                                    : "#ffffff"
                                }
                                onClick={() => {
                                  setSelectedUnit("");
                                  setInputValue("");
                                  setState([]);
                                  setSelectedChat(item);
                                  localStorage.setItem("chat_id", item.id);
                                }}
                              >
                                <UserAvatar
                                  className="userName__icon"
                                  size={avatarWidth}
                                  src={item?.prospectUser?.image?.path}
                                  fullName={`${
                                    item.prospectUser.firstName || ""
                                  } ${item.prospectUser.lastName || ""}`}
                                />
                                <MessageName>
                                  {selectedFilter?.includes("Name") && (
                                    <h2>
                                      {upperCase(item.prospectUser.firstName)}{" "}
                                      {upperCase(item.prospectUser.lastName)}
                                      <Badge
                                        count={item?.unseenMessageCount}
                                      ></Badge>
                                      {/* <Divider type="vertical" /> {upperCase(item.unit_name)} */}
                                    </h2>
                                  )}

                                  <h3>
                                    {selectedFilter?.includes("Property")
                                      ? upperCase(item.propertyName)
                                      : ""}{" "}
                                    {selectedFilter?.includes("Unit")
                                      ? `- ${upperCase(
                                          item?.unitDetails?.unit_name
                                        )}`
                                      : ""}
                                  </h3>

                                  {item?.latestMessage?.message ? (
                                    <p>
                                      {slpitFunc(item?.latestMessage?.message)}
                                    </p>
                                  ) : (
                                    <p>Chat Joined</p>
                                  )}
                                </MessageName>
                              </AdminMessage>
                            </>
                          ))}
                        </>
                      )}
                      {/* {[...adminChats]?.map((item, id) => (
                        <>
                          <AdminMessage
                            key={id}
                            background={
                              selectedChat.prospect_id ===
                              item.prospect_id
                                ? "#ECF2FF"
                                : "#ffffff"
                            }
                            onClick={() => {
                              setSelectedUnit("");
                              setState([]);
                              setSelectedChat(item);
                              localStorage.setItem("chat_id", item.id);
                            }}
                          >
                            <UserAvatar
                              className="userName__icon"
                              size={avatarWidth}
                              src={item?.prospectUser?.image?.path}
                              fullName={`${item.prospectUser.firstName || ""} ${
                                item.prospectUser.lastName || ""
                              }`}
                            />
                            <MessageName>
                              {selectedFilter?.includes("Name") && (
                                <h2>
                                  {upperCase(item.prospectUser.firstName)}{" "}
                                  {upperCase(item.prospectUser.lastName)}
                                  <Badge
                                    count={item?.unseenMessageCount}
                                  ></Badge>
                                </h2>
                              )}

                              {selectedFilter?.includes("Property") && (
                                <h3>{upperCase(item.unit_name)}</h3>
                              )}
                              {item?.latestMessage?.message ? (
                                <p>{slpitFunc(item?.latestMessage?.message)}</p>
                              ) : (
                                <p>Chat Joined</p>
                              )}
                            </MessageName>
                          </AdminMessage>
                        </>
                      ))} */}
                    </MessagerWrap>
                  </LeftWrap>
                </Col>
                <Col xs={0} sm={0} md={16} lg={13} xl={17} xxl={16}>
                  <Row>
                    <Col span={24}>
                      <TopMessageBar>
                        <UserAvatar
                          className="user__icon"
                          src={selectedChat?.prospectUser?.image?.path}
                          fullName={`${
                            selectedChat?.prospectUser?.firstName || ""
                          } ${selectedChat?.prospectUser?.lastName || ""}`}
                        />

                        <Row
                          justify="space-between"
                          style={{
                            width: "92%",
                            alignItems: "center",
                            marginLeft: "22px",
                          }}
                        >
                          <Col span={12}>
                            <h1>
                              {upperCase(selectedChat?.prospectUser?.firstName)}{" "}
                              {upperCase(selectedChat?.prospectUser?.lastName)}
                            </h1>
                            <span>{selectedChat?.unitDetails?.unit_name}</span>
                          </Col>
                          {applicationData &&
                          Object.values(applicationData)?.length ? (
                            <Col
                              span={12}
                              style={{ justifyContent: "end", display: "flex" }}
                            >
                              <AddNewApr
                                bgColor={"#777777"}
                                fw={"400"}
                                displayView="none"
                                onClick={() => {
                                  navigateParam(applicationData?.id);
                                }}
                              >
                                View Application
                              </AddNewApr>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </TopMessageBar>
                      {/* {unitMessages?.length ? (
                        <SelectUnitWrap
                          placeholder="Select Propety"
                          onChange={selectChange}
                          defaultValue={
                            selectedUnit
                              ? selectedUnit
                              : [...unitMessages][0]?.id
                          }
                          value={
                            selectedUnit
                              ? selectedUnit
                              : [...unitMessages][0]?.id
                          }
                          suffixIcon={<IoIosArrowDown />}
                          // allowClear
                        >
                          {unitMessages?.map((item, idx) => (
                            <Option key={idx} value={item.id}>
                              <OptionContent>
                                <AvenewImg
                                  style={{
                                    background: "#eeeeee",
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "50%",
                                    marginRight: "15px",
                                  }}
                                />
                                <span className="unit_name">
                                  {Capitalize(item.unitDetails.unitType)}
                                </span>
                                <Divider type="vertical" />
                                <span className="unit_name">
                                  {Capitalize(item.unitDetails.unit_name)}
                                </span>
                              </OptionContent>
                            </Option>
                          ))}
                        </SelectUnitWrap>
                      ) : (
                        ""
                      )} */}
                      <ChatBox>
                        <div style={{ display: "flex" }}>
                          {state.map((img, idx) => (
                            <StyledUploadPreviewWrapper isPhoto={state.length}>
                              {attachmentType.includes(img.ext) ? (
                                <object
                                  data={img.path}
                                  type="application/pdf"
                                  width="100%"
                                  // height="100%"
                                  height="400"
                                >
                                  <p>
                                    Alternative text - include a link{" "}
                                    <a href={img.path}>to the PDF!</a>
                                  </p>
                                </object>
                              ) : (
                                <ImagePreview
                                  key={idx}
                                  src={
                                    img.path ? img.path : imagePath(img.path)
                                  }
                                  height={95}
                                  width={95}
                                  onDelete={() => {
                                    const st = [...state];
                                    st.splice(idx, 1);
                                    setState(st);
                                  }}
                                  isCoverDisProp={false}
                                />
                              )}
                            </StyledUploadPreviewWrapper>
                          ))}
                        </div>
                        {isTyping ? (
                          <>
                            <LeftMessage background="#eeeeee">
                              <div className="typing">
                                <div className="typing__dot"></div>
                                <div className="typing__dot"></div>
                                <div className="typing__dot"></div>
                              </div>
                            </LeftMessage>
                          </>
                        ) : (
                          ""
                        )}

                        {isChatLoading ? (
                          <Spin className="ant__spin" size="large" />
                        ) : (
                          <>
                            {newSortMess?.length ? (
                              <>
                                {newSortMess?.map((item, id) => (
                                  <>
                                    <div key={id}>
                                      {item.sender === user?.id ? (
                                        <>
                                          {Array.isArray(item) ? (
                                            <>
                                              <RightMessage>
                                                <Row
                                                  style={{ width: "100%" }}
                                                  gutter={[2, 2]}
                                                >
                                                  {item.map((img, idx) => (
                                                    <>
                                                      {img.sender ===
                                                      user?.id ? (
                                                        <>
                                                          {attachmentType.includes(
                                                            img.attachment_ext ||
                                                              img.ext
                                                          ) ? (
                                                            <AtchBox
                                                              onClick={() =>
                                                                download(
                                                                  img.attachment_URL,
                                                                  img.message
                                                                )
                                                              }
                                                            >
                                                              <CgFileDocument />
                                                              <h5>
                                                                {slpitFunc(
                                                                  img.message ||
                                                                    img.name
                                                                )}
                                                              </h5>
                                                            </AtchBox>
                                                          ) : (
                                                            <>
                                                              {idx < 3 ? (
                                                                <Col
                                                                  span={
                                                                    item?.length ===
                                                                    1
                                                                      ? "24"
                                                                      : "12"
                                                                  }
                                                                >
                                                                  <StyledUploadPreviewWrapper
                                                                    isPhoto={
                                                                      item.length
                                                                    }
                                                                  >
                                                                    <ImagePreview
                                                                      key={idx}
                                                                      src={
                                                                        img.attachment_URL
                                                                      }
                                                                      downloadImgUrl={
                                                                        img.attachment_URL
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        padding:
                                                                          "2px",
                                                                        minHeight:
                                                                          "200px",
                                                                        maxHeight:
                                                                          "200px",
                                                                      }}
                                                                      isCoverDisProp={
                                                                        false
                                                                      }
                                                                      isDeleteDisProp={
                                                                        false
                                                                      }
                                                                      isDownload={
                                                                        true
                                                                      }
                                                                    />
                                                                  </StyledUploadPreviewWrapper>
                                                                </Col>
                                                              ) : (
                                                                <Col span="5">
                                                                  <StyledUploadPreviewWrapper
                                                                    isPhoto={
                                                                      item.length
                                                                    }
                                                                  >
                                                                    <ImagePreview
                                                                      key={idx}
                                                                      src={
                                                                        img.path
                                                                          ? img.path
                                                                          : imagePath(
                                                                              img.path
                                                                            )
                                                                      }
                                                                      downloadImgUrl={
                                                                        img.path
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        padding:
                                                                          "2px",
                                                                        minHeight:
                                                                          "200px",
                                                                        maxHeight:
                                                                          "200px",
                                                                      }}
                                                                      isDeleteDisProp={
                                                                        false
                                                                      }
                                                                      isCoverDisProp={
                                                                        false
                                                                      }
                                                                      isDownload={
                                                                        true
                                                                      }
                                                                    />
                                                                  </StyledUploadPreviewWrapper>
                                                                </Col>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  ))}
                                                </Row>
                                              </RightMessage>
                                            </>
                                          ) : item?.attachments ? (
                                            <>
                                              <RightMessage width={"45%"}>
                                                <Row
                                                  style={{ width: "100%" }}
                                                  gutter={[2, 2]}
                                                >
                                                  {item?.attachments.map(
                                                    (img, idx) => (
                                                      <>
                                                        {attachmentType.includes(
                                                          img.attachment_ext ||
                                                            img.ext
                                                        ) ? (
                                                          <AtchBox
                                                            onClick={() =>
                                                              download(
                                                                img.attachment_URL,
                                                                img.message
                                                              )
                                                            }
                                                          >
                                                            <CgFileDocument />
                                                            <h5>
                                                              {slpitFunc(
                                                                img.message ||
                                                                  img.name
                                                              )}
                                                            </h5>
                                                          </AtchBox>
                                                        ) : (
                                                          <>
                                                            {idx < 3 ? (
                                                              <Col
                                                                span={
                                                                  item
                                                                    ?.attachments
                                                                    ?.length ===
                                                                  1
                                                                    ? "24"
                                                                    : "12"
                                                                }
                                                              >
                                                                <StyledUploadPreviewWrapper
                                                                  isPhoto={
                                                                    item
                                                                      ?.attachments
                                                                      ?.length
                                                                  }
                                                                >
                                                                  <ImagePreview
                                                                    key={idx}
                                                                    src={
                                                                      img.path
                                                                    }
                                                                    downloadImgUrl={
                                                                      img.path
                                                                    }
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      padding:
                                                                        "2px",
                                                                      minHeight:
                                                                        "200px",
                                                                      maxHeight:
                                                                        "200px",
                                                                    }}
                                                                    isCoverDisProp={
                                                                      false
                                                                    }
                                                                    isDeleteDisProp={
                                                                      false
                                                                    }
                                                                    isDownload={
                                                                      true
                                                                    }
                                                                  />
                                                                </StyledUploadPreviewWrapper>
                                                              </Col>
                                                            ) : (
                                                              <Col span="12">
                                                                <StyledUploadPreviewWrapper
                                                                  isPhoto={
                                                                    item
                                                                      ?.attachments
                                                                      ?.length
                                                                  }
                                                                >
                                                                  <ImagePreview
                                                                    key={idx}
                                                                    src={
                                                                      img.path
                                                                    }
                                                                    downloadImgUrl={
                                                                      img.path
                                                                    }
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      padding:
                                                                        "2px",
                                                                      minHeight:
                                                                        "200px",
                                                                      maxHeight:
                                                                        "200px",
                                                                    }}
                                                                    isDeleteDisProp={
                                                                      false
                                                                    }
                                                                    isCoverDisProp={
                                                                      false
                                                                    }
                                                                    isDownload={
                                                                      true
                                                                    }
                                                                  />
                                                                </StyledUploadPreviewWrapper>
                                                              </Col>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )
                                                  )}
                                                </Row>
                                                <p>
                                                  {getTime(
                                                    item.created_at
                                                      ? item.created_at
                                                      : new Date()
                                                  )}
                                                </p>
                                              </RightMessage>
                                              {item.message ? (
                                                <RightMessage>
                                                  <h2>{item.message}</h2>
                                                  <p>
                                                    {getTime(
                                                      item.created_at
                                                        ? item.created_at
                                                        : new Date()
                                                    )}
                                                  </p>
                                                </RightMessage>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            <RightMessage>
                                              <h2>{item.message}</h2>
                                              <p>
                                                {getTime(
                                                  item.created_at
                                                    ? item.created_at
                                                    : new Date()
                                                )}
                                              </p>
                                            </RightMessage>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {Array.isArray(item) ? (
                                            <>
                                              {item[0]?.sender === user?.id ? (
                                                <RightMessage width={"45%"}>
                                                  <Row
                                                    style={{ width: "100%" }}
                                                    gutter={[2, 2]}
                                                  >
                                                    {item.map((img, idx) => (
                                                      <>
                                                        {img.sender ===
                                                        user?.id ? (
                                                          <>
                                                            {attachmentType.includes(
                                                              img.attachment_ext ||
                                                                img.ext
                                                            ) ? (
                                                              <AtchBox
                                                                onClick={() =>
                                                                  download(
                                                                    img.attachment_URL,
                                                                    img.message
                                                                  )
                                                                }
                                                              >
                                                                <CgFileDocument />
                                                                <h5>
                                                                  {slpitFunc(
                                                                    img.message ||
                                                                      img.name
                                                                  )}
                                                                </h5>
                                                              </AtchBox>
                                                            ) : (
                                                              <>
                                                                {idx < 3 ? (
                                                                  <Col
                                                                    key={idx}
                                                                    span={
                                                                      item?.length ===
                                                                      1
                                                                        ? "24"
                                                                        : "12"
                                                                    }
                                                                  >
                                                                    <StyledUploadPreviewWrapper
                                                                      isPhoto={
                                                                        item.length
                                                                      }
                                                                    >
                                                                      <ImagePreview
                                                                        key={
                                                                          idx
                                                                        }
                                                                        src={
                                                                          img.attachment_URL
                                                                        }
                                                                        downloadImgUrl={
                                                                          img.attachment_URL
                                                                        }
                                                                        style={{
                                                                          width:
                                                                            "100%",
                                                                          padding:
                                                                            "2px",
                                                                          minHeight:
                                                                            "200px",
                                                                          maxHeight:
                                                                            "200px",
                                                                        }}
                                                                        isCoverDisProp={
                                                                          false
                                                                        }
                                                                        isDeleteDisProp={
                                                                          false
                                                                        }
                                                                        isDownload={
                                                                          true
                                                                        }
                                                                      />
                                                                    </StyledUploadPreviewWrapper>
                                                                  </Col>
                                                                ) : (
                                                                  <Col span="12">
                                                                    <StyledUploadPreviewWrapper
                                                                      isPhoto={
                                                                        item.length
                                                                      }
                                                                    >
                                                                      <ImagePreview
                                                                        key={
                                                                          idx
                                                                        }
                                                                        src={
                                                                          img.attachment_URL
                                                                        }
                                                                        downloadImgUrl={
                                                                          img.attachment_URL
                                                                        }
                                                                        style={{
                                                                          width:
                                                                            "100%",
                                                                          padding:
                                                                            "2px",
                                                                          minHeight:
                                                                            "200px",
                                                                          maxHeight:
                                                                            "200px",
                                                                        }}
                                                                        isDeleteDisProp={
                                                                          false
                                                                        }
                                                                        isCoverDisProp={
                                                                          false
                                                                        }
                                                                        isDownload={
                                                                          true
                                                                        }
                                                                      />
                                                                    </StyledUploadPreviewWrapper>
                                                                  </Col>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </>
                                                    ))}
                                                  </Row>
                                                  <p>
                                                    {getTime(
                                                      item.created_at
                                                        ? item.created_at
                                                        : new Date()
                                                    )}
                                                  </p>
                                                </RightMessage>
                                              ) : (
                                                <LeftMessage width={"45%"}>
                                                  <Row
                                                    style={{ width: "100%" }}
                                                    gutter={[2, 2]}
                                                  >
                                                    {item.map((img, idx) => (
                                                      <>
                                                        {attachmentType.includes(
                                                          img.attachment_ext ||
                                                            img.ext
                                                        ) ? (
                                                          <AtchBox
                                                            fontColor="#ffffff"
                                                            onClick={() =>
                                                              download(
                                                                img.attachment_URL,
                                                                img.message
                                                              )
                                                            }
                                                          >
                                                            <CgFileDocument />
                                                            <h5>
                                                              {slpitFunc(
                                                                img.message ||
                                                                  img.name
                                                              )}
                                                            </h5>
                                                          </AtchBox>
                                                        ) : (
                                                          <>
                                                            {idx < 3 ? (
                                                              <Col
                                                                key={idx}
                                                                span={
                                                                  item.length ===
                                                                  1
                                                                    ? "24"
                                                                    : "12"
                                                                }
                                                              >
                                                                <StyledUploadPreviewWrapper
                                                                  isPhoto={
                                                                    item.length
                                                                  }
                                                                >
                                                                  <ImagePreview
                                                                    key={idx}
                                                                    src={
                                                                      img.attachment_URL
                                                                    }
                                                                    downloadImgUrl={
                                                                      img.attachment_URL
                                                                    }
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      padding:
                                                                        "2px",
                                                                      minHeight:
                                                                        "200px",
                                                                      maxHeight:
                                                                        "200px",
                                                                    }}
                                                                    isCoverDisProp={
                                                                      false
                                                                    }
                                                                    isDeleteDisProp={
                                                                      false
                                                                    }
                                                                    isDownload={
                                                                      true
                                                                    }
                                                                  />
                                                                </StyledUploadPreviewWrapper>
                                                              </Col>
                                                            ) : (
                                                              <Col span="12">
                                                                <StyledUploadPreviewWrapper
                                                                  isPhoto={
                                                                    item.length
                                                                  }
                                                                >
                                                                  <ImagePreview
                                                                    key={idx}
                                                                    src={
                                                                      img.attachment_URL
                                                                    }
                                                                    downloadImgUrl={
                                                                      img.attachment_URL
                                                                    }
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      padding:
                                                                        "2px",
                                                                      minHeight:
                                                                        "200px",
                                                                      maxHeight:
                                                                        "200px",
                                                                    }}
                                                                    isDeleteDisProp={
                                                                      false
                                                                    }
                                                                    isCoverDisProp={
                                                                      false
                                                                    }
                                                                    isDownload={
                                                                      true
                                                                    }
                                                                  />
                                                                </StyledUploadPreviewWrapper>
                                                              </Col>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    ))}
                                                  </Row>
                                                  <p>
                                                    {getTime(
                                                      item.created_at
                                                        ? item.created_at
                                                        : new Date()
                                                    )}
                                                  </p>
                                                </LeftMessage>
                                              )}
                                            </>
                                          ) : item?.attachments ? (
                                            <>
                                              {item?.attachments.map(
                                                (img, idx) => (
                                                  <>
                                                    <RightMessage
                                                      key={idx}
                                                      width={"45%"}
                                                    >
                                                      <Row
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                        gutter={[2, 2]}
                                                      >
                                                        {attachmentType.includes(
                                                          img.attachment_ext ||
                                                            img.ext
                                                        ) ? (
                                                          <AtchBox
                                                            onClick={() =>
                                                              download(
                                                                img.attachment_URL,
                                                                img.message
                                                              )
                                                            }
                                                          >
                                                            <CgFileDocument />
                                                            <h5>
                                                              {slpitFunc(
                                                                img.message ||
                                                                  img.name
                                                              )}
                                                            </h5>
                                                          </AtchBox>
                                                        ) : (
                                                          <>
                                                            {idx < 3 ? (
                                                              <Col
                                                                span={
                                                                  item
                                                                    ?.attachments
                                                                    ?.length ===
                                                                  1
                                                                    ? "24"
                                                                    : "12"
                                                                }
                                                              >
                                                                <StyledUploadPreviewWrapper
                                                                  isPhoto={
                                                                    item
                                                                      ?.attachments
                                                                      ?.length
                                                                  }
                                                                >
                                                                  <ImagePreview
                                                                    key={idx}
                                                                    src={
                                                                      img.path
                                                                    }
                                                                    downloadImgUrl={
                                                                      img.path
                                                                    }
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      padding:
                                                                        "2px",
                                                                      minHeight:
                                                                        "200px",
                                                                      maxHeight:
                                                                        "200px",
                                                                    }}
                                                                    isCoverDisProp={
                                                                      false
                                                                    }
                                                                    isDeleteDisProp={
                                                                      false
                                                                    }
                                                                    isDownload={
                                                                      true
                                                                    }
                                                                  />
                                                                </StyledUploadPreviewWrapper>
                                                              </Col>
                                                            ) : (
                                                              <Col span="12">
                                                                <StyledUploadPreviewWrapper
                                                                  isPhoto={
                                                                    item
                                                                      ?.attachments
                                                                      ?.length
                                                                  }
                                                                >
                                                                  <ImagePreview
                                                                    key={idx}
                                                                    src={
                                                                      img.path
                                                                    }
                                                                    downloadImgUrl={
                                                                      img.attachment_URL
                                                                    }
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      padding:
                                                                        "2px",
                                                                      minHeight:
                                                                        "200px",
                                                                      maxHeight:
                                                                        "200px",
                                                                    }}
                                                                    isDeleteDisProp={
                                                                      false
                                                                    }
                                                                    isCoverDisProp={
                                                                      false
                                                                    }
                                                                    isDownload={
                                                                      true
                                                                    }
                                                                  />
                                                                </StyledUploadPreviewWrapper>
                                                              </Col>
                                                            )}
                                                          </>
                                                        )}
                                                      </Row>
                                                      <p>
                                                        {getTime(
                                                          item.created_at
                                                            ? item.created_at
                                                            : new Date()
                                                        )}
                                                      </p>
                                                    </RightMessage>
                                                  </>
                                                )
                                              )}
                                            </>
                                          ) : (
                                            <LeftMessage>
                                              <h2>{item.message}</h2>
                                              <p>
                                                {getTime(
                                                  item.created_at
                                                    ? item.created_at
                                                    : new Date()
                                                )}
                                              </p>
                                            </LeftMessage>
                                          )}
                                        </>
                                      )}
                                    </div>

                                    {/* {item.created_at ? (
                            <>
                              {id === newSortMess?.length - 1 ? (
                                <DateWrap>
                                  <span>
                                    {dateConverter(item.created_at)}
                                  </span>
                                </DateWrap>
                              ) : (
                                <>
                                  {id === 0 ? (
                                    ""
                                  ) : (
                                    <>
                                      {new Date(
                                        sortMessages[id - 1]?.created_at
                                          ? sortMessages[id - 1]
                                              ?.created_at
                                          : item.created_at
                                      )
                                        .toISOString()
                                        .slice(0, 10) ===
                                      new Date(item.created_at)
                                        .toISOString()
                                        .slice(0, 10) ? (
                                        ""
                                      ) : (
                                        <DateWrap>
                                          <span>
                                            {dateConverter(
                                              item.created_at
                                            )}
                                          </span>
                                        </DateWrap>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            ""
                          )} */}

                                    {item.created_at ? (
                                      <>
                                        {id === newSortMess?.length - 1 ? (
                                          <DateWrap>
                                            <span>
                                              {dateConverter(item.created_at)}
                                            </span>
                                          </DateWrap>
                                        ) : (
                                          <>
                                            {id === 0 ? (
                                              ""
                                            ) : (
                                              <>
                                                {new Date(
                                                  sortMessages[id - 1]
                                                    ?.created_at
                                                    ? sortMessages[id - 1]
                                                        ?.created_at
                                                    : item.created_at
                                                )
                                                  .toISOString()
                                                  .slice(0, 10) ===
                                                new Date(item.created_at)
                                                  .toISOString()
                                                  .slice(0, 10) ? (
                                                  ""
                                                ) : (
                                                  <DateWrap>
                                                    <span>
                                                      {dateConverter(
                                                        item.created_at
                                                      )}
                                                    </span>
                                                  </DateWrap>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ))}
                              </>
                            ) : (
                              <NoMessageWrap>
                                <NoMessage />
                                <h2>No Message Available</h2>
                              </NoMessageWrap>
                            )}
                          </>
                        )}
                      </ChatBox>
                      <>
                        <TextBox>
                          <input
                            accept="application/pdf, application/vnd.ms-excel, image/*"
                            id="myInput"
                            multiple={"multiple"}
                            type="file"
                            ref={upload}
                            style={{ display: "none" }}
                            onChange={fileChangeHandler}
                            max="5"
                          />
                          {/* <SendEmail ref={drop}>
                          <Input.Group compact>
                            <Input.TextArea
                              className="description__box"
                              placeholder="Type your message"
                            />
                          </Input.Group>
                        </SendEmail>

                        <Button
                          onClick={() => onFinish()}
                          disabled={
                            state?.length ? !state?.length : !inputValue.length
                          }
                        >
                          <BiSend />
                        </Button>

                        <AttachMent>
                          <AttachmentSvg />
                        </AttachMent>
                        <AttachMent onClick={(e) => upload.current.click()}>
                          <FiPaperclip />
                        </AttachMent> */}

                          {/* <AttachMent>
                          <AttachmentSvg />
                        </AttachMent>
                        <AttachMent onClick={(e) => upload.current.click()}>
                          <FiPaperclip />
                        </AttachMent> */}

                          {/* <SendEmail ref={drop}>
                          <Input.Group compact>
                            <Input
                              // disabled={state?.length}
                              onChange={getInputValue}
                              placeholder="Write a Message"
                              style={{
                                width: "calc(100% - 52px)",
                                height: "50px",
                              }}
                              onPressEnter={() => onFinish()}
                              value={inputValue}
                            />
                            <Button
                              onClick={() => onFinish()}
                              disabled={
                                state?.length
                                  ? !state?.length
                                  : !inputValue.length
                              }
                            >
                              <BiSend />
                            </Button>
                          </Input.Group>
                        </SendEmail> */}

                          <SendEmail ref={drop}>
                            {/* <Input.Group compact> */}
                            <Input.TextArea
                              // disabled={state?.length}
                              onChange={getInputValue}
                              placeholder="Type Your message"
                              style={
                                {
                                  // width: "calc(100% - 52px)",
                                  // height: "120px",
                                }
                              }
                              onPressEnter={() => onFinish()}
                              value={inputValue}
                            />
                            {/* </Input.Group> */}
                          </SendEmail>
                          <Row
                            gutter={[10, 10]}
                            style={{
                              marginLeft: "10px",
                              // paddingTop: "10px",
                            }}
                          >
                            <Col span={20}>
                              <MsgBtn
                                onClick={() => onFinish()}
                                disabled={
                                  state?.length
                                    ? !state?.length
                                    : !inputValue.length
                                }
                              >
                                Send <BiSend />
                              </MsgBtn>
                            </Col>
                            <Col span={24}>
                              <Row>
                                <Col>
                                  {/* <AttachMent>
                                    <AttachmentSvg />
                                  </AttachMent> */}
                                </Col>
                                <Col>
                                  <AttachMent
                                    onClick={(e) => upload.current.click()}
                                  >
                                    <FiPaperclip />
                                  </AttachMent>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </TextBox>
                      </>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </MsgWrap>
          <Row style={{ background: "#fff" }}>
            <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
              <ActiveName br="0" bs="none" onClick={msgOpenSider}>
                <UserAvatar
                  className="userName__icon"
                  size={50}
                  src={selectedChat?.prospectUser?.image?.path}
                  fullName={`${selectedChat?.prospectUser?.firstName || ""} ${
                    selectedChat?.prospectUser?.lastName || ""
                  }`}
                />
                <ActiveMessageName>
                  <h2>
                    {upperCase(selectedChat?.prospectUser?.firstName)}{" "}
                    {upperCase(selectedChat?.prospectUser?.lastName)}
                    {/* <Divider type="vertical" /> reenprenk Hostel */}
                  </h2>
                  <IoIosArrowDown />
                </ActiveMessageName>
              </ActiveName>
              {/* <UnitDetails>
                <img src="/images/property1.png" />
                <h2>Sub unit 2</h2>
              </UnitDetails> */}

              <ActiveChat>
                <ChatBox>
                  <div style={{ display: "flex" }}>
                    {state.map((img, idx) => (
                      <StyledUploadPreviewWrapper isPhoto={state.length}>
                        <ImagePreview
                          key={idx}
                          src={img.path ? img.path : imagePath(img.path)}
                          height={95}
                          width={95}
                          onDelete={() => {
                            const st = [...state];
                            st.splice(idx, 1);
                            setState(st);
                          }}
                          isCoverDisProp={false}
                        />
                      </StyledUploadPreviewWrapper>
                    ))}
                  </div>
                  {isTyping ? (
                    <LeftMessage background="#eeeeee">
                      <div className="typing">
                        <div className="typing__dot"></div>
                        <div className="typing__dot"></div>
                        <div className="typing__dot"></div>
                      </div>
                    </LeftMessage>
                  ) : (
                    ""
                  )}

                  {isChatLoading ? (
                    <Container bgColor="#fff" padding="0 50px 0px 330px">
                      <div
                        style={{
                          position: "relative",
                          minHeight: "calc(100vh - 150px)",
                        }}
                      >
                        <Spin className="ant__spin" size="large" />
                      </div>
                    </Container>
                  ) : (
                    <>
                      {newSortMess?.map((item, id) => (
                        <>
                          <div key={id}>
                            {item.sender === user?.id ? (
                              <>
                                {Array.isArray(item) ? (
                                  <>
                                    <RightMessage>
                                      <Row
                                        style={{ width: "100%" }}
                                        gutter={[2, 2]}
                                      >
                                        {item.map((img, idx) => (
                                          <>
                                            {img.sender === user?.id ? (
                                              <>
                                                {attachmentType.includes(
                                                  img.attachment_ext || img.ext
                                                ) ? (
                                                  <AtchBox
                                                    onClick={() =>
                                                      download(
                                                        img.attachment_URL,
                                                        img.message
                                                      )
                                                    }
                                                  >
                                                    <CgFileDocument />
                                                    <h5>
                                                      {slpitFunc(
                                                        img.message || img.name
                                                      )}
                                                    </h5>
                                                  </AtchBox>
                                                ) : (
                                                  <>
                                                    {idx < 3 ? (
                                                      <Col
                                                        span={
                                                          item?.length === 1
                                                            ? "24"
                                                            : "12"
                                                        }
                                                      >
                                                        <StyledUploadPreviewWrapper
                                                          isPhoto={item.length}
                                                        >
                                                          <ImagePreview
                                                            key={idx}
                                                            src={
                                                              img.attachment_URL
                                                            }
                                                            downloadImgUrl={
                                                              img.attachment_URL
                                                            }
                                                            style={{
                                                              width: "100%",
                                                              padding: "2px",
                                                              minHeight:
                                                                "200px",
                                                              maxHeight:
                                                                "200px",
                                                            }}
                                                            isCoverDisProp={
                                                              false
                                                            }
                                                            isDeleteDisProp={
                                                              false
                                                            }
                                                            isDownload={true}
                                                          />
                                                        </StyledUploadPreviewWrapper>
                                                      </Col>
                                                    ) : (
                                                      <Col span="5">
                                                        <StyledUploadPreviewWrapper
                                                          isPhoto={item.length}
                                                        >
                                                          <ImagePreview
                                                            key={idx}
                                                            src={
                                                              img.path
                                                                ? img.path
                                                                : imagePath(
                                                                    img.path
                                                                  )
                                                            }
                                                            downloadImgUrl={
                                                              img.attachment_URL
                                                            }
                                                            style={{
                                                              width: "100%",
                                                              padding: "2px",
                                                              minHeight:
                                                                "200px",
                                                              maxHeight:
                                                                "200px",
                                                            }}
                                                            isDeleteDisProp={
                                                              false
                                                            }
                                                            isCoverDisProp={
                                                              false
                                                            }
                                                            isDownload={true}
                                                          />
                                                        </StyledUploadPreviewWrapper>
                                                      </Col>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        ))}
                                      </Row>
                                    </RightMessage>
                                  </>
                                ) : item?.attachments ? (
                                  <>
                                    <RightMessage width={"75%"}>
                                      <Row
                                        style={{ width: "100%" }}
                                        gutter={[2, 2]}
                                      >
                                        {item?.attachments.map((img, idx) => (
                                          <>
                                            {attachmentType.includes(
                                              img.attachment_ext || img.ext
                                            ) ? (
                                              <AtchBox
                                                onClick={() =>
                                                  download(
                                                    img.attachment_URL,
                                                    img.message
                                                  )
                                                }
                                              >
                                                <CgFileDocument />
                                                <h5>
                                                  {slpitFunc(
                                                    img.message || img.name
                                                  )}
                                                </h5>
                                              </AtchBox>
                                            ) : (
                                              <>
                                                {idx < 3 ? (
                                                  <Col
                                                    span={
                                                      item?.attachments
                                                        ?.length === 1
                                                        ? "24"
                                                        : "12"
                                                    }
                                                  >
                                                    <StyledUploadPreviewWrapper
                                                      isPhoto={
                                                        item?.attachments
                                                          ?.length
                                                      }
                                                    >
                                                      <ImagePreview
                                                        key={idx}
                                                        src={img.path}
                                                        downloadImgUrl={
                                                          img.path
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          padding: "2px",
                                                          minHeight: "200px",
                                                          maxHeight: "200px",
                                                        }}
                                                        isCoverDisProp={false}
                                                        isDeleteDisProp={false}
                                                        isDownload={true}
                                                      />
                                                    </StyledUploadPreviewWrapper>
                                                  </Col>
                                                ) : (
                                                  <Col span="12">
                                                    <StyledUploadPreviewWrapper
                                                      isPhoto={
                                                        item?.attachments
                                                          ?.length
                                                      }
                                                    >
                                                      <ImagePreview
                                                        key={idx}
                                                        src={img.path}
                                                        downloadImgUrl={
                                                          img.path
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          padding: "2px",
                                                          minHeight: "200px",
                                                          maxHeight: "200px",
                                                        }}
                                                        isDeleteDisProp={false}
                                                        isCoverDisProp={false}
                                                        isDownload={true}
                                                      />
                                                    </StyledUploadPreviewWrapper>
                                                  </Col>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ))}
                                      </Row>
                                      <p>
                                        {getTime(
                                          item.created_at
                                            ? item.created_at
                                            : new Date()
                                        )}
                                      </p>
                                    </RightMessage>
                                    {item.message ? (
                                      <RightMessage>
                                        <h2>{item.message}</h2>
                                        <p>
                                          {getTime(
                                            item.created_at
                                              ? item.created_at
                                              : new Date()
                                          )}
                                        </p>
                                      </RightMessage>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <RightMessage>
                                    <h2>{item.message}</h2>
                                    <p>
                                      {getTime(
                                        item.created_at
                                          ? item.created_at
                                          : new Date()
                                      )}
                                    </p>
                                  </RightMessage>
                                )}
                              </>
                            ) : (
                              <>
                                {Array.isArray(item) ? (
                                  <>
                                    {item[0]?.sender === user?.id ? (
                                      <RightMessage width={"75%"}>
                                        <Row
                                          style={{ width: "100%" }}
                                          gutter={[2, 2]}
                                        >
                                          {item.map((img, idx) => (
                                            <>
                                              {img.sender === user?.id ? (
                                                <>
                                                  {attachmentType.includes(
                                                    img.attachment_ext ||
                                                      img.ext
                                                  ) ? (
                                                    <AtchBox
                                                      onClick={() =>
                                                        download(
                                                          img.attachment_URL,
                                                          img.message
                                                        )
                                                      }
                                                    >
                                                      <CgFileDocument />
                                                      <h5>
                                                        {slpitFunc(
                                                          img.message ||
                                                            img.name
                                                        )}
                                                      </h5>
                                                    </AtchBox>
                                                  ) : (
                                                    <>
                                                      {idx < 3 ? (
                                                        <Col
                                                          key={idx}
                                                          span={
                                                            item?.length === 1
                                                              ? "24"
                                                              : "12"
                                                          }
                                                        >
                                                          <StyledUploadPreviewWrapper
                                                            isPhoto={
                                                              item.length
                                                            }
                                                          >
                                                            <ImagePreview
                                                              key={idx}
                                                              src={
                                                                img.attachment_URL
                                                              }
                                                              downloadImgUrl={
                                                                img.attachment_URL
                                                              }
                                                              style={{
                                                                width: "100%",
                                                                padding: "2px",
                                                                minHeight:
                                                                  "200px",
                                                                maxHeight:
                                                                  "200px",
                                                              }}
                                                              isCoverDisProp={
                                                                false
                                                              }
                                                              isDeleteDisProp={
                                                                false
                                                              }
                                                              isDownload={true}
                                                            />
                                                          </StyledUploadPreviewWrapper>
                                                        </Col>
                                                      ) : (
                                                        <Col span="12">
                                                          <StyledUploadPreviewWrapper
                                                            isPhoto={
                                                              item.length
                                                            }
                                                          >
                                                            <ImagePreview
                                                              key={idx}
                                                              src={
                                                                img.attachment_URL
                                                              }
                                                              downloadImgUrl={
                                                                img.attachment_URL
                                                              }
                                                              style={{
                                                                width: "100%",
                                                                padding: "2px",
                                                                minHeight:
                                                                  "200px",
                                                                maxHeight:
                                                                  "200px",
                                                              }}
                                                              isDeleteDisProp={
                                                                false
                                                              }
                                                              isCoverDisProp={
                                                                false
                                                              }
                                                              isDownload={true}
                                                            />
                                                          </StyledUploadPreviewWrapper>
                                                        </Col>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ))}
                                        </Row>
                                        <p>
                                          {getTime(
                                            item.created_at
                                              ? item.created_at
                                              : new Date()
                                          )}
                                        </p>
                                      </RightMessage>
                                    ) : (
                                      <LeftMessage width={"75%"}>
                                        <Row
                                          style={{ width: "100%" }}
                                          gutter={[2, 2]}
                                        >
                                          {item.map((img, idx) => (
                                            <>
                                              {attachmentType.includes(
                                                img.attachment_ext || img.ext
                                              ) ? (
                                                <AtchBox
                                                  fontColor="#ffffff"
                                                  onClick={() =>
                                                    download(
                                                      img.attachment_URL,
                                                      img.message
                                                    )
                                                  }
                                                >
                                                  <CgFileDocument />
                                                  <h5>
                                                    {slpitFunc(
                                                      img.message || img.name
                                                    )}
                                                  </h5>
                                                </AtchBox>
                                              ) : (
                                                <>
                                                  {idx < 3 ? (
                                                    <Col
                                                      key={idx}
                                                      span={
                                                        item.length === 1
                                                          ? "24"
                                                          : "12"
                                                      }
                                                    >
                                                      <StyledUploadPreviewWrapper
                                                        isPhoto={item.length}
                                                      >
                                                        <ImagePreview
                                                          key={idx}
                                                          src={
                                                            img.attachment_URL
                                                          }
                                                          downloadImgUrl={
                                                            img.attachment_URL
                                                          }
                                                          style={{
                                                            width: "100%",
                                                            padding: "2px",
                                                            minHeight: "200px",
                                                            maxHeight: "200px",
                                                          }}
                                                          isCoverDisProp={false}
                                                          isDeleteDisProp={
                                                            false
                                                          }
                                                          isDownload={true}
                                                        />
                                                      </StyledUploadPreviewWrapper>
                                                    </Col>
                                                  ) : (
                                                    <Col span="12">
                                                      <StyledUploadPreviewWrapper
                                                        isPhoto={item.length}
                                                      >
                                                        <ImagePreview
                                                          key={idx}
                                                          src={
                                                            img.attachment_URL
                                                          }
                                                          downloadImgUrl={
                                                            img.attachment_URL
                                                          }
                                                          style={{
                                                            width: "100%",
                                                            padding: "2px",
                                                            minHeight: "200px",
                                                            maxHeight: "200px",
                                                          }}
                                                          isDeleteDisProp={
                                                            false
                                                          }
                                                          isCoverDisProp={false}
                                                          isDownload={true}
                                                        />
                                                      </StyledUploadPreviewWrapper>
                                                    </Col>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          ))}
                                        </Row>
                                        <p>
                                          {getTime(
                                            item.created_at
                                              ? item.created_at
                                              : new Date()
                                          )}
                                        </p>
                                      </LeftMessage>
                                    )}
                                  </>
                                ) : item?.attachments ? (
                                  <>
                                    {item?.attachments.map((img, idx) => (
                                      <>
                                        <RightMessage key={idx} width={"75%"}>
                                          <Row
                                            style={{ width: "100%" }}
                                            gutter={[2, 2]}
                                          >
                                            {attachmentType.includes(
                                              img.attachment_ext || img.ext
                                            ) ? (
                                              <AtchBox
                                                onClick={() =>
                                                  download(
                                                    img.attachment_URL,
                                                    img.message
                                                  )
                                                }
                                              >
                                                <CgFileDocument />
                                                <h5>
                                                  {slpitFunc(
                                                    img.message || img.name
                                                  )}
                                                </h5>
                                              </AtchBox>
                                            ) : (
                                              <>
                                                {idx < 3 ? (
                                                  <Col
                                                    span={
                                                      item?.attachments
                                                        ?.length === 1
                                                        ? "24"
                                                        : "12"
                                                    }
                                                  >
                                                    <StyledUploadPreviewWrapper
                                                      isPhoto={
                                                        item?.attachments
                                                          ?.length
                                                      }
                                                    >
                                                      <ImagePreview
                                                        key={idx}
                                                        src={img.path}
                                                        downloadImgUrl={
                                                          img.path
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          padding: "2px",
                                                          minHeight: "200px",
                                                          maxHeight: "200px",
                                                        }}
                                                        isCoverDisProp={false}
                                                        isDeleteDisProp={false}
                                                        isDownload={true}
                                                      />
                                                    </StyledUploadPreviewWrapper>
                                                  </Col>
                                                ) : (
                                                  <Col span="12">
                                                    <StyledUploadPreviewWrapper
                                                      isPhoto={
                                                        item?.attachments
                                                          ?.length
                                                      }
                                                    >
                                                      <ImagePreview
                                                        key={idx}
                                                        src={img.path}
                                                        downloadImgUrl={
                                                          img.path
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          padding: "2px",
                                                          minHeight: "200px",
                                                          maxHeight: "200px",
                                                        }}
                                                        isDeleteDisProp={false}
                                                        isCoverDisProp={false}
                                                        isDownload={true}
                                                      />
                                                    </StyledUploadPreviewWrapper>
                                                  </Col>
                                                )}
                                              </>
                                            )}
                                          </Row>
                                          <p>
                                            {getTime(
                                              item.created_at
                                                ? item.created_at
                                                : new Date()
                                            )}
                                          </p>
                                        </RightMessage>
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <LeftMessage>
                                    <h2>{item.message}</h2>
                                    <p>
                                      {getTime(
                                        item.created_at
                                          ? item.created_at
                                          : new Date()
                                      )}
                                    </p>
                                  </LeftMessage>
                                )}
                              </>
                            )}
                          </div>

                          {item.created_at ? (
                            <>
                              {id === newSortMess?.length - 1 ? (
                                <DateWrap>
                                  <span>{dateConverter(item.created_at)}</span>
                                </DateWrap>
                              ) : (
                                <>
                                  {id === 0 ? (
                                    ""
                                  ) : (
                                    <>
                                      {new Date(
                                        sortMessages[id - 1]?.created_at
                                          ? sortMessages[id - 1]?.created_at
                                          : item.created_at
                                      )
                                        .toISOString()
                                        .slice(0, 10) ===
                                      new Date(item.created_at)
                                        .toISOString()
                                        .slice(0, 10) ? (
                                        ""
                                      ) : (
                                        <DateWrap>
                                          <span>
                                            {dateConverter(item.created_at)}
                                          </span>
                                        </DateWrap>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                    </>
                  )}
                </ChatBox>
                <TextBoxMob>
                  <input
                    accept="application/pdf, application/vnd.ms-excel, image/*"
                    id="myInput"
                    multiple={"multiple"}
                    type="file"
                    ref={upload}
                    style={{ display: "none" }}
                    onChange={fileChangeHandler}
                    max="5"
                  />
                  <SendEmail ref={drop}>
                    <Input.Group compact>
                      <Input.TextArea
                        className="description__box"
                        placeholder="Description"
                      />
                    </Input.Group>
                  </SendEmail>

                  {/* <AttachMent>
                    <AttachmentSvg />
                  </AttachMent>
                  <AttachMent onClick={(e) => upload.current.click()}>
                    <FiPaperclip />
                  </AttachMent>

                  <SendEmail ref={drop}>
                    <Input.Group compact>
                      <Input
                        // disabled={state?.length}
                        onChange={getInputValue}
                        placeholder="Write a Message"
                        style={{
                          width: "calc(100% - 52px)",
                          height: "50px",
                        }}
                        onPressEnter={() => onFinish()}
                        value={inputValue}
                      />
                      <Button
                        onClick={() => onFinish()}
                        disabled={
                          state?.length ? !state?.length : !inputValue.length
                        }
                      >
                        <BiSend />
                      </Button>
                    </Input.Group>
                  </SendEmail> */}
                </TextBoxMob>
              </ActiveChat>

              <SidebarContainer className="msg-left-panel">
                <Divider onClick={openSiderDivider} />
                <HeaderSearch maxWidth="100%" bgcolor="#eeeeee" />
                <MessagerWrapMob>
                  {adminChats?.map((item, id) => (
                    <>
                      <AdminMessage
                        key={id}
                        // background={
                        //   selectedChat.id === item.id ? "#ECF2FF" : "#ffffff"
                        // }
                        onClick={(e) => {
                          openSiderDivider(e);
                          setInputValue("");
                          setSelectedChat(item);
                          localStorage.setItem("chat_id", item.id);
                        }}
                      >
                        <UserAvatar
                          className="userName__icon"
                          size={avatarWidth}
                          src={item?.prospectUser?.image?.path}
                          fullName={`${item.prospectUser.firstName || ""} ${
                            item.prospectUser.lastName || ""
                          }`}
                        />
                        <MessageName>
                          <h2>
                            {upperCase(item.prospectUser.firstName)}{" "}
                            {upperCase(item.prospectUser.lastName)}
                            <Badge count={item?.unseenMessageCount}></Badge>
                            {/* <Divider type="vertical" /> {upperCase(item.unit_name)} */}
                          </h2>
                          {/* {item?.latestMessage?.message ? (
                          <p>{item?.latestMessage?.message}</p>
                        ) : (
                          <p>Chat Joined</p>
                        )} */}
                        </MessageName>
                      </AdminMessage>
                      <Divider />
                    </>
                  ))}
                </MessagerWrapMob>
              </SidebarContainer>
            </Col>
          </Row>
        </>
      ) : (
        <Container>
          <NoMessageWrapMain>
            <div className="no_message">
              <NoMessage />
              <h2>No Message Available</h2>
            </div>
          </NoMessageWrapMain>
        </Container>
      )}
    </MessagesWrap>
  );
}

export default Messages;

const StyledUploadPreviewWrapper = styled.div`
  display: ${({ isPhoto }) => (!isPhoto ? "none" : "grid")};
  flex-wrap: wrap;
  margin-top: 10px;

  .ant-image-mask {
    border-radius: 10px;
  }
  .ant-image {
    margin: 0 5px;
  }

  img {
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
  }

  @media (max-width: 575px) {
    .ant-image {
      width: 100% !important;
    }
    img {
      width: 100%;
    }
  }
`;

const MsgWrap = styled.div`
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    background-color: #ffffff !important;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const MessagerWrapMob = styled.div`
  margin-top: 20px;
  .ant-divider-horizontal {
    display: flex !important;
    clear: both !important;
    width: 100% !important;
    min-width: 100% !important;
    margin: 10px 0 !important;
    min-height: 1px !important;
    background: #d9d9d9 !important;
    border-radius: 0 !important;
    border-top: 1px solid rgba(0, 0, 0, 0.06) !important;
  }
`;

const UnitDetails = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 5px;
  margin: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  img {
    width: 50px;
  }
  h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #f67c36;
    margin-bottom: 0;
  }
`;

const ActiveMessageName = styled.div`
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  width: 94%;

  svg {
    font-size: 24px;
    cursor: pointer;
  }

  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
    margin-bottom: 0;

    .ant-divider-vertical {
      color: #2b353f;
      border-left: 2px solid #2b353f;
    }
  }
  @media (max-width: 768px) {
    width: 85%;

    h2 {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #2b353f;
    }
  }
`;

const ActiveName = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  box-shadow: ${({ bs }) => bs || "0px 0px 10px rgba(0, 0, 0, 0.1)"};
  border-radius: ${({ br }) => br || "0px 5px 0px 0px"};

  @media (max-width: 1550px) {
    padding: 10px;
  }
`;

export const TextBoxMob = styled.div`
  ${"" /* background: #ffffff; */}
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 25px;
  display: flex;
  ${"" /* padding: 10px; */}
  position: fixed;
  ${"" /* display: none; */}
  bottom: -1px;
  left: 0;
  ${"" /* z-index: 1000; */}
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  padding: 15px 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
`;

export const SidebarContainer = styled.div`
  padding-top: 115px;
  width: 280px;
  background: #fff;
  box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.06);
  display: block;
  padding: 80px 15px 15px 15px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  transition: ease-in-out 0.5s;
  overflow-y: auto;
  overflow-x: hidden;

  .search__message {
    margin-left: 0 !important;
  }

  .ant-divider-horizontal {
    border-top: 5px solid rgba(0, 0, 0, 0.06);
    width: 60px;
    min-width: 60px;
    margin: auto;
    margin-bottom: 25px;
    min-height: 5px;
    background: #d9d9d9;
    border-radius: 60px;
  }

  @media (max-width: 1550px) {
    width: 100%;
    padding-top: 64px;
  }

  @media (max-width: 991.98px) {
    z-index: 111;
    margin-top: 65px;
    padding: 10px;
  }

  // scrollbar css
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 5px;
  }
`;

const ActiveChat = styled.div`
  width: 100%;
  margin-top: 1px;
`;

const DateWrap = styled.div`
  ${"" /* width: 100%; */}
  margin: auto;
  text-align: center;
  span {
    background: #cecece;
    display: inline-block;
    padding: 2px 15px;
    border-radius: 39px;
    color: #2b353f;
  }
`;

const LeftWrap = styled.div`
  background: #ffffff;
  border-radius: 5px 0px 0px 5px;

  .message__box {
    padding: 25px;
    display: flex;
    gap: 10px;
  }

  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #2b353f;
    margin-bottom: 25px;
  }

  @media (max-width: 1550px) {
    .message__box {
      padding: 15px;
    }

    h1 {
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 15px;
    }
  }
`;

const MessagerWrap = styled.div`
  min-height: calc(100vh - 188px);
  max-height: calc(100vh - 188px);
  overflow-y: auto;

  @media (max-width: 1550px) {
    max-height: calc(100vh - 188px);
    min-height: calc(100vh - 188px);
  }
  /* scroll bar */
  ::-webkit-scrollbar,
  .ant-modal-wrap::-webkit-scrollbar {
    width: 0.5em;

    @media (max-width: 767px) {
      width: 0 !important;
      display: none !important;
    }
  }

  ::-webkit-scrollbar-track,
  .ant-modal-wrap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

    @media (max-width: 767px) {
      box-shadow: none;
    }
  }
  ::-webkit-scrollbar-thumb,
  .ant-modal-wrap::-webkit-scrollbar-thumb {
    background-color: rgb(97, 96, 96);
    background-color: #c4c4c4;
    outline: 1px solid #c4c4c4;
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 1550px) {
    ${"" /* padding: 0 15px 15px 15px; */}
  }
`;

const AdminMessage = styled.div`
  display: flex;
  cursor: pointer;
  padding: 10px 40px;
  background: ${({ background }) => background || "#ffffff"};

  @media (max-width: 1550px) {
    padding: 10px 15px;
  }
`;

const MessageName = styled.div`
  padding: 12px;
  width: 80%;

  .ant-badge-count {
    top: -10px;
    background: #f67c36;
    right: -5px;
    box-shadow: none;
    min-width: 17px;
    height: 17px;
    padding: 0 0px;
    line-height: 19px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #848687;
    margin-bottom: 0;
  }

  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #2b353f;
    margin-bottom: 5px;

    .ant-divider-vertical {
      color: #2b353f;
      border-left: 2px solid #2b353f;
    }
  }

  h3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const SelectUnitWrap = styled(Select)`
  width: 170px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 40px !important;

  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  width: 100%;

  .ant-select-selection-placeholder {
    color: #2b353f !important;
  }
  :hover {
    border-color: #aaaaaa !important;
  }

  .ant-select-selector {
    position: relative;
    background-color: ${({ bgColor }) => bgColor || "#fff"} !important;
    border: none !important;
    border-radius: 5px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 40px !important;
    align-items: center;
  }
  .ant-select:hover {
    box-shadow: 0 0 0 2px rgb(0, 0, 0, 0.1) !important;
  }
  ,
  .ant-select-selector {
    border-color: #aaaaaa !important;
  }
  .ant-select-focused {
    box-shadow: 0 0 0 2px rgb(0, 0, 0, 0.1) !important;
  }

  @media (max-width: 1550px) {
    height: 50px !important;
    font-size: 16px !important;

    .ant-select-selection-placeholder {
      font-size: 16px;
    }

    .ant-select-selector {
      height: 50px !important;
    }
  }
`;
