import React, { useEffect, useState } from "react";
import {
  StyledFormItem,
  StyledForm,
  StyledForms,
  StyledButton,
  StyledTitle,
} from "./RegistrationStyle";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../features/useSlice";
import { createSearchParams, useNavigate } from "react-router-dom";
import { createOrg, getAllOrg, me } from "../../store/reducers/user.reducer";
import Sidebar from "../layout/Sidebar";
import { currentDateTime, upperCase } from "../../utils/fn";

function RegisterFormCompanyDetails({
  reDirect,
  pageTitle,
  bgImage,
  isModal = false,
  onSubmit = console.log,
  addNewhendler,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var formRef = React.createRef();

  const { user } = useSelector((state) => state.user);
  const [loadings, setloadings] = useState(false);
  const [orgNameValidation, setOrgNameValidation] = useState("");

  // Pre-setting Bg image and pagetitle
  useEffect(() => {
    if (pageTitle) {
      pageTitle("Create Your Organization");
      bgImage("/images/createCompany.png");
    }
  }, []);

  useEffect(() => {
    if (!isModal && localStorage.getItem("user_id")) {
      dispatch(me());
    }
  }, []);

  // To add or update Company details upon submitting form
  const onFinish = (value) => {
    setOrgNameValidation("");
    value.org_name = upperCase(value?.org_name.toLowerCase());
    value.user_id = user.id;
    value.createdAt = currentDateTime;
    value.updatedAt = currentDateTime;
    setloadings(true);
    dispatch(createOrg(value)).then((res) => {
      if (isModal) {
        setloadings(false);
        addNewhendler(res.payload);
        if (res?.payload?.Error || res?.payload?.error) {
          setOrgNameValidation(res?.payload?.Error || res?.payload?.error);
        } else {
          // navigate(reDirect);
          dispatch(getAllOrg());
          onSubmit(false);
          addNewhendler(res.payload);
        }
      } else {
        if (res?.payload?.Error || res?.payload?.error) {
          setOrgNameValidation(res?.payload?.Error || res?.payload?.error);
          setloadings(false);
        } else {
          setloadings(false);
          dispatch(getAllOrg());
          onSubmit(false);
          navigate({
            pathname: `/registration/company-details/create-listing`,
            search: createSearchParams({
              orgId: res?.payload?.id,
            }).toString(),
          });
          // navigate("/registration/company-details/create-listing");
        }
      }
    });
  };

  // Validating the organization name or it takes unique name for organization
  useEffect(() => {
    if (
      orgNameValidation?.length &&
      orgNameValidation?.includes("is already exists")
    ) {
      formRef.current.setFields([
        {
          name: "org_name",
          errors: ["Organization Name is already exist"],
        },
      ]);
    }
  }, [orgNameValidation, loadings]);

  // Message for validatting form
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  return (
    <StyledForm wh={isModal ? "100" : "100"} xxswh="100" smwh="100" xswh="100">
      <StyledForms>
        {isModal && <StyledTitle>Add New Organization</StyledTitle>}

        <Form
          layout="vertical"
          onFinish={onFinish}
          requiredMark={"optional"}
          validateMessages={validateMessages}
          ref={formRef}
        >
          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="org_name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Organization Name is required.",
                },
              ]}
            >
              <Input placeholder="Organization Name" />
            </Form.Item>
          </StyledFormItem>
          {/* Second col */}
          {/* <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="phonenumber"
              rules={[
                {
                  required: true,
                  message: "Phone is required",
                },
              ]}
            >
              <Input type="number" placeholder="Organization Number" />
            </Form.Item>
          </StyledFormItem> */}
          {/* Third col */}
          {/* <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="companyEmail"
              rules={[
                {
                  required: true,
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </StyledFormItem> */}
          <Form.Item>
            <StyledButton htmlType="submit" loading={loadings}>
              {isModal ? "Add Organization" : "Next"}
            </StyledButton>
          </Form.Item>
        </Form>
      </StyledForms>
    </StyledForm>
  );
}

export default RegisterFormCompanyDetails;
