import React from "react";
import { CurrentStagWrap } from "./ApplicationsStyle";
import { SendLeaseWrapTop } from "./SendLease";

function DepositSubmitted() {
  return (
    <>
      <CurrentStagWrap>
        <h2 style={{ color: "red" }}>Coming soon in Beta</h2>
      </CurrentStagWrap>
    </>
  );
}

export default DepositSubmitted;
