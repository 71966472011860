import { Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import { IoIosClose } from "react-icons/io";
import { useLocation } from "react-router";
import styled from "styled-components";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../createPersonalProfile/RegistrationStyle";

function WriteQuestionModal({ modalState, onCancel }) {
  const [form] = Form.useForm();

  const [isFormChange, setIsFormChange] = useState(false);
  const { width } = useWindowSize();
  const location = useLocation();

  const [modalWidth, setModalWidth] = useState(800);

  // To set modal width w.r.t screen width
  useEffect(() => {
    if (width < 1550) {
      setModalWidth(640);
    } else {
      setModalWidth(800);
    }
  }, [width, location.pathname]);

  // Alert popup to navigate without saving data
  const openPrompt = (message) => {
    var answer = window.confirm(message);
    if (answer === true) {
      onCancel(false);
      setIsFormChange(false);
    } else {
    }
  };

  // To save data when automatic reload occurs
  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });

  // Message to validate form
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  // Submit function for onclick event
  const onFinish = (value) => {
    console.log(value, "value");
  };
  return (
    <Modal
      getContainer={() => document.querySelector("#modal-container")}
      visible={modalState}
      onCancel={() => {
        if (!isFormChange) {
          onCancel(false);
        } else {
          openPrompt(
            "You will lose all changes if you navigate away from this page without saving"
          );
        }
      }}
      width={modalWidth}
      footer={null}
      style={{ borderRadius: "5px", top: "50px" }}
      className="modal_wrapper"
      closeIcon={<IoIosClose className="modal_close_icon" />}
      maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.8)" }}
      focusTriggerAfterClose={false}
      destroyOnClose={true}
    >
      <FormWrap>
        <h2>Write a Question </h2>
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
              onValuesChange={() => {}}
            >
              <StyledFormItem smwh="100">
                <Form.Item
                  name="firstN"
                  label="First Name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  name="lastN"
                  label="Last Name"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder="Enter Email" />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="enterSubject"
                  label="Enter Subject"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Enter Subject" />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="message"
                  label="Enter Message"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    className="description__box"
                    style={{ height: " 100px" }}
                    placeholder="Enter Message"
                  />
                </Form.Item>
              </StyledFormItem>

              <Form.Item>
                <StyledButton htmlType="submit">Send Message</StyledButton>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      </FormWrap>
    </Modal>
  );
}

export default WriteQuestionModal;

export const FormWrap = styled.div`
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 49px;
    color: #2b353f;
  }
`;
