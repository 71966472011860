import React, { useEffect, useState } from "react";
import {
  LoginMain,
  LoginWrap,
  CreateAccount,
  ManagePropertyBtn,
  LoginRight,
  StyledBg,
} from "./LoginStyle";
import { Row, Col, Form, Input, Divider, message } from "antd";
import {
  StyledForm,
  StyledFormItem,
  StyledForms,
  StyledButton,
} from "../createPersonalProfile/RegistrationStyle";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AvenewLogo } from "../../assets/svgs";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useDispatch } from "react-redux";
import {
  forgotPasswordOtp,
  forgotPasswordWithOtp,
  login,
  verifyResendOtp,
} from "../../store/reducers/user.reducer";
import OtpModal, { StyledFormItemd } from "../createPersonalProfile/OtpModal";

function LoginForm() {
  var formRef = React.createRef();
  var formRefOTP = React.createRef();
  var formRefForgot = React.createRef();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [invalidUserOrPass, setInvalidUserOrPass] = useState(false);
  const [isForgot, setIsForgot] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [emailValidation, setEmailValidation] = useState("");
  const [modalState, setModalState] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [verifyOtp, setVerifyOtp] = useState("");
  const [loadingsOtp, setloadingsOtp] = useState(false);
  const [formVal, setFormVal] = useState({});
  const [signUpUserData, setSignUpUserData] = useState({});
  const [asetNewOtp, setSetNewOtp] = useState("");
  const [invalidCode, setInvalidCode] = useState({});
  const [enterOtp, setEnterOtp] = useState(false);
  const [otp, setOtp] = useState({
    value: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
    disable: true,
  });

  // To change password after forget password
  // useEffect(() => {
  //   if (asetNewOtp) {
  //     setloadingsOtp(true);
  //     dispatch(forgotPasswordWithOtp(signUpUserData)).then(({ payload }) => {
  //       setInvalidCode(payload);
  //       setloadingsOtp(false);
  //       if (payload?.Error) {
  //       } else {
  //         message.success("Password Changed Successfully");
  //         setModalState(false);
  //         setIsForgot(false);
  //       }
  //     });
  //   }
  // }, [asetNewOtp]);

  const newPasswordChange = (value) => {
    let forgotPass = {};
    const obj = Object.values(
      value.otp0 +
        value.otp1 +
        value.otp2 +
        value.otp3 +
        value.otp4 +
        value.otp5
    );
    const arr = [];
    obj.map((val) => {
      arr.push(val);
    });
    const otpValueInput = arr.join("");
    const otpVal = otpValueInput;

    forgotPass.otp = otpVal;
    forgotPass.email = signUpUserData.email;
    forgotPass.password = value.password;

    if (otpVal?.length) {
      setloadingsOtp(true);
      dispatch(forgotPasswordWithOtp(forgotPass)).then(({ payload }) => {
        setInvalidCode(payload);
        setloadingsOtp(false);
        if (payload?.Error) {
        } else {
          message.success("Password Changed Successfully");
          setIsForgot(false);
          setEnterOtp(false);
        }
      });
    }
  };

  // Error or Success message upon otp submit
  useEffect(() => {
    if (invalidCode?.Error) {
      setErrorMessage("Invalid code");
    } else if (invalidCode?.id) {
      setModalState(false);
      message.success("Password Changed Successfully");
    } else {
      setModalState(false);
      setIsForgot(false);
    }
  }, [invalidCode]);

  // Onfinish function for resend OTP
  const onFinishResendOtp = async () => {
    await dispatch(forgotPasswordOtp({ email: formVal.email })).then(
      ({ payload }) => {
        if (payload?.Error) {
        } else {
          message.success("OTP is resent to your Email");
        }
      }
    );
  };

  // Onfinish function for send OTP
  const onFinish = async (value) => {
    value.email = value.email.toLowerCase();
    setEmailValidation("");
    setloadings(true);
    await dispatch(login(value)).then((res) => {
      setloadings(false);
      setEmailValidation(res.payload.error || res.payload.Error);
      setInvalidUserOrPass(res.payload.Error?.includes("password"));
    });
  };

  // Function for forget password
  const getOtp = async (value) => {
    setEmailValidation("");
    value.email = value.email.toLowerCase();
    setloadings(true);
    setFormVal(value);
    await dispatch(forgotPasswordOtp(value)).then(({ payload }) => {
      setEmailValidation(payload.error || payload.Error);

      if (payload?.error) {
        setloadings(false);
      } else {
        setloadings(false);
        setEnterOtp(true);
        setIsForgot(false);
        //     setModalState(true);
      }
    });

    setSignUpUserData({
      email: value.email,
      otp: "",
      password: "",
    });
  };

  // To set signup data obj
  useEffect(() => {
    setSignUpUserData({
      ...signUpUserData,
      otp: verifyOtp,
    });
    setSetNewOtp(verifyOtp);
  }, [verifyOtp]);

  // To verify Email ID
  useEffect(() => {
    if (
      emailValidation?.length &&
      emailValidation?.includes("User with email")
    ) {
      formRef.current.setFields([
        {
          name: "email",
          errors: ["Email does not exists"],
        },
      ]);
    }
  }, [emailValidation, loadings]);

  // Message to validate form
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  const { width } = useWindowSize();
  const location = useLocation();
  const [zoom, setZoom] = useState("100%");

  // Set zoom w.r.t screen width
  useEffect(() => {
    if (width < 1550 && width > 991) {
      setZoom("80%");
    } else {
      setZoom("100%");
    }
  }, [width, location.pathname]);

  // To set otp value
  const otpValue = (value) => {
    setVerifyOtp(value);
  };

  // Onchange function for OTP input
  const handleChanges = (value1, event) => {
    setOtp({ [value1]: event.target.value });

    if (event.target.value.length > 1) {
      form.setFieldsValue({
        otp0: event.target.value[0],
        otp1: event.target.value[1],
        otp2: event.target.value[2],
        otp3: event.target.value[3],
        otp4: event.target.value[4],
        otp5: event.target.value[5],
      });
    }
  };

  // OTP field focus function upon delete or backspace
  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const cancel = () => {
    setIsForgot(false);
    setEnterOtp(false);
  };

  return (
    <LoginMain>
      <LoginWrap>
        <Row justify="center">
          <Col
            xl={{ span: 12 }}
            md={{ span: 20 }}
            sm={{ span: 20 }}
            xs={{ span: 22 }}
          >
            <AvenewLogo className="avenew__logo" />
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xl={{ span: 12 }}
            md={{ span: 20 }}
            sm={{ span: 20 }}
            xs={{ span: 22 }}
          >
            <h1 className="personal__profile">
              {isForgot
                ? "Forgot your password?"
                : enterOtp
                ? "Enter OTP and New Password"
                : "Sign in"}
            </h1>
          </Col>
        </Row>
        {isForgot ? (
          <StyledForm wh="50" smwh="93" xswh="80" xxswh="91">
            <StyledForms>
              <Form
                layout="vertical"
                onFinish={getOtp}
                requiredMark={"optional"}
                validateMessages={validateMessages}
                ref={formRef}
              >
                <StyledFormItem wh="100" smwh="100">
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </StyledFormItem>
                <Form.Item>
                  <StyledButton htmlType="submit" loading={loadings}>
                    Get OTP
                  </StyledButton>
                </Form.Item>
              </Form>
              <CreateAccount>
                <p>Already have an account? </p>
                <h3 onClick={loadings ? "" : cancel}>Login Now</h3>
              </CreateAccount>
              {/* <StyledFormItem wh="100" smwh="100">
                  <Form.Item
                    name="password"
                    label="Password"
                    className="password-w-icon"
                    rules={[
                      {
                        required: true,
                      },
                      // {
                      //   message: "password should contain minimum 8 characters",
                      // },
                      {
                        min: 8,
                        pattern: new RegExp(
                          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                        ),
                        message:
                          "Use 8 and more characters with a mix of letters, numbers & symbols",
                      },
                    ]}
                  >
                    <Input type="password" placeholder="Password" />
                  </Form.Item>
                </StyledFormItem>
                <StyledFormItem wh="100" smwh="100">
                  <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Confirm your password.",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match."
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input type="password" placeholder="Confirm Password" />
                  </Form.Item>
                </StyledFormItem> */}
              {/* <CreateAccount onClick={() => setIsForgot(false)}>
                <p>Have an Account? </p>
                <span>
                  <h3>Login now</h3>
                </span>
              </CreateAccount> */}
              {/* <Row justify="center" className="or__option">
              <Col xl={{ span: 10 }} sm={{ span: 10 }} xs={{ span: 9 }}>
                <Divider />
              </Col>
              <Col>
                <p style={{ color: "#aaaaaa" }}>OR</p>
              </Col>
              <Col xl={{ span: 10 }} sm={{ span: 10 }} xs={{ span: 9 }}>
                <Divider />
              </Col>
            </Row>
            <ManagePropertyBtn onClick={() => navigate("/join-company")}>
              <p>Want to manage existing property?</p>
              <h3>JOIN NOW {">"}</h3>
            </ManagePropertyBtn> */}
            </StyledForms>
          </StyledForm>
        ) : enterOtp ? (
          <>
            <StyledForm wh="50" smwh="93" xswh="80" xxswh="91">
              <StyledForms>
                <Form
                  layout="vertical"
                  onFinish={newPasswordChange}
                  requiredMark={"optional"}
                  validateMessages={validateMessages}
                  ref={formRefOTP}
                >
                  <StyledFormItem>
                    <StyledFormItemd name="otp0">
                      <Input
                        name="otp1"
                        type="number"
                        min="0"
                        // autoComplete="off"
                        className="otpInput"
                        value={otp.otp1}
                        // onKeyPress={keyPressed}
                        onChange={(e) => handleChanges("otp1", e)}
                        tabIndex="1"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus(e)}
                        onWheel={(e) => e.target.blur()}
                      />
                    </StyledFormItemd>
                    <StyledFormItemd name="otp1">
                      <Input
                        name="otp2"
                        type="number"
                        min="0"
                        // autoComplete="off"
                        className="otpInput"
                        value={otp.otp2}
                        onChange={(e) => handleChanges("otp2", e)}
                        tabIndex="2"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus(e)}
                        onWheel={(e) => e.target.blur()}
                      />
                    </StyledFormItemd>
                    <StyledFormItemd name="otp2">
                      <Input
                        name="otp3"
                        type="number"
                        min="0"
                        // autoComplete="off"
                        className="otpInput"
                        value={otp.otp3}
                        onChange={(e) => handleChanges("otp3", e)}
                        tabIndex="3"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus(e)}
                        onWheel={(e) => e.target.blur()}
                      />
                    </StyledFormItemd>
                    <StyledFormItemd name="otp3">
                      <Input
                        name="otp4"
                        type="number"
                        min="0"
                        // autoComplete="off"
                        className="otpInput"
                        value={otp.otp4}
                        onChange={(e) => handleChanges("otp4", e)}
                        tabIndex="4"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus(e)}
                        onWheel={(e) => e.target.blur()}
                      />
                    </StyledFormItemd>
                    <StyledFormItemd name="otp4">
                      <Input
                        name="otp5"
                        type="number"
                        min="0"
                        // autoComplete="off"
                        className="otpInput"
                        value={otp.otp5}
                        onChange={(e) => handleChanges("otp5", e)}
                        tabIndex="5"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus(e)}
                        onWheel={(e) => e.target.blur()}
                      />
                    </StyledFormItemd>
                    <StyledFormItemd name="otp5">
                      <Input
                        name="otp6"
                        type="number"
                        min="0"
                        // autoComplete="off"
                        className="otpInput"
                        value={otp.otp6}
                        onChange={(e) => handleChanges("otp5", e)}
                        tabIndex="6"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus(e)}
                        onWheel={(e) => e.target.blur()}
                      />
                    </StyledFormItemd>
                  </StyledFormItem>
                  <StyledFormItem wh="100" smwh="100" borderColor="none">
                    <Form.Item
                      name="password"
                      label="New Password"
                      className="password-w-icon"
                      rules={[
                        {
                          required: true,
                        },
                        // {
                        //   message: "password should contain minimum 8 characters",
                        // },
                        {
                          min: 8,
                          pattern: new RegExp(
                            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                          ),
                          message:
                            "Use 8 and more characters with a mix of letters, numbers & symbols",
                        },
                      ]}
                    >
                      <Input.Password type="password" placeholder="Password" />
                    </Form.Item>
                  </StyledFormItem>
                  <StyledFormItem wh="100" smwh="100" borderColor="none">
                    <Form.Item
                      name="confirmPassword"
                      label="Confirm New Password"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please Confirm your password.",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match."
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        type="password"
                        placeholder="Confirm Password"
                      />
                    </Form.Item>
                  </StyledFormItem>

                  {errorMessage?.length ? (
                    <h4 className="otp__error">* Invalid Code</h4>
                  ) : (
                    ""
                  )}

                  <Form.Item>
                    <StyledButton htmlType="submit" loading={loadingsOtp}>
                      Save and login
                    </StyledButton>
                  </Form.Item>
                  <CreateAccount>
                    <p>Didn't receive the OTP? </p>
                    <h3
                      onClick={() => (loadingsOtp ? "" : onFinishResendOtp())}
                      style={{ color: "#F67C36", cursor: "pointer" }}
                    >
                      Re-send
                    </h3>
                  </CreateAccount>
                </Form>
                <CreateAccount>
                  <p>Already have an account? </p>
                  <h3 onClick={loadingsOtp ? "" : cancel}>Login Now</h3>
                </CreateAccount>
              </StyledForms>
            </StyledForm>
          </>
        ) : (
          <StyledForm wh="50" smwh="93" xswh="80" xxswh="91">
            <StyledForms>
              <Form
                layout="vertical"
                onFinish={onFinish}
                requiredMark={"optional"}
                validateMessages={validateMessages}
                ref={formRef}
              >
                <StyledFormItem wh="100" smwh="100">
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                </StyledFormItem>
                <StyledFormItem wh="100" smwh="100" borderColor="none">
                  <Form.Item
                    name="password"
                    label="Password"
                    className="password-w-icon pass__filed"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        min: 8,
                        message: "password should contain minimum 8 characters",
                      },
                    ]}
                  >
                    <Input.Password
                      // iconRender={(visible) =>
                      //   visible ? <AiFillEye /> : <AiFillEyeInvisible />
                      // }
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Item>
                </StyledFormItem>
                <Row justify="end">
                  <Col>
                    <p
                      className="forgot__password"
                      onClick={() => (loadings ? "" : setIsForgot(true))}
                    >
                      Forgot Password?
                    </p>
                  </Col>
                </Row>
                {invalidUserOrPass ? (
                  <p style={{ color: "#ff4d4f" }}>
                    * Incorrect Email or Password.
                  </p>
                ) : (
                  ""
                )}
                <Form.Item>
                  <StyledButton htmlType="submit" loading={loadings}>
                    Sign In
                  </StyledButton>
                </Form.Item>
              </Form>
              <CreateAccount>
                <p>Don't have an account? </p>
                <Link to={loadings ? "" : "/registration"}>
                  <h3> CREATE NOW</h3>
                </Link>
              </CreateAccount>
              {/* <Row justify="center" className="or__option">
              <Col xl={{ span: 10 }} sm={{ span: 10 }} xs={{ span: 9 }}>
                <Divider />
              </Col>
              <Col>
                <p style={{ color: "#aaaaaa" }}>OR</p>
              </Col>
              <Col xl={{ span: 10 }} sm={{ span: 10 }} xs={{ span: 9 }}>
                <Divider />
              </Col>
            </Row>
            <ManagePropertyBtn onClick={() => navigate("/join-company")}>
              <p>Want to manage existing property?</p>
              <h3>JOIN NOW {">"}</h3>
            </ManagePropertyBtn> */}
            </StyledForms>
          </StyledForm>
        )}
      </LoginWrap>
      {/* <LoginRight></LoginRight> */}
      <StyledBg></StyledBg>
      {/* <OtpModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        // onFinishOtp={onFinishOtp}
        otpValue={otpValue}
        errorMessage={errorMessage}
        loadingsOtp={loadingsOtp}
        onFinishResendOtp={onFinishResendOtp}
      /> */}
    </LoginMain>
  );
}

export default LoginForm;
