import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { StyledTabWrapper, StyledTabs } from "./MyAccountSidebar";
import { useLocation } from "react-router";
import BusinessDetails from "./BusinessDetails";

const BusinessTab = () => {
  const { allOrganization } = useSelector((state) => state.user);
  const { TabPane } = Tabs;
  const [orgName, setOrgName] = useState([]);
  const [orgData, setOrgData] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const { width } = useWindowSize();
  const [tabPosition, setTabPosition] = useState("left");
  const location = useLocation();

  // Push Object organization details value to array
  useEffect(() => {
    if (allOrganization && Object.values(allOrganization)) {
      setOrgName(Object.values(allOrganization));
    }
    // if (allApartment && Object.values(allApartment)) {
    //   setUnits(Object.values(allApartment));
    // }
  }, [allOrganization]);

  // To set tab position w.r.t screen width
  useEffect(() => {
    if (width < 992) {
      setTabPosition("horizontal");
    } else {
      setTabPosition("left");
    }
  }, [width, location.pathname]);

  useEffect(() => {
    if (orgName?.length && !Object.keys(orgData)?.length) {
      setActiveTab(orgName[0]?.org_name);
      setOrgData(orgName[0]);
    }
  }, [orgName]);

  // useEffect(() => {
  //   if (activeTab && orgName?.length) {
  //     const selectedOrg = orgName?.filter((item) => {
  //       return item.org_name === activeTab;
  //     });
  //     setOrgData(...selectedOrg);
  //   }
  // }, [activeTab]);

  const onActiveTab = (tabKey) => {
    const selectedOrg = orgName?.filter((item) => {
      return item.org_name === tabKey;
    });
    setOrgData(...selectedOrg);
  };

  const onNameChange = (value) => {
    setOrgData(value);
    let arr = [];
    orgName?.map((item) => {
      if (item.id === value.id) {
        item = value;
        arr.push(item);
      } else {
        arr.push(item);
      }
    });
    setOrgName([...arr]);
    setActiveTab(value.org_name);
  };

  return (
    <div>
      <StyledTabWrapper>
        <StyledTabs
          //   defaultActiveKey={"personal-details"}
          activeKey={activeTab}
          onChange={(tabKey) => {
            // setList([]);
            setActiveTab(tabKey);
            onActiveTab(tabKey);
          }}
          tabPosition={tabPosition}
        >
          {orgName?.map((val, idx) => (
            <TabPane tab={val.org_name} key={val?.org_name}>
              <BusinessDetails orgData={orgData} onNameChange={onNameChange} />
            </TabPane>
          ))}
        </StyledTabs>
      </StyledTabWrapper>
    </div>
  );
};

export default BusinessTab;
