import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import Registration from "./components/createPersonalProfile/Registration";
import LoginForm from "./components/login/LoginForm";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import ThanksPage from "./components/thanksPage/ThanksPage";
import JoinCompany from "./components/joinCompany/JoinCompany";
import Layout from "./components/layout/Layout";
import { ThemeProvider } from "styled-components";
import "./App.css";
import Faqs from "./components/faqs/Faqs";
import AddApartmentFromResman from "./components/resman-apartment-modal/AddApartmentFromResman";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./store";
import PrivateRoute from "./privateRoute/PrivateRoute";

function App() {
  const primary = "#ffffff";
  const secondary = "#4160AC";
  const third = "#F67C36";
  const forth = "#2B353F";

  const theme = React.useMemo(() => ({
    colors: {
      primary: primary,
      secondary: secondary,
      third: third,
      forth: forth,
    },
  }));
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = useState("/login");
  const { user } = useSelector((state) => state.user);
  const { allOrganization } = useSelector((state) => state.user);
  const users = useSelector((state) => state.user.user);
  const { accessToken } = useSelector((state) => state.user);
  const [login, setLogin] = useState(false);

  document.addEventListener("visibilitychange", (event) => {
    if (user && localStorage.getItem("user_id")) {
      if (document.visibilityState === "visible") {
        if (user?.id === localStorage.getItem("user_id")) {
        } else {
          window.location.reload();
        }
      } else {
        if (user?.id === localStorage.getItem("user_id")) {
        } else {
        }
      }
    }
  });

  // useEffect(() => {
  //   if (user && localStorage.getItem("user_id")) {
  //     console.log(user, "&&", localStorage.getItem("user_id"));
  //     document.addEventListener("visibilitychange", (event) => {
  //       if (document.visibilityState === "visible") {
  //         if (user?.id === localStorage.getItem("user_id")) {
  //         } else {
  //           window.location.reload();
  //         }
  //       } else {
  //         if (user?.id === localStorage.getItem("user_id")) {
  //         } else {
  //         }
  //       }
  //     });
  //   }
  // }, [user, localStorage.getItem("user_id")]);

  useEffect(() => {
    if (localStorage.getItem("idToken")) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  }, [
    login,
    user,
    location,
    localStorage.getItem("idToken"),
    localStorage.getItem("user_id"),
  ]);

  useEffect(() => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/registration"
    ) {
      if (
        localStorage.getItem("userOrgLength") == 0 &&
        !localStorage.getItem("org_id")
      ) {
        setCurrentPath("/registration/company-details");
      } else {
        setCurrentPath("/listings");
      }
    } else {
      if (
        localStorage.getItem("userOrgLength") == 0 &&
        !localStorage.getItem("org_id")
      ) {
        <Navigate to={"/registration/company-details"} />;
        setCurrentPath("/registration/company-details");
      } else {
        setCurrentPath(location.pathname);
      }
    }
  }, [location]);

  useEffect(() => {
    if (
      localStorage.getItem("userOrgLength") == 0 &&
      !localStorage.getItem("org_id")
    ) {
      navigate("/registration/company-details");
    }
  }, [localStorage.getItem("org_id"), localStorage.getItem("userOrgLength")]);

  useEffect(() => {
    if (!localStorage.getItem("idToken") || !localStorage.getItem("user_id")) {
      navigate(`/login`);
      // console.log("first called");
      setLogin(false);
    }
  }, [localStorage.getItem("idToken"), localStorage.getItem("user_id")]);

  return (
    // <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Routes>
        {/* <Route path="/login" element={<LoginForm />} /> */}
        <Route
          path="/login"
          element={
            localStorage.getItem("idToken") &&
            localStorage.getItem("user_id") ? (
              <Navigate to={currentPath} />
            ) : (
              <LoginForm />
            )
          }
        />
        {/* <Route path="/registration" element={<Registration />} /> */}
        <Route
          path="/registration"
          element={
            localStorage.getItem("idToken") ? (
              <Navigate to={currentPath} />
            ) : (
              <Registration />
            )
          }
        />
        <Route
          path="/registration/company-details"
          element={<Registration />}
        />
        <Route path="/apartment-resman" element={<AddApartmentFromResman />} />
        <Route
          path="/registration/company-details/create-listing"
          element={<Registration />}
        />
        <Route
          path="/registration/company-details/create-listing/create-new-apartment"
          element={<Registration />}
        />
        <Route
          path="/registration/company-details/create-listing/create-new-apartment/list-unit"
          element={<Registration />}
        />

        <Route path="/join-with-us" element={<ThanksPage />} />
        <Route path="/join-company" element={<JoinCompany />} />
        <Route path="/faqs" element={<Faqs />} />

        <Route element={<PrivateRoute auth={login} />}>
          <Route path="*" element={<Layout />} />
        </Route>
      </Routes>
    </ThemeProvider>
    // </Provider>
  );
}

export default App;
