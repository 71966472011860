import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  message,
  Row,
  Col,
  Checkbox,
  List,
  Button,
} from "antd";
import { AiOutlineUpload } from "react-icons/ai";
import { CoverImage, ImagePreview } from "../ImagePreview/index";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { TiDeleteOutline } from "react-icons/ti";
import { MdClear } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledFormSelect,
  StyledForms,
  StyledTitle,
} from "../createPersonalProfile/RegistrationStyle";
import {
  DropDownView,
  ListWrap,
  PlusWrap,
  PropertyBtn,
  StyledCover,
  StyledUploadPreviewWrapper,
  StyledUploadPreviewWrapperCover,
  UploadBox,
  UploadInput,
} from "../apartment-listing/ListApartmentStyle";
import { ManagePropertyBtn } from "../login/LoginStyle";
import { createUnit, updateUnitApi } from "../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { BsCurrencyDollar } from "react-icons/bs";
import { formatedValue, imagePath } from "../../utils/fn";

function ListUnit({
  pageTitle,
  bgImage,
  pageMargin,
  isModal,
  onCancel,
  isEdit = false,
  updateUnit,
  getUpdatedUnit = console.log,
  onFormChange = console.log,
  newListedUnit = console.log,
  getAllApartmentState,
  unitUpdateCall = console.log,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const params = useParams();
  const dispatch = useDispatch();
  const CheckboxGroup = Checkbox.Group;
  const { user } = useSelector((state) => state.user);
  const currentDateTime = new Date();
  const navigate = useNavigate();
  const upload = React.useRef(null);
  const [state, setState] = useState([]);
  const [selectAmmenities, setSelectAmmenities] = useState([]);
  const [allApartmentOrg, setAllApartmentOrg] = useState([]);
  const [totalSelectedAmi, setTotalSelectedAmi] = useState([]);
  const [viewAmmenities, setViewAmmenities] = useState(false);
  const [selectedApartment, setselectedApartment] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [selectImgIdx, setSelectImgIdx] = useState(0);
  const [amenitiesLength, setAmenitiesLength] = useState(12);

  useEffect(() => {
    if (isEdit && Object.keys(updateUnit).length) {
      setSelectAmmenities(updateUnit?.ammenities);
      form.setFieldsValue({
        ammenities: updateUnit?.ammenities,
        bed: updateUnit?.bed,
        diposit: updateUnit?.diposit,
        min_credit_score: updateUnit?.min_credit_score,
        unitNumber: updateUnit?.unitNumber,
        unitStatus: updateUnit?.unitStatus,
        bath: updateUnit?.bath,
        sqft: updateUnit?.sqft,
        buldingNumber: updateUnit?.buldingNumber,
        unitType: updateUnit?.unitType,
        apartment_id: updateUnit?.apartment_id,
        user_id: updateUnit?.user_id,
        images: updateUnit?.images,
        description: updateUnit?.description,
        price: updateUnit?.price,
        id: updateUnit?.id,
        unit_name: updateUnit?.unit_name,
        imageUrls: updateUnit?.imageUrls,
      });
    }
  }, []);

  useEffect(() => {
    if (updateUnit && updateUnit?.images && isEdit) {
      updateUnit?.images.map((img) => {
        setState((s) => [
          ...s,
          {
            ...(img.isCover ? { isCover: img.isCover } : ""),
            key: img.key,
            // url: img.url,
            path: img.path,
          },
        ]);
      });
    }
  }, [updateUnit, isEdit]);

  const removeAmmenities = (value) => {
    if (checkedList?.includes(value)) {
      const arr = checkedList.filter((item) => item !== value);
      setCheckedList(arr);
      setCheckAll(false);
    } else if (checkedListWasherDryer?.includes(value)) {
      const arr = checkedListWasherDryer.filter((item) => item !== value);
      setCheckedListWasherDryer(arr);
      setCheckAllWasherDryer(false);
    } else if (checkedListKitchen?.includes(value)) {
      const arr = checkedListKitchen.filter((item) => item !== value);
      setCheckedListKitchen(arr);
      setCheckAllKitchen(false);
    } else if (checkedListLiving?.includes(value)) {
      const arr = checkedListLiving.filter((item) => item !== value);
      setCheckedListLiving(arr);
      setCheckAllLiving(false);
    } else if (checkedListComfortSafety?.includes(value)) {
      const arr = checkedListComfortSafety.filter((item) => item !== value);
      setCheckedListComfortSafety(arr);
      setCheckAllComfortSafety(false);
    }

    // setSelectAmmenities(selectAmmenities.filter((item) => item !== value));
  };

  useEffect(() => {
    if (pageTitle) {
      pageTitle("List Your Unit");
      pageMargin("50px auto");
      bgImage("/images/listApartment.png");
    }
  }, []);

  // const fileChangeHandler = (event) => {
  //   console.log(event.target.files);
  //   setState((s) => [
  //     ...s,
  //     ...Array.from(event.target.files).map((file) => ({
  //       image: file,
  //       path: URL.createObjectURL(file),
  //     })),
  //   ]);
  // };

  // const fileChangeHandlerDrag = (files) => {
  //   console.log(files, "files");
  //   setState((s) => [
  //     ...s,
  //     ...Array.from(files).map((file) => ({
  //       image: file,
  //       path: URL.createObjectURL(file),
  //     })),
  //   ]);
  // };

  const fileChangeHandler = async (event) => {
    // console.log(event.target.files);
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    // Array(...event.target.files).map(async (img) => {
    //   const convertImg = await convertToBase64(img);
    //   setState((s) => [
    //     ...s,
    //     {
    //       image: convertImg,
    //       path: URL.createObjectURL(img),
    //       name: img.name,
    //     },
    //   ]);
    // });

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setState((s) => [
        ...s,
        {
          image: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
        },
      ]);
    };

    Array(...event.target.files) &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          let fileExtension = [
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
            "jfif",
            "tiff",
          ];
          let filename = file.name;
          let fileExt = filename
            .substring(filename.lastIndexOf(".") + 1)
            .toLowerCase();
          if (fileExtension.includes(fileExt)) {
            return await newFun(file);
          } else {
            return message.warning("Please upload Image file");
          }
        })
      ));
  };

  const fileChangeHandlerDrag = async (files) => {
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setState((s) => [
        ...s,
        {
          image: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
        },
      ]);
    };

    await Promise.all(
      Array(...files).map(async (file) => {
        let fileExtension = [
          "jpeg",
          "jpg",
          "png",
          "gif",
          "bmp",
          "jfif",
          "tiff",
        ];
        let filename = file.name;
        let fileExt = filename
          .substring(filename.lastIndexOf(".") + 1)
          .toLowerCase();
        if (fileExtension.includes(fileExt)) {
          return newFun(file);
        } else {
          return message.warning("Please upload Image file");
        }
        // const convertImg = await convertToBase64(img);
        // setState((s) => [
        //   ...s,
        //   {
        //     image: convertImg,
        //     path: URL.createObjectURL(img),
        //     name: img.name,
        //   },
        // ]);
      })
    );
    // setState((s) => [
    //   ...s,
    //   ...Array.from(files).map((file) => ({
    //     image: file,
    //     path: URL.createObjectURL(file),
    //   })),
    // ]);
  };

  useEffect(() => {
    form.setFieldsValue({
      images: state,
      ammenities: selectAmmenities,
      // apartment: localStorage.getItem("apartment_name"),
    });
  }, [form, state, selectAmmenities]);

  useEffect(() => {
    if (localStorage.getItem("apartment_name")) {
      setselectedApartment(true);
    }
  }, []);

  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    if (params?.id) {
      const selectedApa = [...getAllApartmentState]?.filter((item) => {
        return item["id"] === params.id;
      });
      form.setFieldsValue({
        apartment_id: isEdit ? updateUnit?.apartment_id : params.id,
        unitType: selectedApa?.length
          ? unitApartmentName(selectedApa[0]?.unitType)
          : Object.keys(updateUnit || {}).length
          ? updateUnit?.unitType
          : null,
      });
    }
  }, [params.id]);

  const onCoverChange = (idx) => {
    const st = [...state];
    st.map((item, key) => {
      if (key != idx) {
        delete item.isCover;
      } else {
        st[idx].isCover = true;
      }
    });
    setState(st);
  };

  const returnTag = (val = []) => {
    return val[0]?.map((item) => (
      <Col>
        <ListWrap>
          <p>{item}</p>
        </ListWrap>
      </Col>
    ));
  };

  const onFinish = (value) => {
    if (params.id) {
      if (isEdit && Object.keys(updateUnit).length) {
        value.user_id = user.id;
        value.id = updateUnit.id;
        value.sqft = formatedValue(value.sqft);
        value.bed = formatedValue(value.bed);
        value.bath = formatedValue(value.bath);
        value.price = formatedValue(value.price);
        value.diposit = formatedValue(value.diposit);
        value.apartment_id = updateUnit.apartment_id;
        value.activeStatus = updateUnit.activeStatus;
        value.ammenities = totalSelectedAmi;
        value.updatedAt = currentDateTime;
        value.min_credit_score = value.min_credit_score;
        value.createdAt = updateUnit.createdAt
          ? updateUnit.createdAt
          : currentDateTime;
        // value.unitType = updateUnit?.unitType;
        value.unitStatus = updateUnit?.unitStatus;
        // value.tenant = updateUnit?.tenant;
        value.fees = updateUnit?.fees;

        if (updateUnit?.org_id) {
          value.org_id = updateUnit?.org_id;
        }
        // if (updateUnit?.tenant) {
        //   value.tenant = updateUnit.tenant;
        // }
        if (updateUnit?.tenant_id) {
          value.tenant_id = updateUnit.tenant_id;
        }
        if (state?.length && value.images) {
          const arr = [];
          value.images.map((item) => {
            arr.push(...Object.keys(item));
          });
          if (!arr.includes("isCover")) {
            value.images[0].isCover = true;
          }
        }
        if (
          value.unit_name &&
          value.unitType &&
          value.sqft &&
          value.bed &&
          value.bath &&
          value.price
        ) {
          value.publishStatus = "active";
          value.unitStatus =
            updateUnit.unitStatus === "Occupied" ? "Occupied" : "Available";
          // } else if (updateUnit.unitStatus === "Occupied") {
          //   value.unitStatus = "Occupied";
          //   value.publishStatus = updateUnit.publishStatus;
        } else {
          value.publishStatus = "deactivate";
          value.unitStatus = "Configure";
        }

        setloadings(true);
        dispatch(updateUnitApi(value)).then(({ payload }) => {
          if (payload?.Error) {
            message.error(payload?.Error);
          } else {
            message.success("Unit updated successfully");
          }
          getUpdatedUnit(payload);
          setloadings(false);
          onCancel();
          unitUpdateCall();
        });
      } else {
        setloadings(true);
        // value.unitStatus = "Available";
        value.user_id = user.id;
        value.sqft = formatedValue(value.sqft);
        value.bed = formatedValue(value.bed);
        value.bath = formatedValue(value.bath);
        value.price = formatedValue(value.price);
        if (state?.length && value.images) {
          value.images[selectImgIdx].isCover = true;
        }
        value.tenant = {};
        if (
          value.unit_name &&
          value.unitType &&
          value.sqft &&
          value.bed &&
          value.bath &&
          value.price &&
          value.diposit
        ) {
          value.activeStatus = "active";
          value.publishStatus = "active";
          value.unitStatus = "Available";
        } else {
          value.activeStatus = "deactivate";
          value.publishStatus = "deactivate";
          value.unitStatus = "Configure";
        }
        if (state?.length && value.images) {
          const arr = [];
          value.images.map((item) => {
            arr.push(...Object.keys(item));
          });
          if (!arr.includes("isCover")) {
            value.images[0].isCover = true;
          }
        }
        value.updatedAt = currentDateTime;
        value.createdAt = currentDateTime;
        value.min_credit_score = value.min_credit_score;
        dispatch(createUnit(value)).then(({ payload }) => {
          message.success("Unit created successfully");
          newListedUnit(payload);
          setloadings(false);
          onCancel();
          unitUpdateCall();

          // onCancel();
        });
      }
    } else {
      if (isEdit && Object.keys(updateUnit).length) {
        value.user_id = user.id;
        value.id = updateUnit.id;
        value.sqft = formatedValue(value.sqft);
        value.bed = formatedValue(value.bed);
        value.bath = formatedValue(value.bath);
        value.price = formatedValue(value.price);
        value.diposit = formatedValue(value.diposit);
        value.activeStatus = updateUnit.activeStatus;
        value.ammenities = selectAmmenities;
        value.updatedAt = currentDateTime;
        value.createdAt = updateUnit.createdAt
          ? updateUnit.createdAt
          : currentDateTime;
        value.unitStatus = updateUnit?.unitStatus;
        value.apartment_id = updateUnit.apartment_id;
        // value.tenant = updateUnit?.tenant;
        value.fees = updateUnit?.fees;
        value.min_credit_score = value.min_credit_score;

        if (updateUnit?.org_id) {
          value.org_id = updateUnit.org_id;
        }
        // if (updateUnit?.tenant) {
        //   value.tenant = updateUnit.tenant;
        // }
        if (updateUnit?.tenant_id) {
          value.tenant_id = updateUnit.tenant_id;
        }
        if (state?.length && value.images) {
          const arr = [];
          value.images.map((item) => {
            arr.push(...Object.keys(item));
          });
          if (!arr.includes("isCover")) {
            value.images[0].isCover = true;
          }
        }
        if (
          value.unit_name &&
          value.unitType &&
          value.sqft &&
          value.bed &&
          value.bath &&
          value.price
        ) {
          value.publishStatus = "active";
          value.unitStatus =
            updateUnit.unitStatus === "Occupied" ? "Occupied" : "Available";
          // } else if (updateUnit.unitStatus === "Occupied" {
          //   value.unitStatus = "Occupied";
          //   value.publishStatus = updateUnit.publishStatus;
        } else {
          value.publishStatus = "deactivate";
          value.unitStatus = "Configure";
        }

        setloadings(true);
        dispatch(updateUnitApi(value)).then(({ payload }) => {
          if (payload?.Error) {
            message.error(payload?.Error);
          } else {
            message.success("Unit updated successfully");
          }
          getUpdatedUnit(payload);
          setloadings(false);
          onCancel();
          unitUpdateCall();
        });
      }
    }
    onFormChange(false);
  };

  const onChange = (checkedValues) => {
    setSelectAmmenities(checkedValues);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      fileChangeHandlerDrag(files);
    }
  };

  const drop = React.useRef(null);
  useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);

    // return () => {
    //   drop.current.removeEventListener('dragover', handleDragOver);
    //   drop.current.removeEventListener('drop', handleDrop);
    // };
  }, []);

  const amenities = [
    "Bedroom",
    "Energy",
    "Ac",
    "Dishwasher",
    "Washroom",
    "Gym",
    "Kitchen",
    "Roof",
    "Fridge",
    "Yard",
    "Washer and Dryer",
    "Outdoor areas",
    "Cable ready",
    "Swimming pool",
    "Dining Area",
    "Gated Access",
    "Master Suite",
    "24/7 Water",
    "Pets allowed",
    "Internet access",
  ];

  const unitApartmentName = (value) => {
    return value;

    // if (value && value?.includes("unit")) {
    //   return value;
    // } else {
    //   return value + " " + "unit";
    // }
  };

  const onPropertyChange = (value) => {
    const selectedApa = [...getAllApartmentState].filter((item) => {
      return item["id"] === value;
    });
    form.setFieldsValue({
      unitType: selectedApa?.length
        ? unitApartmentName(selectedApa[0]?.unitType)
        : null,
    });
  };

  const highValue = [
    "Smart Technology in Unit",
    "Vinyl Flooring",
    "Gas Appliances",
    "Kitchen Islands",
    "Quartz Countertops",
    "Marble Countertops",
    "Tile Backsplash",
    "High Ceilings",
    "USB outlets",
    "Walk-in Shower",
  ];

  useEffect(() => {
    if (selectAmmenities?.length) {
      selectAmmenities.map((item) => {
        amenitieSet(Object.keys(item), Object.values(item));
      });
    }
  }, [selectAmmenities]);

  // const onCheckSelectAll = (e) => {
  //   setSelectAmmenities(
  //     e.target.checked
  //       ? [
  //           { "High Value": highValue },
  //           { "Washer Dryer": WasherDryer },
  //           { Kitchen: Kitchen },
  //           { Living: Living },
  //           { "Comfort Safety": ComfortSafety },
  //         ]
  //       : []
  //   );
  // };

  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const onChanges = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < highValue.length);
    setCheckAll(list.length === highValue.length);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? highValue : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const WasherDryer = [
    "Fullsize - connections",
    "Fullsize - supplied",
    "Stackable - connections",
    "Stackable - supplied",
  ];

  const [checkedListWasherDryer, setCheckedListWasherDryer] = useState([]);
  const [indeterminateWasherDryer, setIndeterminateWasherDryer] =
    useState(true);
  const [checkAllWasherDryer, setCheckAllWasherDryer] = useState(false);
  const [viewAllWasherDryer, setViewAllWasherDryer] = useState(false);
  const onChangesWasherDryer = (list) => {
    setCheckedListWasherDryer(list);
    setIndeterminateWasherDryer(
      !!list.length && list.length < WasherDryer.length
    );
    setCheckAllWasherDryer(list.length === WasherDryer.length);
  };
  const onCheckAllChangeWasherDryer = (e) => {
    setCheckedListWasherDryer(e.target.checked ? WasherDryer : []);
    setIndeterminateWasherDryer(false);
    setCheckAllWasherDryer(e.target.checked);
  };

  const Kitchen = [
    "Microwave",
    "Dishwasher",
    "Pantry",
    "Granite Counters",
    "Black/Stainless Appliances",
  ];

  const [checkedListKitchen, setCheckedListKitchen] = useState([]);
  const [indeterminateKitchen, setIndeterminateKitchen] = useState(true);
  const [checkAllKitchen, setCheckAllKitchen] = useState(false);
  const [viewAllKitchen, setViewAllKitchen] = useState(false);
  const onChangesKitchen = (list) => {
    setCheckedListKitchen(list);
    setIndeterminateKitchen(!!list.length && list.length < Kitchen.length);
    setCheckAllKitchen(list.length === Kitchen.length);
  };
  const onCheckAllChangeKitchen = (e) => {
    setCheckedListKitchen(e.target.checked ? Kitchen : []);
    setIndeterminateKitchen(false);
    setCheckAllKitchen(e.target.checked);
  };

  const Living = [
    "Walk-in Closet",
    "Patio",
    "Outside Storage",
    "Bay Windows",
    "Dry/Wet Bar",
  ];

  const [checkedListLiving, setCheckedListLiving] = useState([]);
  const [indeterminateLiving, setIndeterminateLiving] = useState(true);
  const [checkAllLiving, setCheckAllLiving] = useState(false);
  const [viewAllLiving, setViewAllLiving] = useState(false);
  const onChangesLiving = (list) => {
    setCheckedListLiving(list);
    setIndeterminateLiving(!!list.length && list.length < Living.length);
    setCheckAllLiving(list.length === Living.length);
  };
  const onCheckAllChangeLiving = (e) => {
    setCheckedListLiving(e.target.checked ? Living : []);
    setIndeterminateLiving(false);
    setCheckAllLiving(e.target.checked);
  };

  const ComfortSafety = [
    "Furnished Unit",
    "Fireplace",
    "Wood Floors",
    "Garden Tubs",
    "Laminate Floors",
    "Stained Concrete Floors",
    "Alarm System",
    "Ceramic Tile",
    "Ceiling Fans",
  ];

  const [checkedListComfortSafety, setCheckedListComfortSafety] = useState([]);
  const [indeterminateComfortSafety, setIndeterminateComfortSafety] =
    useState(true);
  const [checkAllComfortSafety, setCheckAllComfortSafety] = useState(false);
  const [viewAllComfortSafety, setViewAllComfortSafety] = useState(false);
  const onChangesComfortSafety = (list) => {
    setCheckedListComfortSafety(list);
    setIndeterminateComfortSafety(
      !!list.length && list.length < ComfortSafety.length
    );
    setCheckAllComfortSafety(list.length === ComfortSafety.length);
  };
  const onCheckAllChangeComfortSafety = (e) => {
    setCheckedListComfortSafety(e.target.checked ? ComfortSafety : []);
    setIndeterminateComfortSafety(false);
    setCheckAllComfortSafety(e.target.checked);
  };

  useEffect(() => {
    setTotalSelectedAmi([
      { "High Value": checkedList },
      { "Washer Dryer": checkedListWasherDryer },
      { Kitchen: checkedListKitchen },
      { Living: checkedListLiving },
      { "Comfort Safety": checkedListComfortSafety },
    ]);
    if (
      checkedList?.length ||
      checkedListWasherDryer?.length ||
      checkedListKitchen?.length ||
      checkedListLiving?.length ||
      checkedListComfortSafety?.length
    ) {
      setSelectAmmenities([
        { "High Value": checkedList },
        { "Washer Dryer": checkedListWasherDryer },
        { Kitchen: checkedListKitchen },
        { Living: checkedListLiving },
        { "Comfort Safety": checkedListComfortSafety },
      ]);
    } else {
      // setSelectAmmenities([]);
      setTotalSelectedAmi([]);
      form.setFieldsValue({
        ammenities: null,
      });
    }
  }, [
    state,
    checkedList,
    checkedListWasherDryer,
    checkedListKitchen,
    checkedListLiving,
    checkedListComfortSafety,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      ammenities: selectAmmenities,
    });
  }, [selectAmmenities]);

  const amenitieSet = (amenitiesType, values) => {
    switch (amenitiesType[0]) {
      case "High Value":
        setCheckedList(values[0]);
        setIndeterminate(
          !!values[0]?.length && values[0]?.length < highValue.length
        );
        setCheckAll(values[0].length === highValue.length);
        return "#FFEADE";
      case "Washer Dryer":
        setCheckedListWasherDryer(values[0]);
        setIndeterminateWasherDryer(
          !!values[0]?.length && values[0]?.length < WasherDryer.length
        );
        setCheckAllWasherDryer(values[0]?.length === WasherDryer.length);
      case "Kitchen":
        setCheckedListKitchen(values[0]);
        setIndeterminateKitchen(
          !!values[0]?.length && values[0]?.length < Kitchen.length
        );
        setCheckAllKitchen(values[0]?.length === Kitchen.length);
      case "Living":
        setCheckedListLiving(values[0]);
        setIndeterminateLiving(
          !!values[0]?.length && values[0]?.length < Living.length
        );
        setCheckAllLiving(values[0]?.length === Living.length);
      case "Comfort Safety":
        setCheckedListComfortSafety(values[0]);
        setIndeterminateComfortSafety(
          !!values[0]?.length && values[0]?.length < ComfortSafety.length
        );
        setCheckAllComfortSafety(values[0]?.length === ComfortSafety.length);
    }
  };

  const deleteImgInput = () => {
    const inputImg = document.getElementById("myInput");
    inputImg.value = null;
  };

  return (
    <StyledForm
      style={{ position: "relative" }}
      id="subunit-modal"
      wh={isModal ? "100" : "100"}
      smwh="100"
      xswh="100"
      xxswh="100"
    >
      <StyledForms>
        {isModal && (
          <StyledTitle>
            {isEdit ? "Update Unit" : "New Unit Listing"}
          </StyledTitle>
        )}
        <Form
          layout="vertical"
          // requiredMark={"optional"}
          form={form}
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          {!isEdit ? (
            <StyledFormSelect wh="100" smwh="100">
              <Form.Item
                name="apartment_id"
                label="Select Property"
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Select
                  placeholder="Select Property"
                  // getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  getPopupContainer={() =>
                    document.getElementById("subunit-modal")
                  }
                  suffixIcon={<IoIosArrowDown />}
                  disabled={true}
                  defaultValue={updateUnit?.apartment_id}
                  onChange={(value) => {
                    onPropertyChange(value);
                  }}
                >
                  {[...getAllApartmentState]?.map((item, idx) => (
                    <Option key={idx} value={item.id}>
                      {item.unit_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </StyledFormSelect>
          ) : (
            ""
          )}

          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="unit_name"
              label="Unit Name/Floor Plan"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Unit Name/Floor Plan is required.",
                },
              ]}
            >
              <Input placeholder="Unit Name" />
            </Form.Item>
          </StyledFormItem>

          <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
            <Form.Item
              name="unitType"
              label="Unit Type"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Select
                placeholder="Unit Type"
                suffixIcon={<IoIosArrowDown />}
                getPopupContainer={() =>
                  document.getElementById("subunit-modal")
                }
                disabled={true}
                defaultValue={updateUnit?.unitType}
              >
                <Option value="Single-family home">Single-family home</Option>
                <Option value="Condominium (Condo)">Condominium (Condo)</Option>
                <Option value="Townhouse">Townhouse</Option>
                <Option value="Apartment">Apartment</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="buldingNumber"
              label="Building Number"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input placeholder="Building Number" />
            </Form.Item>
            <Form.Item
              name="unitNumber"
              label="Unit Number"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input placeholder="Unit Number" />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
            <Form.Item
              name="sqft"
              label="Sq ft"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                type="number"
                placeholder="Sq ft"
                step="0.0001"
                min="0"
                onWheel={(e) => e.target.blur()}
                // onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
              />
            </Form.Item>
            <Form.Item
              name="bed"
              label="Bed"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                // onKeyDown={(event) => {
                //   if (event.key === ".") {
                //     event.preventDefault();
                //   }
                // }}
                // onInput={(event) => {
                //   event.target.value = event.target.value.replace(
                //     /[^0-9]*/g,
                //     ""
                //   );
                // }}
                // pattern="[0-9]"
                type="number"
                step="0.5"
                placeholder="Bed"
                min="0"
                onWheel={(e) => e.target.blur()}
                // onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
              />
            </Form.Item>
            <Form.Item
              name="bath"
              label="Bath"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                // onKeyDown={(event) => {
                //   if (event.key === ".") {
                //     event.preventDefault();
                //   }
                // }}
                // onInput={(event) => {
                //   event.target.value = event.target.value.replace(
                //     /[^0-9]*/g,
                //     ""
                //   );
                // }}
                // pattern="[0-9]"
                type="number"
                step="0.5"
                placeholder="Bath"
                min="0"
                onWheel={(e) => e.target.blur()}
                // onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
              />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
            <Form.Item
              name="price"
              label="Price"
              className="input__affix"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                prefix={<BsCurrencyDollar className="site-form-item-icon" />}
                type="number"
                placeholder="Price"
                min="0"
                step="0.0001"
                onWheel={(e) => e.target.blur()}
                // onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
              />
            </Form.Item>
            <Form.Item
              name="diposit"
              label="Deposit Amount"
              className="input__affix"

              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                prefix={<BsCurrencyDollar className="site-form-item-icon" />}
                type="number"
                step="0.0001"
                placeholder="Deposit Amount"
                onWheel={(e) => e.target.blur()}
                min="0"
                // onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
              />
            </Form.Item>
            <Form.Item
              label="Min. Credit Score"
              name="min_credit_score"
              rules={[
                { required: true },
                { max: 3, message: "Only 3 digits are allowed" },
              ]}
            >
              <Input
                type="number"
                min="0"
                placeholder="Min Credit Score"
                onWheel={(e) => e.target.blur()}
              />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="images"
              label="Unit Images/Floor Plan"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <UploadBox ref={drop} onClick={(e) => upload.current.click()}>
                <UploadInput>
                  <p className="ant-upload-text" style={{ fontSize: "16px" }}>
                    Upload Image of Property
                  </p>
                  <p className="ant-upload-drag-icon">
                    <AiOutlineUpload className="upload__icon" />
                  </p>
                  <p className="upload__icon">Upload</p>
                </UploadInput>
              </UploadBox>
              <input
                accept="image/*"
                id="myInput"
                multiple={"multiple"}
                type="file"
                ref={upload}
                style={{ display: "none" }}
                onChange={fileChangeHandler}
              />
              <div style={{ display: "flex" }}>
                <Row>
                  {state.map((img, idx) =>
                    img.isCover ? (
                      <Col>
                        <StyledUploadPreviewWrapperCover isPhoto={state.length}>
                          <CoverImage
                            src={img.path}
                            height={95}
                            width={95}
                            onDelete={() => {
                              const st = [...state];
                              // st.splice(0, 1);
                              st.splice(idx, 1);
                              setState(st);
                              deleteImgInput();
                              // upload.current.value = null;
                            }}
                          />
                          <StyledCover>
                            <p>Cover</p>
                          </StyledCover>
                        </StyledUploadPreviewWrapperCover>
                      </Col>
                    ) : (
                      <Col>
                        <StyledUploadPreviewWrapper isPhoto={state.length}>
                          <ImagePreview
                            key={idx}
                            src={img.path ? img.path : imagePath(img.path)}
                            height={95}
                            width={95}
                            onDelete={() => {
                              const st = [...state];
                              st.splice(idx, 1);
                              setState(st);
                              deleteImgInput();
                              // upload.current.value = null;
                            }}
                            onCover={() => {
                              const st = [...state];
                              setState(st);
                              setSelectImgIdx(idx);
                              onCoverChange(idx);
                            }}
                          />
                        </StyledUploadPreviewWrapper>
                      </Col>
                    )
                  )}
                </Row>
                {/* <StyledUploadPreviewWrapperCover isPhoto={state.length}>
                  <CoverImage
                    src={
                      state[selectImgIdx]?.path
                        ? state[selectImgIdx]?.path
                        : imagePath(state[selectImgIdx]?.path)
                    }
                    height={95}
                    width={95}
                    onDelete={() => {
                      const st = [...state];
                      st.splice(0, 1);
                      setState(st);
                      // upload.current.value = null;
                    }}
                  />
                  <StyledCover>
                    <p>Cover</p>
                  </StyledCover>
                </StyledUploadPreviewWrapperCover>
                <StyledUploadPreviewWrapper isPhoto={state.length}>
                  {state.slice(1).map((img, idx) => (
                    <>
                      {selectImgIdx === idx ? (
                        " "
                      ) : (
                        <ImagePreview
                          key={idx}
                          src={img.path ? img.path : imagePath(img.path)}
                          height={95}
                          width={95}
                          onDelete={() => {
                            const st = [...state];
                            st.splice(idx, 1);
                            setState(st);

                            // upload.current.value = null;
                          }}
                          onCover={() => {
                            const st = [...state];
                            setState(st);
                            setSelectImgIdx(idx);
                          }}
                        />
                      )}
                    </>
                  ))}
                </StyledUploadPreviewWrapper> */}
              </div>
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="ammenities"
              label="Amenities"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <PropertyBtn
                br={viewAmmenities ? "5px 5px 0 0" : ""}
                onClick={() => setViewAmmenities(!viewAmmenities)}
              >
                <p>Unit Amenities</p>
                {!viewAmmenities ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </PropertyBtn>

              {viewAmmenities && (
                <DropDownView>
                  <div>
                    <Row gutter={[20, 20]} justify="space-between">
                      {/* <Col span={24} className="select__aminities">
                        <PlusWrap>
                          <Checkbox
                            // indeterminate={indeterminate}
                            onChange={onCheckSelectAll}
                            // checked={checkAll}
                          >
                            <span className="span__aminities">Select All</span>
                          </Checkbox>
                        </PlusWrap>
                      </Col> */}
                      <Col span={7} className="select__aminities">
                        <PlusWrap>
                          <Checkbox
                            indeterminate={indeterminate}
                            onChange={onCheckAllChange}
                            checked={checkAll}
                          >
                            <span className="span__aminities">High Value</span>
                          </Checkbox>
                          <h1
                            onClick={() => {
                              setViewAll(!viewAll);
                            }}
                          >
                            {viewAll ? "-" : "+"}
                          </h1>
                        </PlusWrap>
                        {viewAll ? (
                          <CheckboxGroup
                            className="select__aminities"
                            options={highValue}
                            value={checkedList}
                            onChange={onChanges}
                            style={{ marginLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col span={7} className="select__aminities">
                        <PlusWrap>
                          <Checkbox
                            indeterminate={indeterminateWasherDryer}
                            onChange={onCheckAllChangeWasherDryer}
                            checked={checkAllWasherDryer}
                          >
                            <span className="span__aminities">
                              Washer/Dryer
                            </span>
                          </Checkbox>
                          <h1
                            onClick={() => {
                              setViewAllWasherDryer(!viewAllWasherDryer);
                            }}
                          >
                            {viewAllWasherDryer ? "-" : "+"}
                          </h1>
                        </PlusWrap>
                        {viewAllWasherDryer ? (
                          <CheckboxGroup
                            className="select__aminities"
                            options={WasherDryer}
                            value={checkedListWasherDryer}
                            onChange={onChangesWasherDryer}
                            style={{ marginLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}

                        <PlusWrap>
                          <Checkbox
                            indeterminate={indeterminateKitchen}
                            onChange={onCheckAllChangeKitchen}
                            checked={checkAllKitchen}
                          >
                            <span className="span__aminities">Kitchen</span>
                          </Checkbox>
                          <h1
                            onClick={() => {
                              setViewAllKitchen(!viewAllKitchen);
                            }}
                          >
                            {viewAllKitchen ? "-" : "+"}
                          </h1>
                        </PlusWrap>
                        {viewAllKitchen ? (
                          <CheckboxGroup
                            className="select__aminities"
                            options={Kitchen}
                            value={checkedListKitchen}
                            onChange={onChangesKitchen}
                            style={{ marginLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col span={7} className="select__aminities">
                        <PlusWrap>
                          <Checkbox
                            indeterminate={indeterminateLiving}
                            onChange={onCheckAllChangeLiving}
                            checked={checkAllLiving}
                          >
                            <span className="span__aminities">Living</span>
                          </Checkbox>
                          <h1
                            onClick={() => {
                              setViewAllLiving(!viewAllLiving);
                            }}
                          >
                            {viewAllLiving ? "-" : "+"}
                          </h1>
                        </PlusWrap>
                        {viewAllLiving ? (
                          <CheckboxGroup
                            className="select__aminities"
                            options={Living}
                            value={checkedListLiving}
                            onChange={onChangesLiving}
                            style={{ marginLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}

                        <PlusWrap>
                          <Checkbox
                            indeterminate={indeterminateComfortSafety}
                            onChange={onCheckAllChangeComfortSafety}
                            checked={checkAllComfortSafety}
                          >
                            <span className="span__aminities">
                              Comfort & Safety:
                            </span>
                          </Checkbox>
                          <h1
                            onClick={() => {
                              setViewAllComfortSafety(!viewAllComfortSafety);
                            }}
                          >
                            {viewAllComfortSafety ? "-" : "+"}
                          </h1>
                        </PlusWrap>
                        {viewAllComfortSafety ? (
                          <CheckboxGroup
                            className="select__aminities"
                            options={ComfortSafety}
                            value={checkedListComfortSafety}
                            onChange={onChangesComfortSafety}
                            style={{ marginLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </div>
                </DropDownView>
              )}

              {/* {viewAmmenities && (
                <DropDownView>
                  <Checkbox.Group
                    defaultValue={selectAmmenities}
                    style={{ width: "100%" }}
                    onChange={onChange}
                  >
                    <Row justify="space-between">
                      {amenities?.slice(0, amenitiesLength).map((value, id) => (
                        <Col key={id} xs={10} sm={6}>
                          <Checkbox value={value}>{value}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>

                  {amenitiesLength === amenities.length ? (
                    ""
                  ) : (
                    <Row>
                      <Col style={{ paddingTop: "15px", cursor: "pointer" }}>
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            setAmenitiesLength(amenities.length);
                          }}
                        >
                          VIEW MORE
                        </span>
                      </Col>
                    </Row>
                  )}
                </DropDownView>
              )} */}
              {!viewAmmenities && (
                <>
                  <Row gutter={[10, 10]}>
                    {totalSelectedAmi?.map((val, idx) => (
                      <>
                        {Object.values(val)[0]?.length ? (
                          <>
                            <h6>{Object.keys(val)}</h6>
                            <Row gutter={[10, 10]}>
                              {returnTag(Object.values(val))}
                            </Row>
                          </>
                        ) : (
                          ""
                        )}
                        {/* <Col key={idx}>
                        <ListWrap>
                          <p>{val}</p>
                          <MdClear onClick={() => removeAmmenities(val)} />
                        </ListWrap>
                      </Col> */}
                      </>
                    ))}
                  </Row>
                  {/* {selectAmmenities?.length ? (
                    <ListWrap>
                      <List
                        size="large"
                        header={<div>Header</div>}
                        footer={<div>Footer</div>}
                        bordered
                        dataSource={selectAmmenities}
                        renderItem={(item) => (
                          <List.Item
                            extra={
                              <TiDeleteOutline
                                onClick={() => removeAmmenities(item)}
                              />
                            }
                          >
                            {item}
                          </List.Item>
                        )}
                      />
                    </ListWrap>
                  ) : (
                    ""
                  )} */}
                </>
              )}
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="description"
              label="Description"
              // rules={[{ required: true }]}
            >
              <Input.TextArea
                className="description__box"
                placeholder="Description"
              />
            </Form.Item>
          </StyledFormItem>

          <Form.Item>
            <StyledButton htmlType="submit" loading={loadings}>
              {isEdit ? "Update   " : "List A New"} Unit
            </StyledButton>
          </Form.Item>
        </Form>
      </StyledForms>
    </StyledForm>
  );
}

export default ListUnit;
