import React, { useCallback, useEffect, useState } from "react";
import { Popover, Table, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link, createSearchParams } from "react-router-dom";
import { Container } from "../../ui/Layout/Container";
import { Heading } from "../../ui/Heading/Heading";
import { httpAPIInvite } from "../../api";
import {
  BsClock,
  BsFileEarmarkCheck,
  BsFileEarmarkExcel,
} from "react-icons/bs";
import { ApartmentTable } from "../apartments-view/ApartmentsViewStyle";
import { dateConverter, upperCase } from "../../utils/fn";
import { updateApplicationList } from "../../store/reducers/user.reducer";

const ApplicationTable = () => {
  const { allApplications } = useSelector((state) => state.user);
  const [isLoading, setIsloading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [filteredApplications, setfilteredApplications] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [totalLength, setTotalLength] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageNumber: 1,
    total: "",
    size: "small",
    showSizeChanger: true,
  });
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (Object.keys(allApplications).length) {
  //     let arr = Object.values(allApplications?.data);
  //     let newPagination = { ...pagination, total: totalLength };
  //     setPagination((p) => ({ ...p, ...newPagination }));
  //     setfilteredApplications(arr);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (filteredApplications?.length) {
  //     dispatch(
  //       updateApplicationList({
  //         data: filteredApplications,
  //         // total: totalLength,
  //       })
  //     );
  //   }
  // }, [filteredApplications]);

  const onChangegetApplication = useCallback(
    (pagination, filters, sorter) => {
      setIsloading(true);
      httpAPIInvite(
        `/applications/admin/${user?.id}?pageSize=${
          pagination?.pageSize
        }&user_id=${user?.id}&org_id=${""}&pageNumber=${
          pagination?.pageNumber
        }&status=${filters?.status}&orderBy=${sorter}`,
        "",
        "GET"
      )
        .then((payload) => {
          if (payload?.data) {
            setfilteredApplications(payload?.data?.Data);
            setSearchData(payload?.data?.Data);
            setPagination((p) => ({
              ...p,
              ...(pagination?.total > 1 ? "" : { total: payload?.data?.total }),
            }));
          } else {
            setfilteredApplications([]);
            setSearchData([]);
          }
          setIsloading(false);
        })
        .catch((error) => {
          setIsloading(false);
          if (error) {
            message.error(error.message);
          }
        })
        .finally(() => {});
    },
    [pagination]
  );

  const getApplication = useCallback(
    (pagination, org_id, user_id) => {
      setIsloading(true);
      httpAPIInvite(
        `/applications/admin/${user_id}?pageSize=${pagination?.pageSize}&userId=${user_id}&org_id=${org_id}&pageNumber=${pagination?.pageNumber}`,
        "",
        "GET"
      )
        .then((payload) => {
          if (payload?.data) {
            setfilteredApplications(payload?.data?.Data);
            setSearchData(payload?.data?.Data);
            setTotalLength(payload?.data?.total);
            setPagination((p) => ({
              ...p,
              ...(pagination?.total > 1 ? "" : { total: payload?.data?.total }),
              ...(pagination?.lastEvaluatedKey?.length
                ? ""
                : { lastEvaluatedKey: payload?.data?.lastEvaluatedKey }),
              // total: payload?.data?.total,
              // lastEvaluatedKey: payload?.data?.lastEvaluatedKey,
            }));
          } else {
            setfilteredApplications([]);
            setSearchData([]);
          }
          setIsloading(false);
        })
        .catch((error) => {
          setIsloading(false);
          if (error) {
            message.error(error.message);
          }
        })
        .finally(() => {});
    },
    [pagination]
  );

  // // To filter application w.r.t org
  useEffect(() => {
    let newPagination = { ...pagination };
    if (user?.id) {
      if (!localStorage.getItem("viewAll")) {
        let orgId = localStorage.getItem("toSave");
        getApplication(newPagination, orgId, user?.id);
      } else {
        getApplication(newPagination, "", user?.id);
      }
    }
  }, [user, localStorage.getItem("viewAll"), localStorage.getItem("toSave")]);

  useEffect(() => {
    if (localStorage.getItem("viewAll")) {
    } else {
      localStorage.setItem("application", true);
    }
  }, [params, localStorage.getItem("toSave")]);

  useEffect(() => {
    if (localStorage.getItem("application")) {
      localStorage.removeItem("viewAll");
    }
  }, [params, localStorage.getItem("viewAll")]);

  // Switching Bg color with respect to application status
  const statusBgColor = (status) => {
    switch (status) {
      case "Unseen":
        return "#FFEADE";
      case "Viewed":
        return "#FFF3CA";
      case "Approved":
        return "#D0FFDD";
      case "Denied":
        return "#d7dadd";
      default:
        return "#ffffff";
    }
  };

  // Switching Font color with respect to application status
  const statusFtColor = (status) => {
    switch (status) {
      case "Unseen":
        return "#F78341";
      case "Viewed":
        return "#FFC123";
      case "Approved":
        return "#56C274";
      case "Denied":
        return "#F04646";
      default:
        return "#aaaaaa";
    }
  };

  // columns data for table
  const columns = [
    {
      title: "Application ID",
      dataIndex: "key",
      width: 160,
      render: (_, record) => (
        <>
          <td>{record?.serialNumber}</td>
        </>
      ),
    },
    {
      title: "Applicant Name",
      dataIndex: "userDetails",
      // width: 170,
      key: "userDetails",
      render: (_, record) => (
        <div>
          <>
            {upperCase(record?.userDetails?.firstName)}{" "}
            {upperCase(record?.userDetails?.lastName)}
          </>
        </div>
      ),
    },
    {
      title: "Unit Name",
      dataIndex: "key",
      render: (_, record) => (
        <>
          <td>
            {record?.unitDetail?.unit_name
              ? record?.unitDetail?.unit_name
              : "Unit Not Available"}
          </td>
        </>
      ),
    },
    {
      title: "Move-In",
      dataIndex: "selectedDate",
      width: "10%",
      key: "selectedDate",
      sorter: (a, b) => new Date(a.selectedDate) - new Date(b.selectedDate),
      render: (_, record) => (
        <>
          <span>{dateConverter(record?.selectedDate)}</span>
        </>
      ),
    },
    {
      title: "Lease Length",
      dataIndex: "leaseLength",
      key: "leaseLength",
      width: "9%",
      render: (_, record) => (
        <>
          <span>{record?.leaseLength}</span>
        </>
      ),
    },
    {
      title: "Credit Score",
      dataIndex: "credit_score_data",
      width: "10%",
      key: "credit_score_data",
      render: (_, record) => (
        <>
          <span>
            {record?.profileDetail?.credit_score_data?.length &&
            record?.unitDetail?.min_credit_score !== "" &&
            Number(record?.profileDetail?.credit_score_data[0]?.score) >=
              record?.unitDetail?.min_credit_score
              ? "Eligible"
              : "Does Not Meet"}
          </span>
        </>
      ),
    },
    {
      title: "Paystubs",
      dataIndex: "is_paystub1_verified",
      width: "7%",
      key: "is_paystub1_verified",
      align: "center",
      render: (_, record) => (
        <>
          <Popover
            content={
              <div>
                {record?.profileDetail?.is_paystub1_verified === 2 &&
                record?.profileDetail?.is_paystub2_verified === 2
                  ? "Rejected"
                  : record?.profileDetail?.is_paystub1_verified === 1 ||
                    record?.profileDetail?.is_paystub2_verified === 1
                  ? "Verified"
                  : "Approval Pending"}
              </div>
            }
          >
            <span>
              {record?.profileDetail?.is_paystub1_verified === 2 &&
              record?.profileDetail?.is_paystub2_verified === 2 ? (
                <BsFileEarmarkExcel style={{ color: "#F04646" }} />
              ) : record?.profileDetail?.is_paystub1_verified === 1 ||
                record?.profileDetail?.is_paystub2_verified === 1 ? (
                <BsFileEarmarkCheck style={{ color: "#56C274" }} />
              ) : (
                <BsClock style={{ color: "#4160AC" }} />
              )}
            </span>
          </Popover>
        </>
      ),
    },
    {
      title: "Employer Details",
      dataIndex: "is_employment_verified",
      key: "is_employment_verified",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <>
          <Popover
            content={
              <div>
                {record?.profileDetail?.is_employment_verified === 0
                  ? "Approval Pending"
                  : record?.profileDetail?.is_employment_verified === 1
                  ? "Verified"
                  : "Rejected"}
              </div>
            }
          >
            <span>
              {record?.profileDetail?.is_employment_verified === 0 ? (
                <BsClock style={{ color: "#4160AC" }} />
              ) : record?.profileDetail?.is_employment_verified === 1 ? (
                <BsFileEarmarkCheck style={{ color: "#56C274" }} />
              ) : (
                <BsFileEarmarkExcel style={{ color: "#F04646" }} />
              )}
            </span>
          </Popover>
        </>
      ),
    },
    {
      title: "Rental History",
      dataIndex: "is_rental_history_verified",
      key: "is_rental_history_verified",
      align: "center",
      width: "9%",
      render: (_, record) => (
        <>
          <Popover
            content={
              <div>
                {record?.profileDetail?.is_rental_history_verified === 0
                  ? "Approval Pending"
                  : record?.profileDetail?.is_rental_history_verified === 1
                  ? "Verified"
                  : "Rejected"}
              </div>
            }
          >
            <span>
              {record?.profileDetail?.is_rental_history_verified === 0 ? (
                <BsClock style={{ color: "#4160AC" }} />
              ) : record?.profileDetail?.is_rental_history_verified === 1 ? (
                <BsFileEarmarkCheck style={{ color: "#56C274" }} />
              ) : (
                <BsFileEarmarkExcel style={{ color: "#F04646" }} />
              )}
            </span>
          </Popover>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "8%",
      // filteSearch: true,
      filters: [
        {
          text: "Approved",
          value: 2,
        },
        {
          text: "Denied",
          value: 3,
        },
        {
          text: "Unseen",
          value: 0,
        },
        {
          text: "Viewed",
          value: 1,
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, record) => (
        <span
          style={{
            color: statusFtColor(
              record?.status === 0
                ? "Unseen"
                : record?.status === 1
                ? "Viewed"
                : record?.status === 2
                ? "Approved"
                : "Denied"
            ),
          }}
        >
          <>
            {record?.status === 0
              ? "Unseen"
              : record?.status === 1
              ? "Viewed"
              : record?.status === 2
              ? "Approved"
              : "Denied"}
          </>
        </span>
      ),
    },
  ];

  // Onchange function for table change/pagination
  const onTableChange = (newPagination, filters, sorter) => {
    if (filters || sorter) {
      newPagination.pageNumber = newPagination.current;
      onChangegetApplication(newPagination, filters, sorter?.order);
      setPagination((p) => ({ ...p, ...newPagination, filters, sorter }));
    } else {
      newPagination.pageNumber = newPagination.current;
      onChangegetApplication(newPagination);
      setPagination((p) => ({ ...p, ...newPagination, filters, sorter }));
    }
  };

  // To navigate to Application details page
  const navigateParam = (item) => {
    localStorage.setItem("prospectUserId", item.prospectUserId);
    navigate({
      pathname: `/applications`,
      search: createSearchParams({
        unitId: item.id,
      }).toString(),
    });
  };

  return (
    <>
      <Container>
        <Heading>
          <Link to="/application">Applications</Link>
        </Heading>
        <ApartmentTable style={{ cursor: "pointer" }}>
          <Table
            columns={columns}
            dataSource={[...filteredApplications]}
            onChange={onTableChange}
            loading={isLoading}
            scroll={{ x: 1100, y: 550 }}
            pagination={pagination}
            onRow={(item) => ({
              onClick: () => {
                navigateParam(item);
                localStorage.setItem("application_id", item?.id);
              },
            })}
          />
        </ApartmentTable>
      </Container>
    </>
  );
};

export default ApplicationTable;
