import {
  Breadcrumb,
  Checkbox,
  Col,
  Divider,
  Form,
  Row,
  Spin,
  Switch,
  Table,
  message,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { FaBath } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
import { IoIosBed, IoMdCall } from "react-icons/io";
import { MdLocationOn, MdMoreVert } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {
  AvenewImg,
  EditIcon,
  HomeSvg,
  ShareIcon,
  SqFtSvg,
} from "../../assets/svgs";
import AddApartmentModal from "../../components/addApartmentModal/AddApartmentModal";
import UpdateApartmentModal from "../../components/addApartmentModal/UpdateApartmentModal";
import UpdateUnitModal from "../../components/addApartmentModal/UpdateUnitModal";
import { ListWrap } from "../../components/apartment-listing/ListApartmentStyle";
import {
  ApartmentTable,
  FloatButton,
  SecondaryButton,
} from "../../components/apartments-view/ApartmentsViewStyle";
import { BreadCrumbsWrapper } from "../../components/layout/TheLayout.styles";
import { AddNewApr } from "../../components/listings/Listingstyle";
import {
  deleteUnit,
  getSingleApartment,
  propertyStatus,
} from "../../store/reducers/user.reducer";
import { Heading } from "../../ui/Heading/Heading";
import { Container } from "../../ui/Layout/Container";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import ShareModal from "../../components/share-modal/ShareModal";
import Actions from "../../ui/Actions/Actions";
import { formatPhoneNumber, toUSACurrency, upperCase } from "../../utils/fn";
import { httpAPI } from "../../api";

function SubUnit() {
  const location = useLocation();
  const { width } = useWindowSize();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const dispatch = useDispatch();
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const [tableDataLoad, setTableDataLoad] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [shareModalState, setshareModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [modalStateUpdate, setModalStateUpdate] = useState(false);
  const [unitsNumberWidth, setUnitsNumberWidth] = useState("12%");
  const [yScroll, setyScroll] = useState(600);
  const [orgName, setOrgName] = useState([]);
  const [propperty, setPropperty] = useState({});
  const [oneUnit, setOneUnit] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [allUnitObj, setAllUnitObj] = useState({});
  const [allUnitArr, setAllUnitArr] = useState([]);
  const [getAllApartmentState, setGetAllApartment] = useState({});
  const [allApartmentForModal, setAllApartmentForModal] = useState([]);
  const [coverImgArr, setCoverImgArr] = useState([]);
  const [deavtiveUnits, setDeavtiveUnits] = useState([]);
  const [filerData, setFilerData] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minSqFt, setMinSqFt] = useState(0);
  const [maxSqFt, setMaxSqFt] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    size: "small",
    showSizeChanger: true,
  });

  const navigate = useNavigate();
  const { units } = useSelector((state) => state.user);
  const { allOrganization } = useSelector((state) => state.user);
  const { apartment } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);
  const unitTypes = [
    { value: "Single-family home", text: "Single-family home" },
    { value: "Condominium (Condo)", text: "Condominium (Condo)" },
    { value: "Townhouse", text: "Townhouse" },
    { value: "Apartment", text: "Apartment" },
    // { value: "Farm/Ranch", text: "Farm/Ranch" },
    // { value: "Multi-family home", text: "Multi-family home" },
    // { value: "Commercial", text: "Commercial" },
  ];
  const unitsStatus = [
    { value: "Available", text: "Available" },
    { value: "Contract Sent", text: "Contract Sent" },
    { value: "Pending", text: "Pending" },
    { value: "Configure", text: "Configure" },
    { value: "Sold", text: "Sold" },
    { value: "Occupied", text: "Occupied" },
  ];

  const returnTag = (val) => {
    return val[0].map((item) => (
      <Col>
        <ListWrap>
          <p>{item}</p>
        </ListWrap>
      </Col>
    ));
  };

  // find unit from params unit id
  useEffect(() => {
    if (searchParams.get("unitId") && params.id) {
      var arrOfUnits = [];

      for (var item in units) {
        if (units.hasOwnProperty(item)) {
          arrOfUnits.push(units[item]);
        }
      }

      var orgValue = arrOfUnits.filter(function (item) {
        return item["id"] === searchParams.get("unitId");
      });
      setSelectedUnit(orgValue[0]);
    } else if (params?.id) {
      var arr = [];

      for (var item in apartment) {
        if (apartment.hasOwnProperty(item)) {
          arr.push(apartment[item]);
        }
      }

      var orgValue = arr.filter(function (item) {
        return item["id"] === params.id;
      });
      navigate({
        pathname: `/subunit/${localStorage.getItem("prop_id")}`,
      });
    } else {
    }
  }, [params.id, apartment, units]);

  //  get organization data from unit ID
  useEffect(() => {
    if (oneUnit?.length) {
      var arr = [];

      for (var item in allOrganization) {
        if (allOrganization.hasOwnProperty(item)) {
          arr.push(allOrganization[item]);
        }
      }

      var orgValue = arr.filter(function (item) {
        return item["id"] === oneUnit[0].org_id;
      });
      setOrgName(orgValue);
    }
  }, [params.id, allOrganization, user, oneUnit]);

  // get API response for get signle apartment
  useEffect(() => {
    dispatch(getSingleApartment()).then(({ payload }) => {
      setPropperty(payload);
    });
  }, []);

  // data set in object to array
  useEffect(() => {
    if (propperty && Object.keys(propperty).length) {
      const arr = Object.values(propperty);
      setOneUnit(arr);
      // setWaitForApart(false);
    } else {
      setOneUnit([]);
      // setWaitForApart(true);
    }
  }, [propperty]);

  // filer data and set value
  useEffect(() => {
    if (allUnitObj && Object.keys(allUnitObj).length) {
      var arr = [];

      for (var item in allUnitObj) {
        if (allUnitObj.hasOwnProperty(item)) {
          arr.push(allUnitObj[item]);
        }
      }

      const arrOfSort = [];
      let sortForeach = arr.map((obj) => {
        return obj.id;
      });
      sortForeach.sort();

      sortForeach?.map((val) => {
        arr.filter((obj) => {
          if (obj["id"] === val) {
            arrOfSort.push(obj);
          }
        });
      });
      setFilerData(arrOfSort);
      setAllUnitArr(arrOfSort);
    } else {
    }
  }, [allUnitObj]);

  // filer data and set value
  useEffect(() => {
    if (allUnitArr?.length) {
      var a = [];

      allUnitArr?.forEach(function (obj) {
        a.push(
          obj?.price
            ? obj?.price === undefined
              ? null
              : Number(obj?.price)
            : []
        );
      });

      function removeNull(array) {
        return array.filter((x) => x !== null);
      }

      const max = Math.max(...removeNull(a));
      const min = Math.min(...removeNull(a));

      setMaxPrice(max);
      setMinPrice(min);
    }
  }, [allUnitArr]);

  // finding low and high Sq.Ft
  useEffect(() => {
    if (allUnitArr?.length) {
      var a = [];

      allUnitArr?.forEach(function (obj) {
        a.push(
          obj?.sqft ? (obj?.sqft === undefined ? null : Number(obj?.sqft)) : []
        );
      });

      function removeNull(array) {
        return array.filter((x) => x !== null);
      }

      const max = Math.max(...removeNull(a));
      const min = Math.min(...removeNull(a));

      setMinSqFt(min);
      setMaxSqFt(max);
    }
  }, [allUnitArr]);

  // When modal open then stop moving backgound
  const showDialog = () => {
    document.documentElement.style.getPropertyValue("--scroll-y");
    const body = document.body;
    body.style.height = "100vh";
    body.style.overflowY = "hidden";
  };

  // When modal close then start moving backgound
  const closeDialog = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = "";
    body.style.top = "";
    body.style.height = "";
    body.style.overflowY = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  };

  // get updated apartment from modal
  const getUpdatedApartment = (value) => {
    if (value && Object.keys(value).length) {
      const arr = [value];
      setOneUnit(arr);
      if (allApartmentForModal.length) {
        const objIndex = allApartmentForModal.findIndex(
          (obj) => obj.id === value.id
        );
        allApartmentForModal[objIndex] = value;
      }
    }
  };

  // get updated unit from modal
  const getUpdatedUnit = (value) => {
    if (value && Object.keys(value).length) {
      setSelectedUnit(value);
      if (allUnitArr?.length) {
        const objIndex = allUnitArr.findIndex((obj) => obj.id === value.id);
        allUnitArr[objIndex] = value;
        setAllUnitArr([...allUnitArr]);
      }
    }
  };

  // table x and y scroll based on screen size
  useEffect(() => {
    if (width < 1550 && width > 991) {
      setyScroll(600);
    } else {
      setyScroll(600);
    }
  }, [width, location.pathname]);

  // with based on screen size
  useEffect(() => {
    if (width > 1500) {
      setUnitsNumberWidth("22%");
    } else if (width < 1500) {
      setUnitsNumberWidth("16%");
    } else if (width < 1441) {
    }
  }, [width, location.pathname]);

  // page navigation header
  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    {
      path: "/subunit",
      label: `${upperCase(oneUnit[0]?.unit_name)}`,
      isLink: false,
      onClick: true,
    },

    {
      path: "/account",
      label: "My Account",
      isLink: false,
    },
  ];

  const extraBreadcrumbItems = pathSnippets
    ?.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap?.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb?.path}_${index}`}>
          {breadcrumb?.isLink ? (
            <Link to={breadcrumb?.isLink && breadcrumb?.path}>
              {breadcrumb?.label}
            </Link>
          ) : breadcrumb?.onClick ? (
            <span
              style={{ cursor: "pointer" }}
              className="breadcrumbs_label"
              onClick={() => setSelectedUnit([])}
            >
              {breadcrumb?.label}{" "}
              {selectedUnit && Object.keys(selectedUnit)?.length ? (
                <span style={{ cursor: "auto" }}>
                  {" "}
                  &gt; {selectedUnit.unit_name}
                </span>
              ) : (
                ""
              )}
            </span>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      {/* <Link to={`/property/${orgName[0]}`}>{orgName[0].org_name}</Link> */}
      <Link
        to={`/property/${orgName[0]?.id}`}
        onClick={() => localStorage.setItem("toSave", orgName[0]?.id)}
      >
        {upperCase(orgName[0]?.org_name)}
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const getAllUnits = useCallback(
    (pagination, filters = {}, apartment_id = params?.id) => {
      setTableDataLoad(true);
      httpAPI(
        `/units/apartment/${apartment_id}?pageSize=${pagination?.pageSize}&pageNumber=${pagination?.pageNumber}`,
        "",
        "GET"
      ).then((payload) => {
        if (payload?.data || payload?.data?.Data) {
          setAllUnitObj(payload?.data?.Data);
          setPagination((p) => ({
            ...p,
            ...(payload?.data?.total ? { total: payload?.data?.total } : ""),
          }));
        }
        setSwitchBtnLoading(false);
        setTableDataLoad(false);
      });
    },
    [pagination]
  );

  useEffect(() => {
    if (params?.id) {
      let newPagination = { ...pagination };
      getAllUnits(newPagination, {}, params?.id);
    }
  }, [params.id]);

  // set editable data in state
  const editValue = (value) => {
    setSelectedUnit(value);
    setModalStateUpdate(true);
  };

  const [switchBtnLoading, setSwitchBtnLoading] = useState(false);
  const [switchBtnId, setSwitchBtnId] = useState("");

  const publishStatusBtn = (value, record) => {
    setSwitchBtnLoading(true);
    setTableDataLoad(true);
    setSwitchBtnId(record.id);
    let valueObj = {};
    const objKeys = Object.keys(record);
    if (objKeys.includes("apartment_id")) {
      valueObj.publishStatus =
        record.publishStatus === "active" ? "deactivate" : "active";
      valueObj.unit_id = record.id;
      valueObj.user_id = record.user_id;
    } else {
      valueObj.publishStatus =
        record.publishStatus === "active" ? "deactivate" : "active";
      valueObj.apartment_id = record.id;
      valueObj.user_id = record.user_id;
    }

    dispatch(propertyStatus(valueObj)).then((res) => {
      if (res?.payload?.Error) {
        message.error(res?.payload?.Error);
      } else {
      }
      dispatch(getAllUnits(pagination, {}, params?.id));
    });

    setTimeout(() => {
      setSwitchBtnLoading(false);
    }, 2000);
  };

  // status active button (submit)
  const activeStatusBtn = (record) => {
    setSwitchBtnId(record.id);
    let valueObj = {};
    const objKeys = Object.keys(record);
    if (objKeys.includes("apartment_id")) {
      valueObj.activeStatus =
        record.activeStatus === "active" ? "deactivate" : "active";

      valueObj.unit_id = record.id;
      valueObj.user_id = record.user_id;
    } else {
      valueObj.activeStatus =
        record.activeStatus === "active" ? "deactivate" : "active";
      valueObj.apartment_id = record.id;
      valueObj.user_id = record.user_id;
    }
    setTableDataLoad(true);

    dispatch(propertyStatus(valueObj)).then((res) => {
      if (res?.payload?.Error) {
        message.error(res?.payload?.Error);
      } else {
        message.success(
          valueObj.activeStatus === "deactivate"
            ? "Deactivated successfully"
            : "Activated successfully"
        );
      }
      dispatch(getAllUnits(pagination, {}, params?.id));
    });

    setTimeout(() => {
      setSwitchBtnLoading(false);
    }, 2000);
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Deactive",
      value: "deactive",
      isDanger: false,
      isActiveStatus: true,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        editValue(item);
        break;
      case "delete":
        onDelete(item);
        break;
      case "deactive":
        activeStatusBtn(item);
        break;

      default:
        break;
    }
  };

  const unitApartmentName = (value) => {
    return value;
    // if (value && value?.includes("unit")) {
    //   return value;
    // } else {
    //   return value + " " + "unit";
    // }
  };

  // table all columns
  const columns = [
    {
      title: "Unit Type",
      dataIndex: "unitType",
      // sorter: true,
      // render: (name) => `${name.first} ${name.last}`,
      width: "15%",
      // filters: unitTypes,
      // filterSearch: true,
      // onFilter: (value, record) => record?.unitType?.includes(value),
      render: (_, record) => {
        return <span>{unitApartmentName(record?.unitType)}</span>;
      },
    },
    {
      title: "Units Name",
      dataIndex: "unit_name",
      width: unitsNumberWidth,
      // filters: tableFilterUnits,
      // filterSearch: true,
      // onFilter: (value, record) => record?.unit_name?.includes(value),
      // sorter: (a, b) => a.unit_name - b.unit_name,
    },

    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   width: "25%",
    // },

    {
      title: "Min. Credit Score",
      dataIndex: "min_credit_score",
      key: "min_credit_score",
      width: "12%",
    },
    {
      title: "Sq.ft",
      dataIndex: "sqft",
      width: "8%",
    },
    {
      title: "Bed",
      dataIndex: "bed",
      width: "8%",
    },
    {
      title: "Bath",
      dataIndex: "bath",
      width: "8%",
    },
    {
      title: "Amount",
      dataIndex: "price",
      width: "12%",
    },
    {
      title: "Status",
      dataIndex: "unitStatus",
      key: "unitStatus",
      width: "15%",
      filters: unitsStatus,
      filterSearch: true,
      onFilter: (value, record) => record?.unitStatus?.includes(value),
      render: (_, record) => {
        let color = "red";
        if (record.unitStatus === "Sold") {
          color = "#E04141";
        } else if (record.unitStatus === "Contract Sent") {
          color = "#4160AC";
        } else if (record.unitStatus === "Available") {
          color = "#58D255";
        } else if (record.unitStatus === "Pending") {
          color = "#F67C36";
        } else if (record.unitStatus === "Configure") {
          color = "#F67C36";
        }
        return (
          <span
            onClick={(event) => {
              event.stopPropagation();
              editValue(record);
            }}
            style={{ color: color }}
            key={record?.unitStatus}
          >
            {record?.unitStatus}
          </span>
        );
      },
    },

    // {
    //   dataIndex: "action",
    //   key: "action",
    //   align: "right",
    //   width: "8%",
    //   render: (_, record) => {
    //     return (
    //       <div style={{ display: "flex", justifyContent: "space-between" }}>
    //         <EditIconWrap
    //           pr="0"
    //           onClick={(event) => {
    //             editValue(record);
    //             event.stopPropagation();
    //           }}
    //         >
    //           <EditSvg />
    //         </EditIconWrap>
    //         <Popconfirm
    //           key={"del-c"}
    //           title="Are you sure?"
    //           onConfirm={(e) => {
    //             e.stopPropagation();
    //             onDelete(record);
    //           }}
    //           onCancel={(e) => {
    //             e.stopPropagation();
    //           }}
    //           okText="Yes"
    //           cancelText="No"
    //           icon={<QuestionCircleOutlined style={{ color: "red" }} />}
    //         >
    //           <EditIconWrap
    //             pr="0"
    //             onClick={(event) => {
    //               event.stopPropagation();
    //             }}
    //           >
    //             <FaTrash />
    //           </EditIconWrap>
    //         </Popconfirm>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Publish Listing",
      dataIndex: "publishStatus",
      key: "publishStatus",
      width: "12%",
      render: (_, record, id) => {
        return (
          <>
            {record.publishStatus ? (
              <Switch
                disabled={record.activeStatus === "deactivate"}
                loading={switchBtnId === record.id ? switchBtnLoading : false}
                defaultChecked={
                  record.publishStatus
                    ? record.publishStatus === "active"
                      ? true
                      : false
                    : false
                }
                checked={
                  record.publishStatus
                    ? record.publishStatus === "active"
                      ? true
                      : false
                    : false
                }
                onChange={(value, event) => {
                  publishStatusBtn(value, record);
                  event.stopPropagation();
                }}
              />
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      fixed: "right",
      width: "7%",
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record}
            onChange={actionHandler}
            Icon={MdMoreVert}
          />
        );
      },
    },
  ];

  // on click delete unit
  const onDelete = (value) => {
    setTableLoading(true);
    const objKeys = Object.keys(value);
    if (objKeys.includes("apartment_id")) {
      dispatch(deleteUnit({ id: value.id })).then((res) => {
        message.success("Unit deleted successfully");
        let arr = [];
        const unit = [...allUnitArr];
        const newUnit = unit.map((val) => {
          if (val.id === value.id) {
          } else {
            arr.push(val);
          }
          if (arr?.length) {
            setAllUnitObj({ ...arr });
          } else {
            setAllUnitArr([]);
          }
        });
        setTableLoading(false);
      });
    } else {
    }
  };

  // table pagination handler
  const handleTableChange = (newPagination, filters, sorter) => {
    if (sorter?.order) {
      // newPagination.pageNumber = 1;
      // getAllUnits(newPagination, filters);
    } else {
      newPagination.pageNumber = newPagination.current;
      getAllUnits(newPagination);
      setPagination((p) => ({ ...p, ...newPagination, filters, sorter }));
    }
  };

  // is unit listed change (set true/false)
  const newListedUnit = (value) => {
    setAllUnitArr((val) => [value, ...val]);
  };

  // data convert object to array
  useEffect(() => {
    if (getAllApartmentState && Object.keys(getAllApartmentState).length) {
      const arr = Object.values(getAllApartmentState);
      setAllApartmentForModal(arr);
    } else {
      setAllApartmentForModal([]);
    }
  }, [getAllApartmentState]);

  // unit data filter for get unit images (also cover image)
  useEffect(() => {
    if (oneUnit && !Object.keys(selectedUnit ? selectedUnit : {})?.length) {
      let coverImg = oneUnit[0]?.images?.filter(
        (item, key) => item.isCover == true
      );
      setCoverImgArr(coverImg);
    } else {
      if (selectedUnit && Object.keys(selectedUnit)?.length) {
        let coverImg = selectedUnit?.images?.filter(
          (item, key) => item.isCover == true
        );
        setCoverImgArr(coverImg);
      }
    }
  }, [oneUnit, selectedUnit]);

  const onChange = (e) => {
    if (e.target.checked) {
      if (deavtiveUnits?.length) {
        setAllUnitArr(deavtiveUnits);
      } else {
        setAllUnitArr([]);
      }
    } else {
      setAllUnitArr(filerData);
    }
  };

  useEffect(() => {
    if (allUnitArr?.length) {
      const deactivatedArr = [];
      allUnitArr?.filter((item) => {
        if (item.activeStatus === "deactivate") {
          deactivatedArr.push(item);
        }
      });
      setDeavtiveUnits(deactivatedArr);
    }
  }, [allUnitArr]);

  return (
    <>
      {loading && !tableDataLoad ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : (
        <Container padding="50px 50px 0 330px" paddingSm="40px 40px 0 265px">
          <Row gutter={[30, 30]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
              <Row justify="space-between">
                <Col xs={24} sm={24} md={24} lg={16} xl={20} xxl={20}>
                  <Row style={{ alignItems: "center" }}>
                    <Col>
                      <Heading marginBottomLep="10px">Property Details</Heading>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={16}>
                      <BreadCrumbsWrapper>
                        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
                      </BreadCrumbsWrapper>
                    </Col>
                    <Col>
                      <Form.Item style={{ marginRight: "20px" }}>
                        <Checkbox onChange={onChange}>
                          Show only Deactivated Properties
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  style={{ textAlign: "end" }}
                >
                  <AddNewApr
                    bgColor={"#777777"}
                    fw={"400"}
                    hg={"40px"}
                    displayView="none"
                    onClick={() => {
                      setModalState(true);
                      showDialog();
                    }}
                  >
                    Add New Unit
                  </AddNewApr>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <ApartmentTable>
                    <Table
                      columns={columns}
                      onRow={(r) => ({
                        onClick: () => setSelectedUnit(r),
                      })}
                      rowClassName={(record) =>
                        record.activeStatus === "deactivate" && "disabled-row"
                      }
                      dataSource={[...allUnitArr]}
                      onChange={handleTableChange}
                      pagination={pagination}
                      loading={tableLoading || tableDataLoad}
                      scroll={{ x: 1550, y: yScroll }}
                      // scroll={{ x: 1000 }}
                      // scroll={{ y: 350 }}
                    />
                  </ApartmentTable>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
              <RightSide>
                <RightSideImgWrap>
                  <ImgWrap>
                    {coverImgArr?.length ? (
                      <img alt="example" src={coverImgArr[0]?.path} />
                    ) : (
                      <AvenewImg
                        style={{ width: "100%", backgroundColor: "#eeee" }}
                      />
                    )}
                    {/* <img alt="example" src="/images/apartment1.png" /> */}
                  </ImgWrap>
                  <Photos>
                    <p>
                      {selectedUnit && Object.keys(selectedUnit).length
                        ? selectedUnit?.images
                          ? selectedUnit?.images?.length
                          : "0"
                        : oneUnit[0]?.images?.length}{" "}
                      Photos
                    </p>
                  </Photos>
                  <Icons>
                    <div
                      onClick={() => {
                        setModalStateUpdate(true);
                      }}
                    >
                      <EditIcon />
                    </div>
                    {selectedUnit && Object.keys(selectedUnit).length ? (
                      <div onClick={() => setshareModalState(true)}>
                        <ShareIcon
                          className="share__icon"
                          style={{ marginLeft: "10px" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Icons>
                </RightSideImgWrap>
                <RightSideDetailsWrap>
                  <h2 className="apartment__name">
                    {upperCase(
                      selectedUnit && Object.keys(selectedUnit).length
                        ? selectedUnit.unit_name
                        : oneUnit[0]?.unit_name
                    )}
                  </h2>
                  {/* <StarRatings
                    rating={
                      selectedUnit && Object.keys(selectedUnit).length
                        ? selectedUnit.starRating
                        : 3.32
                    }
                    // starDimension="20px"
                    // starSpacing="5px"
                    starRatedColor="#F67C36"
                  /> */}
                  <h3 className="apartment__price">
                    {selectedUnit && Object.keys(selectedUnit).length
                      ? `${
                          selectedUnit.price
                            ? toUSACurrency(Number(selectedUnit.price))
                            : toUSACurrency(0)
                        }`
                      : oneUnit[0]?.price
                      ? `${oneUnit[0].price}`
                      : `${toUSACurrency(Number(minPrice))} - ${toUSACurrency(
                          Number(maxPrice)
                        )}`}
                  </h3>
                  {oneUnit[0]?.address ? (
                    <div className="apartment__location">
                      <MdLocationOn />
                      <p className="apartment__address">
                        {oneUnit[0]?.address}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {oneUnit[0]?.phonenumber ? (
                    <div className="apartment__location property__phone ">
                      <IoMdCall />
                      <p className="apartment__phone">
                        +1 {formatPhoneNumber(oneUnit[0]?.phonenumber)}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  <Divider />
                  {selectedUnit && Object.keys(selectedUnit).length ? (
                    <Row>
                      {selectedUnit?.bed ? (
                        <Col span={8} className="apartment__sqft">
                          <IoIosBed />
                          <p className="apartment__bed">
                            Bed : {selectedUnit.bed}
                          </p>
                        </Col>
                      ) : (
                        ""
                      )}
                      {selectedUnit?.bath ? (
                        <Col span={8} className="apartment__sqft">
                          <FaBath />
                          <p className="apartment__bed">
                            Bath : {selectedUnit.bath}
                          </p>
                        </Col>
                      ) : (
                        ""
                      )}
                      {selectedUnit?.sqft ? (
                        <Col span={8} className="apartment__sqft">
                          <SqFtSvg />
                          <p>Sq ft : {selectedUnit.sqft}</p>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  ) : (
                    <Row>
                      <Col span={12} className="apartment__sqft">
                        <SqFtSvg />
                        {/* <p>Sq ft : {oneUnit[0]?.sqft}</p> */}
                        <p>
                          Sq ft : {minSqFt} - {maxSqFt}
                        </p>
                      </Col>
                      <Col span={12} className="apartment__sqft">
                        <HomeSvg />
                        <p>{oneUnit[0]?.unitType}</p>
                      </Col>
                    </Row>
                  )}
                  {selectedUnit && Object.keys(selectedUnit)?.length ? (
                    ""
                  ) : (
                    <>
                      <h4>Email</h4>
                      <h5>{upperCase(oneUnit[0]?.email)}</h5>
                    </>
                  )}
                  {selectedUnit?.description || oneUnit[0]?.description ? (
                    <h4>Description</h4>
                  ) : (
                    ""
                  )}
                  <h5>
                    {selectedUnit && Object.keys(selectedUnit)?.length
                      ? selectedUnit.description
                      : oneUnit[0]?.description}
                  </h5>
                  {selectedUnit?.ammenities?.length ||
                  oneUnit[0]?.ammenities?.length ? (
                    <h4>Amenities</h4>
                  ) : (
                    ""
                  )}
                  {selectedUnit && Object.keys(selectedUnit).length
                    ? selectedUnit?.ammenities?.map((item) => (
                        <>
                          {Object.values(item)[0]?.length ? (
                            <>
                              <h6>{Object.keys(item)}</h6>
                              <Row gutter={[10, 10]}>
                                {returnTag(Object.values(item))}
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ))
                    : oneUnit[0]?.ammenities?.map((val) => (
                        <>
                          {Object.values(val)[0]?.length ? (
                            <>
                              <h6>{Object.keys(val)}</h6>
                              <Row gutter={[10, 10]}>
                                {returnTag(Object.values(val))}
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                          {/* <Col>
                            <ListWrap>
                              <p>{val}</p>
                            </ListWrap>
                          </Col> */}
                        </>
                      ))}
                  {/* {dataAmi?.map((item) => (
                    <>
                      <h6>{Object.keys(item)}</h6>
                      <Row gutter={[10, 10]}>
                        {returnTag(Object.values(item))}
                      </Row>
                        </>
                      ))} */}
                  {/* <Row gutter={[10, 10]}>
                    {selectedUnit && Object.keys(selectedUnit).length
                      ? selectedUnit?.ammenities?.map((val) => (
                          <Col>
                            <ListWrap>
                              <p>{val}</p>
                            </ListWrap>
                          </Col>
                        ))
                      : oneUnit[0]?.ammenities?.map((val) => (
                          <Col>
                            <ListWrap>
                              <p>{val}</p>
                            </ListWrap>
                          </Col>
                        ))}
                  </Row> */}
                </RightSideDetailsWrap>
              </RightSide>
            </Col>
          </Row>
          <FloatButton>
            <SecondaryButton
              shape="circle"
              icon={<HiPlus className="plus_icon" />}
              onClick={() => setModalState(true)}
            />
          </FloatButton>
        </Container>
      )}
      {selectedUnit && selectedUnit?.id ? (
        <UpdateUnitModal
          onCancel={(value) => {
            setModalStateUpdate(value);
            closeDialog();
          }}
          modalState={modalStateUpdate}
          isEdit={true}
          updateUnit={selectedUnit}
          getUpdatedUnit={getUpdatedUnit}
          getAllApartmentState={allApartmentForModal}
        />
      ) : (
        <UpdateApartmentModal
          onCancel={(value) => {
            setModalStateUpdate(value);
            closeDialog();
          }}
          modalState={modalStateUpdate}
          isEdit={true}
          updateUnit={oneUnit}
          getUpdatedApartment={getUpdatedApartment}
        />
      )}
      <ShareModal
        onCancel={(value) => setshareModalState(value)}
        modalState={shareModalState}
        selectedUnit={
          selectedUnit && Object.keys(selectedUnit).length
            ? selectedUnit
            : oneUnit[0]
        }
      />
      <AddApartmentModal
        onCancel={(value) => {
          setModalState(value);
          closeDialog();
        }}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
        isApart="true"
        newListedUnit={newListedUnit}
        getAllApartmentState={oneUnit}
      />
    </>
  );
}

export default SubUnit;

export const RightSideDetailsWrap = styled.div`
  padding: 20px;

  h6 {
    color: #4160ac;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 15px;
  }
  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    margin-top: 35px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.forth};
  }
  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #777777;
  }
  .ant-divider-horizontal {
    background: #dddddd;
  }

  .star-container {
    padding: 5px !important;
  }

  .widget-svg {
    width: 20px !important;
    height: 20px !important;
  }

  .top__id {
    display: none;
  }

  .apartment__name {
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    color: ${({ theme }) => theme.colors.forth};
    margin-bottom: 0px;
  }
  .apartment__location {
    margin-top: 20px;
  }
  .property__phone {
    margin-bottom: 20px;
  }

  .apartment__phone,
  .apartment__bed,
  .apartment__address {
    margin: 0;
    font-size: 18px;
    margin-right: 20px;
  }

  .location__row {
    margin-top: 15px;
  }

  .apartment__location {
    display: flex;
    align-items: center;
    svg {
      font-size: 18px;
      color: #777777;
      margin-right: 10px;
    }
  }
  .apartment__sqft {
    display: flex;
    align-items: center;
    svg {
      font-size: 18px;
      color: #777777;
      margin-right: 10px;
    }
  }

  .ant-row {
    align-items: center;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }

  .card__BHK {
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }

  .ant-divider-vertical {
    border-left: 1px solid #777777;
    height: 1rem;
  }

  .card__bedroom {
    margin-right: 36px;
  }

  .apartment__type {
    padding-left: 20px;
  }

  .apartment__price {
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    color: #323548;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 25px;
  }

  span {
    color: #777777;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
  }

  @media (max-width: 1550px) {
    padding: 15px;

    .star-container {
      padding: 2px !important;
    }

    .widget-svg {
      width: 16px !important;
      height: 16px !important;
    }
    .apartment__name {
      font-size: 20px;
      line-height: 36px;
    }
    .apartment__phone,
    .apartment__bed,
    .apartment__address {
      margin: 0;
      font-size: 14px;
      margin-right: 16px;
    }
    .location__row {
      margin-top: 12px;
    }
    .apartment__location {
      svg {
        ${
          "" /* font-size: 14px;
        margin-right: 8px; */
        }
      }
    }
    p {
      font-size: 14px;
      line-height: 19px;
    }
    .card__BHK {
      font-size: 14px;
    }
    .card__bedroom {
      margin-right: 28px;
    }
    .apartment__type {
      padding-left: 16px;
    }
    .apartment__price {
      font-size: 14px;
      line-height: 23px;
    }
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const Icons = styled.div`
  position: absolute;
  right: 10px;
  bottom: 0;
  width: 90px;
  display: flex;
  svg {
    margin-left: 10px;
    cursor: pointer;
  }
`;

export const Photos = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: #00000090;
  border-radius: 3px;
  padding: 8px 20px;
  p {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;

    /* identical to box height */
    color: #ffffff;
    margin-bottom: 0;
  }
`;

export const ImgWrap = styled.div`
  img,
  svg {
    width: -webkit-fill-available;
    max-height: 255px;
  }

  .ant-skeleton-element .ant-skeleton-image,
  svg {
    height: 255px;
  }
  .ant-skeleton-element,
  svg {
    width: 100%;
  }
`;

export const RightSideImgWrap = styled.div`
  position: relative;
`;

export const RightSide = styled.div`
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-height: calc(100vh - 140px);
`;
