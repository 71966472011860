import React, { useEffect, useState } from "react";
import { Badge, Col, Divider, Row, Table, Tag } from "antd";
import StarRatings from "react-star-ratings";
import { Link } from "react-router-dom";
import { Container } from "../../ui/Layout/Container";
import {
  ApartmentId,
  ApartmentsViewWrap,
  ApartmentsViewWrapForSmall,
  ApartmentTable,
  FloatButton,
  SecondaryButton,
  UpArrowBox,
} from "./ApartmentsViewStyle";
import {
  AddUnitBtn,
  StyledButton,
} from "../createPersonalProfile/RegistrationStyle";
import AddApartmentModal from "../addApartmentModal/AddApartmentModal";
import { Heading } from "../../ui/Heading/Heading";
import Actions from "./Actions";
import { useLocation } from "react-router";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { MdLocationOn } from "react-icons/md";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosBed,
  IoMdCall,
} from "react-icons/io";
import { BiBed } from "react-icons/bi";
import { FaBath } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
import { SqFtSvg } from "../../assets/svgs";
import { ContainerWrap } from "../../ui/Layout/ContainerWrap";
import styled from "styled-components";

function ApartmentsView() {
  const { width } = useWindowSize();
  const location = useLocation();

  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  
  const [unitsNumberWidth, setUnitsNumberWidth] = useState("12%");
  const [yScroll, setyScroll] = useState(250);
 
  // Setting scroll size according to Screen width
  useEffect(() => {
    if (width < 1550 && width > 991) {
      setyScroll(250);
    } else {
      setyScroll(390);
    }
  }, [width, location.pathname]);

  // To set unit number width in table according to screen width
  useEffect(() => {
    if (width > 1500) {
      setUnitsNumberWidth("12%");
    } else if (width < 1500) {
      setUnitsNumberWidth("14%");
    } else if (width < 1441) {
    }
  }, [width, location.pathname]);

  const columns = [
    {
      title: "Units Type",
      dataIndex: "unitsType",
      // sorter: true,
      // render: (name) => `${name.first} ${name.last}`,
      // width: "10%",
    },
    {
      title: "Units Number",
      dataIndex: "unitsNumber",
      width: unitsNumberWidth,
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      width: "14%",
    },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   width: "25%",
    // },
    {
      title: "Sq.ft",
      dataIndex: "sqft",
    },
    {
      title: "Bed",
      dataIndex: "bed",
    },
    {
      title: "Bath",
      dataIndex: "bath",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "13%",

      render: (_, { status }) => {
        let color = "red";
        if (status === "Sold") {
          color = "#E04141";
        } else if (status === "Contract Sent") {
          color = "#4160AC";
        } else if (status === "Available") {
          color = "#58D255";
        } else if (status === "Pending") {
          color = "#F67C36";
        }
        return (
          <span style={{ color: color }} key={status}>
            {status}
          </span>
        );
      },
    },
    {
      dataIndex: "action",
      key: "action",
      align: "right",
      // width: 60,
      render: (_, record) => {
        return (
          <Actions
          // options={actionOptions}
          // item={record._id}
          // onChange={actionHandler}
          />
        );
      },
    },
  ];

  const dataSource = [
    {
      key: "1",
      unitsType: "A1",
      unitsNumber: "895625",
      contactNumber: "+1 236 47580",
      address: "913 Tower Dr.Oak Lawn, IL 60453",
      sqft: "1250",
      bed: "3",
      bath: "2",
      amount: "$5898.00",
      status: "Sold",
    },
    {
      key: "2",
      unitsType: "A1",
      unitsNumber: "895625",
      contactNumber: "+1 236 47580",
      address: "913 Tower Dr.Oak Lawn, IL 60453",
      sqft: "1250",
      bed: "3",
      bath: "2",
      amount: "$5898.00",
      status: "Contract Sent",
    },
    {
      key: "3",
      unitsType: "A1",
      unitsNumber: "895625",
      contactNumber: "+1 236 47580",
      address: "913 Tower Dr.Oak Lawn, IL 60453",
      sqft: "1250",
      bed: "3",
      bath: "2",
      amount: "$5898.00",
      status: "Available",
    },
    {
      key: "4",
      unitsType: "A1",
      unitsNumber: "895625",
      contactNumber: "+1 236 47580",
      address: "913 Tower Dr.Oak Lawn, IL 60453",
      sqft: "1250",
      bed: "3",
      bath: "2",
      amount: "$5898.00",
      status: "Sold",
    },
    {
      key: "5",
      unitsType: "A1",
      unitsNumber: "895625",
      contactNumber: "+1 236 47580",
      address: "913 Tower Dr.Oak Lawn, IL 60453",
      sqft: "1250",
      bed: "3",
      bath: "2",
      amount: "$5898.00",
      status: "Contract Sent",
    },
    {
      key: "6",
      unitsType: "A1",
      unitsNumber: "895625",
      contactNumber: "+1 236 47580",
      address: "913 Tower Dr.Oak Lawn, IL 60453",
      sqft: "1250",
      bed: "3",
      bath: "2",
      amount: "$5898.00",
      status: "Sold",
    },
    {
      key: "7",
      unitsType: "A1",
      unitsNumber: "895625",
      contactNumber: "+1 236 47580",
      address: "913 Tower Dr.Oak Lawn, IL 60453",
      sqft: "1250",
      bed: "3",
      bath: "2",
      amount: "$5898.00",
      status: "Available",
    },
    {
      key: "8",
      unitsType: "A1",
      unitsNumber: "895625",
      contactNumber: "+1 236 47580",
      address: "913 Tower Dr.Oak Lawn, IL 60453",
      sqft: "1250",
      bed: "3",
      bath: "2",
      amount: "$5898.00",
      status: "Pending",
    },
    {
      key: "9",
      unitsType: "A1",
      unitsNumber: "895625",
      contactNumber: "+1 236 47580",
      address: "913 Tower Dr.Oak Lawn, IL 60453",
      sqft: "1250",
      bed: "3",
      bath: "2",
      amount: "$5898.00",
      status: "Contract Sent",
    },
    {
      key: "10",
      unitsType: "A1",
      unitsNumber: "895625",
      contactNumber: "+1 236 47580",
      address: "913 Tower Dr.Oak Lawn, IL 60453",
      sqft: "1250",
      bed: "3",
      bath: "2",
      amount: "$5898.00",
      status: "Sold",
    },
  ];

  return (
    <Container>
      <ContainerWrap minH="100%" padding="0">
        <ApartmentsViewWrap>
          <Row justify="space-between">
            <Col>
              <Row>
                <Col>
                  <h2 className="apartment__name">Apartments Name Here</h2>
                </Col>
                <Col>
                  <ApartmentId>
                    <span>ID : APRYU56982</span>
                  </ApartmentId>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>
                  <StarRatings
                    rating={3.32}
                    // starDimension="20px"
                    // starSpacing="5px"
                    starRatedColor="#F67C36"
                  />
                </Col>
                <Col className="apartment__price">
                  <h3>$1598.56 - $ 2256.00</h3>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="location__row" justify="space-between">
            <Col>
              <Row>
                <Col className="apartment__location">
                  <MdLocationOn />
                  <p className="apartment__address">
                    913 Tower Dr.Oak Lawn, IL 60453
                  </p>
                </Col>
                <Col className="apartment__location">
                  <IoMdCall />
                  <p className="apartment__phone">+1 123 457 4702</p>
                </Col>
                <Col className="apartment__location">
                  <IoIosBed />
                  <p className="apartment__bed">Bed : 2</p>
                </Col>
                <Col className="apartment__location">
                  <FaBath />
                  <p className="apartment__bed">Bath : 2</p>
                </Col>
                <Col className="apartment__location">
                  <SqFtSvg />
                  <p>Sq ft : 1500</p>
                </Col>
              </Row>
            </Col>
            <Col>
              <span>SHARE</span>
              <Divider type="vertical" />
              <span>EDIT</span>
            </Col>
          </Row>
        </ApartmentsViewWrap>
        <BedgeWrap>
          <Badge.Ribbon text="ID : APRYU56982">
            <ApartmentsViewWrapForSmall>
              <Row justify="space-between">
                <Col span={24}>
                  <h2 className="apartment__name">Apartments Name Here</h2>
                </Col>
                <Col>
                  <Col className="apartment__price">
                    <h3>$1598.56 - $ 2256.00</h3>
                  </Col>
                </Col>
                <Col>
                  <StarRatings
                    rating={3.32}
                    // starDimension="20px"
                    // starSpacing="5px"
                    starRatedColor="#F67C36"
                  />
                </Col>
              </Row>
              <Row justify="end" style={{ paddingTop: "0px" }}>
                {/* <Col className="apartment__price">
                <h3>$1598.56 - $ 2256.00</h3>
              </Col> */}
                <Col>
                  <span>
                    {!isOpen ? (
                      <IoIosArrowDown onClick={() => setIsOpen(!isOpen)} />
                    ) : (
                      ""
                    )}
                  </span>
                </Col>
              </Row>
              {isOpen && (
                <>
                  <Row>
                    <Col className="apartment__location">
                      <MdLocationOn />
                      <p className="apartment__address">
                        913 Tower Dr.Oak Lawn, IL 60453
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="apartment__location">
                      <IoMdCall />
                      <p className="apartment__phone">+1 123 457 4702</p>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>
                      <Row justify="space-between">
                        <Col className="apartment__location">
                          <IoIosBed />
                          <p className="apartment__bed">Bed : 2</p>
                        </Col>
                        <Col className="apartment__location">
                          <FaBath />
                          <p className="apartment__bed">Bath : 2</p>
                        </Col>
                        <Col className="apartment__location">
                          <SqFtSvg />
                          <p>Sq ft : 1500</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="space-between">
                        <Col style={{ marginTop: "15px", marginRight: "50px" }}>
                          <span>SHARE</span>
                          <Divider type="vertical" />
                          <span>EDIT</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <UpArrowBox>
                    <span>
                      {!isOpen ? (
                        ""
                      ) : (
                        <IoIosArrowUp onClick={() => setIsOpen(!isOpen)} />
                      )}
                    </span>
                  </UpArrowBox>
                </>
              )}
            </ApartmentsViewWrapForSmall>
          </Badge.Ribbon>
        </BedgeWrap>
      </ContainerWrap>

      <Container minH="100%" padding="26px 0" paddingSm="26px 0">
        <Row justify="space-between" style={{ alignItems: "center" }}>
          <Col
          // xl={{ span: 6 }}
          // md={{ span: 4 }}
          // sm={{ span: 5 }}
          // xs={{ span: 6 }}
          >
            <Heading marginBottom={"0px"}>
              <h2>Listed Units</h2>
            </Heading>
          </Col>
          <Col xl={{ span: 3 }}>
            <StyledButton
              bgColor={"#777777"}
              fw={"400"}
              hg={"40px"}
              displayView="none"
              onClick={() => setModalState(true)}
            >
              Add New Unit
            </StyledButton>
          </Col>
        </Row>
        <FloatButton>
          <AddUnitBtn
            shape="circle"
            icon={<HiPlus className="plus_icon" />}
            onClick={() => setModalState(true)}
          />
        </FloatButton>
      </Container>
      <AddApartmentModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
        isApart="true"
      />
      <ApartmentTable>
        <Table
          columns={columns}
          // rowKey={(record) => record.login.uuid}
          dataSource={dataSource}
          pagination={{
            current: 1,
            pageSize: 10,
            size: "small",
            defaultPageSize: 1,
            total: 90,
          }}
          // loading={loading}
          // onChange={this.handleTableChange}
          scroll={{ x: 1100, y: yScroll }}
          // scroll={{ x: 1000 }}
          // scroll={{ y: 350 }}
        />
      </ApartmentTable>
    </Container>
  );
}

export default ApartmentsView;

export const BedgeWrap = styled.div`
  .ant-ribbon {
    top: 2px;
    display: none;
    background: #ffeade;
    span {
      font-size: 12px;
      line-height: 15px;
      color: #f67c36;
      font-weight: 400;
    }

    @media (max-width: 798px) {
      display: block;
    }
  }
`;
