import React, { useCallback, useEffect, useState } from "react";
import { Container } from "../../ui/Layout/Container";
import {
  Row,
  Col,
  Skeleton,
  Spin,
  Form,
  Switch,
  Table,
  Checkbox,
  Popconfirm,
  message,
} from "antd";
import { Heading } from "../../ui/Heading/Heading";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { StyledButton } from "../createPersonalProfile/RegistrationStyle";
import AddApartmentModal from "../addApartmentModal/AddApartmentModal";
import { Card } from "../../ui/Card";
import {
  AddNewApr,
  StyledApparCard,
  StyledApparCardWrapper,
  StyledApparContentWrapper,
} from "./Listingstyle";
import ApartmentSection from "./ApartmentSection";
import { apartmentDummyData } from "./dummy.data";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import {
  ApartmentTable,
  FloatButton,
  SecondaryButton,
} from "../apartments-view/ApartmentsViewStyle";
import { HiPlus } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteApartment,
  deleteUnit,
  getAllApartment,
  getAllApartmentsPost,
  getAllOrg,
  getAllOrgId,
  getOrg,
  getUnitsByUserId,
  propertyStatus,
} from "../../store/reducers/user.reducer";
import NoListing, { AddApartment } from "../no-listing page/NoListing";
import { httpAPI } from "../../api";
import { EditIconWrap } from "../apartments-view/Actions";
import { EditSvg, NoListingsSvg } from "../../assets/svgs";
import UpdateUnitModal from "../addApartmentModal/UpdateUnitModal";
import UpdateApartmentModal from "../addApartmentModal/UpdateApartmentModal";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { FaTrash } from "react-icons/fa";
import { Icon } from "../ImagePreview/CoverImage";
import Actions from "../../ui/Actions/Actions";
import { MdMoreVert } from "react-icons/md";
import { get, map } from "lodash";
import { upperCase } from "../../utils/fn";

function AllPropperty({ name, displayShow = true }) {
  const navigate = useNavigate();

  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const { loading, user_id } = useSelector((state) => state.user);
  const [getAllApartmentState, setGetAllApartment] = useState({});
  const [getAllUnit, setGetAllUnit] = useState([]);
  const [getAllUnitArr, setGetAllUnitArr] = useState([]);
  const [allApartment, setAllApartment] = useState([]);
  const [allPropUnit, setAllPropUnit] = useState([]);
  const [sortAllPropUnit, setSortAllPropUnit] = useState([]);
  const [deavtiveUnits, setDeavtiveUnits] = useState([]);
  const [filerData, setFilerData] = useState([]);
  const [selectedUnitFromTable, setSelectedUnitFromTable] = useState([]);
  const [padding, setPadding] = useState("50px 50px 0 330px");
  const [showHeader, setShowHeader] = useState(true);
  const [onlyUnit, setOnlyUnit] = useState(false);
  const [valueChanger, setValueChanger] = useState("false");
  const [allApartmentForModal, setAllApartmentForModal] = useState([]);
  const [newApi, setNewApi] = useState([]);
  const [modalStateUpdate, setModalStateUpdate] = useState(false);
  const [getAllApartmentStateArr, setGetAllApartmentStateArr] = useState({});
  const [apartments, setApartments] = useState([]);
  const [tableFilterOrg, setTableFilterOrg] = useState([]);
  const [allOrgName, setAllOrgName] = useState([]);
  const [tableFilterUnits, setTableFilterUnits] = useState([]);
  const [allUnitName, setAllUnitName] = useState([]);
  const [svgWidth, setSvgWidth] = useState("388");
  const [svgHeight, setSvgHeight] = useState("265");
  const [loadings, setLoadings] = useState(false);
  const [isTableDataLoad, setIsTableDataLoad] = useState(false);
  const [searchBarText, setSearchBarText] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    size: "small",
    query: `${encodeURIComponent(searchBarText)}`,
    lastEvaluatedKey: [],
    showSizeChanger: true,
  });

  const [deactiveDataCardAllProp, setDeactiveDataCardAllprop] = useState([]);
  const { width } = useWindowSize();
  const valueOfSelect = allApartmentForModal[0];
  const dispatch = useDispatch();
  const location = useLocation();
  const unitTypes = [
    { value: "Single-family home", text: "Single-family home" },
    { value: "Condominium (Condo)", text: "Condominium (Condo)" },
    { value: "Townhouse", text: "Townhouse" },
    { value: "Apartment", text: "Apartment" },
    // { value: "Farm/Ranch", text: "Farm/Ranch" },
    // { value: "Multi-family home", text: "Multi-family home" },
    // { value: "Commercial", text: "Commercial" },
  ];
  useEffect(() => {
    if (width < 426) {
      setSvgWidth("300");
      setSvgHeight("210");
    } else {
      setSvgWidth("388");
      setSvgHeight("265");
    }
  }, [width, location.pathname]);

  // Settinng padding w.r.t screen width
  useEffect(() => {
    if (width < 1550 && width > 991) {
      setPadding("40px 40px 0 265px");
    } else {
      setPadding("50px 50px 0 330px");
    }
  }, [width, location.pathname]);

  //
  const newListedApartment = (value) => {
    setApartments((val) => [value, ...val]);
    setShowHeader(true);
    setGetAllApartmentStateArr((val) => [value, ...val]);
  };

  useEffect(() => {
    if (getAllApartmentState && Object.keys(getAllApartmentState).length) {
      const arr = Object.values(getAllApartmentState);
      setAllApartment(arr);
      setGetAllApartmentStateArr(arr);
    } else {
      setAllApartment([]);
      setGetAllApartmentStateArr([]);
    }
  }, [getAllApartmentState]);

  const handleHeaderChange = (enable) => {
    setShowHeader(enable);
  };

  useEffect(() => {
    if (getAllUnit && Object.keys(getAllUnit).length) {
      const arr = Object.values(getAllUnit);
      setGetAllUnitArr(arr);
    }
  }, [getAllUnit]);

  const [getAllOrgObj, setGetAllOrgObj] = useState({});
  const [getAllOrgArr, setGetAllOrgArr] = useState([]);

  const getChildOrgNameApart = (ele) => {
    const val = getAllOrgArr?.filter(function (item) {
      return item["id"] === ele[0]?.org_id;
    });
    return val;
  };

  const getChildOrgName = (ele) => {
    const abc = newApi?.filter(({ id }) => {
      return id === ele.apartment_id;
    });
    return getChildOrgNameApart(abc);
  };

  const unitApartmentName = (value) => {
    if (value && value?.includes("unit")) {
      return value;
    } else {
      return value + " " + "unit";
    }
  };

  useEffect(() => {
    if (Object.keys(tableFilterOrg)) {
      const dataArray = Object.keys(tableFilterOrg).map((key) => ({
        value: key,
        text: tableFilterOrg[key],
      }));

      setAllOrgName(dataArray);
    }
  }, [tableFilterOrg]);

  useEffect(() => {
    if (tableFilterUnits?.length) {
      let arr = [];
      [...new Set(tableFilterUnits)].map((item) => {
        let obj = {};
        obj.text = item;
        obj.value = item;
        arr.push(obj);
      });
      setAllUnitName(arr);
    }
  }, [tableFilterUnits]);

  // useEffect(() => {
  //   if (getAllUnit) {
  //     var arr = [];

  //     for (var item in getAllUnit) {
  //       if (getAllUnit.hasOwnProperty(item)) {
  //         arr.push(getAllUnit[item]);
  //       }
  //     }

  //     var newArr = [];

  //     if (arr.length) {
  //       const newChild = arr?.map((ele, idx) => ({
  //         ...ele,
  //         unitType: ele.unitType + " " + "unit",
  //       }));
  //     }

  //     if (allApartment.length) {
  //       const newChild = arr?.map((ele, idx) => ({
  //         ...ele,
  //         unitType: unitApartmentName(ele.unitType),
  //         orgName: getChildOrgName(ele),
  //       }));

  //       const launchOptimistic = allApartment?.map((elem, idx) => ({
  //         ...elem,
  //         key: idx + 1,
  //         orgName: getAllOrgArr?.filter(function (item) {
  //           return item["id"] === elem.org_id;
  //         }),
  //         children: newChild.filter(function (item) {
  //           return item["apartment_id"] === elem.id;
  //         }),
  //       }));
  //       // if (onlyUnit) {
  //       //   setAllPropUnit(getAllUnitArr);
  //       // } else {
  //       //   setAllPropUnit(launchOptimistic);
  //       // }
  //     }

  //     // var orgValue = arr.filter(function (item) {
  //     //   return item["apartment_id"] === "867caf48-43d7-4176-b831-13f5e9994883";
  //     // });
  //     // setValueOfApartment(orgValue);
  //   } else {
  //   }
  // }, [allApartment, getAllUnit, onlyUnit, valueChanger]);

  // To set Modal state to true
  const editValue = (value) => {
    setAllApartmentForModal([value]);
    setModalStateUpdate(true);
  };

  // To navigate to subunit upon selected
  useEffect(() => {
    if (selectedUnitFromTable && Object.values(selectedUnitFromTable).length) {
      if (selectedUnitFromTable?.children) {
        navigate(`/subunit/${selectedUnitFromTable?.id}`);
        localStorage.setItem("prop_id", selectedUnitFromTable?.id);
      } else {
        localStorage.setItem("prop_id", selectedUnitFromTable?.apartment_id);
        navigate({
          pathname: `/subunit/${selectedUnitFromTable?.apartment_id}`,
          search: createSearchParams({
            unitId: selectedUnitFromTable?.id,
          }).toString(),
        });
      }
    }
  }, [selectedUnitFromTable]);

  const [switchBtnLoading, setSwitchBtnLoading] = useState(false);
  const [switchBtnId, setSwitchBtnId] = useState("");

  // To set the status of listing for publish
  const publishStatusBtn = (value, record) => {
    setSwitchBtnLoading(true);
    setSwitchBtnId(record.id);
    let valueObj = {};
    const objKeys = Object.keys(record);
    if (objKeys.includes("apartment_id")) {
      valueObj.publishStatus =
        record.publishStatus === "active" ? "deactivate" : "active";
      valueObj.unit_id = record.id;
      valueObj.user_id = record.user_id;
    } else {
      valueObj.publishStatus =
        record.publishStatus === "active" ? "deactivate" : "active";
      valueObj.apartment_id = record.id;
      valueObj.user_id = record.user_id;
    }

    dispatch(propertyStatus(valueObj)).then((res) => {
      if (res?.payload?.Error) {
        message.error(res?.payload?.Error);
      } else {
        getOrgApa(pagination);
      }
    });
  };

  // To set the status of listing
  const activeStatusBtn = (record) => {
    setSwitchBtnId(record.id);
    let valueObj = {};
    const objKeys = Object.keys(record);
    if (objKeys.includes("apartment_id")) {
      valueObj.activeStatus =
        record.activeStatus === "active" ? "deactivate" : "active";
      record.activeStatus =
        record.activeStatus === "active" ? "deactivate" : "active";
      valueObj.unit_id = record.id;
      valueObj.user_id = record.user_id;
    } else {
      valueObj.activeStatus =
        record.activeStatus === "active" ? "deactivate" : "active";
      record.activeStatus =
        record.activeStatus === "active" ? "deactivate" : "active";
      valueObj.apartment_id = record.id;
      valueObj.user_id = record.user_id;
    }
    setLoadings(true);

    dispatch(propertyStatus(valueObj)).then((res) => {
      setLoadings(false);
      if (res?.payload?.Error) {
        message.error(res?.payload?.Error);
      } else {
        setSortAllPropUnit((val) => [...val]);
        message.success(
          valueObj.activeStatus === "deactivate"
            ? "Deactivated successfully"
            : "Activated successfully"
        );
        setLoadings(true);
        getOrgApa(pagination);
      }
      // get updated unit

      if (onlyUnit) {
        setSortAllPropUnit(deavtiveUnits);
      } else {
        setSortAllPropUnit(filerData);
      }
    });

    setTimeout(() => {
      setSwitchBtnLoading(false);
    }, 2000);
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Deactive",
      value: "deactive",
      isDanger: false,
      isActiveStatus: true,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        editValue(item);
        break;
      case "delete":
        onDelete(item);
        break;
      case "deactive":
        activeStatusBtn(item);
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      title: "Unit Type",
      dataIndex: "unitType",
      key: "unitType",
      width: "15%",
      filters: unitTypes,
      filterSearch: true,
      onFilter: (value, record) => record?.unitType?.includes(value),
    },
    {
      title: "Org Name",
      dataIndex: "unit_name",
      key: "org_name",
      filters: allOrgName,
      filterSearch: true,
      onFilter: (value, record) => record?.orgName[0]?.id?.includes(value),
      render: (_, record) => {
        return (
          <>
            {record.orgName ? (
              <span key={record.orgName[0]?.id}>
                {upperCase(record.orgName[0]?.org_name)}
              </span>
            ) : (
              " "
            )}
          </>
        );
      },
    },
    {
      title: "Units Name",
      dataIndex: "unit_name",
      key: "unit_name",
      width: "15%",
      // filterMode: "tree",
      filters: allUnitName,
      filterSearch: true,
      onFilter: (value, record) => record?.unit_name?.includes(value),
    },

    {
      title: "Min. Credit Score",
      dataIndex: "min_credit_score",
      key: "min_credit_score",
      width: "8%",

      render: (_, record) => {
        return (
          <>
            {record?.min_credit_score ? (
              <span>{record?.min_credit_score}</span>
            ) : (
              ""
            )}
          </>
        );
      },
    },

    {
      title: "No Of Unit",
      dataIndex: "numberOfUnit",
      key: "numberOfUnit",
      render: (_, record) => {
        return (
          <>{record?.numberOfUnit ? <span>{record?.numberOfUnit}</span> : ""}</>
        );
      },
    },
    {
      title: "Sq.ft",
      dataIndex: "sqft",
      key: "sqft",
    },
    {
      title: "Bed",
      dataIndex: "bed",
      key: "bed",
    },
    {
      title: "Bath",
      dataIndex: "bath",
      key: "bath",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Status",
      dataIndex: "unitStatus",
      key: "unitStatus",
      render: (_, record) => {
        let color = "red";
        if (record.unitStatus === "Sold") {
          color = "#E04141";
        } else if (record.unitStatus === "Contract Sent") {
          color = "#4160AC";
        } else if (record.unitStatus === "Available") {
          color = "#58D255";
        } else if (record.unitStatus === "Pending") {
          color = "#F67C36";
        } else if (record.unitStatus === "Configure") {
          color = "#F67C36";
        }
        return (
          <span
            style={{ color: color }}
            key={record.unitStatus}
            onClick={(event) => {
              editValue(record);
              event.stopPropagation();
            }}
          >
            {record.unitStatus}
          </span>
        );
      },
    },
    // {
    //   dataIndex: "action",
    //   key: "action",
    //   align: "right",
    //   // width: "10%",
    //   render: (_, record) => {
    //     return (
    //       <div style={{ display: "flex" }}>
    //         <EditIconWrap
    //           onClick={(event) => {
    //             editValue(record);
    //             event.stopPropagation();
    //           }}
    //         >
    //           <EditSvg />
    //         </EditIconWrap>
    //         <Popconfirm
    //           key={"del-c"}
    //           title="Are you sure?"
    //           onConfirm={(e) => {
    //             e.stopPropagation();
    //             onDelete(record);
    //           }}
    //           onCancel={(e) => {
    //             e.stopPropagation();
    //           }}
    //           okText="Yes"
    //           cancelText="No"
    //           icon={<QuestionCircleOutlined style={{ color: "red" }} />}
    //         >
    //           <EditIconWrap
    //             onClick={(event) => {
    //               event.stopPropagation();
    //             }}
    //           >
    //             <FaTrash />
    //           </EditIconWrap>
    //         </Popconfirm>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Publish Listing",
      dataIndex: "publishStatus",
      key: "publishStatus",
      width: "8%",
      render: (_, record) => {
        return (
          <>
            {record.publishStatus ? (
              <Switch
                disabled={record.activeStatus === "deactivate"}
                loading={switchBtnId === record.id ? switchBtnLoading : false}
                defaultChecked={
                  record.publishStatus
                    ? record.publishStatus === "active"
                      ? true
                      : false
                    : false
                }
                checked={
                  record.publishStatus
                    ? record.publishStatus === "active"
                      ? true
                      : false
                    : false
                }
                onChange={(value, event) => {
                  publishStatusBtn(value, record);
                  event.stopPropagation();
                }}
              />
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      fixed: "right",
      width: "5%",
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record}
            onChange={actionHandler}
            Icon={MdMoreVert}
          />
        );
      },
    },
  ];

  // To remove the property from the listing
  const onDelete = (value) => {
    setLoadings(true);
    const objKeys = Object.keys(value);
    if (objKeys.includes("apartment_id")) {
      dispatch(deleteUnit({ id: value.id })).then((res) => {
        message.success("Unit deleted successfully");
        let editObj = [...sortAllPropUnit];
        let changedObj = editObj.filter(
          (item) => item.id === value.apartment_id
        );
        let arr = [];
        changedObj[0].children.map((item) => {
          if (item.id === value.id) {
          } else {
            arr.push(item);
          }
        });
        changedObj[0].children = arr;
        changedObj[0].numberOfUnit = arr.length;
        editObj?.forEach((val, idx) => {
          if (val.id === changedObj[0].id) {
            editObj[idx] = changedObj[0];
          }
        });
        setSortAllPropUnit(editObj);
        setLoadings(false);
      });
    } else {
      dispatch(deleteApartment({ id: value.id })).then((res) => {
        message.success("Property deleted successfully");
        let arr = [];
        const unit = [...sortAllPropUnit];
        const newUnit = unit.map((val) => {
          if (val.id === value.id) {
          } else {
            arr.push(val);
          }
          setSortAllPropUnit([...arr]);
        });
        setLoadings(false);
      });
    }
  };

  const [yScroll, setyScroll] = useState(550);

  const onChange = (e) => {
    setOnlyUnit(e.target.checked);
    if (e.target.checked) {
      setSortAllPropUnit(deavtiveUnits);
      setDeactiveDataCardAllprop(deavtiveUnits);
    } else {
      setSortAllPropUnit(filerData);
      setDeactiveDataCardAllprop(filerData);
    }
  };

  // Function to sort the table
  const handleTableChange = (newPagination, filters, sorter) => {
    if (
      filters?.org_name?.length ||
      filters?.unitType?.length ||
      filters?.unit_name?.length
    ) {
      newPagination.pageNumber = 1;
      getOrgApa(newPagination, filters);
      setIsTableDataLoad(true);
    } else {
      setIsTableDataLoad(true);
      setOnlyUnit(false);
      newPagination.pageNumber = newPagination.current;
      getOrgApa(newPagination);
      setPagination((p) => ({ ...p, ...newPagination, filters, sorter }));
    }
  };

  // To updated the unit value
  const getUpdatedUnit = (value) => {
    setLoadings(true);
    getOrgApa(pagination);

    if (value && Object.keys(value).length) {
      if (apartments?.length) {
        const objIndex = getAllUnitArr?.findIndex((obj) => obj.id === value.id);
        getAllUnitArr[objIndex] = value;
        setValueChanger(value);
        setGetAllApartmentStateArr((val) => [...val, value]);
      }
    }
  };

  // To updated the apartment value
  const getUpdatedApartment = (value) => {
    if (value && Object.keys(value)?.length) {
      if (apartments && apartments?.length) {
        const objIndex = apartments?.findIndex((obj) => obj.id === value.id);
        apartments[objIndex] = value;
        setValueChanger(value);
      }
    }
  };

  const getOrgApa = useCallback(
    (pagination, filters) => {
      dispatch(
        getAllApartmentsPost({
          user_id: user_id ? user_id : localStorage.getItem("user_id"),
          pageSize: pagination.pageSize,
          lastEvaluatedKey: pagination?.lastEvaluatedKey?.length
            ? pagination?.lastEvaluatedKey[pagination.pageNumber - 1]
            : null,
          pageNumber: pagination.pageNumber,
          ...(filters?.org_name ? { org_ids: filters?.org_name } : ""),
          ...(filters?.unitType ? { type: filters?.unitType } : ""),
          ...(filters?.unit_name ? { unit_name: filters?.unit_name } : ""),
        })
      )
        .then(({ payload }) => {
          if (payload?.data || payload?.Data) {
            if (!tableFilterUnits?.length) {
              setTableFilterUnits(payload?.apartment_names);
              setTableFilterOrg(payload?.org_names);
            }
            setPagination((p) => ({
              ...p,
              ...(payload?.total ? { total: payload?.total } : ""),
              ...(pagination?.lastEvaluatedKey?.length
                ? ""
                : { lastEvaluatedKey: payload?.lastEvaluatedKey }),
            }));

            let value = payload?.Data;
            const launchOptimistic = value?.map((elem, idx) => ({
              ...elem,
              key: idx,
            }));

            setNewApi(launchOptimistic);
            setSwitchBtnLoading(false);
            setLoadings(false);
            setIsTableDataLoad(false);
          } else {
            setSwitchBtnLoading(false);
            setIsTableDataLoad(false);
            setLoadings(false);
            setNewApi([]);
          }
        })
        .catch((error) => {
          setLoadings(false);

          if (error) {
            message.error(error.message);
          }
        });
    },

    [pagination]
  );

  useEffect(() => {
    if (user_id || localStorage.getItem("user_id")) {
      setLoadings(true);
      let newPagination = { ...pagination };
      newPagination.user_id = user_id
        ? user_id
        : localStorage.getItem("user_id");
      getOrgApa(newPagination);
    }
  }, []);

  //  To set default sort function
  useEffect(() => {
    if (newApi?.length) {
      setLoadings(true);
      const arrOfSort = [];
      newApi?.map((value) => {
        let sortForeach = value.children?.map((val) => {
          return val.id;
        });
        sortForeach?.sort();

        sortForeach?.map((val) => {
          value.children?.filter((obj) => {
            if (obj["id"] === val) {
              arrOfSort.push(obj);
            }
          });
        });
      });

      if (getAllUnit) {
        var arr = [];

        for (var item in getAllUnit) {
          if (getAllUnit.hasOwnProperty(item)) {
            arr.push(getAllUnit[item]);
          }
        }

        const newChild = arrOfSort?.map((ele, idx) => ({
          ...ele,
          unitType: unitApartmentName(ele.unitType),
          orgName: getChildOrgName(ele),
        }));

        const launchOptimistic = newApi?.map((elem, idx) => ({
          ...elem,
          key: idx + 1,
          orgName: getAllOrgArr?.filter(function (item) {
            return item["id"] === elem.org_id;
          }),
          children: newChild.filter(function (item) {
            return item["apartment_id"] === elem.id;
          }),
        }));
        setFilerData(launchOptimistic);
        setSortAllPropUnit(launchOptimistic);
        setLoadings(false);
      }
    } else {
      setSortAllPropUnit([]);
    }
  }, [getAllOrgArr]);

  useEffect(() => {
    if (newApi && newApi?.length) {
      // for org
      let arr = [];
      newApi?.map((item) => {
        if (item?.orgName) {
          arr.push(item?.orgName);
        }
      });
      function getUniqueListBy(arr, key) {
        return [...new Map(arr?.map((item) => [item[key], item])).values()];
      }
      const arr1 = getUniqueListBy(arr, "id");
      setGetAllOrgArr(arr1);

      // for unit
      let arrUnit = [];
      newApi?.map((item) => {
        if (item?.children) {
          arrUnit.push(...item?.children);
        }
      });
      setGetAllUnit(arrUnit);
    }
  }, [newApi]);

  useEffect(() => {
    if (sortAllPropUnit?.length) {
      const deData = sortAllPropUnit?.map((item) => ({
        ...item,
        children: item.children?.filter((val) => {
          return val["activeStatus"] === "deactivate";
        }),
      }));

      if (deData?.length) {
        const deactiveMergedData = deData?.filter((item) => {
          return item.children?.length;
        });
        setDeavtiveUnits(deactiveMergedData);
      }
    }
  }, [sortAllPropUnit]);

  useEffect(() => {
    if (onlyUnit) {
      setSortAllPropUnit(deavtiveUnits);
    }
  }, [newApi, filerData]);
  return (
    <>
      <Container padding={padding} paddingSm={padding}>
        <>
          <Row justify="space-between">
            <Col>
              <Heading>All Properties</Heading>
            </Col>
            <Col style={{ display: "flex" }}>
              <Form.Item style={{ marginRight: "20px" }}>
                <Checkbox checked={onlyUnit} onChange={onChange}>
                  Show only Deactivated Properties
                </Checkbox>
              </Form.Item>

              <Form.Item
                label="Table View &nbsp;"
                style={{ marginRight: "20px" }}
              >
                <Switch checked={showHeader} onChange={handleHeaderChange} />
              </Form.Item>
            </Col>
          </Row>
          {!showHeader ? (
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <StyledApparCardWrapper wd={"100"} ml={"0"}>
                  <Row gutter={[30, 30]}>
                    {loading &&
                      [...Array(6).keys()].map((v) => (
                        <Col
                          key={v}
                          xs={24}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={8}
                          xxl={8}
                        >
                          <StyledApparCard>
                            <Skeleton.Image
                              active={loading}
                              style={{ width: "100%" }}
                            ></Skeleton.Image>
                            <StyledApparContentWrapper mh={"0"}>
                              <Skeleton.Input
                                active={loading}
                                style={{
                                  width: "100%",
                                  height: "20px",
                                  marginBottom: 20,
                                }}
                              ></Skeleton.Input>
                              <Skeleton.Input
                                active={loading}
                                style={{
                                  width: "100%",
                                  height: 20,
                                  marginBottom: 20,
                                }}
                              ></Skeleton.Input>
                              <Skeleton.Input
                                active={loading}
                                style={{
                                  width: "100%",
                                  height: 20,
                                  marginBottom: 20,
                                }}
                              ></Skeleton.Input>
                            </StyledApparContentWrapper>
                          </StyledApparCard>
                        </Col>
                      ))}
                  </Row>
                </StyledApparCardWrapper>
                <ApartmentSection
                  deactiveDataCardAllProp={deactiveDataCardAllProp}
                  apartmentDummyData={apartmentDummyData}
                  apartmentsWithUnits={allApartment}
                />
              </Col>
            </Row>
          ) : (
            <ApartmentTable>
              <Table
                defaultExpandAllRows={true}
                scroll={{ x: 1900, y: yScroll }}
                columns={columns}
                dataSource={[...sortAllPropUnit]}
                pagination={pagination}
                loading={loadings || isTableDataLoad}
                onChange={handleTableChange}
                rowClassName={(record) =>
                  record.activeStatus === "deactivate" && "disabled-row"
                }
                onRow={(r) => ({
                  onClick: (event) => {
                    event.isDefaultPrevented();
                    setSelectedUnitFromTable(r);
                  },
                })}
              />
            </ApartmentTable>
          )}
        </>

        {!allApartmentForModal[0]?.children ? (
          <UpdateUnitModal
            onCancel={(value) => setModalStateUpdate(value)}
            modalState={modalStateUpdate}
            isEdit={true}
            updateUnit={valueOfSelect}
            getAllApartmentState={getAllApartmentStateArr}
            getUpdatedUnit={getUpdatedUnit}
          />
        ) : (
          <UpdateApartmentModal
            onCancel={(value) => setModalStateUpdate(value)}
            modalState={modalStateUpdate}
            isEdit={true}
            updateUnit={allApartmentForModal}
            getUpdatedApartment={getUpdatedApartment}
            getUpdatedUnitApartment={getUpdatedUnit}
          />
        )}
      </Container>

      {/* {loadings ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : newApi?.length ? (
        <Container padding={padding} paddingSm={padding}>
          <>
            <Row justify="space-between">
              <Col>
                <Heading>All Properties</Heading>
              </Col>
              <Col style={{ display: "flex" }}>
                <Form.Item style={{ marginRight: "20px" }}>
                  <Checkbox checked={onlyUnit} onChange={onChange}>
                    Show only Deactivated Properties
                  </Checkbox>
                </Form.Item>
                
                <Form.Item
                  label="Table View &nbsp;"
                  style={{ marginRight: "20px" }}
                >
                  <Switch checked={showHeader} onChange={handleHeaderChange} />
                </Form.Item>
               
              </Col>
            </Row>
            {!showHeader ? (
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <StyledApparCardWrapper wd={"100"} ml={"0"}>
                    <Row gutter={[30, 30]}>
                      {loading &&
                        [...Array(6).keys()].map((v) => (
                          <Col
                            key={v}
                            xs={24}
                            sm={12}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                          >
                            <StyledApparCard>
                              <Skeleton.Image
                                active={loading}
                                style={{ width: "100%" }}
                              ></Skeleton.Image>
                              <StyledApparContentWrapper mh={"0"}>
                                <Skeleton.Input
                                  active={loading}
                                  style={{
                                    width: "100%",
                                    height: "20px",
                                    marginBottom: 20,
                                  }}
                                ></Skeleton.Input>
                                <Skeleton.Input
                                  active={loading}
                                  style={{
                                    width: "100%",
                                    height: 20,
                                    marginBottom: 20,
                                  }}
                                ></Skeleton.Input>
                                <Skeleton.Input
                                  active={loading}
                                  style={{
                                    width: "100%",
                                    height: 20,
                                    marginBottom: 20,
                                  }}
                                ></Skeleton.Input>
                              </StyledApparContentWrapper>
                            </StyledApparCard>
                          </Col>
                        ))}
                    </Row>
                  </StyledApparCardWrapper>
                  <ApartmentSection
                    deactiveDataCardAllProp={deactiveDataCardAllProp}
                    apartmentDummyData={apartmentDummyData}
                    apartmentsWithUnits={allApartment}
                  />
                </Col>
              </Row>
            ) : (
              <ApartmentTable>
                <Table
                  defaultExpandAllRows={true}
                  scroll={{ x: 1900, y: yScroll }}
                  columns={columns}
                  dataSource={[...sortAllPropUnit]}
                  pagination={pagination}
                  loading={loadings || isTableDataLoad}
                  onChange={handleTableChange}
                  rowClassName={(record) =>
                    record.activeStatus === "deactivate" && "disabled-row"
                  }
                  onRow={(r) => ({
                    onClick: (event) => {
                      event.isDefaultPrevented();
                      setSelectedUnitFromTable(r);
                    },
                  })}
                />
              </ApartmentTable>
            )}
          </>

          {!allApartmentForModal[0]?.children ? (
            <UpdateUnitModal
              onCancel={(value) => setModalStateUpdate(value)}
              modalState={modalStateUpdate}
              isEdit={true}
              updateUnit={valueOfSelect}
              getAllApartmentState={getAllApartmentStateArr}
              getUpdatedUnit={getUpdatedUnit}
            />
          ) : (
            <UpdateApartmentModal
              onCancel={(value) => setModalStateUpdate(value)}
              modalState={modalStateUpdate}
              isEdit={true}
              updateUnit={allApartmentForModal}
              getUpdatedApartment={getUpdatedApartment}
              getUpdatedUnitApartment={getUpdatedUnit}
            />
          )}
        </Container>
      ) : (
        <Container minH="calc(100vh - 65px)" padding="50px 50px 25px 330px">
          <AddApartment>
            <Row>
              <Col>
                <NoListingsSvg width={svgWidth} height={svgHeight} />
              </Col>
            </Row>
            <Row className="new__organization">
              <Col>
                <p>Now you can easily create properties under it.</p>
              </Col>
            </Row>
          </AddApartment>
        </Container>
      )} */}
      <AddApartmentModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
        newListedApartment={newListedApartment}
      />
    </>
  );
}

export default AllPropperty;
