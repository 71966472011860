import { Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LeftBottomEdit, PersonalDetailsWrap } from "./PersonalDetails";
import { AddNewApr } from "../listings/Listingstyle";
import { MdOutlineModeEditOutline } from "react-icons/md";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../createPersonalProfile/RegistrationStyle";
import { editOrg, getAllOrg } from "../../store/reducers/user.reducer";
import styled from "styled-components";
import { toUSACurrency, upperCase } from "../../utils/fn";

function BusinessDetails({
  isEdit,
  orgData = {},
  orgName,
  onNameChange = console.log,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  var formRef = React.createRef();
  const [edit, setEdit] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [orgNameValidation, setOrgNameValidation] = useState("");
  // const {
  //   OrganizationName,
  //   OrganizationNumber,
  //   OrganizationEmail,
  //   TotalListedApartment,
  //   TotalGeneratedRevenue,
  // } = useSelector((state) => state.businessDetails.userbusinessDetails);

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({ org_name: orgData?.org_name });
    }
  }, [edit]);

  const toThreeChar = (n, length = 2) => {
    var len = length - ("" + n).length;
    return (len > 0 ? new Array(++len).join("0") : "") + n;
  };

  const onFinish = (value) => {
    setOrgNameValidation("");
    value.id = orgData?.id;
    value.user_id = orgData?.user_id;
    value.change_org_name = true;

    setIsloading(true);
    dispatch(editOrg(value)).then((res) => {
      setIsloading(false);

      if (res?.payload?.Error || res?.payload?.error) {
        setOrgNameValidation(res?.payload?.Error || res?.payload?.error);
      } else {
        dispatch(getAllOrg());
        setEdit(false);
        let newChangedName = { ...orgData };
        newChangedName["org_name"] = value.org_name;
        onNameChange(newChangedName);
      }
    });
  };

  // Validating the organization name or it takes unique name for organization
  useEffect(() => {
    if (
      orgNameValidation?.length &&
      orgNameValidation?.includes("is already exists")
    ) {
      formRef.current.setFields([
        {
          name: "org_name",
          errors: ["Organization Name is already exist"],
        },
      ]);
    }
  }, [orgNameValidation, isloading]);

  // Message for validatting form
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  return (
    <BusinessDetailsWrap>
      {edit ? (
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              onFinish={onFinish}
              requiredMark={"optional"}
              validateMessages={validateMessages}
              // onFieldsChange={onFieldsChange}
              ref={formRef}
              form={form}
            >
              <StyledFormItem
                smwh="100"
                wh="100"
                flexDirection="column"
                flexDirectionSmwh="100"
                style={{ flexDirection: "column" }}
              >
                <Form.Item
                  name="org_name"
                  label="Organization Name"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Organization Name Required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Row
                    justify="end"
                    gutter={[20, 20]}
                    style={{ alignItems: "center" }}
                  >
                    <Col>
                      <h6
                        className="close__class"
                        hg="40px"
                        onClick={() => setEdit(false)}
                      >
                        Cancel
                      </h6>
                    </Col>
                    <Col>
                      <StyledButton htmlType="submit" loading={isloading}>
                        SAVE
                      </StyledButton>
                    </Col>
                  </Row>
                </Form.Item>
              </StyledFormItem>
            </Form>
          </StyledForms>
        </StyledForm>
      ) : (
        <Row gutter={[15, 15]} justify="space-between">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            xxl={{ span: 24 }}
          >
            <p className="span__style">Organization Name</p>
            <h2>
              {upperCase(orgData?.org_name)}
              <MdOutlineModeEditOutline
                style={{
                  color: "#848687",
                  marginLeft: "15px",
                  cursor: "pointer",
                }}
                onClick={() => setEdit(true)}
              />
            </h2>
          </Col>
        </Row>
      )}
      {/* <Row gutter={[15, 15]} justify="space-between">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>Organization Contact</span>
          <h2>+1 985 9637 74 </h2>
        </Col>
      </Row>
      <Row gutter={[15, 15]} justify="space-between">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <span>Organization Email</span>
          <h2>help@prompt.com</h2>
        </Col>
      </Row> */}
      <Row gutter={[15, 15]} justify="space-between">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <p>Total Listed Apartments</p>
          <h2>{toThreeChar(orgData?.apartmentCount)}</h2>
        </Col>
      </Row>
      <Row gutter={[15, 15]} justify="space-between">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <p>Total Listed Units</p>
          <h2>{toThreeChar(orgData?.unitCount, 3)}</h2>
        </Col>
      </Row>
      <Row gutter={[15, 15]} justify="space-between">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <p>Total Generated Revenue</p>
          <h2>{toUSACurrency(orgData?.revenueAmount)}</h2>
        </Col>
      </Row>

      {/* <LeftBottomEdit>
        <Row>
          <Col>
            <AddNewApr hg="40px" onClick={isEdit}>
              Edit
            </AddNewApr>
          </Col>
        </Row>
      </LeftBottomEdit> */}
    </BusinessDetailsWrap>
  );
}

export default BusinessDetails;

const BusinessDetailsWrap = styled.div`
  .close__class {
    margin-top: 0;
  }
  @media (max-width: 1441px) {
    padding: 0;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #aaaaaa;
    margin-bottom: 0;
  }
  h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.forth};
  }
  @media (max-width: 1550px) {
    padding: 0;
    p {
      font-size: 12px;
      line-height: 13px;
    }
    h2 {
      font-size: 16px;
      line-height: 19px;
    }
  }
  @media (max-width: 991.98px) {
    padding: 0 20px;
  }
  @media (max-width: 420px) {
    padding: 0 10px;
  }
`;
