import React, { useState, useEffect } from "react";
import { Row, Col, Divider } from "antd";
import { RegistrationMain, RegistrationWrap } from "./RegistrationStyle";
import RegisterFormPersonalDetails from "./RegisterFormPersonalDetails";
import RegisterFormCompanyDetails from "./RegisterFormCompanyDetails";
import RegisterFormCreateListing from "./RegisterFormCreateListing";
import { useLocation, useNavigate } from "react-router-dom";
import ListApartment from "../apartment-listing/ListApartment";
import ListUnit from "../unit-listing/ListUnit";
import { AvenewLogo } from "../../assets/svgs";
import { useWindowSize } from "../../utils/hooks/useWindowSize";

function Registration() {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const [backgroundImg, setBackgroundImg] = useState("");
  const [margin, setMargin] = useState("");
  const navigate = useNavigate();

  // Set pagetitle
  const pageName = (title) => {
    setPageTitle(title);
  };

  // Set Bg image
  const bgImage = (image) => {
    setBackgroundImg(image);
  };

  // Set pageMargin
  const pageMargin = (margin) => {
    setMargin(margin);
  };
  const pathUrl = [
    "/registration/company-details/create-listing",
    "/registration/company-details/create-listing/create-new-apartment",
    "/registration/company-details/create-listing/create-new-apartment/list-unit",
  ];

  return (
    <RegistrationMain backgroundImg={backgroundImg}>
      <RegistrationWrap backgroundImg={backgroundImg} margin={margin}>
        <Row justify="space-between">
          <Col
            xl={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            {/* <h2 className="avenew__signup">Avenew Signup</h2> */}
            <AvenewLogo className="avenew__logo" />
          </Col>
          {location.pathname === "/registration" ? (
            <Col
              xl={{ span: 12 }}
              md={{ span: 12 }}
              sm={{ span: 12 }}
              xs={{ span: 12 }}
              style={{ justifyContent: "end", display: "flex" }}
            >
              {/* <h2 className="avenew__signup">Avenew Signup</h2> */}
              <p className="have__account" onClick={() => navigate("/login")}>
                Already have an account?<span> Login Now</span>
              </p>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row justify="center">
          <Col
            xl={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <h1 className="personal__profile">
              {pageTitle.length ? pageTitle : ""}
            </h1>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xl={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <p className="welcome__avenew">
              Welcome to Avenew! Once you create your organization you would be
              able to add or manage a building/apartment, create unit listings
              and send user invites.
            </p>
          </Col>
        </Row>
        <Row justify="center" className="nav__bar">
          <Col
            xl={{ span: 1 }}
            md={{ span: 1 }}
            sm={{ span: 0 }}
            xs={{ span: 0 }}
          >
            <Divider />
          </Col>
          <Col>
            {/* <p className="nav__font" style={{ color: "#323548" }}> */}
            <p
              className="nav__font"
              style={{
                color: `${
                  location.pathname === "/registration" ? "#4160AC" : "#2B353F"
                }`,
              }}
            >
              PERSONAL DETAILS
            </p>
          </Col>
          <Col
            xl={{ span: 1 }}
            md={{ span: 1 }}
            sm={{ span: 0 }}
            xs={{ span: 0 }}
          >
            <Divider />
          </Col>
          <Col>
            <p
              className="nav__font"
              style={{
                color: `${
                  location.pathname === "/registration/company-details"
                    ? "#4160AC"
                    : "#2B353F"
                }`,
              }}
            >
              ORGANIZATION DETAILS
            </p>
          </Col>
          <Col
            xl={{ span: 1 }}
            md={{ span: 1 }}
            sm={{ span: 0 }}
            xs={{ span: 0 }}
          >
            <Divider />
          </Col>
          {/* /registration/company-details/create-listing/create-new-apartment */}
          {/* /registration/company-details/create-listing/create-new-apartment/list-unit */}
          <Col>
            <p
              className="nav__font"
              style={{
                color: `${
                  pathUrl.includes(location.pathname) ? "#4160AC" : "#2B353F"
                }`,
              }}
            >
              CREATE PROPERTY
            </p>
          </Col>
          <Col
            xl={{ span: 1 }}
            md={{ span: 1 }}
            sm={{ span: 0 }}
            xs={{ span: 0 }}
          >
            <Divider />
          </Col>
        </Row>
        {location.pathname === "/registration" ? (
          <RegisterFormPersonalDetails pageTitle={pageName} bgImage={bgImage} />
        ) : location.pathname === "/registration/company-details" ? (
          <RegisterFormCompanyDetails pageTitle={pageName} bgImage={bgImage} />
        ) : location.pathname ===
          "/registration/company-details/create-listing" ? (
          <RegisterFormCreateListing pageTitle={pageName} bgImage={bgImage} />
        ) : location.pathname ===
          "/registration/company-details/create-listing/create-new-apartment" ? (
          <ListApartment
            pageTitle={pageName}
            bgImage={bgImage}
            pageMargin={pageMargin}
          />
        ) : location.pathname ===
          "/registration/company-details/create-listing/create-new-apartment/list-unit" ? (
          <ListUnit
            pageTitle={pageName}
            bgImage={bgImage}
            pageMargin={pageMargin}
          />
        ) : (
          ""
        )}
      </RegistrationWrap>
    </RegistrationMain>
  );
}

export default Registration;
