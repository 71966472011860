import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import styled from "styled-components";
import { onEdit, openMyAccountTab } from "../../features/useSlice";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import BusinessDetails from "../my-account page/BusinessDetails";
import ChangePassword from "../my-account page/ChangePassword";
import DeleteAccount from "../my-account page/DeleteAccount";
import GettingStarted from "./GettingStarted";

function FaqsSidebar() {
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const { width } = useWindowSize();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState("getting-started");
  const [tabPosition, setTabPosition] = useState("left");

  // 
  useEffect(() => {
    dispatch(openMyAccountTab(activeTab));
    dispatch(onEdit(""));
  }, [activeTab]);

  // Setting tab Horizontal or verticle w.r.t screen width 
  useEffect(() => {
    if (width < 992) {
      setTabPosition("horizontal");
    } else {
      setTabPosition("left");
    }
  }, [width, location.pathname]);
  return (
    <StyledTabWrapper>
      <StyledTabs
        defaultActiveKey={"getting-started"}
        activeKey={activeTab}
        onChange={(tabKey) => {
          setActiveTab(tabKey);
        }}
        tabPosition={tabPosition}
      >
        <TabPane tab="Getting Started" key="getting-started">
          <GettingStarted />
        </TabPane>
        <TabPane tab="Home Rent" key="home-rent">
          coming soon...
        </TabPane>
        <TabPane tab="Rent Property" key="rent-property">
          coming soon...
        </TabPane>
        <TabPane tab="Payment" key="payment">
          coming soon...
        </TabPane>
      </StyledTabs>
    </StyledTabWrapper>
  );
}

export default FaqsSidebar;

export const StyledTabWrapper = styled.div`
  padding: 50px 0px 50px 40px;
  .ant-divider-vertical {
    margin-top: 0px;
    height: 13.9em;
    margin: 0 263px;
    @media (max-width: 1540px) {
      height: 8.9em;
    }

    @media (max-width: 993px) {
      display: none;
    }
  }
  @media (max-width: 992px) {
    ${"" /* margin-left: 30px; */}
    padding:30px;
  }
  .ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding: 0 50px;
  }

  @media (max-width: 1550px) {
    padding: 40px 40px 40px 32px;
    .ant-divider-vertical {
      height: 10.9em;
      margin: 0 210px;
    }
    .ant-tabs-left
      > .ant-tabs-content-holder
      > .ant-tabs-content
      > .ant-tabs-tabpane {
      padding: 0 40px;
    }
  }

  @media (max-width: 991.98px) {
    padding: 15px;
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-tab:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
  .ant-tabs-tab {
    padding: 8px 100px 8px 24px !important;
    font-weight: 400;
    font-size: 18px;
    @media (max-width: 1200px) {
      padding: 8px 10px 8px 24px !important;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.secondary};
  }
  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colors.secondary};
  }

  @media (max-width: 1550px) {
    .ant-tabs-tab {
      padding: 6px 80px 6px 20px !important;
      font-size: 16px;
    }
  }

  @media (max-width: 992px) {
    .ant-tabs-tab {
      padding: 8px 24px 8px 24px !important;
    }
  }
`;
