import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "antd";
import { HiPlus } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import Actions from "./Actions";
import { Container } from "../../ui/Layout/Container";
import { Heading } from "../../ui/Heading/Heading";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import HeaderSearch from "../../components/layout/header/HeaderSearch";
import { RangePickerWrap } from "../../components/dashboard/DashboardStyle";
import {
  getAllTransactions,
  getAllTransactionsByOrg,
} from "../../store/reducers/user.reducer";
import { useDispatch } from "react-redux";
import { dateConverter, toUSACurrency, upperCase } from "../../utils/fn";

function Invoices() {
  const { width } = useWindowSize();
  const location = useLocation();
  const dispatch = useDispatch();
  const [tablePaymentData, setTablePaymentData] = useState({});
  const [tablePaymentDataArr, setTablePaymentDataArr] = useState([]);
  const [tableSortedData, setTableSortedData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  // souce type convert in word
  const sourceTypeFun = (type) => {
    switch (type) {
      case "WALLET":
        return "Cash App";
      case "CARD":
        return "Card";
      case "BANK_ACCOUNT":
        return "ACH";

      default:
        return type;
    }
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  // to lowercase
  const lowerCase = (value) => {
    if (value) {
      const arr = value.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
      }
      const str2 = arr.join(" ");
      return str2;
    }
  };

  // souce type convert in word
  const statusFtColor = (status) => {
    switch (status) {
      case "Awaiting Approval":
        return "#F78341";
      case "Waiting for Lease":
        return "#4160AC";
      case "Deposit Pending":
        return "#FFC123";
      case "PENDING":
        return "#F78341";
      case "COMPLETED":
        return "#56C274";
      case "Denied":
        return "#2B353F";
      default:
        return "#aaaaaa";
    }
  };

  // filter button in Table
  const filterReturn = () => {
    let arr = [];
    if (tablePaymentDataArr?.length) {
      tablePaymentDataArr.map((item) => {
        arr.push({
          text: item.unit_name,
          value: item.unit_name,
        });
      });
    }
    return arr;
  };

  // sort data for table view
  useEffect(() => {
    if (tablePaymentDataArr) {
      const val = tablePaymentDataArr.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return (
          new Date(b.squareResponse.createdAt) -
          new Date(a.squareResponse.createdAt)
        );
      });

      setTableSortedData(val);
      setFilterData(val);
    }
  }, [tablePaymentDataArr]);

  const onSearch = (date) => {
    if (date?.length) {
      const sDate = new Date(date[0]).toLocaleDateString();
      const eDate = new Date(date[1]).toLocaleDateString();

      let filtered = tableSortedData?.filter((product) => {
        let productDate = new Date(
          product.squareResponse["createdAt"]
        ).toLocaleDateString();
        return productDate >= sDate && productDate <= eDate;
      });
      setFilterData(filtered);
    } else {
      setFilterData(tableSortedData);
    }
  };

  // table columns
  const columns = [
    {
      title: "Tx ID",
      dataIndex: "transactionId",
      width: 180,

      render: (_, record) => <span>#{record.transactionId}</span>,
    },
    {
      title: "Units",
      dataIndex: "unit_name",
      width: 300,
      filters: filterReturn(),
      // filterMode: "tree",
      onFilter: (value, record) => {
        return (
          record.unit_name?.startsWith(value) ||
          record.apartment_name?.startsWith(value)
        );
      },
      filterSearch: true,
      render: (_, record) => (
        <span>
          {record.unit_name}, {record.apartment_name}
        </span>
      ),
    },
    {
      title: "Rent",
      dataIndex: "rate",
      width: 110,
      render: (_, record) => (
        <span>{toUSACurrency(Number(record?.payableAmount))}</span>
      ),
    },

    {
      title: "Payment Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 130,

      render: (_, record) => (
        <span>{dateConverter(record?.squareResponse.createdAt)}</span>
      ),
    },
    {
      title: "Received from",
      dataIndex: "firstName",
      width: 180,

      render: (_, record) => (
        <div>
          <td>
            {upperCase(record.firstName)} {upperCase(record.lastName)}
          </td>
        </div>
      ),
    },
    {
      title: "Payment Type",
      dataIndex: "sourceType",
      key: "sourceType",
      width: 130,

      render: (_, record) => (
        <span>{sourceTypeFun(record.squareResponse.sourceType)}</span>
      ),
    },
    {
      title: "Payment Details",
      dataIndex: "paymentDetails",
      key: "sourceType",
      width: 200,
      render: (_, record) => (
        <>
          {record.squareResponse.cardDetails ? (
            <span>
              {lowerCase(record.squareResponse.cardDetails?.card?.cardBrand)}{" "}
              {lowerCase(record.squareResponse.cardDetails?.card?.cardType)}, *
              {record.squareResponse.cardDetails?.card?.last4}
            </span>
          ) : record.bankAccountDetails ? (
            <span>
              {lowerCase(
                record.squareResponse.bankAccountDetails?.accountOwnershipType
              )}{" "}
              {lowerCase(
                record.squareResponse.bankAccountDetails?.achDetails
                  ?.accountType
              )}
              , *
              {lowerCase(
                record.squareResponse.bankAccountDetails?.achDetails
                  ?.accountNumberSuffix
              )}
            </span>
          ) : (
            <span></span>
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (_, record) => (
        <span style={{ color: statusFtColor(record.squareResponse.status) }}>
          {record.squareResponse.status}
        </span>
      ),
    },
    {
      title: "Received Amt",
      dataIndex: "amount",
      width: 130,

      render: (_, record) => (
        <span>
          {toUSACurrency(
            Number(record.squareResponse.totalMoney?.amount) / 100
          )}
        </span>
      ),
    },

    {
      title: "Balance",
      dataIndex: "amount",
      key: "amount",
      width: 130,

      render: (_, record) => <span>{toUSACurrency(record.balance)}</span>,
    },

    // {
    //   dataIndex: "action",
    //   key: "action",
    //   align: "right",
    //   // width: 0,
    //   render: (_, record) => {
    //     return (
    //       <Actions
    //       // options={actionOptions}
    //       // item={record._id}
    //       // onChange={actionHandler}
    //       />
    //     );
    //   },
    // },F
  ];

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    size: "small",
  });

  // pagination for data showing more data
  const fetchData = (params = {}) => {
    // setLoading(true);
    // fetch(
    //   `https://randomuser.me/api?${qs.stringify(getRandomuserParams(params))}`
    // )
    //   .then((res) => res.json())
    //   .then(({ results }) => {
    //     setData(results);
    //     setLoading(false);
    setPagination({
      ...params.pagination,
      total: tablePaymentDataArr.length, // 200 is mock data, you should read it from server
      // total: data.totalCount,
    });

    // setTimeout(() => {
    //   setLoading(false);
    // }, 500);

    //   });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  // filter on table
  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  const [yScroll, setyScroll] = useState(450);

  // table Y scroll screen size
  useEffect(() => {
    if (width < 1550) {
      setyScroll(450);
    } else {
      setyScroll(600);
    }
  }, [width, location.pathname]);

  // get all transactions (API)
  useEffect(() => {
    if (localStorage.getItem("org_id")) {
      setLoading(true);
      if (localStorage.getItem("viewAll")) {
        dispatch(getAllTransactions()).then(({ payload }) => {
          setTablePaymentData(payload);
          setLoading(false);
        });
      } else {
        dispatch(getAllTransactionsByOrg()).then(({ payload }) => {
          setTablePaymentData(payload);
          setLoading(false);
        });
      }
    }
  }, [localStorage.getItem("viewAll"), localStorage.getItem("org_id")]);

  // object data convert to in array
  useEffect(() => {
    if (tablePaymentData && Object.keys(tablePaymentData).length) {
      const arr = Object.values(tablePaymentData);
      setTablePaymentDataArr(arr);
    } else {
      setTablePaymentDataArr([]);
    }
  }, [tablePaymentData]);

  return (
    <Container paddingSm="40px 40px 0 265px">
      <Row justify="space-between">
        <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={5}>
          <Heading>
            <Link to="/transactions">Transactions</Link>
          </Heading>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={10} xxl={10}>
          <Row gutter={[10, 10]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <HeaderSearch height="40px" bgcolor="#fff" />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <RangePickerWrap
                smHeight="35px"
                format="DD MMM, YYYY"
                onChange={onSearch}
                allowClear
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <DiscountTable>
        <Table
          columns={columns}
          // rowKey={(record) => record.login.uuid}
          dataSource={[...filterData]}
          loading={loading}
          onChange={handleTableChange}
          //   pagination={{
          //     current: 1,
          //     pageSize: 10,
          //     size: "small",
          //     // total: 90,
          //   }}
          pagination={pagination}
          // onChange={this.handleTableChange}
          scroll={{ x: 1300, y: yScroll }}
          // scroll={{ x: 1000 }}
          // scroll={{ y: 350 }}
        />
      </DiscountTable>
    </Container>
  );
}

export default Invoices;

export const DiscountTable = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #dee8ff;
  }

  .ant-table-body {
    /* scroll bar */
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;

      @media (max-width: 767px) {
        width: 0 !important;
        display: none !important;
      }
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

      @media (max-width: 767px) {
        box-shadow: none;
      }
    }

    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      outline: 1px solid #c4c4c4;
      border-radius: 10px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  .ant-table-container,
  .ant-table-content,
  table {
    border-radius: 5px 5px 0 0 !important;
  }

  .ant-table-container .ant-table-content table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  }

  .ant-table-container,
  .ant-table-header,
  table {
    border-radius: 5px 5px 0 0 !important;
  }
  .ant-table-container .ant-table-body table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  }

  .ant-table-tbody > tr > td {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.forth};
  }

  .ant-table-thead > tr > th {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    background-color: ${({ theme }) => theme.colors.forth} !important;
  }
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    display: flex;
  }

  /* FOR PAGINATION */
  .ant-table-pagination.ant-pagination {
    margin: 30px 0;
  }
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  .ant-pagination {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 20px;
    padding-right: 25px;
  }
  .ant-pagination.mini .ant-pagination-item {
    margin-right: 20px;
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 20px;
  }
  .ant-pagination-item a {
    padding: 8px 6px;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background: ${({ theme }) => theme.colors.forth};
    border: none;
  }
  .ant-pagination-item {
    border-radius: 50%;
  }

  @media (max-width: 1550px) {
    .ant-table.ant-table-middle {
      font-size: 14px;
    }

    .ant-table.ant-table-middle .ant-table-thead > tr > th {
      padding: 10px 6px;
    }

    .ant-table-thead > tr > th {
      font-weight: 500;
      font-size: 14px;
      line-height: 12px;
      padding: 12px 12px;
    }

    .ant-table-tbody > tr > td {
      font-size: 14px;
      line-height: 16px;
    }

    /* FOR PAGINATION */
    .ant-table-pagination.ant-pagination {
      margin: 24px 0;
    }
    .ant-pagination.mini .ant-pagination-options {
      display: none;
    }
    .ant-pagination {
      font-size: 16px;
      line-height: 20px;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      font-size: 16px;
      padding-right: 20px;
    }
    .ant-pagination.mini .ant-pagination-item {
      margin-right: 16px;
      min-width: 32px;
      height: 32px;
    }
    .ant-pagination.mini .ant-pagination-jump-next {
      margin-right: 26px;
    }
    .ant-pagination-item a {
      padding: 5px 4px;
    }
  }

  @media (max-width: 991.98px) {
    margin-top: 10px;
  }
  @media (max-width: 425px) {
    .ant-pagination.mini .ant-pagination-item {
      min-width: 22px;
      margin-right: 10px;
      height: 23px;
    }
    .ant-pagination-prev .ant-pagination-item-link {
      padding-right: 10px;
    }
    .ant-pagination.mini .ant-pagination-item {
      line-height: 5px;
    }
    .ant-pagination-item a {
      padding: 9px 0px;
    }
  }
`;
