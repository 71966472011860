import React, { useState, useEffect } from "react";
import { Col, DatePicker, Form, Input, message, Row, Select } from "antd";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
  StyledFormSelect,
  StyledTitle,
} from "../createPersonalProfile/RegistrationStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  createTenants,
  getAllApartment,
  getAllOrg,
  getProspectUser,
  getUnitsByUserId,
  inviteTenants,
  propertiesDetails,
} from "../../store/reducers/user.reducer";
import { IoIosArrowDown } from "react-icons/io";
import moment from "moment";
import { ThanksWrap } from "../thanksPage/ThanksPageStyle";
import { AccountCreateLogo, SendInvitationSvg } from "../../assets/svgs";
import { SelectArrow } from "./AddTenantsForm";
import PhoneSearchCompo from "./PhoneSearchCompo";
import useDebounce from "../../utils/hooks/useDebounce";
import { BsCurrencyDollar } from "react-icons/bs";

function InviteTenantsForm({
  isModal = false,
  isEdit = false,
  onCancel,
  newAddedVal = console.log,
  inviteTenant,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { user } = useSelector((state) => state.user);
  const [loadings, setloadings] = useState(false);
  const [getAllUnits, setGetAllUnits] = useState({});
  const [getAllUnitsArr, setGetAllUnitsArr] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [unitType, setUnitType] = useState("");
  const [isSave, setIsSave] = useState(true);
  const [getAllOrgObj, setGetAllOrgObj] = useState({});
  const [getAllOrgArr, setGetAllOrgArr] = useState([]);
  const [getAllApartmentObj, setGetAllApartment] = useState({});
  const [allApartmentArr, setGetAllApartmentArr] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [apaName, setApaName] = useState("");
  const [apaAddress, setApaAddress] = useState("");
  const [apaphonenumber, setApaphonenumber] = useState("");
  const [invitationValue, setInvitationValue] = useState({});
  const [prospectNumber, setProspectNumber] = useState("");
  const [phoneSearch, setPhoneSearch] = useState(false);
  const [changeDisable, setChangeDisable] = useState(false);

  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day}-${month}-${year}`;
  }

  const [newDayTerm, setNewDayTerm] = useState("");

  const dateConverter = (dateValue, month) => {
    if (selectedDate) {
      var date = new Date(selectedDate);
      var newDate = new Date(
        date.setMonth(date.getMonth() + Number(dateValue?.substring(0, 2)))
      );
      var newDay = new Date(newDate.setDate(newDate.getDate() - 1));

      setNewDayTerm(newDay);

      form.setFieldsValue({
        leaseEnd: moment(newDay),
      });

      return formatDate(newDay);
    }
  };

  // To set all unit list in SetGetUnitsArr(array)
  useEffect(() => {
    if (getAllUnits && Object.keys(getAllUnits).length) {
      const arr = Object.values(getAllUnits);
      setGetAllUnitsArr(arr);
    } else {
      setGetAllUnitsArr([]);
    }
  }, [getAllUnits]);

  // To set all organization list in SetGetAllOrgArr(array)
  useEffect(() => {
    if (getAllOrgObj && Object.keys(getAllOrgObj).length) {
      const arr = Object.values(getAllOrgObj);
      setGetAllOrgArr(arr);
    } else {
      setGetAllOrgArr([]);
    }
  }, [getAllOrgObj]);

  // To set all Apartment list in SetGetAllApartmentArr(array)
  useEffect(() => {
    if (getAllApartmentObj && Object.keys(getAllApartmentObj).length) {
      const arr = Object.values(getAllApartmentObj);
      setGetAllApartmentArr(arr);
    } else {
      setGetAllApartmentArr([]);
    }
  }, [getAllApartmentObj]);

  // Message to validate the form
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  //  To set date when selected
  const onChange = (date, dateString) => {
    setSelectedDate(date);
  };

  // To disable date before today
  const disabledDate = (current) => {
    // Can not select days before today and today
    if (selectedDate) {
      return current && current < selectedDate?.endOf("day");
    }
  };

  // To set field upon change
  const changeHandler = (value) => {
    var orgValue = getAllUnitsArr.filter(function (item) {
      return item["id"] === value;
    });
    if (orgValue?.length) {
      setUnitType(orgValue[0]?.unitType);
      form.setFieldsValue({
        unitType: orgValue[0]?.unitType,
      });
    }
  };

  useEffect(() => {
    if (inviteTenant && inviteTenant?.id) {
      var orgValueApa = allApartmentArr?.filter(function (item) {
        return item["id"] === inviteTenant.apartment_id;
      });
      var orgValueOrg = getAllOrgArr?.filter(function (item) {
        return item["id"] === orgValueApa[0]?.org_id;
      });

      setOrgName(orgValueOrg[0]?.org_name);
      setOrgId(orgValueOrg[0]?.id);
      setApaName(orgValueApa[0]?.unit_name);
      setApaAddress(orgValueApa[0]?.address ? orgValueApa[0]?.address : "");
      setApaphonenumber(
        orgValueApa[0]?.phonenumber ? orgValueApa[0]?.phonenumber : ""
      );
    }
  }, [inviteTenant, allApartmentArr, getAllOrgArr]);

  useEffect(() => {
    dispatch(propertiesDetails()).then(({ payload }) => {
      setGetAllOrgArr(payload?.userOrganizarions);
      setGetAllApartmentArr(payload?.userApartments);
      setGetAllUnitsArr(payload?.userUnits);
    });
  }, []);

  // To set Rent value default w.r.t unit listing
  useEffect(() => {
    var orgValueUnit = getAllUnitsArr?.filter(function (item) {
      return item["id"] === inviteTenant.id;
    });
    if (inviteTenant?.price?.length) {
      form.setFieldsValue({
        rate: orgValueUnit[0]?.price,
      });
    } else {
      form.setFieldsValue({
        rate: "",
      });
    }
  }, [inviteTenant, getAllUnitsArr]);

  // To save or update the form when submitted or saved
  const onFinish = (value) => {
    value.user_id = user.id;
    value.unitType = inviteTenant?.unitType;
    value.phoneNumber = `+1${value.phoneNumber}`;
    value.unitNumber = inviteTenant?.unitNumber;
    value.org_name = orgName;
    value.org_id = orgId;
    value.apartment_name = apaName;
    value.unit_name = inviteTenant?.unit_name;
    value.address = apaAddress;
    value.apaphonenumber = apaphonenumber;
    value.leaseStart = value.leaseStart?.format("DD-MMM-YYYY");
    value.leaseEnd = value.leaseEnd?.format("DD-MMM-YYYY");
    value.apartment_id = inviteTenant?.apartment_id;
    value.unit_id = inviteTenant?.id;
    value.isSeen = false;
    value.paidAmount = "0";
    value.invitaionRequest = "sent";
    value.isDeleted = false;

    setloadings(true);
    dispatch(createTenants(value)).then(({ payload }) => {
      newAddedVal(value);
      setloadings(false);
      // onCancel();
      setIsSave(false);
      setInvitationValue(payload);
    });
  };

  // To invite the tenant
  const inviteChanger = () => {
    setloadings(true);
    // const forTenantsId = {...invitationValue}
    // forTenantsId
    const objCopy = { ...invitationValue };
    objCopy.tenant_id = invitationValue.id;

    dispatch(inviteTenants(objCopy)).then(({ payload }) => {
      message.success("Invitation has been sent");
      setloadings(false);
      onCancel();
    });
  };

  const prefixSelector = (
    <SelectArrow>
      <Form.Item name="prefix" noStyle>
        {/* <span>+1</span> */}
        <Select
          style={{
            width: 70,
          }}
          defaultValue="+1"
        >
          <Option value="+1">+1</Option>
        </Select>
      </Form.Item>
    </SelectArrow>
  );

  const handleChange = (value) => {
    setProspectNumber(value);
  };

  const debouncedSearchValue = useDebounce(prospectNumber, 800);

  useEffect(() => {
    if (debouncedSearchValue?.length === 10) {
      setPhoneSearch(true);
      const phoneNumber = `+1${debouncedSearchValue}`;
      dispatch(getProspectUser({ phoneNumber: phoneNumber })).then(
        ({ payload }) => {
          if (payload.status) {
            message.success(payload.message);
            form.setFieldsValue({
              email: payload.userDetails.email,
              firstName: payload.userDetails.firstName,
              lastName: payload.userDetails.lastName,
            });
            setChangeDisable(true);
          } else {
            message.info(payload.message);
            setChangeDisable(false);
            form.setFieldsValue({
              email: null,
              firstName: null,
              lastName: null,
            });
          }
          setPhoneSearch(false);
        }
      );
    }
  }, [debouncedSearchValue]);

  return (
    <>
      {phoneSearch ? <PhoneSearchCompo /> : ""}

      {isSave ? (
        <StyledForm
          id="tenant-modal"
          wh={isModal ? "100" : "100"}
          smwh="100"
          xswh="100"
          xxswh="100"
        >
          <StyledForms>
            {isModal && (
              <StyledTitle>{isEdit ? "Update" : "Add "} New Tenant</StyledTitle>
            )}

            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem smwh="100">
                <Form.Item
                  name="phoneNumber"
                  label="Contact Number"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      min: 10,
                      max: 10,
                      message: "Invalid Contact Number!",
                    },
                  ]}
                >
                  <Input
                    disabled={isEdit}
                    // addonBefore={prefixSelector}
                    onChange={(e) => {
                      handleChange(e.target.value);
                    }}
                    prefix={`+1`}
                    type="number"
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    placeholder="Contact Number"
                    onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                  />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email"
                  // rules={[
                  //   {
                  //     required: true,
                  //     type: "email",
                  //   },
                  // ]}
                >
                  <Input disabled={changeDisable} placeholder="Email" />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem smwh="100">
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[{ required: true }]}
                >
                  <Input disabled={changeDisable} placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[{ required: true }]}
                >
                  <Input disabled={changeDisable} placeholder="Last Name" />
                </Form.Item>
              </StyledFormItem>

              <StyledFormItem smwh="100">
                <Form.Item name="leaseStart" label="Lease Start">
                  <DatePicker
                    getPopupContainer={() =>
                      document.getElementById("tenant-modal")
                    }
                    allowClear={false}
                    onChange={onChange}
                  />
                </Form.Item>
                <Form.Item name="leaseTerm" label="Lease Term">
                  <Select
                    placeholder="Lease Term"
                    suffixIcon={<IoIosArrowDown />}
                    disabled={isEdit}
                    onChange={dateConverter}
                    getPopupContainer={() =>
                      document.getElementById("tenant-modal")
                    }
                  >
                    <Option value="03 Months">03 Months</Option>
                    <Option value="06 Months">06 Months</Option>
                    <Option value="09 Months">09 Months</Option>
                    <Option value="12 Months">12 Months</Option>
                    <Option value="18 Months">18 Months</Option>
                    <Option value="24 Months">24 Months</Option>
                    <Option value="30 Months">30 Months</Option>
                    <Option value="36 Months">36 Months</Option>
                  </Select>
                </Form.Item>
              </StyledFormItem>
              <StyledFormSelect smwh="100" wh="49">
                <Form.Item name="leaseEnd" label="Lease End">
                  <DatePicker
                    getPopupContainer={() =>
                      document.getElementById("tenant-modal")
                    }
                    disabledDate={disabledDate}
                    allowClear={false}
                  />
                </Form.Item>

                <Form.Item name="rate" label="Rent">
                  <Input
                    prefix={
                      <BsCurrencyDollar className="site-form-item-icon" />
                    }
                    type="number"
                    placeholder="Rent"
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                  />
                </Form.Item>
              </StyledFormSelect>

              <Form.Item>
                <StyledButton loading={loadings} htmlType="submit">
                  save
                </StyledButton>
              </Form.Item>
            </Form>
          </StyledForms>
        </StyledForm>
      ) : (
        <ThanksWrap
          style={{ padding: "0" }}
          boxWidth="100"
          smwh="100"
          xswh="100"
          xxswh="100"
        >
          {/* <img className="vector" src="/Vector.svg" /> */}
          <SendInvitationSvg />
          <h2 className="avenew__signup">Send Invite to Tenant</h2>
          <h1 className="personal__profile">
            Tenant Information has been saved successfully in Avenew system
          </h1>
          {/* <StyledButton loading={loadings} htmlType="submit"> */}
          <Form.Item>
            <Row justify="center">
              <Col xs={18} sm={18} md={18} lg={16} xl={18} xxl={18}>
                <StyledButton
                  br="10px"
                  loading={loadings}
                  onClick={inviteChanger}
                >
                  Invite
                </StyledButton>
              </Col>
            </Row>
          </Form.Item>
        </ThanksWrap>
      )}
    </>
  );
}

export default InviteTenantsForm;
