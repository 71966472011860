import React from "react";
import { Avatar } from "antd";
import { getInitials } from "../../utils/fn";
import styled from "styled-components";

const UserAvatarRelationship = ({ src, fullName, ...rest }) => {
  const avatarProps = {
    ...(src ? { src } : { children: getInitials(fullName) }),
  };
  // return styled avatar

  return <StyledAvatar {...rest} {...avatarProps} />;
};

const StyledAvatar = styled(Avatar)``;

export default UserAvatarRelationship;
