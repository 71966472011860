import React, { useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import { IoIosClose } from "react-icons/io";
import { Modal } from "antd";
import ListApartment from "../apartment-listing/ListApartment";
import ListUnit from "../unit-listing/ListUnit";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useLocation } from "react-router";

function AddApartmentModal({
  modalState,
  onCancel,
  isApart = false,
  newListedApartment,
  newListedSubUnit,
  newListedUnit,
  getAllApartmentState,
}) {
  const [isFormChange, setIsFormChange] = useState(false);
  const [isApartValue, setIsApartValue] = useState(isApart);
  const [apartmentName, setApartmentName] = useState("");

  const { width } = useWindowSize();
  const location = useLocation();

  const [modalWidth, setModalWidth] = useState(1000);

  // To set width of Modal according to width of the window screen
  useEffect(() => {
    if (width < 1550) {
      setModalWidth(800);
    } else {
      setModalWidth(1000);
    }
  }, [width, location.pathname]);

  function fromChanges(val) {
    setIsFormChange(val);
  }

  // To pop the alert when someone wants to exit or navigate without saving or submitting details
  const openPrompt = (message) => {
    var answer = window.confirm(message);
    if (answer === true) {
      onCancel(false);
      setIsFormChange(false);
    } else {
    }
  };

  // To set apartment values
  const apartValue = (val) => {
    setIsApartValue(val);
  };

  // preventDefault event is isFormChange is true
  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });

  // To set Aparment Name
  const ApartmentValue = (val) => {
    setApartmentName(val.apartment_name);
  };

  return (
    <>
      <Modal
        getContainer={() => document.querySelector("#modal-container")}
        visible={modalState}
        onCancel={() => {
          if (!isFormChange) {
            onCancel(false);
          } else {
            openPrompt(
              "You will lose all changes if you navigate away from this page without saving"
            );
          }
        }}
        width={modalWidth}
        footer={null}
        style={{ borderRadius: "5px", top: "50px" }}
        className="modal_wrapper"
        closeIcon={<IoIosClose className="modal_close_icon" />}
        maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.8)" }}
        focusTriggerAfterClose={false}
        destroyOnClose={true}
      >
        {!isApartValue ? (
          <ListApartment
            isModal={true}
            onCancel={onCancel}
            apartValue={apartValue}
            onFormChange={fromChanges}
            ApartmentValue={ApartmentValue}
            newListedApartment={newListedApartment}
            newListedSubUnit={newListedSubUnit}
          />
        ) : (
          <ListUnit
            isModal={true}
            onCancel={onCancel}
            apartValue={apartValue}
            onFormChange={fromChanges}
            apartmentName={apartmentName}
            newListedUnit={newListedUnit}
            getAllApartmentState={getAllApartmentState}
          />
        )}
      </Modal>
    </>
  );
}

export default AddApartmentModal;
