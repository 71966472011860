import React, { useEffect, useState } from "react";
import { StyledButton } from "../createPersonalProfile/RegistrationStyle";
import { ThanksPageWrap, ThanksWrap } from "./ThanksPageStyle";
import { Link, useLocation } from "react-router-dom";
import { AccountCreateLogo } from "../../assets/svgs";
import { useWindowSize } from "../../utils/hooks/useWindowSize";

function ThanksPage() {
  const { width } = useWindowSize();
  const location = useLocation();

  const [zoom, setZoom] = useState("100%");

  // To set zoom in % w.r.t screen width
  useEffect(() => {
    if (width < 1550 && width > 991) {
      setZoom("80%");
    } else {
      setZoom("100%");
    }
  }, [width, location.pathname]);
  return (
    <ThanksPageWrap>
      <ThanksWrap smwh="60" xswh="80" xxswh="100">
        {/* <img className="vector" src="/Vector.svg" /> */}
        <AccountCreateLogo />
        <h2 className="avenew__signup">Congratulations!</h2>
        <h1 className="personal__profile">
          Your Avenew Account has been created successfully
        </h1>
        <p className="welcome__avenew">
          You can now manage your organization, add new properties, create
          listings, and invite users easily.
        </p>
        <StyledButton wh={"60%"} displayView="unset">
          <Link to="/listings">View Listings</Link>
        </StyledButton>
      </ThanksWrap>
    </ThanksPageWrap>
  );
}

export default ThanksPage;
