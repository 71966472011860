import { Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { BiBath, BiBed } from "react-icons/bi";
import StarRatings from "react-star-ratings";
import styled from "styled-components";
import { AvenewLogo, SqFtSvg } from "../../assets/svgs";
import {
  RegistrationMain,
  RegistrationWrap,
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../createPersonalProfile/RegistrationStyle";

function AddApartmentFromResman() {
  const [form] = Form.useForm();

  const [pageTitle, setPageTitle] = useState("");
  const [backgroundImg, setBackgroundImg] = useState("");
  const [margin, setMargin] = useState("");

  // To set page title
  const pageName = (title) => {
    setPageTitle(title);
  };

  // To set Bg Image
  const bgImage = (image) => {
    setBackgroundImg(image);
  };

  // To set page margin
  const pageMargin = (margin) => {
    setMargin(margin);
  };

  // Submit function for onclick event
  const onFinish = (value) => {
    console.log(value, "value");
    form.resetFields();
    // navigate("/");
  };

  // Message to validate form
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  return (
    <RegistrationMain backgroundImg="/images/listApartment.png">
      <RegistrationWrap
        backgroundImg="/images/listApartment.png"
        margin={margin}
      >
        <Row justify="center">
          <Col
            xl={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <AvenewLogo className="avenew__logo" />
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xl={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <h1 className="personal__profile">List Your Property</h1>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xl={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <p className="welcome__avenew">
              Welcome to Avenew! Once you create your organization you would be
              able to add or manage a building/ apartment, create unit listings
              and send user invites.
            </p>
          </Col>
          <Col
            xl={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <h2 className="property__directly">
              Want to add property directly through Resman?
            </h2>
          </Col>
        </Row>
        <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
          <StyledForms>
            <Form
              layout="vertical"
              // requiredMark={"optional"}
              form={form}
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="account_id"
                  label="Account ID"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Account ID" />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem wh="100" smwh="100">
                <Form.Item
                  name="property_id"
                  label="Property ID"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Property ID" />
                </Form.Item>
              </StyledFormItem>
            </Form>
          </StyledForms>
        </StyledForm>
        <ApplicationsWrap>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <ApplicationsCard>
                <img src={"/images/property1.png"} />
                <CardDetails>
                  <Row>
                    <Col>
                      <StarRatings
                        rating={4.7}
                        starRatedColor="#F67C36"
                        starDimension="20px"
                        starSpacing="5px"
                        // changeRating={this.changeRating}
                        numberOfStars={5}
                        name="rating"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2>Merrick in Spring Way</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>$569.00</p>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col className="card__bottom">
                      <p>
                        <BiBed className="icon" />
                      </p>
                      <span>Bed : 2</span>
                    </Col>
                    <Col className="card__bottom">
                      <p>
                        <BiBath />
                      </p>
                      <span>Bath : 2</span>
                    </Col>
                    <Col className="card__bottom">
                      <p>
                        <SqFtSvg />
                      </p>
                      <span>Sq ft : 1500</span>
                    </Col>
                  </Row>
                </CardDetails>
              </ApplicationsCard>
            </Col>
          </Row>
        </ApplicationsWrap>
      </RegistrationWrap>
    </RegistrationMain>
  );
}

export default AddApartmentFromResman;

export const CardDetails = styled.div`
  padding: 10px 15px;
  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  p {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #4160ac;
    margin-bottom: 10px;
  }
  .card__bottom p,
  span {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 0;
    letter-spacing: 0.05em;
    color: #848687;
  }

  .card__bottom {
    display: flex;
    align-items: center;
    margin-right: 30px;
    svg {
      margin-right: 10px;
    }
  }
`;

export const ApplicationsCard = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.4s;

  :hover {
    -ms-transform: scale(0.98); /* IE 9 */
    -webkit-transform: scale(0.98); /* Safari 3-8 */
    transform: scale(0.98);
  }
  img {
    width: 150px;
    height: 150px;
  }
`;

export const ApplicationsWrap = styled.div`
  width: 100%;
`;
