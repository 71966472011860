import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
const PrivateRoute = ({ auth }) => {
  // const isLogin = useSelector((state) => state.user.isLoggedIn);
  const isLogin = localStorage.getItem("idToken");

  useEffect(() => {
    isAthenticate();
  }, [isLogin]);

  // Private Route auth
  const isAthenticate = () => {
    if (auth) {
      return true;
    } else {
      return false;
    }
  };

  return isAthenticate() ? <Outlet /> : <Navigate to="/login" />;
};
export default PrivateRoute;
