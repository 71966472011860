import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Popover,
  Row,
  Select,
  message,
} from "antd";
import { AiOutlineUpload } from "react-icons/ai";
import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowUp,
} from "react-icons/io";
import { CoverImage, ImagePreview } from "../ImagePreview/index";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
  StyledFormSelect,
  StyledTitle,
} from "../createPersonalProfile/RegistrationStyle";
import {
  DropDownView,
  ListWrap,
  PlusWrap,
  PropertyBtn,
  StyledCover,
  StyledUploadPreviewWrapper,
  StyledUploadPreviewWrapperCover,
  UploadBox,
  UploadInput,
} from "./ListApartmentStyle";
import { useBeforeunload } from "react-beforeunload";
import { ManagePropertyBtn, ManagePropertyBtnSm } from "../login/LoginStyle";
import { MdClear } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  createApartment,
  createOrg,
  createUnit,
  me,
  updateApartment,
} from "../../store/reducers/user.reducer";
import data from "./state.json";
import useDebounce from "../../utils/hooks/useDebounce";
import { BsCurrencyDollar } from "react-icons/bs";
import { SidebarDivider } from "../layout/TheLayout.styles";
import styled from "styled-components";
import { formatedValue, imagePath, upperCase } from "../../utils/fn";

function ListApartment({
  pageTitle,
  bgImage,
  pageMargin,
  isModal = false,
  isEdit = false,
  onCancel = console.log,
  updateUnit,
  apartValue,
  onFormChange = console.log,
  getUpdatedUnit = console.log,
  newListedApartment,
  newListedSubUnit = console.log,
  getUpdatedUnitApartment = console.log,
}) {
  const { Option } = Select;
  const currentDateTime = new Date();
  const params = useParams();
  const { user } = useSelector((state) => state.user);
  const currentDayTime = new Date();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const upload = React.useRef(null);
  const [state, setState] = useState([]);
  const [selectAmmenities, setSelectAmmenities] = useState([]);
  const [dataChange, setDataChange] = useState(false);
  const [viewAmmenities, setViewAmmenities] = useState(false);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [citesValue, setCitesValue] = useState([]);
  const [totalSelectedAmi, setTotalSelectedAmi] = useState([]);
  const [stateValue, setstateValue] = useState("");
  const [selectImgIdx, setSelectImgIdx] = useState(0);
  const [countNumberOfUnit, setcountNumberOfUnit] = useState(1);
  const [searchCityValue, setSearchCityValue] = useState("");
  const [searchStateValue, setSearchStateValue] = useState("");
  const [multiProperty, setMultiProperty] = useState(false);
  const [loadings, setloadings] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [amenitiesLength, setAmenitiesLength] = useState(12);
  const CheckboxGroup = Checkbox.Group;

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const [statesValue, setStatesValue] = useState(states);

  // Remove Ammenities function
  const removeAmmenities = (value) => {
    if (checkedListprivateParking?.includes(value)) {
      const arr = checkedListprivateParking.filter((item) => item !== value);
      setCheckedListprivateParking(arr);
      setCheckAllprivateParking(false);
    } else if (checkedList?.includes(value)) {
      const arr = checkedList.filter((item) => item !== value);
      setCheckedList(arr);
      setCheckAll(false);
    } else if (checkedListfitnessSports?.includes(value)) {
      const arr = checkedListfitnessSports.filter((item) => item !== value);
      setCheckedListfitnessSports(arr);
      setCheckAllfitnessSports(false);
    } else if (checkedListsharedParking?.includes(value)) {
      const arr = checkedListsharedParking.filter((item) => item !== value);
      setCheckedListsharedParking(arr);
      setCheckAllsharedParking(false);
    } else if (checkedListSafety?.includes(value)) {
      const arr = checkedListSafety.filter((item) => item !== value);
      setCheckedListSafety(arr);
      setCheckAllSafety(false);
    } else if (checkedListEntertainment?.includes(value)) {
      const arr = checkedListEntertainment.filter((item) => item !== value);
      setCheckedListEntertainment(arr);
      setCheckAllEntertainment(false);
    } else if (checkedListAccessibility?.includes(value)) {
      const arr = checkedListAccessibility.filter((item) => item !== value);
      setCheckedListAccessibility(arr);
      setCheckAllAccessibility(false);
    } else if (checkedListOther?.includes(value)) {
      const arr = checkedListOther.filter((item) => item !== value);
      setCheckedListOther(arr);
      setCheckAllOther(false);
    }

    // setSelectAmmenities(selectAmmenities.filter((item) => item !== value));
  };

  // CSS style for Bg image and title of the page
  useEffect(() => {
    if (pageTitle) {
      pageTitle("List Your Property");
      pageMargin("50px auto");
      bgImage("/images/listApartment.png");
    }
  }, []);

  // const fileChangeHandler = (event) => {
  //   setState((s) => [
  //     ...s,
  //     ...Array.from(event.target.files).map((file) => ({
  //       image: file,
  //       path: URL.createObjectURL(file),
  //     })),
  //   ]);
  // };

  // Onchange function for image upload or image input
  const fileChangeHandler = async (event) => {
    // console.log(event.target.files);
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    // Array(...event.target.files).map(async (img) => {
    //   const convertImg = await convertToBase64(img);
    //   setState((s) => [
    //     ...s,
    //     {
    //       image: convertImg,
    //       path: URL.createObjectURL(img),
    //       name: img.name,
    //     },
    //   ]);
    // });

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setState((s) => [
        ...s,
        {
          image: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
        },
      ]);
    };

    Array(...event.target.files).length &&
      (await Promise.all(
        Array(...event.target.files).map(async (file) => {
          let fileExtension = [
            "jpeg",
            "jpg",
            "png",
            "gif",
            "bmp",
            "jfif",
            "tiff",
          ];
          let filename = file.name;
          let fileExt = filename
            .substring(filename.lastIndexOf(".") + 1)
            .toLowerCase();
          if (fileExtension.includes(fileExt)) {
            return await newFun(file);
          } else {
            return message.warning("Please upload Image file");
          }
        })
      ));
  };

  // Onchange function for image upload or image input
  const fileChangeHandlerDrag = async (files) => {
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const newFun = async (img) => {
      const convertImg = await convertToBase64(img);
      setState((s) => [
        ...s,
        {
          image: convertImg,
          path: URL.createObjectURL(img),
          name: img.name,
        },
      ]);
    };

    await Promise.all(
      Array(...files).map(async (file) => {
        let fileExtension = [
          "jpeg",
          "jpg",
          "png",
          "gif",
          "bmp",
          "jfif",
          "tiff",
        ];
        let filename = file.name;
        let fileExt = filename
          .substring(filename.lastIndexOf(".") + 1)
          .toLowerCase();
        if (fileExtension.includes(fileExt)) {
          return newFun(file);
        } else {
          return message.warning("Please upload Image file");
        }
        // const convertImg = await convertToBase64(img);
        // setState((s) => [
        //   ...s,
        //   {
        //     image: convertImg,
        //     path: URL.createObjectURL(img),
        //     name: img.name,
        //   },
        // ]);
      })
    );
    // setState((s) => [
    //   ...s,
    //   ...Array.from(files).map((file) => ({
    //     image: file,
    //     path: URL.createObjectURL(file),
    //   })),
    // ]);
  };

  // To set formchage to true when datachange is true
  useEffect(() => {
    if (dataChange) {
      onFormChange(true);
    }
    return () => {};
  }, [dataChange]);

  useEffect(() => {
    if (isEdit && updateUnit.length) {
      onChangeMultiPropertyUpDown(updateUnit[0]?.numberOfUnit);
      form.setFieldsValue({
        images: state,
        ammenities: selectAmmenities,
        numberOfUnit: updateUnit[0]?.numberOfUnit,
      });
    } else {
      form.setFieldsValue({
        images: state,
        ammenities: selectAmmenities,
        numberOfUnit: countNumberOfUnit,
      });
    }
  }, [form, state, selectAmmenities, isEdit, updateUnit]);

  // Message for validating the form
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get("orgId");

  useEffect(() => {
    if (orgId) {
      dispatch(me());
    }
  }, []);

  // To change the cover image
  const onCoverChange = (idx) => {
    const st = [...state];
    st.map((item, key) => {
      if (key != idx) {
        delete item.isCover;
      } else {
        st[idx].isCover = true;
      }
    });
    setState(st);
  };

  // function to subbmit form after filling it
  const onFinish = (value) => {
    if (params.id) {
      if (isEdit && updateUnit.length) {
        value.ammenities = selectAmmenities;
        value.multi_property = multiProperty;
        value.org_id = updateUnit[0].org_id;
        value.publishStatus = updateUnit[0].publishStatus;
        value.user_id = updateUnit[0].user_id;
        value.activeStatus = updateUnit[0].activeStatus;
        value.id = updateUnit[0].id;
        value.fees = updateUnit[0]?.fees;
        value.bath = formatedValue(value.bath);
        value.bed = formatedValue(value.bed);
        value.default_rent = formatedValue(value.default_rent);
        value.default_security = formatedValue(value.default_security);
        value.sqft = formatedValue(value.sqft);
        value.numberOfUnit = updateUnit[0]?.numberOfUnit;
        value.updatedAt = currentDateTime;
        value.min_credit_score = value.min_credit_score;
        value.email = value.email.toLowerCase();
        value.createdAt = updateUnit.createdAt
          ? updateUnit.createdAt
          : currentDateTime;
        // value.images = updateUnit[0]?.images;
        // if (state?.length && value.images) {
        //   value.images.map((item, key) => {
        //     if (key != selectImgIdx) {
        //       delete item.isCover;
        //     } else {
        //       value.images[selectImgIdx].isCover = true;
        //     }
        //   });
        // }
        if (state?.length && value.images) {
          const arr = [];
          value.images.map((item) => {
            arr.push(...Object.keys(item));
          });
          if (!arr.includes("isCover")) {
            value.images[0].isCover = true;
          }
        }

        setloadings(true);
        dispatch(updateApartment(value)).then(({ payload }) => {
          message.success("Property updated successfully");
          getUpdatedUnit(payload);
          setloadings(false);
          onCancel();
        });
      } else {
        value.multi_property = multiProperty;
        value.publishStatus = "deactivate";
        value.org_id = params.id;
        value.bath = formatedValue(value.bath);
        value.bed = formatedValue(value.bed);
        value.default_rent = formatedValue(value.default_rent);
        value.default_security = formatedValue(value.default_security);
        value.sqft = formatedValue(value.sqft);
        value.user_id = user.id;
        value.updatedAt = currentDateTime;
        value.createdAt = currentDateTime;
        value.activeStatus = "deactivate";
        value.min_credit_score = value.min_credit_score;
        value.email = value.email.toLowerCase();
        if (state?.length && value.images) {
          const arr = [];
          value.images.map((item) => {
            arr.push(...Object.keys(item));
          });
          if (!arr.includes("isCover")) {
            value.images[0].isCover = true;
          }
        }

        setloadings(true);
        dispatch(createApartment(value)).then(({ payload }) => {
          message.success("Property created successfully");
          setloadings(false);
          onCancel();
          newListedApartment(payload.apartment, payload.units);
          newListedSubUnit(payload.units);
        });
      }
    } else if (orgId) {
      setloadings(true);
      value.publishStatus = "deactivate";
      value.multi_property = multiProperty;
      value.bath = formatedValue(value.bath);
      value.bed = formatedValue(value.bed);
      value.default_rent = formatedValue(value.default_rent);
      value.default_security = formatedValue(value.default_security);
      value.sqft = formatedValue(value.sqft);
      value.activeStatus = "deactivate";
      value.ammenities = selectAmmenities;
      value.org_id = orgId;
      value.user_id = user.id;
      value.updatedAt = currentDateTime;
      value.createdAt = currentDateTime;
      value.min_credit_score = value.min_credit_score;
      value.email = value.email.toLowerCase();
      if (state?.length && value.images) {
        const arr = [];
        value.images.map((item) => {
          arr.push(...Object.keys(item));
        });
        if (!arr.includes("isCover")) {
          value.images[0].isCover = true;
        }
      }

      dispatch(createApartment(value)).then(({ payload }) => {
        message.success("Property created successfully");
        newListedSubUnit(payload.units);
        setloadings(false);
        onCancel();
        navigate(`/join-with-us`);

        // if (payload && payload.numberOfUnit) {
        //   for (let i = 0; i < Number(payload.numberOfUnit); i++) {
        //     const arrValue = {};
        //     arrValue.user_id = user.id;
        //     arrValue.unitStatus = "Configure";
        //     arrValue.apartment_id = payload.id;
        //     // arrValue.unitType = payload.unitType;
        //     arrValue.unit_name = `Sub Unit ${i + 1}`;
        //     arrValue.unitType = payload.unitType;

        //     // dispatch(createUnit(arrValue)).then(({ payload }) => {
        //     //   newListedSubUnit(payload);
        //     //   setloadings(false);
        //     //   onCancel();
        //     // });
        //     newListedSubUnit(payload.units);
        //     setloadings(false);
        //     onCancel();
        //   }
        //   navigate(`/join-with-us`);
        // } else {
        //   setloadings(false);
        //   onCancel();
        // }
      });
    } else {
      if (isEdit && Object.keys(updateUnit).length) {
        value.multi_property = multiProperty;
        value.org_id = updateUnit[0].org_id;
        value.user_id = updateUnit[0].user_id;
        value.publishStatus = updateUnit[0].publishStatus;
        value.activeStatus = updateUnit[0].activeStatus;
        value.id = updateUnit[0].id;
        value.fees = updateUnit[0]?.fees;
        value.bath = formatedValue(value.bath);
        value.bed = formatedValue(value.bed);
        value.default_rent = formatedValue(value.default_rent);
        value.default_security = formatedValue(value.default_security);
        value.sqft = formatedValue(value.sqft);
        value.numberOfUnit = updateUnit[0]?.numberOfUnit;
        value.updatedAt = currentDateTime;
        value.min_credit_score = value.min_credit_score;
        value.email = value.email.toLowerCase();
        value.createdAt = updateUnit.createdAt
          ? updateUnit.createdAt
          : currentDateTime;

        if (state?.length && value.images) {
          const arr = [];
          value.images.map((item) => {
            arr.push(...Object.keys(item));
          });
          if (!arr.includes("isCover")) {
            value.images[0].isCover = true;
          }
        }

        setloadings(true);
        dispatch(updateApartment(value)).then(({ payload }) => {
          message.success("Property updated successfully");
          getUpdatedUnitApartment(payload);
          setloadings(false);
          onCancel();
        });
      }
    }

    // setState([]);
    // form.resetFields();

    // if (!isModal) {
    //   navigate(
    //     "/registration/company-details/create-listing/create-new-apartment/list-unit"
    //   );
    // } else {
    //   // apartValue(true);
    //   // ApartmentValue(value);
    // }
    setDataChange(false);
  };
  // useBeforeunload((event) => {
  //   if (showPrompt) {
  //     event.preventDefault();
  //   }
  // });

  // setting field value  upon edit or update listing
  useEffect(() => {
    if (isEdit && updateUnit.length) {
      setSelectAmmenities(updateUnit[0]?.ammenities);
      form.setFieldsValue({
        unitType: updateUnit[0]?.unitType,
        unit_name: updateUnit[0]?.unit_name,
        description: updateUnit[0]?.description,
        address: updateUnit[0]?.address,
        phonenumber: updateUnit[0]?.phonenumber,
        state: updateUnit[0]?.state,
        city: updateUnit[0]?.city,
        zipcode: updateUnit[0]?.zipcode,
        images: updateUnit[0]?.images,
        // images: updateUnit[0]?.images,
        ammenities: updateUnit[0]?.ammenities,
        multi_property: updateUnit[0]?.multi_property,
        numberOfUnit: updateUnit[0]?.numberOfUnit,
        default_rent: updateUnit[0]?.default_rent,
        default_security: updateUnit[0]?.default_security,
        sqft: updateUnit[0]?.sqft,
        bed: updateUnit[0]?.bed,
        bath: updateUnit[0]?.bath,
        price: updateUnit[0]?.price,
        diposit: updateUnit[0]?.diposit,
        email: updateUnit[0]?.email,
      });
    }
  }, [isEdit, updateUnit]);

  // setting img upon edit
  useEffect(() => {
    if (updateUnit?.length && updateUnit[0]?.images && isEdit) {
      updateUnit[0]?.images.map((img) => {
        setState((s) => [
          ...s,
          {
            ...(img.isCover ? { isCover: img.isCover } : ""),
            key: img.key,
            // url: img.url,
            path: img.path,
          },
        ]);
      });
    }
  }, [updateUnit, isEdit]);

  // To set ammenities value upon input checkbox
  const onChange = (checkedValues) => {
    setSelectAmmenities(checkedValues);
  };

  // It will set property unit to 1 by default and more then 1 upon increasing
  const onChangeMultiPropertyUpDown = (e) => {
    setcountNumberOfUnit(e);
    if (0 < Number(e) && Number(e) <= 1) {
      setMultiProperty(false);
    } else if (Number(e) > 1) {
      setMultiProperty(true);
    } else {
      setMultiProperty(true);
    }

    if (0 < Number(e)) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  };

  // It will prevent default event to occur and prevent propagation of event while dragging
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // It will prevent default event to occur and prevent propagation of event while dropping
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      fileChangeHandlerDrag(files);
    }
  };

  const drop = React.useRef(null);
  React.useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);

    // return () => {
    //   drop.current.removeEventListener('dragover', handleDragOver);
    //   drop.current.removeEventListener('drop', handleDrop);
    // };
  }, []);

  // function to set state upon selecting
  const onChangeState = (newValue) => {
    setstateValue(newValue);
    // form.setFieldsValue({ city: [] });
  };

  // debouncing the value to reflect latest result after a given interval of time
  const debouncedSearchValue = useDebounce(searchCityValue, 800);
  const debouncedSearchStateValue = useDebounce(searchStateValue, 800);

  // debouncing the city value
  useEffect(() => {
    if (
      debouncedSearchValue?.length &&
      !citesValue.includes(debouncedSearchValue)
    ) {
      setCitesValue((val) => [...val, debouncedSearchValue]);
    }
  }, [searchCityValue, debouncedSearchValue]);

  // debouncing the state value
  useEffect(() => {
    if (
      debouncedSearchStateValue?.length &&
      !statesValue.includes(debouncedSearchStateValue)
    ) {
      setStatesValue((val) => [...val, debouncedSearchStateValue]);
      states.push(debouncedSearchStateValue);
    }
  }, [searchStateValue, debouncedSearchStateValue]);

  // setting city value upon selecting
  const handleChange = (value) => {
    if (value?.length === 0) {
      const cityArr = Object.values(data);
      const value = flatDeep(cityArr, cityArr.length);
      let uniqueChars = [...new Set(value)];
      setCitesValue(uniqueChars);
    } else {
      filterFunction(value);
    }

    if (value.length > 3) {
      setSearchCityValue(upperCase(value.toLowerCase()));
    }
  };

  // filtering the array of city upon input search
  const filterFunction = (input) => {
    const filter = input.toUpperCase();
    var arr = [];

    for (let i = 0; i < citesValue.length; i++) {
      const txtValue = citesValue[i];
      if (txtValue.toUpperCase().startsWith(filter)) {
        arr.push(txtValue);
      } else {
      }
    }
    setCitesValue(arr);
  };

  // Setting state value upon selected input
  const handleChangeState = (value) => {
    setSearchStateValue(upperCase(value.toLowerCase()));
    // states.push(value)
  };

  function flatDeep(arr, d = 1) {
    if (!Array.isArray(arr)) {
      return arr;
    }
    return d > 0
      ? arr.reduce((acc, val) => acc.concat(flatDeep(val, d - 1)), [])
      : arr.slice();
  }

  useEffect(() => {
    const cityArr = Object.values(data);
    const value = flatDeep(cityArr, cityArr.length);
    let uniqueChars = [...new Set(value)];
    setCitesValue(uniqueChars);
  }, []);

  // const inputImages = document.querySelector("#myInput");

  // Listen for files selection
  // inputImages?.addEventListener("change", (e) => {
  //   // Retrieve all files
  //   const files = inputImages.files;

  //   // Check files count
  //   if (files.length > 4) {
  //     alert(`Only 4 files are allowed to upload.`);
  //   }

  //   // TODO: continue uploading on server
  // });

  const amenities = [
    "Bedroom",
    "Energy",
    "Ac",
    "Dishwasher",
    "Washroom",
    "Gym",
    "Kitchen",
    "Roof",
    "Fridge",
    "Yard",
    "Washer and Dryer",
    "Outdoor areas",
    "Cable ready",
    "Swimming pool",
    "Dining Area",
    "Gated Access",
    "Master Suite",
    "24/7 Water",
    "Pets allowed",
    "Internet access",
  ];

  const newAmenities = [
    {
      "High Value": [
        "Locker Systems",
        "Package Services",
        "Wi-Fi Access",
        "Concierge Services",
        "Rooftop Amenities",
        "Virtual Fitness",
        "Smoke Free Community",
        "Gameroom",
        "Outdoor Kitchens",
        "Dry Cleaning / Laundry Services",
        "Co-Working Area",
        "Doorman",
        "Fiber optic lines",
        "Pet Washing Stations",
        "Bike Storage",
        "Valet Parking",
        "Wine Rooms",
        "Electric Car Charging Stations",
        "Ride Sharing Lounge",
        "Recycling",
        "Golf Simulator",
      ],
    },
    // {
    //   "Fitness & Sports": [
    //     "Basketball Courts",
    //     "Golf Course",
    //     "Tennis Court",
    //     "Swimming Pool",
    //     "Racquetball Court",
    //     "Volleyball Court",
    //     "Jogging Trail",
    //     "Fitness Center",
    //   ],
    // },
    // {
    //   "Private Parking": ["Attached Private Garage", "Detached Private Garage"],
    // },
    // {
    //   "Shared Parking": [
    //     "Reserved Spaces",
    //     "Parking Garage Lot",
    //     "Covered Spaces",
    //     "Breezeway Parking",
    //   ],
    // },
    // {
    //   Safety: ["Gated with access code", "Fenced Yards", "Fenced Communit"],
    // },
    // {
    //   Entertainment: [
    //     "Picnic Area",
    //     "Billiard Room",
    //     "Clubhouse",
    //     "Playground",
    //     "Spa/Jaccuzi",
    //     "Cinema Room",
    //   ],
    // },
    {
      Other: [
        "Valet Trash Service",
        "Dog/Walk Trail",
        "Laundry Rooms",
        "Roomate Floorplans",
      ],
    },
    {
      Accessibility: [
        "School Bus Pickup",
        "On Shuttle/Bus Route",
        "Elevators",
        "Handicap Friendly",
      ],
    },
  ];

  // const CheckboxGroup = Checkbox.Group;
  // const plainOptions = ["Apple", "Pear", "Orange"];
  // const defaultCheckedList = ["Apple", "Orange"];

  // const [checkedList, setCheckedList] = useState([]);
  // const [indeterminate, setIndeterminate] = useState(true);
  // const [customCheck, setCustomCheck] = useState("");
  // const [checkAll, setCheckAll] = useState(false);
  // const onChangesele = (list, arr, idx) => {
  //   let arrs = [];
  //   arrs[idx] = list;
  //   setCheckedList(list);
  //   // setCheckedList(list);
  //   // setIndeterminate(!!list.length && list.length < plainOptions.length);
  //   setCheckAll(list.length === arr.length);
  // };
  // const onCheckAllChange = (e, arr) => {
  //   setCheckedList(e.target.checked ? arr : []);
  //   setIndeterminate(false);
  //   setCheckAll(e.target.checked);
  // };

  const returnTag = (val = []) => {
    return val[0]?.map((item) => (
      <Col>
        <ListWrap>
          <p>{item}</p>
        </ListWrap>
      </Col>
    ));
  };

  useEffect(() => {
    if (selectAmmenities?.length) {
      selectAmmenities.map((item) => {
        amenitieSet(Object.keys(item), Object.values(item));
      });
    }
  }, [selectAmmenities]);

  const highValue = [
    "Locker Systems",
    "Package Services",
    "Wi-Fi Access",
    "Concierge Services",
    "Rooftop Amenities",
    "Virtual Fitness",
    "Smoke Free Community",
    "Gameroom",
    "Outdoor Kitchens",
    "Dry Cleaning / Laundry Services",
    "Co-Working Area",
    "Doorman",
    "Fiber optic lines",
    "Pet Washing Stations",
    "Bike Storage",
    "Valet Parking",
    "Wine Rooms",
    "Electric Car Charging Stations",
    "Ride Sharing Lounge",
    "Recycling",
    "Golf Simulator",
  ];

  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const onChanges = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < highValue.length);
    setCheckAll(list.length === highValue.length);
  };
  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? highValue : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const fitnessSports = [
    "Basketball Courts",
    "Golf Course",
    "Tennis Court",
    "Swimming Pool",
    "Racquetball Court",
    "Volleyball Court",
    "Jogging Trail",
    "Fitness Center",
  ];

  const [checkedListfitnessSports, setCheckedListfitnessSports] = useState([]);
  const [indeterminatefitnessSports, setIndeterminatefitnessSports] =
    useState(true);
  const [checkAllfitnessSports, setCheckAllfitnessSports] = useState(false);
  const [viewAllfitnessSports, setViewAllfitnessSports] = useState(false);

  const onChangesfitnessSports = (list) => {
    setCheckedListfitnessSports(list);
    setIndeterminatefitnessSports(
      !!list.length && list.length < fitnessSports.length
    );
    setCheckAllfitnessSports(list.length === fitnessSports.length);
  };
  const onCheckAllChangefitnessSports = (e) => {
    setCheckedListfitnessSports(e.target.checked ? fitnessSports : []);
    setIndeterminatefitnessSports(false);
    setCheckAllfitnessSports(e.target.checked);
  };

  const privateParking = ["Attached Private Garage", "Detached Private Garage"];

  const [checkedListprivateParking, setCheckedListprivateParking] = useState(
    []
  );
  const [indeterminateprivateParking, setIndeterminateprivateParking] =
    useState(true);
  const [checkAllprivateParking, setCheckAllprivateParking] = useState(false);
  const [viewAllprivateParking, setViewAllprivateParking] = useState(false);

  const onChangesprivateParking = (list) => {
    setCheckedListprivateParking(list);
    setIndeterminateprivateParking(
      !!list.length && list.length < privateParking.length
    );
    setCheckAllprivateParking(list.length === privateParking.length);
  };
  const onCheckAllChangeprivateParking = (e) => {
    setCheckedListprivateParking(e.target.checked ? privateParking : []);
    setIndeterminateprivateParking(false);
    setCheckAllprivateParking(e.target.checked);
  };

  const sharedParking = [
    "Reserved Spaces",
    "Parking Garage Lot",
    "Covered Spaces",
    "Breezeway Parking",
  ];

  const [checkedListsharedParking, setCheckedListsharedParking] = useState([]);
  const [indeterminatesharedParking, setIndeterminatesharedParking] =
    useState(true);
  const [checkAllsharedParking, setCheckAllsharedParking] = useState(false);
  const [viewAllsharedParking, setViewAllsharedParking] = useState(false);

  const onChangessharedParking = (list) => {
    setCheckedListsharedParking(list);
    setIndeterminatesharedParking(
      !!list.length && list.length < sharedParking.length
    );
    setCheckAllsharedParking(list.length === sharedParking.length);
  };
  const onCheckAllChangesharedParking = (e) => {
    setCheckedListsharedParking(e.target.checked ? sharedParking : []);
    setIndeterminatesharedParking(false);
    setCheckAllsharedParking(e.target.checked);
  };

  const safety = ["Gated with access code", "Fenced Yards", "Fenced Communit"];

  const [checkedListSafety, setCheckedListSafety] = useState([]);
  const [indeterminateSafety, setIndeterminateSafety] = useState(true);
  const [checkAllSafety, setCheckAllSafety] = useState(false);
  const [viewAllsafety, setViewAllsafety] = useState(false);

  const onChangesSafety = (list) => {
    setCheckedListSafety(list);
    setIndeterminateSafety(!!list.length && list.length < safety.length);
    setCheckAllSafety(list.length === safety.length);
  };
  const onCheckAllChangeSafety = (e) => {
    setCheckedListSafety(e.target.checked ? safety : []);
    setIndeterminateSafety(false);
    setCheckAllSafety(e.target.checked);
  };

  const Entertainment = [
    "Picnic Area",
    "Billiard Room",
    "Clubhouse",
    "Playground",
    "Spa/Jaccuzi",
    "Cinema Room",
  ];

  const [checkedListEntertainment, setCheckedListEntertainment] = useState([]);
  const [indeterminateEntertainment, setIndeterminateEntertainment] =
    useState(true);
  const [checkAllEntertainment, setCheckAllEntertainment] = useState(false);
  const [viewAllEntertainment, setViewAllEntertainment] = useState(false);

  const onChangesEntertainment = (list) => {
    setCheckedListEntertainment(list);
    setIndeterminateEntertainment(
      !!list.length && list.length < Entertainment.length
    );
    setCheckAllEntertainment(list.length === Entertainment.length);
  };
  const onCheckAllChangeEntertainment = (e) => {
    setCheckedListEntertainment(e.target.checked ? Entertainment : []);
    setIndeterminateEntertainment(false);
    setCheckAllEntertainment(e.target.checked);
  };

  const Accessibility = [
    "School Bus Pickup",
    "On Shuttle/Bus Route",
    "Elevators",
    "Handicap Friendly",
  ];

  const [checkedListAccessibility, setCheckedListAccessibility] = useState([]);
  const [indeterminateAccessibility, setIndeterminateAccessibility] =
    useState(true);
  const [checkAllAccessibility, setCheckAllAccessibility] = useState(false);
  const [viewAllAccessibility, setViewAllAccessibility] = useState(false);

  const onChangesAccessibility = (list) => {
    setCheckedListAccessibility(list);
    setIndeterminateAccessibility(
      !!list.length && list.length < Accessibility.length
    );
    setCheckAllAccessibility(list.length === Accessibility.length);
  };
  const onCheckAllChangeAccessibility = (e) => {
    setCheckedListAccessibility(e.target.checked ? Accessibility : []);
    setIndeterminateAccessibility(false);
    setCheckAllAccessibility(e.target.checked);
  };

  const Other = [
    "Valet Trash Service",
    "Dog/Walk Trail",
    "Laundry Rooms",
    "Roomate Floorplans",
  ];

  const [checkedListOther, setCheckedListOther] = useState([]);
  const [indeterminateOther, setIndeterminateOther] = useState(true);
  const [checkAllOther, setCheckAllOther] = useState(false);
  const [viewAllOther, setViewAllOther] = useState(false);

  const onChangesOther = (list) => {
    setCheckedListOther(list);
    setIndeterminateOther(!!list.length && list.length < Other.length);
    setCheckAllOther(list.length === Other.length);
  };
  const onCheckAllChangeOther = (e) => {
    setCheckedListOther(e.target.checked ? Other : []);
    setIndeterminateOther(false);
    setCheckAllOther(e.target.checked);
  };

  useEffect(() => {
    setTotalSelectedAmi([
      { "High Value": checkedList },
      { "Private Parking": checkedListprivateParking },
      { "Shared Parking": checkedListsharedParking },
      { "Fitness Sports": checkedListfitnessSports },
      { Safety: checkedListSafety },
      { Entertainment: checkedListEntertainment },
      { Accessibility: checkedListAccessibility },
      { Other: checkedListOther },
    ]);

    // setTotalSelectedAmi({
    //   highValue: checkedList,
    //   privateParking: checkedListprivateParking,
    //   sharedParking: checkedListsharedParking,
    //   fitnessSports: checkedListfitnessSports,
    //   safety: checkedListSafety,
    //   entertainment: checkedListEntertainment,
    //   accessibility: checkedListAccessibility,
    //   listOther: checkedListOther,
    // });
    if (
      checkedList?.length ||
      checkedListprivateParking?.length ||
      checkedListsharedParking?.length ||
      checkedListfitnessSports?.length ||
      checkedListSafety?.length ||
      checkedListEntertainment?.length ||
      checkedListAccessibility?.length ||
      checkedListOther?.length
    ) {
      setSelectAmmenities([
        { "High Value": checkedList },
        { "Private Parking": checkedListprivateParking },
        { "Shared Parking": checkedListsharedParking },
        { "Fitness Sports": checkedListfitnessSports },
        { Safety: checkedListSafety },
        { Entertainment: checkedListEntertainment },
        { Accessibility: checkedListAccessibility },
        { Other: checkedListOther },
      ]);
    } else {
      // setSelectAmmenities([]);
      setTotalSelectedAmi([]);
      form.setFieldsValue({
        ammenities: null,
      });
    }
  }, [
    state,
    checkedList,
    checkedListprivateParking,
    checkedListsharedParking,
    checkedListfitnessSports,
    checkedListSafety,
    checkedListEntertainment,
    checkedListAccessibility,
    checkedListOther,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      ammenities: selectAmmenities,
    });
  }, [selectAmmenities]);

  const amenitieSet = (amenitiesType, values) => {
    switch (amenitiesType[0]) {
      case "High Value":
        setCheckedList(values[0]);
        setIndeterminate(
          !!values[0]?.length && values[0]?.length < highValue.length
        );
        setCheckAll(values[0].length === highValue.length);
      case "Private Parking":
        setCheckedListprivateParking(values[0]);
        setIndeterminateprivateParking(
          !!values[0]?.length && values[0]?.length < privateParking.length
        );
        setCheckAllprivateParking(values[0]?.length === privateParking.length);
      case "Shared Parking":
        setCheckedListsharedParking(values[0]);
        setIndeterminatesharedParking(
          !!values[0]?.length && values[0]?.length < sharedParking.length
        );
        setCheckAllsharedParking(values[0]?.length === sharedParking.length);
      case "Fitness Sports":
        setCheckedListfitnessSports(values[0]);
        setIndeterminatefitnessSports(
          !!values[0]?.length && values[0]?.length < fitnessSports.length
        );
        setCheckAllfitnessSports(values[0]?.length === fitnessSports.length);
      case "Safety":
        setCheckedListSafety(values[0]);
        setIndeterminateSafety(
          !!values[0]?.length && values[0]?.length < safety.length
        );
        setCheckAllSafety(values[0]?.length === safety.length);
      case "Entertainment":
        setCheckedListEntertainment(values[0]);
        setIndeterminateEntertainment(
          !!values[0]?.length && values[0]?.length < Entertainment.length
        );
        setCheckAllEntertainment(values[0]?.length === Entertainment.length);
      case "Accessibility":
        setCheckedListAccessibility(values[0]);
        setIndeterminateAccessibility(
          !!values[0]?.length && values[0]?.length < Accessibility.length
        );
        setCheckAllAccessibility(values[0]?.length === Accessibility.length);
      case "Other":
        setCheckedListOther(values[0]);
        setIndeterminateOther(
          !!values[0]?.length && values[0]?.length < Other.length
        );
        setCheckAllOther(values[0]?.length === Other.length);
    }
  };

  const renderAmiNew = () => {
    return (
      <div style={{ maxWidth: "900px" }}>
        <Row gutter={[20, 20]}>
          <Col span={6} className="select__aminities">
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              High Value
            </Checkbox>
            <CheckboxGroup
              className="select__aminities"
              options={highValue}
              value={checkedList}
              onChange={onChanges}
            />
          </Col>
          <Col span={6} className="select__aminities">
            <Checkbox
              indeterminate={indeterminateprivateParking}
              onChange={onCheckAllChangeprivateParking}
              checked={checkAllprivateParking}
            >
              Private Parking
            </Checkbox>
            <CheckboxGroup
              options={privateParking}
              value={checkedListprivateParking}
              onChange={onChangesprivateParking}
              style={{ marginBottom: "20px" }}
            />
            <Checkbox
              indeterminate={indeterminatesharedParking}
              onChange={onCheckAllChangesharedParking}
              checked={checkAllsharedParking}
            >
              Shared Parking
            </Checkbox>
            <CheckboxGroup
              options={sharedParking}
              value={checkedListsharedParking}
              onChange={onChangessharedParking}
              style={{ marginBottom: "20px" }}
            />
            <Checkbox
              className="select__aminities"
              indeterminate={indeterminatefitnessSports}
              onChange={onCheckAllChangefitnessSports}
              checked={checkAllfitnessSports}
            >
              Fitness & Sports
            </Checkbox>
            <CheckboxGroup
              options={fitnessSports}
              value={checkedListfitnessSports}
              onChange={onChangesfitnessSports}
            />
          </Col>
          <Col span={6} className="select__aminities">
            <Checkbox
              indeterminate={indeterminateSafety}
              onChange={onCheckAllChangeSafety}
              checked={checkAllSafety}
            >
              Safety
            </Checkbox>
            <CheckboxGroup
              options={safety}
              value={checkedListSafety}
              onChange={onChangesSafety}
              style={{ marginBottom: "20px" }}
            />
            <Checkbox
              indeterminate={indeterminateEntertainment}
              onChange={onCheckAllChangeEntertainment}
              checked={checkAllEntertainment}
            >
              Entertainment
            </Checkbox>
            <CheckboxGroup
              className="select__aminities"
              options={Entertainment}
              value={checkedListEntertainment}
              onChange={onChangesEntertainment}
              style={{ marginBottom: "20px" }}
            />
          </Col>
          <Col span={6} className="select__aminities">
            <Checkbox
              indeterminate={indeterminateAccessibility}
              onChange={onCheckAllChangeAccessibility}
              checked={checkAllAccessibility}
            >
              Accessibility
            </Checkbox>
            <CheckboxGroup
              options={Accessibility}
              value={checkedListAccessibility}
              onChange={onChangesAccessibility}
              style={{ marginBottom: "20px" }}
            />
            <Checkbox
              indeterminate={indeterminateOther}
              onChange={onCheckAllChangeOther}
              checked={checkAllOther}
            >
              Other
            </Checkbox>
            <CheckboxGroup
              className="select__aminities"
              options={Other}
              value={checkedListOther}
              onChange={onChangesOther}
            />
          </Col>
        </Row>
      </div>
    );
  };

  // const renderAmi = (value) => {
  //   return value?.map((item, idx) => {
  //     return (
  //       <>
  //         <Checkbox
  //           indeterminate={indeterminate}
  //           onChange={(e) => {
  //             onCheckAllChange(e, Object.values(item)[0], idx);
  //           }}
  //           checked={checkAll}
  //           key={idx}
  //         >
  //           {Object.keys(item)[0]}
  //         </Checkbox>
  //         <CheckboxGroup
  //           key={idx + 20000}
  //           options={Object.values(item)[0]}
  //           value={checkedList}
  //           onChange={(list) => onChangesele(list, Object.values(item)[0], idx)}
  //         />
  //         <Divider />
  //       </>
  //     );
  //   });
  // };

  // useEffect(() => {
  //   const selectAmi = newAmenities?.map((item) => {
  //     return (
  //       <>
  //         <Checkbox
  //           indeterminate={indeterminate}
  //           onChange={onCheckAllChange}
  //           checked={checkAll}
  //         >
  //           {Object.keys(item)[0]}
  //         </Checkbox>
  //         <Divider />
  //         <CheckboxGroup
  //           options={Object.values(item)[0]}
  //           value={checkedList}
  //           onChange={onChangesele}
  //         />
  //       </>
  //     );
  //   });
  //   setCustomCheck(selectAmi);
  // }, [newAmenities]);

  const deleteImgInput = () => {
    const inputImg = document.getElementById("myInput");
    inputImg.value = null;
  };

  return (
    <StyledForm
      style={{ position: "relative" }}
      id="property-modal"
      wh={isModal ? "100" : "100"}
      smwh="100"
      xswh="100"
      xxswh="100"
    >
      <StyledForms>
        {isModal && (
          <StyledTitle>{isEdit ? "Update" : "List "} Your Property</StyledTitle>
        )}

        {/* <ManagePropertyBtn>
          <img className="resman__img" src="/images/Resman.png" />
          <p>Want to add property directly through Resman? </p>
          <Link to="/apartment-resman">
            LINK HERE <IoIosArrowForward />
          </Link>
        </ManagePropertyBtn>
        <ManagePropertyBtnSm>
          <div className="smScreen__btn">
            <img className="resman__img" src="/images/Resman.png" />
            <h3>
              LINK HERE <IoIosArrowForward />
            </h3>
          </div>
          <p>Want to add property directly through Resman? </p>
        </ManagePropertyBtnSm>
        <Row justify="center" className="or__option">
          <Col
            span={11}
          >
            <Divider />
          </Col>
          <Col span={2}>
            <p>OR</p>
          </Col>
          <Col
            span={11}
          >
            <Divider />
          </Col>
        </Row> */}

        <Form
          layout="vertical"
          // requiredMark={"optional"}
          form={form}
          onFinish={onFinish}
          validateMessages={validateMessages}
          onValuesChange={() => {
            setDataChange(true);
            setShowPrompt(true);
            onFormChange(true);
          }}
        >
          <StyledFormSelect wh="49" smwh="100">
            <Form.Item
              name="unitType"
              label="Select Property Type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                getPopupContainer={() =>
                  document.getElementById("property-modal")
                }
                placeholder="Select Property Type"
                // allowClear
                suffixIcon={<IoIosArrowDown />}
                // disabled={selectedApartment}
              >
                <Option value="Single-family home">Single-family home</Option>
                {/* <Option value="Multi-family home">Multi-family home</Option> */}
                <Option value="Condominium (Condo)">Condominium (Condo)</Option>
                <Option value="Townhouse">Townhouse</Option>
                <Option value="Apartment">Apartment</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="unit_name"
              label="Property Name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Property Name is required.",
                },
              ]}
            >
              <Input placeholder="Property Name" />
            </Form.Item>
          </StyledFormSelect>
          {/* <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="unit_name"
              label="Property Name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Property Name" />
            </Form.Item>
          </StyledFormItem> */}
          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="description"
              label="Description"
              // rules={[{ required: true }]}
            >
              <Input placeholder="Description" />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh={isEdit ? "" : "32"} smwh="100">
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="phonenumber"
              label="Contact Number"
              rules={[
                // { max: 10, message: "Invalid Phone Number!" },
                {
                  min: 10,
                  max: 10,
                  message: "Invalid Contact Number!",
                },
              ]}
              className="input__affix"
            >
              <Input
                type="number"
                placeholder="Contact Number"
                onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                prefix={`+1`}
                min="0"
                onWheel={(e) => e.target.blur()}
              />
            </Form.Item>
            {isEdit ? (
              ""
            ) : (
              <Form.Item
                name="min_credit_score"
                label="Min. Credit Score"
                rules={[
                  { required: isEdit ? false : true },
                  { max: 3, message: "Only 3 digits are allowed" },
                ]}
              >
                <Input
                  type="number"
                  disabled={isEdit}
                  min="0"
                  placeholder="Min Credit Score"
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Item>
            )}
          </StyledFormItem>
          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="address"
              label="Address"
              // rules={[{ required: true }]}
            >
              <Input placeholder="Address" />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
            <Form.Item
              name="city"
              label="City"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Select
                getPopupContainer={() =>
                  document.getElementById("property-modal")
                }
                showSearch
                placeholder="City"
                // onChange={handleChange}
                onSearch={handleChange}
                suffixIcon={<IoIosArrowDown />}
              >
                {citesValue
                  ? citesValue.sort().map((cites, id) => (
                      <Option key={id} value={cites}>
                        {cites}
                      </Option>
                    ))
                  : ""}
              </Select>
            </Form.Item>
            <Form.Item
              name="state"
              label="State"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Select
                showSearch
                placeholder="State"
                onChange={onChangeState}
                onSearch={handleChangeState}
                suffixIcon={<IoIosArrowDown />}
                getPopupContainer={() =>
                  document.getElementById("property-modal")
                }
              >
                {statesValue.length &&
                  statesValue.sort().map((state, id) => (
                    <Option key={id} value={state}>
                      {state}
                    </Option>
                  ))}
                {/* <Option value="3BHK">Texas</Option>
                        <Option value="4BHK">4BHk</Option> */}
              </Select>
            </Form.Item>
            <Form.Item
              name="zipcode"
              label="Zip code"
              rules={[{ max: 6, message: "Invalid Zip code!" }]}
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                type="number"
                placeholder="Zip code"
                onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                min="0"
                onWheel={(e) => e.target.blur()}
              />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem wh="100" smwh="100">
            <Form.Item
              name="images"
              label="Property Images"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <UploadBox ref={drop} onClick={(e) => upload.current.click()}>
                <UploadInput>
                  <p
                    className="ant-upload-text upload__image"
                    style={{ fontSize: "16px" }}
                  >
                    Upload Image of Property
                  </p>
                  <p className="ant-upload-drag-icon">
                    <AiOutlineUpload className="upload__icon" />
                  </p>
                  <p className="upload__icon">Upload</p>
                </UploadInput>
              </UploadBox>
              <input
                accept="image/*"
                id="myInput"
                multiple={"multiple"}
                type="file"
                ref={upload}
                style={{ display: "none" }}
                onChange={fileChangeHandler}
              />
              <div style={{ display: "flex" }}>
                <Row>
                  {state?.map((img, idx) =>
                    img.isCover ? (
                      <Col>
                        <StyledUploadPreviewWrapperCover isPhoto={state.length}>
                          <CoverImage
                            src={img.path}
                            height={95}
                            width={95}
                            onDelete={() => {
                              const st = [...state];
                              // st.splice(0, 1);
                              st.splice(idx, 1);
                              setState(st);
                              deleteImgInput();
                              // upload.current.value = null;
                            }}
                          />
                          <StyledCover>
                            <p>Cover</p>
                          </StyledCover>
                        </StyledUploadPreviewWrapperCover>
                      </Col>
                    ) : (
                      <Col>
                        <StyledUploadPreviewWrapper isPhoto={state.length}>
                          <ImagePreview
                            key={idx}
                            src={img.path ? img.path : imagePath(img.path)}
                            height={95}
                            width={95}
                            onDelete={() => {
                              const st = [...state];
                              st.splice(idx, 1);
                              setState(st);
                              deleteImgInput();
                              // upload.current.value = null;
                            }}
                            onCover={() => {
                              const st = [...state];
                              setState(st);
                              setSelectImgIdx(idx);
                              onCoverChange(idx);
                            }}
                          />
                        </StyledUploadPreviewWrapper>
                      </Col>
                    )
                  )}
                </Row>
              </div>
            </Form.Item>
          </StyledFormItem>

          <StyledFormItem wh="100" smwh="100" checkBoxWidth="4.714286px">
            {/* <Form.Item
              name="ammenities"
              label="Amenities"
              // rules={[{ required: true }]}
            >
              <PropertyBtn
                br={viewAmmenities ? "5px 5px 0 0" : ""}
                onClick={() => setViewAmmenities(!viewAmmenities)}
              >
                <p>Property Amenities</p>
                {!viewAmmenities ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </PropertyBtn>
              {viewAmmenities && (
                <DropDownView>
                  <Checkbox.Group
                    defaultValue={selectAmmenities}
                    style={{ width: "100%" }}
                    onChange={onChange}
                  >
                    <Row justify="space-between">
                      {amenities?.slice(0, amenitiesLength).map((value, id) => (
                        <Col key={id} xs={10} sm={6}>
                          <Checkbox value={value}>{value}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>

                  {amenitiesLength === amenities.length ? (
                    ""
                  ) : (
                    <Row>
                      <Col style={{ paddingTop: "15px", cursor: "pointer" }}>
                        <span
                          onClick={(e) => {
                            e.preventDefault();
                            setAmenitiesLength(amenities.length);
                          }}
                        >
                          VIEW MORE
                        </span>
                      </Col>
                    </Row>
                  )}
                </DropDownView>
              )}
              {!viewAmmenities && (
                <Row gutter={[10, 10]}>
                  {selectAmmenities?.map((val, id) => (
                    <Col key={id}>
                      <ListWrap>
                        <p>{val}</p>
                        <MdClear onClick={() => removeAmmenities(val)} />
                      </ListWrap>
                    </Col>
                  ))}
                </Row>
              )}
            </Form.Item> */}

            {/* <Form.Item
              name="ami"
              label="Select ami"
              rules={[
                {
                  required: true,
                },
              ]}
            > */}
            {/* <Menu triggerSubMenuAction="click" mode="horizontal">
                {renderAmi(newAmenities)}
              </Menu> */}
            {/* <Select
                getPopupContainer={() =>
                  document.getElementById("property-modal")
                }
                placeholder="Select Property Type"
                allowClear
                suffixIcon={<IoIosArrowDown />}
                // disabled={selectedApartment}
              > */}
            {/* {renderAmi(newAmenities)} */}
            {/* </Select> */}
            {/* </Form.Item> */}

            <Form.Item
              name="ammenities"
              label="Amenities"
              rules={[
                {
                  required: true,
                  message: "Amenities are required",
                },
              ]}
            >
              {/* <Popover
                placement="bottom"
                trigger="click"
                content={renderAmiNew}
                // getPopupContainer={() =>
                //   document.getElementById("property-modal")
                // }
              >
                <PropertyBtn
                  br={viewAmmenities ? "5px 5px 0 0" : ""}
                  onClick={() => setViewAmmenities(!viewAmmenities)}
                >
                  <p>Property Amenities</p>
                  {!viewAmmenities ? <IoIosArrowDown /> : <IoIosArrowUp />}
                </PropertyBtn>
              </Popover> */}

              <PropertyBtn
                br={viewAmmenities ? "5px 5px 0 0" : ""}
                onClick={() => setViewAmmenities(!viewAmmenities)}
              >
                <p>Property Amenities</p>
                {!viewAmmenities ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </PropertyBtn>

              {viewAmmenities && (
                <DropDownView>
                  <div>
                    <Row gutter={[20, 20]}>
                      <Col span={6} className="select__aminities">
                        <PlusWrap>
                          <Checkbox
                            indeterminate={indeterminate}
                            onChange={onCheckAllChange}
                            checked={checkAll}
                          >
                            <span className="span__aminities">High Value</span>
                          </Checkbox>
                          <h1
                            onClick={() => {
                              setViewAll(!viewAll);
                            }}
                          >
                            {viewAll ? "-" : "+"}
                          </h1>
                        </PlusWrap>
                        {viewAll ? (
                          <CheckboxGroup
                            className="select__aminities"
                            options={highValue}
                            value={checkedList}
                            onChange={onChanges}
                            style={{ marginLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col span={6} className="select__aminities">
                        <PlusWrap>
                          <Checkbox
                            indeterminate={indeterminateprivateParking}
                            onChange={onCheckAllChangeprivateParking}
                            checked={checkAllprivateParking}
                          >
                            <span className="span__aminities">
                              Private Parking
                            </span>
                          </Checkbox>
                          <h1
                            onClick={() => {
                              setViewAllprivateParking(!viewAllprivateParking);
                            }}
                          >
                            {viewAllprivateParking ? "-" : "+"}
                          </h1>
                        </PlusWrap>
                        {viewAllprivateParking ? (
                          <CheckboxGroup
                            options={privateParking}
                            value={checkedListprivateParking}
                            onChange={onChangesprivateParking}
                            style={{ marginBottom: "20px", marginLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}
                        <PlusWrap>
                          <Checkbox
                            indeterminate={indeterminatesharedParking}
                            onChange={onCheckAllChangesharedParking}
                            checked={checkAllsharedParking}
                          >
                            <span className="span__aminities">
                              Shared Parking
                            </span>
                          </Checkbox>

                          <h1
                            onClick={() => {
                              setViewAllsharedParking(!viewAllsharedParking);
                            }}
                          >
                            {viewAllsharedParking ? "-" : "+"}
                          </h1>
                        </PlusWrap>
                        {viewAllsharedParking ? (
                          <CheckboxGroup
                            options={sharedParking}
                            value={checkedListsharedParking}
                            onChange={onChangessharedParking}
                            style={{ marginBottom: "20px", marginLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}
                        <PlusWrap>
                          <Checkbox
                            className="select__aminities"
                            indeterminate={indeterminatefitnessSports}
                            onChange={onCheckAllChangefitnessSports}
                            checked={checkAllfitnessSports}
                          >
                            <span className="span__aminities">
                              Fitness & Sports
                            </span>
                          </Checkbox>
                          <h1
                            onClick={() => {
                              setViewAllfitnessSports(!viewAllfitnessSports);
                            }}
                          >
                            {viewAllfitnessSports ? "-" : "+"}
                          </h1>
                        </PlusWrap>
                        {viewAllfitnessSports ? (
                          <CheckboxGroup
                            options={fitnessSports}
                            value={checkedListfitnessSports}
                            onChange={onChangesfitnessSports}
                            style={{ marginLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}
                      </Col>

                      <Col span={6} className="select__aminities">
                        <PlusWrap>
                          <Checkbox
                            indeterminate={indeterminateSafety}
                            onChange={onCheckAllChangeSafety}
                            checked={checkAllSafety}
                          >
                            <span className="span__aminities">Safety</span>
                          </Checkbox>
                          <h1
                            onClick={() => {
                              setViewAllsafety(!viewAllsafety);
                            }}
                          >
                            {viewAllsafety ? "-" : "+"}
                          </h1>
                        </PlusWrap>
                        {viewAllsafety ? (
                          <CheckboxGroup
                            options={safety}
                            value={checkedListSafety}
                            onChange={onChangesSafety}
                            style={{ marginBottom: "20px", marginLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}
                        <PlusWrap>
                          <Checkbox
                            indeterminate={indeterminateEntertainment}
                            onChange={onCheckAllChangeEntertainment}
                            checked={checkAllEntertainment}
                          >
                            <span className="span__aminities">
                              Entertainment
                            </span>
                          </Checkbox>
                          <h1
                            onClick={() => {
                              setViewAllEntertainment(!viewAllEntertainment);
                            }}
                          >
                            {viewAllEntertainment ? "-" : "+"}
                          </h1>
                        </PlusWrap>
                        {viewAllEntertainment ? (
                          <CheckboxGroup
                            className="select__aminities"
                            options={Entertainment}
                            value={checkedListEntertainment}
                            onChange={onChangesEntertainment}
                            style={{ marginBottom: "20px", marginLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col span={6} className="select__aminities">
                        <PlusWrap>
                          <Checkbox
                            indeterminate={indeterminateAccessibility}
                            onChange={onCheckAllChangeAccessibility}
                            checked={checkAllAccessibility}
                          >
                            <span className="span__aminities">
                              Accessibility
                            </span>
                          </Checkbox>
                          <h1
                            onClick={() => {
                              setViewAllAccessibility(!viewAllAccessibility);
                            }}
                          >
                            {viewAllAccessibility ? "-" : "+"}
                          </h1>
                        </PlusWrap>
                        {viewAllAccessibility ? (
                          <CheckboxGroup
                            options={Accessibility}
                            value={checkedListAccessibility}
                            onChange={onChangesAccessibility}
                            style={{ marginBottom: "20px", marginLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}
                        <PlusWrap>
                          <Checkbox
                            indeterminate={indeterminateOther}
                            onChange={onCheckAllChangeOther}
                            checked={checkAllOther}
                          >
                            <span className="span__aminities">Other</span>
                          </Checkbox>
                          <h1
                            onClick={() => {
                              setViewAllOther(!viewAllOther);
                            }}
                          >
                            {viewAllOther ? "-" : "+"}
                          </h1>
                        </PlusWrap>
                        {viewAllOther ? (
                          <CheckboxGroup
                            className="select__aminities"
                            options={Other}
                            value={checkedListOther}
                            onChange={onChangesOther}
                            style={{ marginLeft: "5px" }}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </div>
                </DropDownView>
              )}

              {!viewAmmenities && (
                <Row gutter={[10, 10]}>
                  {totalSelectedAmi?.map((val, id) => (
                    <>
                      {Object.values(val)[0]?.length ? (
                        <>
                          <h6>{Object.keys(val)}</h6>
                          <Row gutter={[10, 10]}>
                            {returnTag(Object.values(val))}
                          </Row>
                        </>
                      ) : (
                        ""
                      )}
                      {/* <Col key={id}>
                      <ListWrap>
                        <p>{val}</p>
                        <MdClear onClick={() => removeAmmenities(val)} />
                      </ListWrap>
                    </Col> */}
                    </>
                  ))}
                </Row>
              )}
            </Form.Item>
          </StyledFormItem>
          {/* <StyledFormItem smwh="100">
            <Form.Item
              name="multi_property"
              label="Multi Unit Property"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Checkbox onChange={onChangeMultiProperty}>
                Multi Unit Property
              </Checkbox>
            </Form.Item>
          </StyledFormItem> */}
          <StyledFormItem smwh="100" wh="32" inputNumberHeight="52px">
            <Form.Item
              name="numberOfUnit"
              label="How Many Units"
              rules={[
                {
                  required: true,
                  message: "Units are required",
                },
              ]}
            >
              <InputNumber
                disabled={isEdit}
                placeholder="How Many Units"
                onChange={onChangeMultiPropertyUpDown}
                type="number"
                onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                min={1}
                onWheel={(e) => e.target.blur()}
                // max={10}
              />
              {/* <Input
                // disabled={!multiProperty}
                type="number"
                placeholder="How Many Units"
                onChange={onChangeMultiProperty}
              /> */}
            </Form.Item>
            <Form.Item
              name="default_rent"
              label="Rent"
              className="input__affix"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                prefix={<BsCurrencyDollar className="site-form-item-icon" />}
                disabled={multiProperty}
                type="number"
                placeholder="Rent"
                step="0.0001"
                min="0"
                onWheel={(e) => e.target.blur()}
                // onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
              />
            </Form.Item>
            <Form.Item
              name="default_security"
              label="Deposit"
              // rules={[
              //   {
              //     required: true,
              //   },
              // ]}
            >
              <Input
                prefix={<BsCurrencyDollar className="site-form-item-icon" />}
                disabled={multiProperty}
                type="number"
                placeholder="Deposit"
                step="0.0001"
                min="0"
                onWheel={(e) => e.target.blur()}
                // onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
              />
            </Form.Item>
          </StyledFormItem>
          {!multiProperty && !isEdit ? (
            <>
              <StyledFormItem wh="32" fd={"column"} smwh={"100"}>
                <Form.Item
                  name="sqft"
                  label="Sq ft"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Input
                    type="number"
                    placeholder="Sq ft"
                    step="0.0001"
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    // onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                  />
                </Form.Item>
                <Form.Item
                  name="bed"
                  label="Bed"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Input
                    type="number"
                    placeholder="Bed"
                    min="0"
                    step="0.5"
                    onWheel={(e) => e.target.blur()}
                    // onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                  />
                </Form.Item>
                <Form.Item
                  name="bath"
                  label="Bath"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Input
                    type="number"
                    min="0"
                    step="0.5"
                    placeholder="Bath"
                    onWheel={(e) => e.target.blur()}
                    // onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                  />
                </Form.Item>
              </StyledFormItem>

              {/* <StyledFormItem fd={"row"}>
                <Form.Item
                  name="price"
                  label="Price"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Input type="number" placeholder="Price" />
                </Form.Item>
                <Form.Item
                  name="diposit"
                  label="Deposit Amount"
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                >
                  <Input type="number" placeholder="Deposit Amount" />
                </Form.Item>
              </StyledFormItem> */}
            </>
          ) : (
            ""
          )}
          <Form.Item>
            <StyledButton
              disabled={disableBtn}
              loading={loadings}
              htmlType="submit"
            >
              {isEdit ? "Update" : "List "} This Property
            </StyledButton>
          </Form.Item>
        </Form>
      </StyledForms>
    </StyledForm>
  );
}

export default ListApartment;
