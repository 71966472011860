import styled from "styled-components";
import { Button } from "antd";

export const RegistrationMain = styled.div`
  background-color: #ffffff;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(37, 54, 78);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  box-shadow: inset 0 0 0 1000px ${(props) => props.theme.colors.forth}80;
  background-position: fixed;
  @media (max-width: 425px) {
    box-shadow: inset 0 0 0 1000px ${(props) => props.theme.colors.primary}99;
    background-image: none;
    background-color: #ffffff;
  }
`;

export const RegistrationWrap = styled.div`
  background-color: #ffffff;
  width: 50%;
  padding: 50px;
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin: ${({ margin }) => margin || "auto"};
  position: unset;
  top: 0;
  left: 0;
  transform: none;

  .have__account {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #2b353f;
    margin-bottom: 0;
    cursor: pointer;
  }
  .have__account span {
    color: #f67c36;
  }
  .avenew__signup {
    color: #777777;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
  }
  .avenew__logo {
    margin-bottom: 15px;
    width: 199px;
    height: 49px;
  }

  .personal__profile {
    color: ${({ theme }) => theme.colors.forth};
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
  }

  .welcome__avenew {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #777777;
    margin-bottom: 33px;
  }

  .nav__bar {
    align-items: center;
    margin-bottom: 42px;
    justify-content: space-between;
    @media (max-width: 625px) {
      justify-content: space-around;
    }
  }

  .nav__font {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-transform: uppercase;
    color: #777777;
    margin-bottom: 0;
    ${"" /* padding: 0px 10px 0 10px; */}
  }

  .property__directly {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: ${({ theme }) => theme.colors.forth};
  }

  @media (max-width: 1550px) {
    padding: 40px;
    width: 60%;

    .avenew__logo {
      width: 160px;
      height: 40px;
    }
    .avenew__signup,
    .property__directly {
      font-size: 24px;
      line-height: 30px;
    }
    .personal__profile {
      font-size: 32px;
      line-height: 40px;
    }

    .welcome__avenew {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 20px;
    }

    .nav__bar {
      margin-bottom: 34px;
    }

    .nav__font {
      font-size: 16px;
      line-height: 18px;
    }
  }
  @media (max-width: 1050px) {
    padding: 40px;
    width: 70%;
  }

  @media (max-width: 991.98px) {
    width: 85%;

    .nav__font {
      font-size: 16px;
    }
  }
  @media (max-width: 769px) {
    width: 90%;
    padding: 20px;
  }
  @media (max-width: 545px) {
    .nav__font {
      font-size: 14px;
    }
  }
  @media (max-width: 428px) {
    .nav__font {
      font-size: 11px;
    }
  }

  @media (max-width: 425px) {
    width: 100%;
    transform: none;
    top: 0;
    left: 0;
    /* this is for background image */
    background: transparent;
    ${
      "" /* position: relative;
    background-image: url("/images/createProfileBg.png");
    background-image: url(${({ backgroundImg }) => backgroundImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: ${(props) => props.theme.colors.primary};
    box-shadow: inset 0 0 0 1000px ${(props) => props.theme.colors.primary}99;
    background-position: initial;
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
    min-height: 100vh; */
    }

    .avenew__signup,
    .personal__profile {
      margin-bottom: 0px;
    }
    .welcome__avenew {
      margin-bottom: 16px;
    }
    .nav__bar {
      margin-bottom: 20px;
    }
  }
  @media (max-width: 635px) {
    .avenew__signup {
      font-size: 28px;
    }
    .personal__profile {
      font-size: 35px;
    }
    .welcome__avenew {
      font-size: 12px;
      line-height: 15px;
    }
  }
  @media (max-width: 501px) {
    .avenew__signup {
      font-size: 26px;
    }
    .personal__profile {
      font-size: 30px;
    }
  }
  @media (max-width: 432px) {
    .avenew__signup {
      font-size: 20px;
    }
    .personal__profile {
      font-size: 25px;
    }
  }
  @media (max-width: 358px) {
    .avenew__signup {
      font-size: 18px;
    }
    .personal__profile {
      font-size: 22px;
    }
  }
`;

export const StyledFormItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  h6 {
    color: #4160ac;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 15px;
    width: 100%;
  }

  .input__affix .ant-input-affix-wrapper {
    min-height: auto;
    background: #eeeeee;
    border: ${({ borderColor }) => borderColor || "1px solid #e5e5e5"};
    border-radius: 5px;
    padding: 0;
  }
  .input__affix input {
    border-left: none !important;
  }

  .input__affix
    .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background: #eeeeee;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input {
    background: #eeeeee;
  }

  .input__affix .ant-input-affix-wrapper-status-error .ant-input-prefix {
    color: #000000;
  }
  .ant-input-prefix {
    margin-left: 4px;
  }

  .profile_edit .ant-input-prefix {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  @media screen and (min-width: 1500px) {
    .profile_edit .ant-input-prefix {
      font-size: 20px;
    }
  }

  .ant-select-arrow {
    font-size: 18px;
    color: #777;
  }
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border: none;
  }
  .ant-input-group-addon:last-child {
    padding: 0;
    background: #eee;
    border: none;
  }
  .ant-radio-button-wrapper-checked:not(
      [class*=" ant-radio-button-wrapper-disabled"]
    ).ant-radio-button-wrapper:first-child {
    border-right-color: ${({ theme }) => theme.colors.third};
    border-radius: 3px;
    height: 40px;
    width: 40px;
    font-size: 18px;
    padding-top: 4px;
    height: ${({ radioHeight }) => radioHeight || "40px"};
    width: ${({ radioWidth }) => radioWidth || "40px"};
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #fff;
    background: ${({ theme }) => theme.colors.third};
    border-color: ${({ theme }) => theme.colors.third};
  }
  .ant-radio-button-wrapper:last-child,
  .ant-radio-button-wrapper:first-child {
    border-radius: 3px;
    height: 40px;
    width: 40px;
    font-size: 18px;
    padding-top: 4px;
    margin-right: 5px;
    border: none;
    height: ${({ radioHeight }) => radioHeight || "40px"};
    width: ${({ radioWidth }) => radioWidth || "40px"};
    line-height: 24px;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: #eee;
  }
  .ant-radio-button-wrapper {
    padding: 0;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-radio-button-wrapper:hover {
    position: relative;
    color: #000;
  }
  .ant-form-item {
    width: ${({ wh }) => wh || "49"}%;

    .ant-form-item-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    .ant-input-number {
      width: 100%;
      height: ${({ inputNumberHeight }) => inputNumberHeight || "50px"};
      background: #eeeeee;
      border: ${({ borderColor }) => borderColor || "1px solid #e5e5e5"};
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
    input {
      width: 100%;
      height: 50px;
      height: ${({ inputHeight }) => inputHeight || "50px"};

      background: #eeeeee;
      border: ${({ borderColor }) => borderColor || "1px solid #e5e5e5"};
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
  }

  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 45px;
  }

  .ant-checkbox {
    top: 0;
  }
  .ant-checkbox-inner::after {
    ${"" /* width: 4.714286px; */}
    width: ${({ checkBoxWidth }) => checkBoxWidth || "6.714286px"};
    height: 11.142857px;
  }

  .ant-checkbox + span {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #2b353f;
  }

  .ant-checkbox-inner {
    background: #f6f6f6;
    border: 1px solid #aaaaaa;
    border-radius: 3px;
    width: 23px;
    height: 23px;
  }
  .ant-checkbox-wrapper {
    align-items: center;
  }
  .ant-form input[type="checkbox"]:focus {
    outline: 5px auto ${({ theme }) => theme.colors.secondary};
  }
  .ant-checkbox-checked::after {
    border: 1px solid ${({ theme }) => theme.colors.secondary};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
  }
  .ant-form-item .ant-form-item-label {
    ${"" /* display: none; */}
  }

  .description__box {
    height: 100px;
    padding: 14px 20px;
  }

  display: flex;
  width: 100%;
  justify-content: space-between;

  .ant-form-item {
    width: ${({ wh }) => wh || "49"}%;
    .ant-form-item-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    .ant-select-selector,
    .ant-picker {
      width: 100%;
      height: 50px;
      background: #eeeeee;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      align-items: center;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
    .ant-select-selection-placeholder {
      color: #aaaaaa;
    }
  }
  .ant-picker {
    width: 100%;
    border: none;
    height: 50px;
    background: #eee;

    .ant-picker-input {
      border: none;
      flex-direction: row-reverse;
      .ant-picker-suffix {
        margin-right: 15px;
        font-size: 16px;
        color: #777777;
      }
    }
    .ant-form-item input {
      border: none;
    }
  }
  .ant-picker-input > input::placeholder {
    color: #aaaaaa;
  }
  .ant-picker-input > input {
    border: none;
  }
  .ant-picker-status-error.ant-picker,
  .ant-picker-status-error.ant-picker:not([disabled]):hover {
    background: #eee;
  }

  @media screen and (max-width: 1550px) {
    flex-direction: ${({ flexDirection }) => flexDirection || "row"};
    .ant-form-item {
      width: ${({ flexDirectionSmwh }) => flexDirectionSmwh}%;
    }

    .ant-form-item {
      input,
      .ant-input-number,
      .ant-picker {
        height: 40px;
      }
    }
    .ant-form-item {
      .ant-input-number {
        height: 42px;
      }
    }
    .description__box {
      height: 80px;

      padding: 12px 16px;
    }
    .ant-form-item {
      .ant-select-selector {
        height: 40px;
      }
    }
  }
  @media screen and (max-width: 624px) {
    flex-direction: ${({ fd }) => fd || "column"};
    align-items: center;
    .ant-form-item {
      width: ${({ smwh }) => smwh || "48"}%;
    }
  }
`;
export const StyledFormSelect = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .ant-select-arrow {
    font-size: 18px;
    color: #777;
  }

  .input__affix input {
    border-left: none !important;
  }

  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    background: #eeeeee;
  }

  .input__affix
    .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background: #eeeeee;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input {
    background: #eeeeee;
  }

  .input__affix .ant-input-affix-wrapper-status-error .ant-input-prefix {
    color: #000000;
  }

  .ant-picker,
  input {
    width: 100%;
    ${"" /* border: none; */}
    height: 50px;
    background: #eee;

    .ant-picker-input {
      border: none;
      flex-direction: row-reverse;
      .ant-picker-suffix {
        margin-right: 15px;
        font-size: 16px;
        color: #777777;
      }
    }
    .ant-form-item input {
      border: none;
    }
  }

  .ant-form-item {
    width: ${({ wh }) => wh || "48"}%;
    .ant-form-item-label {
      ${
        "" /* font-family: Mulish;
      font-style: normal; */
      }
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    .ant-select-selector,
    .ant-picker {
      width: 100%;
      height: 50px;
      background: #eeeeee;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      align-items: center;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
    .ant-select-selection-placeholder {
      color: #aaaaaa;
    }
  }
  .ant-form-item .ant-form-item-label {
    ${"" /* display: none; */}
  }
  .description__box {
    padding: 14px 20px;
  }

  @media (max-width: 1550px) {
    .ant-form-item {
      .ant-select-selector,
      .ant-picker,
      input {
        height: 40px;
        background: #eeeeee;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 5px;
        align-items: center;
        @media screen and (max-width: 595px) {
          width: 100%;
        }
      }
      .ant-select-selection-placeholder {
        color: #aaaaaa;
      }
    }
    .ant-form-item .ant-form-item-label {
      ${"" /* display: none; */}
    }
    .description__box {
      padding: 14px 20px;
    }
  }
  @media screen and (max-width: 624px) {
    flex-direction: ${({ fd }) => fd || "column"};
    align-items: center;
    .ant-form-item {
      width: ${({ smwh }) => smwh || "48"}%;
    }
  }
`;

export const StyledFormsLabel = styled.div`
  width: max-content;
  line-height: 34px;
  font-size: 34px;
  font-weight: 700;
  padding-bottom: 14px;
  border-bottom: 1px solid #000;
  @media screen and (max-width: 991.98px) {
    display: none;
  }
`;

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  // width: 50%;
  width: ${({ wh }) => wh || "50"}%;
  max-width: 100%;
  margin: auto;
  // min-height: 100vh;

  @media (max-width: 1199px) {
    width: ${({ smwh }) => smwh || "50"}%;
  }
  @media (max-width: 768px) {
    width: ${({ xswh }) => xswh || "50"}%;
  }
  @media (max-width: 576px) {
    width: ${({ xxswh }) => xxswh || "50"}%;
  }
`;

export const StyledForms = styled.div`
  position: relative;

  .forgot__password {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #f67c36;
    justify-content: end;
    display: flex;
    margin-bottom: 0;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: -10px;
  }
  .otp__error {
    color: #ff4d4f;
  }

  .close__class {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #848687;
    margin-bottom: 0;
    margin-top: 16px;
  }

  .ant-form-item {
    margin-bottom: 20px;
  }
  .or__option {
    align-items: center;
    margin: 18px 0;
    text-align: center;
    p {
      margin: 0 15px 0 15px;
      font-weight: 400;
      font-size: 18px;
      color: #aaa;
    }
    .ant-divider {
      border-top: 1px solid #eee;
    }
  }

  .pass__filed {
  }

  .ant-input-affix-wrapper {
    min-height: 40px;
    background: #eeeeee !important;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 0 11px;
  }
  .ant-input {
    background-color: #eeeeee;
    border-radius: 5px;
  }
  .ant-input::placeholder {
    font-size: 14px;
    color: #949494;
  }

  .label-ant {
    font-size: 62px;
    line-height: 62px;
    font-weight: 700;
  }
  @media (max-width: 1550px) {
    .ant-form-item {
      margin-bottom: 16px;
    }
    .or__option {
      align-items: center;
      margin: 0px 0;
      p {
        margin: 0;
        font-size: 16px;
      }
      .ant-divider {
        border-top: 1px solid #eee;
      }
    }
  }
  @media screen and (max-width: 991.98px) {
    padding-top: 0px;
  }
  @media screen and (max-width: 350px) {
    .or__option {
      p {
        margin: 0;
        font-size: 14px;
      }
    }
  }
`;

export const StyledTitle = styled.h3`
  font-weight: 700;
  font-size: 30px;
  line-height: 49px;
  color: ${({ theme }) => theme.colors.forth};
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 1550px) {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 16px;
  }
  @media (max-width: 400px) {
    font-size: 25px;
  }
`;

export const StyledButton = styled(Button)`
  :hover,
  :focus {
    color: #ffffff;
    border-color: #4160acdb;
    background: #4160acdb;
  }

  span {
    font-weight: ${({ fw }) => fw || "700"};
    font-size: ${({ fs }) => fs || "18px"};
  }

  svg {
    width: 20px !important;
    height: 20px !important;
  }

  width: ${({ wh }) => wh || "100%"};
  height: ${({ hg }) => hg || "50px"};
  border-radius: ${({ br }) => br || "5px"};
  font-size: 14px;
  font-weight: 700;
  color: #ffffff !important;
  background: ${({ theme }) => theme.colors.secondary};
  padding: 0 15px;
  text-transform: uppercase;
  border: none;

  @media (max-width: 1550px) {
    span {
      font-size: ${({ fs }) => fs || "14px"};
    }

    height: ${({ hg }) => hg || "40px"};
  }

  @media (max-width: 991.98px) {
    display: ${({ displayView }) => displayView || "block"};
  }
`;

export const CreateFirstListing = styled.div`
  text-align: center;
  width: 100%;
  max-width: 100%;
  margin: auto;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 49px;
    color: #000000;
    padding-bottom: 52px;
  }

  p {
    margin-top: 35px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #777777;
  }

  svg {
    width: 259px;
    height: 196px;
  }

  @media (max-width: 1550px) {
    h2 {
      font-size: 32px;
      line-height: 40px;
      padding-bottom: 35px;
    }

    p {
      margin-top: 28px;
      font-size: 16px;
      line-height: 18px;
    }

    svg {
      width: 207px;
      height: 156px;
    }
  }

  @media (max-width: 420px) {
    h2 {
      font-size: 35px;
    }
  }
  @media (max-width: 365px) {
    h2 {
      font-size: 30px;
    }
  }
  @media (max-width: 1199px) {
    width: ${({ smwh }) => smwh || "50"}%;
  }
  @media (max-width: 768px) {
    width: ${({ xswh }) => xswh || "50"}%;
  }
  @media (max-width: 576px) {
    width: ${({ xxswh }) => xxswh || "50"}%;
  }
`;

export const AddUnitBtn = styled(Button)`
  border: 1px solid transparent;
  background: ${({ theme }) => theme.colors.secondary};
  box-shadow: 0px 8px 17px #00000019;
  &:not(&.ant-btn-circle, &.ant-btn-round) {
    border-radius: 5px;
    padding: 15px;
    height: 48px;
    min-width: ${({ width }) => width || "120px"};
    ${({ margin }) => (margin ? { margin } : {})}
  }
  color: ${(props) => (props.color ? props.color : "#FFFFFF")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.secondary};
  }

  &.ant-btn-circle {
    height: ${({ height }) => height || "48px"};
    width: ${({ width }) => width || "48px"};
  }
`;
