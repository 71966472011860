import React from "react";

const copy = ({ color = "#747487", ...props }) => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.17454 3.13399C3.18576 3.00312 3.19698 2.91218 3.20034 2.82457C3.24408 1.41277 3.67141 1.00797 5.11828 1.00797C6.56514 1.00797 8.03444 0.979137 9.49251 1.02793C9.89455 1.05168 10.2784 1.20165 10.5883 1.45602C11.2799 2.04968 11.928 2.69121 12.5276 3.37575C12.7912 3.67584 12.9487 4.05273 12.9762 4.4493C13.0121 7.38712 13.002 10.3272 12.993 13.2639C12.993 14.2975 12.4446 14.8165 11.3925 14.8431C11.2108 14.8431 11.0291 14.8598 10.7857 14.872C10.7857 15.1559 10.8025 15.411 10.7857 15.6627C10.7274 16.4257 10.1711 16.9814 9.39606 16.9869C7.06089 17.0024 4.72497 17.0024 2.3883 16.9869C1.55383 16.9802 1.02107 16.3858 1.01434 15.4864C0.999763 13.5456 1.00761 11.6048 1.00649 9.66507C1.00649 8.11243 1.00649 6.55978 1.00649 5.00714C1.00649 3.62529 1.44392 3.19055 2.84031 3.15838C2.92779 3.15728 3.02089 3.14618 3.17454 3.13399ZM9.62262 1.68448C8.03668 1.68448 6.48999 1.68448 4.94331 1.68448C4.13015 1.68448 3.90358 1.90074 3.90246 2.69703C3.90246 6.18604 3.90246 9.67468 3.90246 13.163C3.90246 13.9393 4.11781 14.1389 4.9119 14.14C7.022 14.14 9.13136 14.14 11.24 14.14C12.1058 14.14 12.2887 13.9559 12.2887 13.0953V4.50697C11.4934 4.28516 10.7117 4.23414 10.1229 3.8726C9.37026 3.41124 9.77516 2.48188 9.62262 1.68448ZM3.20258 3.90254C1.84881 3.79164 1.70076 3.93027 1.70076 5.16684C1.70076 8.52942 1.70076 11.8935 1.70076 15.259C1.70076 16.1274 1.90265 16.3215 2.79544 16.3226C4.83227 16.3226 6.86909 16.3226 8.90592 16.3226C9.97144 16.3226 10.2406 15.9821 10.0129 14.8498H9.42522C7.92975 14.8498 6.43429 14.8498 4.93882 14.8498C3.72413 14.8498 3.20146 14.3241 3.20146 13.123C3.20146 10.2595 3.20146 7.39526 3.20146 4.53026L3.20258 3.90254Z"
        // fill="#747487"
        stroke="#747487"
        // strokeWidth="0.5"
      />
    </svg>
  );
};

export default copy;
