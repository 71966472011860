import React, { useState, useRef } from "react";
import { StyledHeaderSearch } from "./TheHeader.styles";
import { TreeSelect } from "antd";
import { FiSearch } from "react-icons/fi";
import "./style.css";

const HeaderSearch = ({ bgcolor, marginBottom, height, maxWidth }) => {
  const inputRef = useRef(null);
  const [placeHolderVal, setPlaceHolderVal] = useState("");
  const [searchTerm, setSearchTerm] = useState(null);
  const [stateDatas, setStateDatas] = useState();

  // To set input value upon click event
  const onButtonClick = () => {
    inputRef.current.focus();
  };

  // Change handler upon searching the value
  const onChange = (val) => {
    setSearchTerm(val);
  };
  return (
    <>
      <StyledHeaderSearch
        style={{ marginBottom: marginBottom }}
        bgcolor={bgcolor}
        height={height}
        maxWidth={maxWidth}
      >
        <FiSearch className="header_search_icon" onClick={onButtonClick} />
        <TreeSelect
          treeData={stateDatas}
          onSearch={onChange}
          showSearch={true}
          showArrow={false}
          listHeight={628}
          style={{ width: "100%" }}
          treeDefaultExpandAll={true}
          dropdownClassName="header_tree_select_dropdown"
          ref={inputRef}
          // onFocus={(e) =>
          //   (e.target.placeholder = placeHolderVal ? "" : "Search...")
          // }
          onSelect={(val, node) => {
            setPlaceHolderVal(val);
            inputRef.current.blur();
          }}
          onBlur={(e) => (e.target.placeholder = "")}
          placeholder="Search..."
          allowClear
        ></TreeSelect>
      </StyledHeaderSearch>
    </>
  );
};

export default HeaderSearch;
