import React, { useCallback, useEffect, useState } from "react";
import { Container } from "../../ui/Layout/Container";
import {
  Row,
  Col,
  Skeleton,
  Spin,
  Form,
  Switch,
  Table,
  Popover,
  Checkbox,
  message,
} from "antd";
import { Heading } from "../../ui/Heading/Heading";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import AddApartmentModal from "../addApartmentModal/AddApartmentModal";
import {
  AddNewApr,
  StyledApparCard,
  StyledApparCardWrapper,
  StyledApparContentWrapper,
} from "./Listingstyle";
import ApartmentSection from "./ApartmentSection";
import { apartmentDummyData } from "./dummy.data";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import {
  ApartmentTable,
  FloatButton,
  SecondaryButton,
} from "../apartments-view/ApartmentsViewStyle";
import { HiPlus } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteApartment,
  deleteUnit,
  getAllPropId,
  propertyStatus,
} from "../../store/reducers/user.reducer";
import NoListing from "../no-listing page/NoListing";
import UpdateUnitModal from "../addApartmentModal/UpdateUnitModal";
import UpdateApartmentModal from "../addApartmentModal/UpdateApartmentModal";
import InviteTenantsModal from "../addTenantsModal/InviteTenantsModal";
import {
  MyAccountWrapRight,
  NameWrap,
  RightSide,
  TenantsDetails,
  RightSideWrap,
} from "../tenants/Tenants";
import { UserAvatar } from "../../ui/Avatar";
import Actions from "../../ui/Actions/Actions";
import { MdMoreVert } from "react-icons/md";
import { formatPhoneNumber, toUSACurrency, upperCase } from "../../utils/fn";
import { httpAPI } from "../../api";

function Listings({ name, displayShow = true }) {
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [modalStateInvite, setModalStateInvite] = useState(false);
  const [loadingIn, setLoadingIn] = useState(true);
  const [loadings, setLoadings] = useState(false);
  const [allApartments, setAllApartments] = useState({});
  const [apartments, setApartments] = useState([]);
  const [valueOfOrg, setValueOfOrg] = useState([]);
  const [waitForApart, setWaitForApart] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [allPropUnit, setAllPropUnit] = useState([]);
  const [sortAllPropUnit, setSortAllPropUnit] = useState([]);
  const [getAllData, setGetAllData] = useState([]);
  const [getAllUnit, setGetAllUnit] = useState({});
  const [inviteTenant, setInviteTenant] = useState({});
  const [getAllUnitArr, setGetAllUnitArr] = useState([]);
  const [modalStateUpdate, setModalStateUpdate] = useState(false);
  const [valueChanger, setValueChanger] = useState("false");
  const [allApartmentForModal, setAllApartmentForModal] = useState([]);
  const [selectedUnitFromTable, setSelectedUnitFromTable] = useState([]);
  const [heading, setHeading] = useState("All Property");
  const unitTypes = [
    { value: "Single-family home", text: "Single-family home" },
    { value: "Condominium (Condo)", text: "Condominium (Condo)" },
    { value: "Townhouse", text: "Townhouse" },
    { value: "Apartment", text: "Apartment" },
    // { value: "Farm/Ranch", text: "Farm/Ranch" },
    // { value: "Multi-family home", text: "Multi-family home" },
    // { value: "Commercial", text: "Commercial" },
  ];
  const [tableFilterUnits, setTableFilterUnits] = useState([]);
  const [tableAllUnits, setTableAllUnits] = useState([]);

  const valueOfSelect = allApartmentForModal[0];
  const { allOrganization, loadingApartment } = useSelector(
    (state) => state.user
  );
  const [getAllApartmentState, setGetAllApartment] = useState({});
  const [getAllApartmentStateArr, setGetAllApartmentStateArr] = useState({});
  const [deavtiveUnits, setDeavtiveUnits] = useState([]);
  const [filerData, setFilerData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchBarText, setSearchBarText] = useState("");
  const [deactiveDataCard, setDeactiveDataCard] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    size: "small",
    query: `${encodeURIComponent(searchBarText)}`,
    lastEvaluatedKey: [],
    showSizeChanger: true,
  });

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = (e) => {
    if (e.target.checked) {
      setSortAllPropUnit(deavtiveUnits);
      setDeactiveDataCard(deavtiveUnits);
    } else {
      setSortAllPropUnit(filerData);
      setDeactiveDataCard(filerData);
    }
  };

  useEffect(() => {
    if (params?.id) {
      var arr = [];

      for (var item in allOrganization) {
        if (allOrganization.hasOwnProperty(item)) {
          arr.push(allOrganization[item]);
        }
      }

      var orgValue = arr.filter(function (item) {
        return item["id"] == localStorage.getItem("toSave");
      });
      setValueOfOrg(orgValue);
    } else {
      if (localStorage.getItem("toSave")) {
        var arr = [];

        for (var item in allOrganization) {
          if (allOrganization.hasOwnProperty(item)) {
            arr.push(allOrganization[item]);
          }
        }

        var orgValue = arr.filter(function (item) {
          return item["id"] == localStorage.getItem("toSave");
        });
        setValueOfOrg(orgValue);
      }
    }
  }, [params.id, heading, allOrganization, localStorage.getItem("toSave")]);

  useEffect(() => {
    if (valueOfOrg && valueOfOrg.length) {
      setHeading(valueOfOrg[0]?.org_name);
    }
  }, [valueOfOrg]);

  useEffect(() => {
    if (params.id && localStorage.getItem("toSave")) {
      // localStorage.setItem("org_id", params.id);
      localStorage.removeItem("viewAll");
    }
  }, [params.id, localStorage.getItem("toSave")]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (allApartments && Object.keys(allApartments).length) {
      const arr = Object.values(allApartments);
      setApartments(arr);
      setWaitForApart(false);
    } else {
      setWaitForApart(true);
      setApartments([]);
    }
  }, [allApartments]);

  useEffect(() => {
    if (getAllUnit && Object.keys(getAllUnit).length) {
      const arr = Object.values(getAllUnit);
      const arrOfSort = [];

      let sortForeach = arr.map((obj) => {
        return obj.unit_name;
      });
      sortForeach.sort();

      sortForeach?.map((val) => {
        arr.filter((obj) => {
          if (obj["unit_name"] === val) {
            arrOfSort.push(obj);
          }
        });
      });

      setGetAllUnitArr(arr);
      setWaitForApart(false);
    } else {
      setWaitForApart(true);
      setGetAllUnitArr([]);
    }
  }, [getAllUnit]);

  useEffect(() => {
    if (allPropUnit?.length) {
      const arrOfSort = [];
      allPropUnit?.map((value) => {
        let sortForeach = value.children.map((val) => {
          return val.unit_name;
        });
        sortForeach.sort();

        sortForeach?.map((val) => {
          value.children.filter((obj) => {
            if (obj["unit_name"] === val) {
              arrOfSort.push(obj);
            }
          });
        });
      });

      const launchOptimistic = apartments?.map((elem, idx) => ({
        ...elem,
        key: idx + 1,
        orgName: getAllOrgArr?.filter(function (item) {
          return item["id"] === elem.org_id;
        }),
        children: arrOfSort.filter(function (item) {
          return item["apartment_id"] === elem.id;
        }),
      }));
      // setSortAllPropUnit(launchOptimistic);
    }
  }, [allPropUnit]);

  useEffect(() => {
    setTimeout(() => setLoadingIn(false), 400);
  }, []);

  const { width } = useWindowSize();
  const location = useLocation();
  const [padding, setPadding] = useState("50px 50px 50px 330px");

  // Setting padding w.r.t screnn width
  useEffect(() => {
    if (width < 1550 && width > 991) {
      setPadding("40px 40px 40px 265px");
    } else {
      setPadding("");
    }
  }, [width, location.pathname]);

  //
  const handleHeaderChange = (enable) => {
    setShowHeader(enable);
  };

  const [switchBtnLoading, setSwitchBtnLoading] = useState(false);
  const [switchBtnId, setSwitchBtnId] = useState("");

  // To set the status of the property
  const publishStatusBtn = (value, record) => {
    setSwitchBtnLoading(true);
    setSwitchBtnId(record.id);
    let valueObj = {};
    const objKeys = Object.keys(record);
    if (objKeys.includes("apartment_id")) {
      valueObj.publishStatus =
        record.publishStatus === "active" ? "deactivate" : "active";
      valueObj.unit_id = record.id;
      valueObj.user_id = record.user_id;
    } else {
      valueObj.publishStatus =
        record.publishStatus === "active" ? "deactivate" : "active";
      valueObj.apartment_id = record.id;
      valueObj.user_id = record.user_id;
    }

    dispatch(propertyStatus(valueObj)).then((res) => {
      if (res?.payload?.Error) {
        message.error(res?.payload?.Error);
      } else {
      }
      getAllProperty(pagination);
    });

    // setTimeout(() => {
    //   setSwitchBtnLoading(false);
    // }, 2000);
  };

  // To set the status of listing
  const activeStatusBtn = (record) => {
    setSwitchBtnId(record.id);
    let valueObj = {};
    const objKeys = Object.keys(record);
    if (objKeys.includes("apartment_id")) {
      valueObj.activeStatus =
        record.activeStatus === "active" ? "deactivate" : "active";
      record.activeStatus =
        record.activeStatus === "active" ? "deactivate" : "active";
      valueObj.unit_id = record.id;
      valueObj.user_id = record.user_id;
    } else {
      valueObj.activeStatus =
        record.activeStatus === "active" ? "deactivate" : "active";
      record.activeStatus =
        record.activeStatus === "active" ? "deactivate" : "active";
      valueObj.apartment_id = record.id;
      valueObj.user_id = record.user_id;
    }

    setTableLoading(true);
    dispatch(propertyStatus(valueObj)).then((res) => {
      if (res?.payload?.Error) {
        message.error(res?.payload?.Error);
      } else {
        message.success(
          valueObj.activeStatus === "deactivate"
            ? "Deactivated successfully"
            : "Activated successfully"
        );
      }
      getAllProperty(pagination);
      setSortAllPropUnit((val) => [...val]);
    });

    // setTimeout(() => {
    //   setSwitchBtnLoading(false);
    // }, 2000);
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Deactive",
      value: "deactive",
      isDanger: false,
      isActiveStatus: true,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        editValue(item);
        break;
      case "delete":
        onDelete(item);
        break;
      case "deactive":
        activeStatusBtn(item);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (tableFilterUnits?.length) {
      let arr = [];
      [...new Set(tableFilterUnits)].map((item) => {
        let obj = {};
        obj.text = item;
        obj.value = item;
        arr.push(obj);
      });
      setTableAllUnits(arr);
    }
  }, [tableFilterUnits]);

  const columns = [
    {
      title: "Unit Type",
      dataIndex: "unitType",
      key: "unitType",
      width: "12%",
      filters: unitTypes,
      filterSearch: true,
      onFilter: (value, record) => record?.unitType?.includes(value),
    },

    {
      title: "Units Name",
      dataIndex: "unit_name",
      key: "unit_name",
      width: "15%",
      // filterMode: "tree",
      filters: tableAllUnits,
      filterSearch: true,
      onFilter: (value, record) => record?.unit_name?.includes(value),
    },
    {
      width: "10%",
      title: "Min. Credit Score",
      dataIndex: "min_credit_score",
      key: "min_credit_score",
    },
    {
      width: "8%",
      title: "No Of Unit",
      dataIndex: "numberOfUnit",
      key: "numberOfUnit",
    },
    {
      title: "Sq.ft",
      dataIndex: "sqft",
      key: "sqft",
    },
    {
      title: "Bed",
      dataIndex: "bed",
      key: "bed",
    },
    {
      title: "Bath",
      dataIndex: "bath",
      key: "bath",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      width: "8%",
      title: "Status",
      dataIndex: "unitStatus",
      key: "unitStatus",
      render: (_, record) => {
        let color = "red";
        if (record.unitStatus === "Sold") {
          color = "#E04141";
        } else if (record.unitStatus === "Contract Sent") {
          color = "#4160AC";
        } else if (record.unitStatus === "Available") {
          color = "#58D255";
        } else if (record.unitStatus === "Pending") {
          color = "#F67C36";
        } else if (record.unitStatus === "Configure") {
          color = "#F67C36";
        }
        return (
          <>
            {record.unitStatus === "Configure" ? (
              <span
                style={{ color: color }}
                key={record.unitStatus}
                onClick={(event) => {
                  editValue(record);
                  event.stopPropagation();
                }}
              >
                {record.unitStatus}
              </span>
            ) : (
              <span style={{ color: color }} key={record.unitStatus}>
                {record.unitStatus}
              </span>
            )}
          </>
        );
      },
    },
    {
      width: "8%",
      title: "Tenants",
      dataIndex: "tenants",
      key: "tenants",
      render: (_, record) => {
        return (
          <>
            {(record.tenant?.length ? record.tenant[0]?.id : false) ? (
              <>
                <Popover
                  placement="left"
                  // trigger="click"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  content={
                    <RightSideWrap
                      style={{ width: "400px" }}
                      padding="15px"
                      minH="auto"
                    >
                      <RightSide>
                        <NameWrap>
                          <UserAvatar
                            className="userName__icon"
                            fullName={"Mark Jecno"}
                            // fullName={`${firstName || ""} ${lastName || ""}`}
                            // src={
                            //   item?.user?.avatar?.path
                            //     ? imagePath(item?.user?.avatar?.path)
                            //     : ""
                            // }
                            src={"/images/tenants.png"}
                          />
                        </NameWrap>
                        <MyAccountWrapRight>
                          <Row justify="space-between">
                            <Col>
                              <h2>
                                {upperCase(record.tenant[0]?.firstName)}{" "}
                                {upperCase(record.tenant[0]?.lastName)}
                              </h2>
                              <p>
                                {formatPhoneNumber(
                                  record.tenant[0]?.phoneNumber
                                )}
                              </p>
                            </Col>
                          </Row>
                        </MyAccountWrapRight>
                      </RightSide>
                      <TenantsDetails>
                        {record.tenant[0]?.org_name ? (
                          <Row>
                            <Col span={12}>
                              <span>Organization Name</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant[0]?.org_name}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        {record.tenant[0]?.apartment_name ? (
                          <Row>
                            <Col span={12}>
                              <span>Property Name</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant[0]?.apartment_name}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        {record.tenant[0]?.unit_name ? (
                          <Row>
                            <Col span={12}>
                              <span>Unit Name</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant[0]?.unit_name}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        {record.tenant[0]?.unitNumber ? (
                          <Row>
                            <Col span={12}>
                              <span>Unit Number</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant[0]?.unitNumber}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant[0]?.unitType ? (
                          <Row>
                            <Col span={12}>
                              <span>Unit Type</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant[0]?.unitType}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant[0]?.leaseTerm ? (
                          <Row>
                            <Col span={12}>
                              <span>Lease Term</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant[0]?.leaseTerm}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant[0]?.leaseStart ? (
                          <Row>
                            <Col span={12}>
                              <span>Lease Start</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant[0]?.leaseStart}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant[0]?.leaseEnd ? (
                          <Row>
                            <Col span={12}>
                              <span>Lease End</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant[0]?.leaseEnd}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant[0]?.dueDate ? (
                          <Row>
                            <Col span={12}>
                              <span>Due Date</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant[0]?.dueDate}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant[0]?.rate ? (
                          <Row>
                            <Col span={12}>
                              <span>Rent</span>
                            </Col>
                            <Col span={12}>
                              <p>
                                {toUSACurrency(Number(record.tenant[0]?.rate))}
                              </p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant[0]?.balance ? (
                          <Row>
                            <Col span={12}>
                              <span>Balance</span>
                            </Col>
                            <Col span={12}>
                              <p>
                                {toUSACurrency(
                                  Number(record.tenant[0]?.balance)
                                )}
                              </p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}

                        {record.tenant[0]?.email ? (
                          <Row>
                            <Col span={12}>
                              <span>Tenant Email</span>
                            </Col>
                            <Col span={12}>
                              <p>{record.tenant[0]?.email}</p>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </TenantsDetails>
                    </RightSideWrap>
                  }
                >
                  <span key={record.tenant[0]?.id}>
                    {upperCase(record.tenant[0]?.firstName)}{" "}
                    {upperCase(record.tenant[0]?.lastName)}
                  </span>
                </Popover>
              </>
            ) : !record.children ? (
              <>
                {record.unitStatus === "Configure" ? (
                  <span
                    className="hover__invite"
                    onClick={(event) => {
                      event.stopPropagation();
                      // setModalStateInvite(true);
                      // setInviteTenant(record);
                    }}
                  >
                    Invite &gt;
                  </span>
                ) : (
                  <span
                    onClick={(event) => {
                      setModalStateInvite(true);
                      event.stopPropagation();
                      setInviteTenant(record);
                    }}
                    style={{ color: "#F67C36" }}
                  >
                    Invite &gt;
                  </span>
                )}
              </>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    // {
    //   dataIndex: "action",
    //   key: "action",
    //   align: "right",
    //   width: "8%",
    //   render: (_, record) => {
    //     return (
    //       <div style={{ display: "flex" }}>
    //         <EditIconWrap
    //           onClick={(event) => {
    //             editValue(record);
    //             event.stopPropagation();
    //           }}
    //         >
    //           <EditSvg />
    //         </EditIconWrap>
    //         <Popconfirm
    //           key={"del-c"}
    //           title="Are you sure?"
    //           onConfirm={(e) => {
    //             e.stopPropagation();
    //             onDelete(record);
    //           }}
    //           onCancel={(e) => {
    //             e.stopPropagation();
    //           }}
    //           okText="Yes"
    //           cancelText="No"
    //           icon={<QuestionCircleOutlined style={{ color: "red" }} />}
    //         >
    //           <EditIconWrap
    //             onClick={(event) => {
    //               event.stopPropagation();
    //             }}
    //           >
    //             <FaTrash />
    //           </EditIconWrap>
    //         </Popconfirm>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Publish Listing",
      dataIndex: "publishStatus",
      key: "publishStatus",
      width: "10%",
      render: (_, record, id) => {
        return (
          <>
            {record.publishStatus ? (
              <Switch
                disabled={record.activeStatus === "deactivate"}
                loading={switchBtnId === record.id ? switchBtnLoading : false}
                defaultChecked={
                  record.publishStatus
                    ? record.publishStatus === "active"
                      ? true
                      : false
                    : false
                }
                checked={
                  record.publishStatus
                    ? record.publishStatus === "active"
                      ? true
                      : false
                    : false
                }
                onChange={(value, event) => {
                  publishStatusBtn(value, record);
                  event.stopPropagation();
                }}
              />
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      fixed: "right",
      width: "6%",
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record}
            onChange={actionHandler}
            Icon={MdMoreVert}
          />
        );
      },
    },
  ];

  // Delete function to remove property from list
  const onDelete = (value) => {
    setTableLoading(true);
    const objKeys = Object.keys(value);
    if (objKeys.includes("apartment_id")) {
      dispatch(deleteUnit({ id: value.id })).then((res) => {
        message.success("Unit deleted successfully");
        // let arr = [];
        // const unit = [...getAllUnitArr];
        // const newUnit = unit.children.map((val) => {
        //   if (val.id === value.id) {
        //   } else {
        //     arr.push(val);
        //   }
        //   setGetAllData({ ...arr });
        // });
        // setTableLoading(false);
        // });
        let editObj = [...getAllData];
        let changedObj = editObj.filter(
          (item) => item.id === value.apartment_id
        );
        let arr = [];
        changedObj[0].children.map((item) => {
          if (item.id === value.id) {
          } else {
            arr.push(item);
          }
        });
        changedObj[0].children = arr;
        changedObj[0].numberOfUnit = arr.length;

        editObj?.forEach((val, idx) => {
          if (val.id === changedObj[0].id) {
            editObj[idx] = changedObj[0];
          }
        });
        setGetAllData([...editObj]);
        setTableLoading(false);
      });
    } else {
      dispatch(deleteApartment({ id: value.id })).then((res) => {
        message.success("Property deleted successfully");
        let arr = [];
        const unit = [...getAllData];
        const newUnit = unit.map((val) => {
          if (val.id === value.id) {
          } else {
            arr.push(val);
          }
          setGetAllData([...arr]);
        });
        setTableLoading(false);
      });
    }
  };

  // To edit or update Apartment and setting the modal state true
  const editValue = (value) => {
    setAllApartmentForModal([value]);
    setModalStateUpdate(true);
  };

  // To Update apartment value for update apratment modal
  const getUpdatedApartment = (value) => {
    if (value && Object.keys(value).length) {
      if (getAllData?.length) {
        const changeUnitType = getAllData.filter((val) => {
          return val.id === value.id;
        });

        changeUnitType[0].children.filter((itm) => {
          return (itm["unitType"] = value.unitType);
        });
        value.children = changeUnitType[0].children;
        value.orgName = changeUnitType[0].orgName;

        const objIndex = getAllData.findIndex((obj) => obj.id === value.id);
        getAllData[objIndex] = value;
        setGetAllData((val) => [...val]);
      }
      if (apartments.length) {
        const objIndex = apartments.findIndex((obj) => obj.id === value.id);
        apartments[objIndex] = value;
        setValueChanger(value);
      }
    }
  };

  // To Update apartment value for update unit modal
  const getUpdatedUnit = (value) => {
    if (value && Object.keys(value).length) {
      if (apartments?.length) {
        const objIndex = getAllUnitArr?.findIndex((obj) => obj.id === value.id);
        getAllUnitArr[objIndex] = value;
        setValueChanger(value);
        setGetAllApartmentStateArr((val) => [...val, value]);
      }
    }
  };
  const [getAllOrgObj, setGetAllOrgObj] = useState({});
  const [getAllOrgArr, setGetAllOrgArr] = useState([]);

  // useEffect(() => {
  //   dispatch(getAllOrg()).then(({ payload }) => {
  //     setGetAllOrgObj(payload);
  //   });
  // }, []);

  // Setting All organization value to SetGetAllorg
  useEffect(() => {
    if (getAllOrgObj && Object.keys(getAllOrgObj).length) {
      const arr = Object.values(getAllOrgObj);
      setGetAllOrgArr(arr);
      setValueChanger(arr);
    } else {
      setGetAllOrgArr([]);
      setValueChanger([]);
    }
  }, [getAllOrgObj]);

  useEffect(() => {
    if (getAllUnitArr) {
      var arr = [];
      for (var item in getAllUnit) {
        if (getAllUnit.hasOwnProperty(item)) {
          arr.push(getAllUnit[item]);
        }
      }

      if (apartments.length) {
        const newChild = getAllUnitArr?.map((ele, idx) => ({
          ...ele,
          unitType: ele.unitType,
        }));

        const launchOptimistic = apartments?.map((elem, idx) => ({
          ...elem,
          key: idx + 1,
          orgName: getAllOrgArr?.filter(function (item) {
            return item["id"] === elem.org_id;
          }),
          children: newChild.filter(function (item) {
            return item["apartment_id"] === elem.id;
          }),
        }));
        setAllPropUnit(launchOptimistic);
      }
    }
  }, [apartments, getAllUnitArr, valueChanger, showHeader]);

  // To set value for newly listed apartment
  const newListedApartment = (value, unit) => {
    let newValue = value;
    newValue.children = unit;
    setGetAllData((val) => [newValue, ...val]);
    setShowHeader(true);
    setGetAllApartmentStateArr((val) => [value, ...val]);
  };

  // To set value for newly listed sub unit under apartment
  const newListedSubUnit = (value) => {
    setGetAllUnitArr((val) => [...value, ...val]);
  };

  // Onchange handle for sorting table value
  const handleTableChange = (newPagination, filters, sorter) => {
    if (
      filters?.org_name?.length ||
      filters?.unitType?.length ||
      filters?.unit_name?.length
    ) {
      newPagination.pageNumber = 1;
      getAllProperty(newPagination, filters);
    } else {
      newPagination.pageNumber = newPagination.current;
      getAllProperty(newPagination);
      setPagination((p) => ({ ...p, ...newPagination, filters, sorter }));
    }
  };
  const [yScroll, setyScroll] = useState(550);

  // To set all apartment object value to setGetAllApartmentStateArr state
  useEffect(() => {
    if (getAllApartmentState && Object.keys(getAllApartmentState).length) {
      const arr = Object.values(getAllApartmentState);
      setGetAllApartmentStateArr(arr);
    } else {
      setGetAllApartmentStateArr([]);
    }
  }, [getAllApartmentState]);

  // To navigate upon selecting particular apartment or property
  useEffect(() => {
    if (selectedUnitFromTable && Object.values(selectedUnitFromTable).length) {
      if (selectedUnitFromTable?.children) {
        localStorage.setItem("prop_id", selectedUnitFromTable?.id);
        navigate(`/subunit/${selectedUnitFromTable?.id}`);
      } else {
        localStorage.setItem("prop_id", selectedUnitFromTable?.apartment_id);
        navigate({
          pathname: `/subunit/${selectedUnitFromTable?.apartment_id}`,
          search: createSearchParams({
            unitId: selectedUnitFromTable?.id,
          }).toString(),
        });
      }
    }
  }, [selectedUnitFromTable]);

  // Dispatch action for tenant modal value
  const newAddedVal = (value) => {
    getAllProperty(pagination);

    // const val = allPropUnit?.filter((item) =>{
    //   return item["id"] === value.apartment_id
    // })
  };

  // TO set unit name conditionally
  const unitApartmentName = (value) => {
    return value;

    // if (value && value?.includes("unit")) {
    //   return value;
    // } else {
    //   return value + " " + "unit";
    // }
  };

  const getAllProperty = useCallback(
    (pagination, filters = {}, org_id = params?.id) => {
      setFilerData([]);
      setIsLoading(true);
      setLoadings(true);
      dispatch(
        getAllPropId({
          org_id: org_id,
          lastEvaluatedKey: pagination?.lastEvaluatedKey?.length
            ? pagination?.lastEvaluatedKey[pagination.pageNumber - 1]
            : null,
          pageSize: pagination.pageSize,
          pageNumber: pagination.pageNumber,
          ...(filters?.unitType ? { type: filters?.unitType } : ""),
          ...(filters?.unit_name ? { unit_name: filters?.unit_name } : ""),
        })
      )
        .then(({ payload }) => {
          if (payload?.data || payload?.Data) {
            if (!tableFilterUnits?.length) {
              setTableFilterUnits(payload?.apartment_names);
            }
            setPagination((p) => ({
              ...p,
              ...(payload?.total ? { total: payload?.total } : ""),
              ...(pagination?.lastEvaluatedKey?.length
                ? ""
                : { lastEvaluatedKey: payload?.lastEvaluatedKey }),
            }));
            let value = payload?.Data;
            const launchOptimistic = value?.map((elem, idx) => ({
              ...elem,
              key: idx,
            }));
            setGetAllData(launchOptimistic);
            setTableLoading(false);
            setSwitchBtnLoading(false);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setLoadings(false);
          setIsLoading(false);
          setSortAllPropUnit([]);
          setFilerData([]);
          setApartments([]);
          setGetAllData([]);
          setTableLoading(false);
          setSwitchBtnLoading(false);
          if (error) {
            message.error(error.message);
          }
        });
    },

    [pagination]
  );

  useEffect(() => {
    if (params?.id) {
      navigate({
        pathname: `/property/${localStorage.getItem("toSave")}`,
      });
      let newPagination = { ...pagination };
      getAllProperty(newPagination, {}, params?.id);
    }
  }, [params.id]);

  const unitUpdateCall = () => {
    setTableLoading(true);
    getAllProperty(pagination);
  };

  useEffect(() => {
    if (getAllData?.length) {
      const arrOfSort = [];
      getAllData?.map((value) => {
        let sortForeach = value?.children?.map((val) => {
          return val.id;
        });
        sortForeach?.sort();

        sortForeach?.map((val) => {
          value.children.filter((obj) => {
            if (obj["id"] === val) {
              arrOfSort.push(obj);
            }
          });
        });
      });

      const newChild = arrOfSort?.map((ele, idx) => ({
        ...ele,
        unitType: unitApartmentName(ele.unitType),
      }));

      const launchOptimistic = getAllData?.map((elem, idx) => ({
        ...elem,
        key: idx + 1,
        orgName: getAllOrgArr?.filter(function (item) {
          return item["id"] === elem.org_id;
        }),
        children: newChild.filter(function (item) {
          return item["apartment_id"] === elem.id;
        }),
      }));
      setFilerData(launchOptimistic);
      setSortAllPropUnit(launchOptimistic);
    } else {
      setFilerData([]);
      setSortAllPropUnit([]);
    }
  }, [getAllData]);

  // useEffect(() => {
  //   if (sortAllPropUnit?.length) {
  //     const deactivatedArr = [];
  //     sortAllPropUnit?.filter((item) => {
  //       if (item.activeStatus === "deactivate") {
  //         deactivatedArr.push(item);
  //       } else {
  //         item.children?.filter((value) => {
  //           if (value.activeStatus === "deactivate") {
  //             deactivatedArr.push(item);
  //           }
  //         });
  //       }
  //     });
  //     setDeavtiveUnits(deactivatedArr);
  //   }
  // }, [sortAllPropUnit]);

  useEffect(() => {
    if (sortAllPropUnit?.length) {
      const deData = sortAllPropUnit?.map((item) => ({
        ...item,
        children: item.children?.filter((val) => {
          return val["activeStatus"] === "deactivate";
        }),
      }));

      if (deData?.length) {
        const deactiveMergedData = deData?.filter((item) => {
          return item.children?.length;
        });

        setDeavtiveUnits(deactiveMergedData);
      }
    }
  }, [sortAllPropUnit, loadings]);

  return (
    <>
      {displayShow && (
        <Container padding={padding} paddingSm={padding}>
          <Row justify="space-between">
            <Col>
              <Heading>{upperCase(heading)}</Heading>
            </Col>
            <Col style={{ display: "flex" }}>
              <Form.Item
                label="Table View &nbsp;"
                style={{ marginRight: "20px" }}
              >
                <Switch checked={showHeader} onChange={handleHeaderChange} />
              </Form.Item>
              <Form.Item style={{ marginRight: "20px" }}>
                <Checkbox onChange={onChange}>
                  Show only Deactivated Properties
                </Checkbox>
              </Form.Item>
              <AddNewApr
                bgColor={"#777777"}
                fw={"400"}
                displayView="none"
                onClick={() => setModalState(true)}
              >
                Add New Property
              </AddNewApr>
            </Col>
          </Row>
          {!showHeader ? (
            <Row>
              <Col xs={24} sm={24} md={24} lg={24}>
                <StyledApparCardWrapper wd={"100"} ml={"0"}>
                  <Row gutter={[30, 30]}>
                    {loadingApartment &&
                      [...Array(6).keys()].map((v) => (
                        <Col
                          key={v}
                          xs={24}
                          sm={12}
                          md={8}
                          lg={8}
                          xl={8}
                          xxl={8}
                        >
                          <StyledApparCard>
                            <Skeleton.Image
                              active={loadingIn}
                              style={{ width: "100%" }}
                            ></Skeleton.Image>
                            {/* <StyledVideoProgressWrapper></StyledVideoProgressWrapper> */}
                            <StyledApparContentWrapper mh={"0"}>
                              <Skeleton.Input
                                active={loadingIn}
                                style={{
                                  width: "100%",
                                  height: "20px",
                                  marginBottom: 20,
                                }}
                              ></Skeleton.Input>
                              <Skeleton.Input
                                active={loadingIn}
                                style={{
                                  width: "100%",
                                  height: 20,
                                  marginBottom: 20,
                                }}
                              ></Skeleton.Input>
                              <Skeleton.Input
                                active={loadingIn}
                                style={{
                                  width: "100%",
                                  height: 20,
                                  marginBottom: 20,
                                }}
                              ></Skeleton.Input>
                            </StyledApparContentWrapper>
                          </StyledApparCard>
                        </Col>
                      ))}
                  </Row>
                </StyledApparCardWrapper>
                <ApartmentSection
                  deactiveDataCard={deactiveDataCard}
                  apartmentDummyData={apartmentDummyData}
                  getAllData={getAllData}
                />
              </Col>
            </Row>
          ) : (
            <ApartmentTable>
              <Table
                columns={columns}
                dataSource={[...sortAllPropUnit]}
                onChange={handleTableChange}
                pagination={pagination}
                loading={isLoading}
                rowClassName={(record) =>
                  record.activeStatus === "deactivate" && "disabled-row"
                }
                scroll={{ x: 1500, y: yScroll }}
                onRow={(r) => ({
                  onClick: (event) => {
                    // navigate({
                    //   pathname: `/subunit/${r.id}`,
                    //   search: createSearchParams({
                    //     unitId: "hello",
                    //   }).toString(),
                    // });
                    event.isDefaultPrevented();
                    setSelectedUnitFromTable(r);
                  },
                })}
              />
            </ApartmentTable>
          )}
          <FloatButton>
            <SecondaryButton
              shape="circle"
              icon={<HiPlus className="plus_icon" />}
              onClick={() => setModalState(true)}
            />
          </FloatButton>
        </Container>
      )}
      {!allApartmentForModal[0]?.children ? (
        <UpdateUnitModal
          onCancel={(value) => setModalStateUpdate(value)}
          modalState={modalStateUpdate}
          isEdit={true}
          updateUnit={valueOfSelect}
          // getUpdatedUnit={getUpdatedUnit}
          getAllApartmentState={getAllApartmentStateArr}
          getUpdatedUnit={getUpdatedUnit}
          unitUpdateCall={unitUpdateCall}
        />
      ) : (
        <UpdateApartmentModal
          onCancel={(value) => setModalStateUpdate(value)}
          modalState={modalStateUpdate}
          isEdit={true}
          updateUnit={allApartmentForModal}
          getUpdatedApartment={getUpdatedApartment}
        />
      )}
      <AddApartmentModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
        newListedApartment={newListedApartment}
        newListedSubUnit={newListedSubUnit}
      />
      <InviteTenantsModal
        onCancel={(value) => setModalStateInvite(value)}
        modalState={modalStateInvite}
        newAddedVal={newAddedVal}
        inviteTenant={inviteTenant}
      />
    </>
  );
}

export default Listings;
