import React, { createRef, useState, forwardRef, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import Opensans from "../../assets/fonts/Product Sans Regular.ttf";
import OpensansBold from "../../assets/fonts/Product Sans Bold.ttf";
import OpensansMed from "../../assets/fonts/Product Sans Regular.ttf";
import { toUSACurrency, upperCase } from "../../utils/fn";

const NewInvoicePdf = (props, ref) => {
  const invRef = createRef();
  const { invoiceDetails, totalAmount, totalTax, payableAmount } = props;

  Font.register({
    family: "Open sans",
    format: "truetype",
    src: Opensans,
  });
  Font.register({
    family: "Open sans bold",
    format: "truetype",
    src: OpensansBold,
  });
  Font.register({
    family: "Open sans med",
    format: "truetype",
    src: OpensansMed,
  });

  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date)
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    // return `${day}-${month}-${year}`;
    return `${month} ${day}, ${year}`;
  }

  const dateConverter = (dateValue) => {
    var date = new Date(dateValue);

    return formatDate(date);
  };

  const feeType = (status) => {
    switch (status) {
      case "rate":
        return "Rent";
      case "lateFee":
        return "Late Fee";
      case "parkingFee":
        return "Parking Fee";
      case "avenewFees":
        return "Avenew Fee";
      case "otherFee":
        return "Other Fee";
      case "taxFee":
        return "Tax";
      default:
        return "";
    }
  };

  const ThirdrdPage = () => (
    <Document ref={ref}>
      <Page ref={invRef}>
        <Image src="/images/avenewImg.png" style={styles.image} />
        <Text
          style={[
            styles.label_title_invoice,
            styles.border_bottom,
            styles.padding_para,
          ]}
        >
          Invoice
          {/* Transfer Confirmation */}
        </Text>
        <View>
          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cell]}>Invoice ID</Text>
            <Text style={[styles.fontText, styles.cell]}>Property Name</Text>
          </View>
          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cell]}>
              #{invoiceDetails?.invoiceCode ? invoiceDetails?.invoiceCode : ""}
            </Text>
            <Text style={[styles.tableText, styles.cell]}>
              {invoiceDetails?.unit_name ? invoiceDetails?.unit_name : ""}
            </Text>
          </View>
          {/* 1st row start*/}
          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cell]}>Due Date</Text>
            <Text style={[styles.fontText, styles.cell]}>Bill Date</Text>
          </View>
          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cell]}>
              {invoiceDetails?.dueDate
                ? dateConverter(invoiceDetails?.dueDate)
                : ""}
            </Text>
            <Text style={[styles.tableText, styles.cell]}>
              {invoiceDetails?.invoiceCreatedDate
                ? dateConverter(invoiceDetails?.invoiceCreatedDate)
                : ""}
            </Text>
          </View>
        </View>

        {/* transfer online end */}
        {/* your Details start */}
        <Text
          style={[
            styles.label_title,
            styles.border_bottom,
            styles.padding_para,
          ]}
        >
          Invoice Overview
        </Text>
        <View>
          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            {invoiceDetails?.fees?.map((value, id) => (
              <Text key={id} style={[styles.fontText, styles.cell]}>
                {feeType(value.type)}
              </Text>
            ))}
          </View>
          <View
            style={[styles.row, styles.padding_para_table, styles.lineHeight20]}
          >
            {invoiceDetails?.fees?.map((value, id) => (
              <>
                {value.dollerOrPercentage ? (
                  <Text
                    key={id}
                    style={[
                      styles.tableText,
                      styles.cell,
                      styles.d_block,
                      styles.lineHeight20,
                    ]}
                  >
                    {value.dollerOrPercentage === "$" ? "$" : ""}
                    {value.amount}
                    {value.dollerOrPercentage === "%" ? "%" : ""}
                  </Text>
                ) : (
                  <Text
                    key={id}
                    style={[
                      styles.tableText,

                      styles.cell,
                      styles.d_block,
                      styles.lineHeight20,
                    ]}
                  >
                    {toUSACurrency(Number(value.amount))}
                  </Text>
                )}
              </>
            ))}
          </View>
        </View>
        {/* Your Detaills end */}
        {/* transafer overview start */}
        <Text
          style={[
            styles.label_title,
            styles.border_bottom,
            styles.padding_para,
          ]}
        >
          Total Payable Amount
        </Text>
        <View>
          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cell]}>Total Amount :</Text>
            <Text style={[styles.tableText, styles.cell]}>
              {toUSACurrency(Number(totalAmount))}
            </Text>
          </View>
        </View>
        <View>
          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cell]}>TAX :</Text>
            <Text style={[styles.tableText, styles.cell]}>
              {toUSACurrency(Number(totalTax))}
            </Text>
          </View>
        </View>
        <View>
          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cell, styles.amountTotal]}>
              Total Payable Amount :
            </Text>
            <Text
              style={[
                styles.tableText,
                styles.cell,
                styles.tableTextAmountTotal,
              ]}
            >
              {toUSACurrency(
                Number(payableAmount ? payableAmount : totalAmount)
              )}
            </Text>
          </View>
        </View>

        <Text
          style={[
            styles.label_title,
            styles.border_bottom,
            styles.padding_para,
          ]}
        >
          Your Details
          {/* Transaction History */}
        </Text>
        <View>
          {/* 1st row start*/}
          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cell]}>Name</Text>
            <Text style={[styles.fontText, styles.cell]}>Address</Text>
          </View>
          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cell]}>
              {upperCase(invoiceDetails?.firstName)}{" "}
              {upperCase(invoiceDetails?.lastName)}
            </Text>
            <Text style={[styles.tableText, styles.cell]}>
              7750 Carondelet Ave, Clayton, MO 63105, United States 7750
              Carondelet Ave, Clayton, MO 63105, United States
            </Text>
          </View>
        </View>

        {/* transfer online end */}
        {/* <Image src={footer} style={[styles.margintopsecond]} /> */}
        {/* <Image src={ThirdrdPageImg} /> */}
      </Page>
    </Document>
  );

  const styles = StyleSheet.create({
    page: {},
    label_title: {
      paddingTop: 40,
      fontFamily: "Open sans bold",
      fontSize: 12,
      lineHeight: 2,
    },
    label_title_invoice: {
      paddingTop: 5,
      fontFamily: "Open sans bold",
      fontSize: 12,
      lineHeight: 2,
      justifyContent: "end",
      display: "flex",
      right: 0,
    },
    margin_top: {
      marginTop: 10,
    },
    padding_para: {
      marginLeft: 30,
      marginRight: 30,
    },
    padding_para_table: {
      marginLeft: 40,
      marginRight: 40,
    },
    border_bottom: {
      paddingBottom: 10,
      borderBottom: 1,
      borderColor: "grey",
    },
    marginTop20: {
      marginTop: 20,
    },
    label_content: {
      marginLeft: 70,
      marginRight: 70,
      paddingTop: 35,
      fontFamily: "Open sans",
    },
    d_block: {
      display: "block",
    },

    breakable: { width: "100%", height: 10 },
    unbreakable: { width: "100%", height: 100 },
    table: {
      fontSize: 10,
      width: 550,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignContent: "stretch",
      flexWrap: "nowrap",
      alignItems: "stretch",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignContent: "stretch",
      flexWrap: "nowrap",
      alignItems: "stretch",
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 20,
    },
    cell: {
      width: 50,
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto",
      alignSelf: "stretch",
    },

    headerText: {
      fontSize: 12,
      fontWeight: 1200,
      color: "#1a245c",
      margin: 2,
    },
    tableText: {
      // margin: 5,
      fontSize: 12,
      color: "#000000",
      fontFamily: "Open sans med",
    },
    tableTextAmountTotal: {
      // margin: 5,
      fontSize: 16,
      color: "#000000",
      fontFamily: "Open sans med",
    },
    fontTextBottom: {
      fontFamily: "Open sans med",
      fontSize: 7,
      textAlign: "center",
      color: "#5C737F",
      paddingTop: 30,
      marginLeft: 15,
      marginRight: 15,
    },
    fontText: {
      color: "#5C737F",
      fontFamily: "Open sans med",
      fontSize: 10,
    },
    amountTotal: {
      color: "#5C737F",
      fontFamily: "Open sans med",
      fontSize: 16,
    },
    margintopfirst: {
      marginTop: 40,
    },
    margintopsecond: {
      marginTop: 300,
    },
    // image: {
    //   objectFit: "cover",
    //   height: "92px",
    // },
    image: {
      marginVertical: 20,
      marginHorizontal: 20,
      width: 100,
    },
    lineHeight30: {
      paddingTop: "30px",
    },
    lineHeight10: {
      paddingTop: "10px",
    },

    // // lineHeight20: {
    //   paddingTop: "20px",
    // // },
    //lineHeight20:{
    //  lineHeight:40,
    // },
    // lineHeight30:{
    //   lineHeight:30,
    // }
  });

  return <ThirdrdPage />;
};

export default forwardRef(NewInvoicePdf);
