import { Button, Dropdown, Menu, Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { MdMoreHoriz } from "react-icons/md";
import styled, { useTheme } from "styled-components";
import { BsDot } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import { Capitalize } from "../../utils/fn";

const Actions = ({
  triggerComponent,
  Icon = MdMoreHoriz,
  item,
  options = [],
  onChange,
  trigger = "hover",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const theme = useTheme();
  const handleChange = (val) => {
    // setSelectedKeys([val.key]);
    setSelectedKeys();
    onChange(val);
    setIsOpen(false);
  };
  return (
    <Dropdown
      overlay={
        <OptionMenuList
          options={options}
          item={item}
          selectedKeys={selectedKeys}
          onClick={handleChange}
        />
      }
      visible={isOpen}
      onVisibleChange={setIsOpen}
      // trigger="click"
      trigger={trigger}
      onClick={(event) => {
        event.stopPropagation();
        event.isPropagationStopped();
      }}
    >
      {triggerComponent || (
        <ActionButton
          type="text"
          shape="circle"
          icon={
            isOpen ? (
              <LinearGradient
                onClick={(event) => {
                  event.stopPropagation();
                }}
                colors={[
                  { hex: theme.colors.primary, offset: "100%" },
                  { hex: theme.colors.primary2, offset: "100%" },
                ]}
                Icon={Icon}
              />
            ) : (
              <Icon color={"#D3D6DD"} />
            )
          }
        />
      )}
    </Dropdown>
  );
};

const unitStatus = (status) => {
  switch (status) {
    case "deactivate":
      return "Activate";
    case "active":
      return "deactivate";
    default:
      return "deactivate";
  }
};

const OptionMenuList = ({
  options = [],
  item = null,
  selectedKeys = [],
  onClick,
}) => {
  return (
    <ActionMenu
      onClick={(event) => {
        event.domEvent.stopPropagation();
      }}
      selectedKeys={selectedKeys}
      className="ant__popover"
      triggerSubMenuAction="hover"
    >
      {options.map(({ label, value, isDanger, isActiveStatus = false }) =>
        isDanger ? (
          <Popconfirm
            className="ant__popover"
            onClick={(event) => {
              event.domEvent.stopPropagation();
            }}
            key={value}
            title="Are you sure?"
            onConfirm={(event) => {
              event.stopPropagation();
              onClick({ key: value, item });
            }}
            onCancel={(event) => {
              event.stopPropagation();
            }}
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <ActionMenuItem key={value}>{label}</ActionMenuItem>
          </Popconfirm>
        ) : isActiveStatus ? (
          <ActionMenuItem
            key={value}
            disabled={item?.unitStatus === "Configure"}
            onClick={(event) => {
              onClick({ key: value, item });
              event.domEvent.stopPropagation();
            }}
            className="status__dot"
            // style={{ display: "flex", alignItems: "center" }}
          >
            {Capitalize(unitStatus(item?.activeStatus))}
          </ActionMenuItem>
        ) : (
          <ActionMenuItem
            key={value}
            onClick={(event) => {
              onClick({ key: value, item });
              event.domEvent.stopPropagation();
            }}
          >
            {label}
          </ActionMenuItem>
        )
      )}
    </ActionMenu>
  );
};

const ActionButton = styled(Button)`
  &:active,
  &:hover {
    background: transparent;
  }
  &.ant-btn-icon-only > * {
    font-size: 26px;
  }
`;

const ActionMenu = styled(Menu)`
  background: #fff;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 35%);
  min-width: 180px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  .ant__popover {
    z-index: 1060 !important;
  }

  .ant-popconfirm {
    z-index: 1060 !important;
  }
`;

const ActionMenuItem = styled(Menu.Item)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  background-color: #ffffff;

  .status__dot svg {
    color: green;
  }

  &.ant-menu-item-active {
    color: #666666;
  }

  .ant-popconfirm {
    z-index: 1060 !important;
  }

  &.ant-menu-item-selected {
    color: ${({ theme }) => theme.colors.primary};
    background-color: #ffffff !important;
  }
  &.ant-menu-item {
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }
`;

export const LinearGradient = ({ colors = [], Icon, iconProps }) => (
  <>
    <svg width="0" height="0">
      <linearGradient id="primary-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
        {colors.map((color, idx) => (
          <stop key={idx} stopColor={color.hex} offset={color.offset} />
        ))}
      </linearGradient>
    </svg>
    <Icon
      {...iconProps}
      style={{
        stroke: "url(#primary-gradient)",
        fill: "url(#primary-gradient)",
      }}
    />
  </>
);

export default Actions;
