import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Skeleton,
  Divider,
  Dropdown,
  Button,
  Menu,
  Space,
  message,
  Spin,
} from "antd";
import {
  ActionBtn,
  ShareDropdown,
  ShareDropdownMenuItem,
  StyledApartCardWrapper,
  StyledApparCard,
} from "./Listingstyle";
import { Link, useLocation, useParams } from "react-router-dom";
import { MdMoreVert, MdShare } from "react-icons/md";
import { FaGreaterThan } from "react-icons/fa";
import { StyledHeaderDropdownContentWrappeer } from "../layout/header/TheHeader.styles";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { AvenewImg, AvenewImgSvg } from "../../assets/svgs";
import {
  deleteApartment,
  deleteUnit,
  getAllApartment,
  getAllApartmentsPost,
  getAllPropId,
  getApartmentCard,
} from "../../store/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../ui/Actions/Actions";
import UpdateApartmentModal from "../addApartmentModal/UpdateApartmentModal";
import ShareModal from "../share-modal/ShareModal";
import { Photos } from "../../pages/subUnit/SubUnit";
import { Container } from "../../ui/Layout/Container";
import InfiniteScroll from "react-infinite-scroll-component";
import { httpAPI } from "../../api";
import { toUSACurrency, upperCase } from "../../utils/fn";

function ApartmentSection({
  getAllData = {},
  deactiveDataCard,
  deactiveDataCardAllProp,
}) {
  const routeApart = "/subunit/:id";
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const params = useParams();
  const [allApartmentsWithUnits, setAllApartmentsWithUnits] = useState([]);
  const [allApartmentForModal, setAllApartmentForModal] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState({});
  const [modalStateUpdate, setModalStateUpdate] = useState(false);
  const [shareModalState, setshareModalState] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageNumber: 1,
    total: 0,
    size: "small",
    lastEvaluatedKey: [],
  });

  const { user_id } = useSelector((state) => state.user);
  const { width } = useWindowSize();
  const location = useLocation();
  const [zoom, setZoom] = useState("100%");

  useEffect(() => {
    // if (params?.id) {
    //   dispatch(
    //     getAllApartmentsPost({
    //       user_id: user_id ? user_id : localStorage.getItem("user_id"),
    //       org_id: params?.id,
    //       pageSize: pagination.pageSize,
    //       pageNumber: 1,
    //       lastEvaluatedKey: null,
    //     })
    //   ).then(({ payload }) => {
    //     if (payload?.Data) {
    //       setAllApartmentsWithUnits(payload?.Data);
    //       setPagination((p) => ({
    //         ...p,
    //         ...(payload?.total ? { total: payload?.total } : ""),
    //         ...(pagination?.lastEvaluatedKey?.length
    //           ? ""
    //           : { lastEvaluatedKey: payload?.lastEvaluatedKey }),
    //       }));
    //     }
    //   });
    // } else {
    dispatch(
      getAllApartmentsPost({
        ...(params?.id ? { org_id: params?.id } : ""),
        user_id: user_id ? user_id : localStorage.getItem("user_id"),
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageNumber,
        lastEvaluatedKey: pagination?.lastEvaluatedKey?.length
          ? pagination?.lastEvaluatedKey[pagination.pageNumber - 1]
          : null,
      })
    ).then(({ payload }) => {
      if (payload?.Data) {
        setAllApartmentsWithUnits(payload?.Data);
        setPagination((p) => ({
          ...p,
          ...(payload?.total ? { total: payload?.total } : ""),
          ...(pagination?.lastEvaluatedKey?.length
            ? ""
            : { lastEvaluatedKey: payload?.lastEvaluatedKey }),
        }));
      }
    });
    // }
  }, [params?.id]);

  const fetchMoreData = (e) => {
    setPageNumber(pageNumber + 1);
    setPagination((p) => ({ ...p, pageNumber: p.pageNumber + 1 }));
    dispatch(
      getAllApartmentsPost({
        ...pagination,
        ...(params?.id ? { org_id: params?.id } : ""),
        user_id: user_id ? user_id : localStorage.getItem("user_id"),
        lastEvaluatedKey: pagination?.lastEvaluatedKey?.length
          ? pagination?.lastEvaluatedKey[pageNumber]
          : null,
        ...{ pageNumber: pagination.pageNumber + 1 },
      })
    ).then(({ payload }) => {
      if (payload?.Data) {
        setAllApartmentsWithUnits((val) => [...val, ...payload?.Data]);
      }
    });
  };

  // To get deactivated props
  useEffect(() => {
    if (deactiveDataCard?.length && localStorage.getItem("toSave")) {
      setAllApartmentsWithUnits(deactiveDataCard);
    } else if (
      deactiveDataCardAllProp?.length &&
      localStorage.getItem("viewAll")
    ) {
      setAllApartmentsWithUnits(deactiveDataCardAllProp);
    } else {
      setAllApartmentsWithUnits([]);
    }
  }, [
    deactiveDataCard,
    deactiveDataCardAllProp,
    localStorage.getItem("viewAll"),
    localStorage.getItem("toSave"),
  ]);

  const getPrice = (valueObj) => {
    const value = [valueObj];
    var a = [];

    value?.map(function ({ children }) {
      if (children?.length) {
        a.push(...children);
      }
    });

    var b = [];
    a?.forEach(function (obj) {
      b.push(
        obj?.price ? (obj?.price === undefined ? null : Number(obj?.price)) : []
      );
    });

    function removeNull(array) {
      return array.filter((x) => x !== null);
    }

    const max = Math.max(...removeNull(b));
    const min = Math.min(...removeNull(b));

    if (min === Infinity || max === Infinity) {
      return (
        <p>
          {toUSACurrency(0.0)} - {toUSACurrency(0.0)}
        </p>
      );
    } else {
      return (
        <p>
          {toUSACurrency(min)} - {toUSACurrency(max)}
        </p>
      );
    }
  };

  // To fetch the data for bed value
  const getBed = (valueObj) => {
    const value = [valueObj];
    var a = [];

    value?.map(function ({ children }) {
      if (children?.length) {
        a.push(...children);
      }
    });

    var b = [];
    a?.map((value) => b.push(value.bed));
    function removeNull(array) {
      return array.filter((x) => x !== undefined);
    }

    const max = Math.max(...removeNull(b));
    const min = Math.min(...removeNull(b));

    if (min === Infinity || max === Infinity) {
      return <span>0 Bedroom</span>;
    } else {
      return (
        <span>
          {min} Bedroom - {max} Bedroom
        </span>
      );
    }
  };

  // useEffect(() => {
  //   apartmentsWithUnits.map((value) => {
  //     getBed(value);
  //   });
  // }, []);

  // Function to share functionality
  const shareHandler = (e) => {
    e.preventDefault();
    // setshareModalState(true);
  };

  const menu = (
    <ShareDropdown>
      <ShareDropdownMenuItem>
        <Link to="/listings">Share</Link>
      </ShareDropdownMenuItem>
    </ShareDropdown>
  );

  // To set zoom w.r.t screen width
  useEffect(() => {
    if (width < 1550 && width > 991) {
      setZoom("80%");
    } else {
      setZoom("100%");
    }
  }, [width, location.pathname]);

  // To set Modal state to true
  const editValue = (value) => {
    setAllApartmentForModal([value]);
    setModalStateUpdate(true);
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    // {
    //   label: "Deactive",
    //   value: "deactive",
    //   isDanger: false,
    //   isActiveStatus: true,
    // },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        editValue(item);
        break;
      case "delete":
        onDelete(item);
        break;
      // case "deactive":
      //   activeStatusBtn(item);
      //   break;

      default:
        break;
    }
  };

  const getUpdatedApartment = (value) => {
    if (value && Object.keys(value)?.length) {
      if (allApartmentsWithUnits && allApartmentsWithUnits?.length) {
        const objIndex = allApartmentsWithUnits?.findIndex(
          (obj) => obj.id === value.id
        );
        allApartmentsWithUnits[objIndex] = value;
        setAllApartmentsWithUnits((val) => [...val]);
        // setAllApartmentsWithUnits[...allApartmentsWithUnits]
      }
    }
  };

  // To remove the property from the listing
  const onDelete = (value) => {
    let arr = [];
    const unit = [...allApartmentsWithUnits];
    unit.map((val) => {
      if (val.id === value.id) {
      } else {
        arr.push(val);
      }
      setAllApartmentsWithUnits([...arr]);
    });
    message.success("Property deleted successfully");
    dispatch(deleteApartment({ id: value.id })).then((res) => {
      // let arr = [];
      // const unit = [...allApartmentsWithUnits];
      // unit.map((val) => {
      //   if (val.id === value.id) {
      //   } else {
      //     arr.push(val);
      //   }
      //   setAllApartmentsWithUnits([...arr]);
      // });
    });
  };

  return (
    <>
      <StyledApartCardWrapper>
        <InfiniteScroll
          dataLength={allApartmentsWithUnits?.length}
          next={fetchMoreData}
          hasMore={
            pagination.total > pagination.pageNumber * pagination.pageSize
          }
          loader={
            <Container padding="0" minH="auto">
              <div
                style={{
                  position: "relative",
                  minHeight: "calc(100vh - 800px)",
                }}
              >
                <Spin className="ant__spin" size="large" />
              </div>
            </Container>
          }
          scrollableTarget="scrollableDiv"
          endMessage={
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                color: "#747487",
                marginTop: "20px",
              }}
            >
              <span>
                {allApartmentsWithUnits?.length > 0
                  ? "Yay! You have all caught up!"
                  : "There is no Unit."}
              </span>
            </p>
          }
        >
          <Row gutter={[15, 15]}>
            {allApartmentsWithUnits?.map((items, _id) => {
              let coverImg = items.images?.filter(
                (item, key) => item.isCover == true
              );
              return (
                <Col
                  key={_id}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                  onClick={() => localStorage.setItem("prop_id", items?.id)}
                >
                  <Link to={routeApart.replace(":id", items.id)}>
                    <StyledApparCard hoverable>
                      {coverImg?.length ? (
                        <div style={{ position: "relative" }}>
                          <img
                            style={{ width: "100%" }}
                            alt="example"
                            src={coverImg[0]?.path}
                          />
                          <Photos>
                            <p>{items.images?.length} Photos</p>
                          </Photos>
                        </div>
                      ) : (
                        <AvenewImg
                          style={{
                            background: "#eeeeee",
                            height: "225px",
                            width: "100%",
                          }}
                        />
                      )}

                      <Col className="card__title">
                        <p>{upperCase(items.unit_name)}</p>

                        <Dropdown
                          overlay={menu}
                          trigger={["click"]}
                          placement="bottom"
                          className=""
                          arrow
                        >
                          <MdShare
                            onClick={(e) => {
                              shareHandler(e);
                              // setSelectedUnit(items);
                            }}
                          />
                        </Dropdown>
                      </Col>
                      <Col className="card__BHK">
                        <span>1 BHK - 2 BHK - 3 BHK</span>
                        <Divider type="vertical" />
                        {getBed(items)}
                      </Col>
                      <Col className="card__price">
                        <p>{getPrice(items)}</p>
                        <ActionBtn>
                          <Actions
                            trigger="click"
                            options={actionOptions}
                            item={items}
                            onChange={actionHandler}
                            Icon={MdMoreVert}
                          />
                        </ActionBtn>
                      </Col>
                    </StyledApparCard>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </InfiniteScroll>
      </StyledApartCardWrapper>

      <UpdateApartmentModal
        onCancel={(value) => setModalStateUpdate(value)}
        modalState={modalStateUpdate}
        isEdit={true}
        updateUnit={allApartmentForModal}
        getUpdatedUnitApartment={getUpdatedApartment}
      />

      <ShareModal
        onCancel={(value) => setshareModalState(value)}
        modalState={shareModalState}
        selectedUnit={selectedUnit}
        isApartment={true}
      />
    </>
  );
}

export default ApartmentSection;
