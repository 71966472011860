import { Col, Divider, Modal, Popover, Row, Skeleton, message } from "antd";
import React, { useState } from "react";
import { FaBath } from "react-icons/fa";
import { IoIosBed, IoIosClose, IoMdCall } from "react-icons/io";
import { MdLocationOn, MdShare } from "react-icons/md";
import StarRatings from "react-star-ratings";
import styled from "styled-components";
import { AvenewImg, CopySvg, SqFtSvg } from "../../assets/svgs";
import { ListWrap } from "../apartment-listing/ListApartmentStyle";
import { toUSACurrency } from "../../utils/fn";
import ShareModal, { SharedBtn } from "../share-modal/ShareModal";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { StyledTitle } from "../createPersonalProfile/RegistrationStyle";
import { useSelector } from "react-redux";

const UnitDetailModal = ({
  modalState,
  onCancel,
  selectedApplicationsWithUnitArr,
}) => {
  const [modalWidth, setModalWidth] = useState(1200);
  const [shareModalState, setshareModalState] = useState(false);
  const { user } = useSelector((state) => state.user);

  const title = `I am ${user?.firstName}`;
  const copyLinkUrl = `http://dev.avenew.app/property-details/${selectedApplicationsWithUnitArr[0]?.unitId}`;

  const copyLink = () => {
    navigator.clipboard.writeText(copyLinkUrl);
    message.success("Link Copied");
  };

  const returnTag = (val) => {
    return val[0].map((item) => (
      <Col>
        <ListWrap>
          <p>{item}</p>
        </ListWrap>
      </Col>
    ));
  };

  return (
    <Modal
      width={modalWidth}
      closable={true}
      destroyOnClose={true}
      visible={modalState}
      onCancel={() => {
        onCancel(false);
      }}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(20px)",
      }}
      footer={null}
      closeIcon={<IoIosClose className="modal_close_icon" />}
    >
      <UnitTitle>
        {selectedApplicationsWithUnitArr[0]?.unitDetails?.unit_name}
      </UnitTitle>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14}>
          <LeftSide>
            <CoverImgWrap>
              {selectedApplicationsWithUnitArr[0]?.unitDetails?.images &&
              selectedApplicationsWithUnitArr[0]?.unitDetails?.images
                ?.length ? (
                <>
                  {selectedApplicationsWithUnitArr[0]?.unitDetails?.images.map(
                    (img, idx) => (
                      <div key={idx}>
                        {img.isCover === true ? (
                          <img alt="Loading.." src={img.path} />
                        ) : (
                          ""
                        )}
                      </div>
                    )
                  )}
                </>
              ) : (
                <AvenewImg
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "#eeeeee",
                  }}
                />
              )}
            </CoverImgWrap>
          </LeftSide>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10}>
          <RightSideDetailsWrap>
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col>
                <StarRatings
                  rating={3.32}
                  starDimension="20px"
                  starSpacing="5px"
                  starRatedColor="#F67C36"
                />
              </Col>
              <Col>
                <Popover
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  placement="bottomLeft"
                  content={
                    <>
                      <StyledTitle>Share this place</StyledTitle>
                      <Row style={{ width: "700px" }} gutter={[20, 20]}>
                        <Col span={12}>
                          <SharedBtn onClick={() => copyLink()}>
                            <CopySvg className="copy__svg" />
                            <h4>Copy Link</h4>
                          </SharedBtn>
                        </Col>
                        <Col span={12}>
                          <EmailShareButton
                            style={{ width: "100%" }}
                            title={title}
                            url={copyLinkUrl}
                            size={32}
                            round={true}
                          >
                            <SharedBtn>
                              <EmailIcon size={40} round={true} />
                              <h4>Email</h4>
                            </SharedBtn>
                          </EmailShareButton>
                        </Col>
                        <Col span={12}>
                          <WhatsappShareButton
                            style={{ width: "100%" }}
                            title={title}
                            url={copyLinkUrl}
                            size={32}
                            round={true}
                          >
                            <SharedBtn>
                              <WhatsappIcon size={40} round={true} />
                              <h4>WhatsApp</h4>
                            </SharedBtn>
                          </WhatsappShareButton>
                        </Col>
                        <Col span={12}>
                          <FacebookShareButton
                            style={{ width: "100%" }}
                            title={title}
                            url={copyLinkUrl}
                            size={32}
                            round={true}
                          >
                            <SharedBtn>
                              <FacebookIcon size={40} round={true} />
                              <h4>Facebook</h4>
                            </SharedBtn>
                          </FacebookShareButton>
                        </Col>
                        <Col span={12}>
                          <TwitterShareButton
                            style={{ width: "100%" }}
                            title={title}
                            url={copyLinkUrl}
                            size={32}
                            round={true}
                          >
                            <SharedBtn>
                              <TwitterIcon size={40} round={true} />
                              <h4>Twitter</h4>
                            </SharedBtn>
                          </TwitterShareButton>
                        </Col>
                        <Col span={12}>
                          <LinkedinShareButton
                            style={{ width: "100%" }}
                            title={title}
                            url={copyLinkUrl}
                            size={32}
                            round={true}
                          >
                            <SharedBtn>
                              <LinkedinIcon size={40} round={true} />
                              <h4>Linkedin</h4>
                            </SharedBtn>
                          </LinkedinShareButton>
                        </Col>
                      </Row>
                    </>
                  }
                >
                  <MdShare
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                    }}
                  />
                </Popover>
              </Col>
            </Row>
            <div className="apartment__price">
              {selectedApplicationsWithUnitArr[0]?.unitDetails?.price
                ? toUSACurrency(
                    Number(
                      selectedApplicationsWithUnitArr[0]?.unitDetails.price
                    )
                  )
                : toUSACurrency(Number(0))}
            </div>
            {/* <div className="apartment__location">
              <MdLocationOn />
              <p className="apartment__address">{""}</p>
            </div>
            <div className="apartment__location property__phone ">
              <IoMdCall />
              <p className="apartment__phone">+1{""}</p>
            </div> */}
            <Divider />
            <Row>
              <Col span={8} className="apartment__sqft">
                <IoIosBed />
                <p className="apartment__bed">
                  Bed : {selectedApplicationsWithUnitArr[0]?.unitDetails?.bed}
                </p>
              </Col>
              <Col span={8} className="apartment__sqft">
                <FaBath />
                <p className="apartment__bed">
                  Bath : {selectedApplicationsWithUnitArr[0]?.unitDetails?.bath}
                </p>
              </Col>
              <Col span={8} className="apartment__sqft">
                <SqFtSvg />
                <p>
                  Sq ft :{" "}
                  {selectedApplicationsWithUnitArr[0]?.unitDetails?.sqft}
                </p>
              </Col>
            </Row>
            <h4>Description</h4>
            <h5>
              {selectedApplicationsWithUnitArr[0]?.unitDetails?.description}
            </h5>
            <h4>Amenities</h4>
            <Row gutter={[10, 10]}>
              {selectedApplicationsWithUnitArr[0]?.unitDetails?.ammenities?.map(
                (val) => (
                  <>
                    {Object.values(val)[0]?.length ? (
                      <>
                        <h6>{Object.keys(val)}</h6>
                        <Row gutter={[10, 10]}>
                          {returnTag(Object.values(val))}
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )
              )}
            </Row>
          </RightSideDetailsWrap>
        </Col>
      </Row>
    </Modal>
  );
};

export default UnitDetailModal;

const UnitTitle = styled.h3`
  font-weight: 700;
  font-size: 35px;
  line-height: 49px;
  color: ${({ theme }) => theme.colors.forth};
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 1550px) {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 16px;
  }
  @media (max-width: 400px) {
    font-size: 25px;
  }
`;

const LeftSide = styled.div`
  width: 100%;
  ${"" /* height: 344px; */}
  flex-shrink: 0;
`;

const CoverImgWrap = styled.div`
  ${"" /* width: 682px; */}
  img {
    width: 100%;
    max-height: 344px;
    border-radius: 5px;
  }
  .ant-skeleton-element .ant-skeleton-image {
    height: 344px;
  }
  .ant-skeleton-element {
    width: 100%;
  }
  @media (max-width: 730px) {
    img {
      width: 100%;
    }
  }
`;

const OtherImgWrap = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  img {
    width: 163px;
    height: 163px;
    border-radius: 5px;
  }
  .ant-skeleton-element .ant-skeleton-image {
    height: 163px;
    width: 163px;
  }
  .skeleton-wrap {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 750px) {
  }
`;

const RightSideDetailsWrap = styled.div`
  margin-left: 40px;
  @media (max-width: 1199px) {
    margin-left: 0;
  }

  h6 {
    color: #4160ac;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 15px;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    margin-top: 35px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.forth};
  }
  h3 {
    font-size: 24px;
    font-family: Product Sans;
    font-weight: 700;
    line-height: 30px;
    color: #4160ac;
  }
  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #777777;
  }
  .ant-divider-horizontal {
    background: #dddddd;
  }

  .star-container {
    padding: 5px !important;
  }

  .widget-svg {
    width: 20px !important;
    height: 20px !important;
  }

  .top__id {
    display: none;
  }

  .apartment__name {
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    color: ${({ theme }) => theme.colors.forth};
    margin-bottom: 0px;
  }
  .apartment__location {
    margin-top: 20px;
  }
  .property__phone {
    margin-bottom: 20px;
  }

  .apartment__phone,
  .apartment__bed,
  .apartment__address {
    margin: 0;
    font-size: 18px;
    margin-right: 20px;
  }

  .location__row {
    margin-top: 15px;
  }

  .apartment__price {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #323548;
    color: ${({ theme }) => theme.colors.secondary};
    margin-bottom: 25px;
  }

  .apartment__location {
    display: flex;
    align-items: center;
    svg {
      font-size: 18px;
      color: #777777;
      margin-right: 10px;
    }
  }
  .apartment__sqft {
    display: flex;
    align-items: center;
    svg {
      font-size: 18px;
      color: #777777;
      margin-right: 10px;
    }
  }

  .ant-row {
    align-items: center;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }

  .card__BHK {
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }

  .ant-divider-vertical {
    border-left: 1px solid #777777;
    height: 1rem;
  }

  .card__bedroom {
    margin-right: 36px;
  }

  .apartment__type {
    padding-left: 20px;
  }

  span {
    color: #777777;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
  }

  @media (max-width: 1550px) {
    padding: 15px;

    .star-container {
      padding: 2px !important;
    }

    .widget-svg {
      width: 16px !important;
      height: 16px !important;
    }
    .apartment__name {
      font-size: 20px;
      line-height: 36px;
    }
    .apartment__phone,
    .apartment__bed,
    .apartment__address {
      margin: 0;
      font-size: 14px;
      margin-right: 16px;
    }
    .location__row {
      margin-top: 12px;
    }
    .apartment__location {
      svg {
        ${
          "" /* font-size: 14px;
        margin-right: 8px; */
        }
      }
    }
    p {
      font-size: 14px;
      line-height: 19px;
    }
    .card__BHK {
      font-size: 14px;
    }
    .card__bedroom {
      margin-right: 28px;
    }
    .apartment__type {
      padding-left: 16px;
    }
    .apartment__price {
      font-size: 14px;
      line-height: 23px;
    }
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;
