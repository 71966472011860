import React, { useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import { IoIosClose } from "react-icons/io";
import { Modal } from "antd";
import ListApartment from "../apartment-listing/ListApartment";
import ListUnit from "../unit-listing/ListUnit";
import RegisterFormCompanyDetails from "../createPersonalProfile/RegisterFormCompanyDetails";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useLocation } from "react-router";

function AddOrgModal({ modalState, onCancel, addNewhendler }) {
  const [isFormChange, setIsFormChange] = useState(false);
  const [isApartValue, setIsApartValue] = useState(false);

  const { width } = useWindowSize();
  const location = useLocation();

  const [modalWidth, setZoom] = useState(1000);

  // To set modal width according to width of the window screen
  useEffect(() => {
    if (width < 1550) {
      setZoom(800);
    } else {
      setZoom(1000);
    }
  }, [width, location.pathname]);

  function fromChanges(val) {
    setIsFormChange(val);
  }

  // To pop the alert when someone wants to exit or navigate without saving or submitting details
  const openPrompt = (message) => {
    var answer = window.confirm(message);
    if (answer === true) {
      onCancel(false);
      setIsFormChange(false);
    } else {
    }
  };

   // To set apartment values
  const apartValue = (val) => {
    setIsApartValue(val);
  };

  // To submit cancel
  const onSubmit = () => {
    onCancel(false);
  };

  // preventDefault event is isFormChange is true
  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });

  return (
    <>
      <Modal
        getContainer={() => document.querySelector("#modal-container")}
        visible={modalState}
        onCancel={() => {
          if (!isFormChange) {
            onCancel(false);
          } else {
            openPrompt(
              "You will lose all changes if you navigate away from this page without saving"
            );
          }
        }}
        width={modalWidth}
        footer={null}
        style={{ borderRadius: "5px", top: "50px" }}
        className="modal_wrapper"
        closeIcon={<IoIosClose className="modal_close_icon" />}
        maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.8)" }}
        focusTriggerAfterClose={false}
        destroyOnClose={true}
      >
        <RegisterFormCompanyDetails
          isModal={true}
          onSubmit={onSubmit}
          reDirect="/no-listings"
          addNewhendler={addNewhendler}
        />
      </Modal>
    </>
  );
}

export default AddOrgModal;
