import { Button } from "antd";
import styled from "styled-components";

export const ApartmentsViewWrap = styled.div`
  border-radius: 5px;
  background-color: #ffffff;
  padding: 20px;

  .star-container {
    padding: 5px !important;
  }

  .widget-svg {
    width: 20px !important;
    height: 20px !important;
  }

  .top__id {
    display: none;
  }

  .apartment__name {
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    color: ${({ theme }) => theme.colors.forth};
    margin-bottom: 0px;
  }

  .apartment__phone,
  .apartment__bed,
  .apartment__address {
    margin: 0;
    font-size: 18px;
    margin-right: 20px;
  }

  .location__row {
    margin-top: 15px;
  }

  .apartment__location {
    display: flex;
    align-items: center;
    svg {
      font-size: 18px;
      color: #777777;
      margin-right: 10px;
    }
  }

  .ant-row {
    align-items: center;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16\px;
    line-height: 19px;
    color: #777777;
  }

  .card__BHK {
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }

  .ant-divider-vertical {
    border-left: 1px solid #777777;
    height: 1rem;
  }

  .card__bedroom {
    margin-right: 36px;
  }

  .apartment__type {
    padding-left: 20px;
  }

  .apartment__price {
    margin-left: 20px;
    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 29px;
      color: #323548;
      color: ${({ theme }) => theme.colors.secondary};
      margin-bottom: 0px;
    }
  }

  span {
    color: #777777;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
  }

  @media (max-width: 1550px) {
    padding: 15px;

    .star-container {
      padding: 2px !important;
    }

    .widget-svg {
      width: 16px !important;
      height: 16px !important;
    }
    .apartment__name {
      font-size: 20px;
      line-height: 36px;
    }
    .apartment__phone,
    .apartment__bed,
    .apartment__address {
      margin: 0;
      font-size: 14px;
      margin-right: 16px;
    }
    .location__row {
      margin-top: 12px;
    }
    .apartment__location {
      svg {
        font-size: 14px;
        margin-right: 8px;
      }
    }
    p {
      font-size: 14px;
      line-height: 19px;
    }
    .card__BHK {
      font-size: 14px;
    }
    .card__bedroom {
      margin-right: 28px;
    }
    .apartment__type {
      padding-left: 16px;
    }
    .apartment__price {
      margin-left: 16px;
      h3 {
        font-size: 14px;
        line-height: 23px;
      }
    }
    span {
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (max-width: 798px) {
    display: none;
  }
`;
export const ApartmentsViewWrapForSmall = styled.div`
  border-radius: 5px;
  background-color: #ffffff;
  padding: 20px;
  display: none;
  position: relative;

  .star-container {
    padding: 5px !important;
  }

  .widget-svg {
    width: 20px !important;
    height: 20px !important;
  }

  .top__id {
    display: none;
  }

  .apartment__name {
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    color: ${({ theme }) => theme.colors.forth};
    margin-bottom: 0px;
  }

  .apartment__phone,
  .apartment__bed,
  .apartment__address {
    margin: 0;
    font-size: 18px;
    margin-right: 20px;
  }

  .location__row {
    margin-top: 15px;
  }

  .apartment__location {
    display: flex;
    align-items: center;
    svg {
      font-size: 18px;
      color: #777777;
      margin-right: 10px;
    }
  }

  .ant-row {
    align-items: center;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16\px;
    line-height: 19px;
    color: #777777;
  }

  .card__BHK {
    font-size: 16px;
    line-height: 19px;
    color: #777777;
  }

  .ant-divider-vertical {
    border-left: 1px solid #777777;
    height: 1rem;
  }

  .card__bedroom {
    margin-right: 36px;
  }

  .apartment__type {
    padding-left: 20px;
  }

  .apartment__price {
    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 29px;
      color: #323548;
      color: ${({ theme }) => theme.colors.secondary};
      margin-bottom: 0px;
    }
  }

  span {
    color: #777777;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
  }

  @media (max-width: 1550px) {
    padding: 15px;

    .star-container {
      padding: 2px !important;
    }

    .widget-svg {
      width: 16px !important;
      height: 16px !important;
    }
    .apartment__name {
      font-size: 20px;
      line-height: 36px;
    }
    .apartment__phone,
    .apartment__bed,
    .apartment__address {
      margin: 0;
      font-size: 14px;
      margin-right: 16px;
    }
    .location__row {
      margin-top: 12px;
    }
    .apartment__location {
      margin-top: 15px;
      svg {
        font-size: 14px;
        margin-right: 8px;
      }
    }
    p {
      font-size: 14px;
      line-height: 19px;
    }
    .card__BHK {
      font-size: 14px;
    }
    .card__bedroom {
      margin-right: 28px;
    }
    .apartment__type {
      padding-left: 16px;
    }
    .apartment__price {
      h3 {
        font-size: 16px;
        line-height: 23px;
      }
    }
    span {
      font-size: 16px;
      line-height: 14px;
      svg {
        margin-bottom: -10px;
        font-size: 24px;
      }
    }
  }
  @media (max-width: 798px) {
    display: block;
  }
`;

export const UpArrowBox = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
`;

export const ApartmentId = styled.div`
  background: #ffeade;
  border-radius: 5px;
  padding: 5px;
  margin-left: 20px;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.third};
  }
  @media (max-width: 1550px) {
    padding: 2px 4px;
    margin-left: 16px;
    span {
      font-size: 12px;
      line-height: 15px;
    }
  }
  @media (max-width: 798px) {
    margin-left: 0;
  }
`;

export const ApartmentTable = styled.div`
  background: white !important;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  .ant-pagination.mini .ant-pagination-options-size-changer {
    margin-right: 20px;
  }

  .ant-select-selection-placeholder {
    color: #2b353f !important;
  }
  :hover {
    border-color: #aaaaaa !important;
  }

  .ant-select-selector {
    position: relative;
    background-color: #eee;
    border: none !important;
    border-radius: 5px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 40px !important;
    align-items: center;
    margin-right: 20px;
  }

  .ant-select-selector {
    border-color: #aaaaaa !important;
    background-color: #eeeeee !important;
    box-shadow: none !important;
  }

  @media (max-width: 1550px) {
    .ant-select-selection-placeholder {
      font-size: 13px;
    }

    .ant-select-selector {
      height: 40px !important;
    }
  }

  .hover__invite {
    color: #9da8b3;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #dee8ff;
  }

  .ant-table {
    ${"" /* height: 100vh; */}
  }
  .ant-table-tbody > tr.ant-table-placeholder {
    min-height: calc(100vh - 345px) !important;
  }

  .ant-table-body {
    min-height: calc(100vh - 345px) !important;

    /* scroll bar */
    ::-webkit-scrollbar,
    .ant-modal-wrap::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;

      @media (max-width: 767px) {
        width: 0 !important;
        display: none !important;
      }
    }

    ::-webkit-scrollbar-track,
    .ant-modal-wrap::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

      @media (max-width: 767px) {
        box-shadow: none;
      }
    }

    ::-webkit-scrollbar-thumb,
    .ant-modal-wrap::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      outline: 1px solid #c4c4c4;
      border-radius: 10px;

      @media (max-width: 767px) {
        display: none;
      }
    }

    .ant-modal-wrap::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  .ant-table-container,
  .ant-table-content,
  table {
    border-radius: 5px 5px 0 0 !important;
  }

  ${
    "" /* .ant-table-container .ant-table-content table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  } */
  }

  .ant-table-container,
  .ant-table-header,
  table {
    border-radius: 5px 5px 0 0 !important;
  }
  ${
    "" /* .ant-table-container .ant-table-body table tr:nth-of-type(odd) {
    background-color: #eeeeee;
  } */
  }
  .ant-table-tbody {
    ${"" /* height: calc(100vh - 245px); */}
  }

  .ant-table-tbody > tr > td {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.forth};
    cursor: pointer;
  }

  .ant-table-thead > tr > th {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    background-color: ${({ theme, background }) =>
      background || theme.colors.forth} !important;
  }
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    display: flex;
  }

  /* FOR PAGINATION */
  .ant-table-pagination.ant-pagination {
    margin: 30px 0;
  }
  ${
    "" /* .ant-pagination.mini .ant-pagination-options {
    display: none;
  } */
  }
  .ant-pagination {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 20px;
    padding-right: 25px;
  }
  .ant-pagination.mini .ant-pagination-item {
    margin-right: 20px;
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 20px;
  }
  .ant-pagination-item a {
    padding: 8px 6px;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background: ${({ theme }) => theme.colors.forth};
    border: none;
  }
  .ant-pagination-item {
    border-radius: 50%;
  }
  .hover__invite {
    :hover {
      cursor: not-allowed;
    }
  }

  @media (max-width: 1550px) {
    .ant-table.ant-table-middle {
      font-size: 14px;
    }

    .ant-table.ant-table-middle .ant-table-thead > tr > th {
      padding: 10px 6px;
    }

    .ant-table-thead > tr > th {
      font-weight: 500;
      font-size: 14px;
      line-height: 12px;
      padding: 12px 12px;
    }

    .ant-table-tbody > tr > td {
      font-size: 14px;
      line-height: 16px;
    }

    /* FOR PAGINATION */
    .ant-table-pagination.ant-pagination {
      margin: 24px 0;
    }
    ${
      "" /* .ant-pagination.mini .ant-pagination-options {
      display: none;
    } */
    }
    .ant-pagination {
      font-size: 16px;
      line-height: 20px;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      font-size: 16px;
      padding-right: 20px;
    }
    .ant-pagination.mini .ant-pagination-item {
      margin-right: 16px;
      min-width: 32px;
      height: 32px;
    }
    .ant-pagination.mini .ant-pagination-jump-next {
      margin-right: 26px;
    }
    .ant-pagination-item a {
      padding: 5px 4px;
    }
  }

  @media (max-width: 425px) {
    .ant-pagination.mini .ant-pagination-item {
      min-width: 22px;
      margin-right: 10px;
      height: 23px;
    }
    .ant-pagination-prev .ant-pagination-item-link {
      padding-right: 10px;
    }
    .ant-pagination.mini .ant-pagination-item {
      line-height: 5px;
    }
    .ant-pagination-item a {
      padding: 9px 0px;
    }
  }
`;

export const FloatButton = styled.div`
  display: none;

  .plus_icon {
    font-size: 34px;
  }

  @media (max-width: 991.98px) {
    display: block;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
  }
`;
export const SecondaryButton = styled(Button)`
  border: 1px solid transparent;
  background: ${({ theme }) => theme.colors.third};
  box-shadow: 0px 8px 17px #00000019;
  &:not(&.ant-btn-circle, &.ant-btn-round) {
    border-radius: 5px;
    padding: 15px;
    height: 48px;
    min-width: ${({ width }) => width || "120px"};
    ${({ margin }) => (margin ? { margin } : {})}
  }
  color: ${(props) => (props.color ? props.color : "#FFFFFF")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.third};
    border: 1px solid ${(props) => props.theme.colors.third};
  }

  &.ant-btn-circle {
    height: ${({ height }) => height || "48px"};
    width: ${({ width }) => width || "48px"};
  }
`;
