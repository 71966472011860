import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  message,
  Row,
  Space,
  Spin,
  Switch,
  TreeSelect,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { SettingSvg } from "../../assets/svgs";
import HeaderSearch from "../../components/layout/header/HeaderSearch";
import {
  BreadCrumbsWrapper,
  SidebarMenu,
} from "../../components/layout/TheLayout.styles";
import { Container } from "../../ui/Layout/Container";
import "antd/dist/antd.min.css";
import Item from "antd/lib/list/Item";
import SubMenu from "antd/lib/menu/SubMenu";
import { IoIosArrowDown } from "react-icons/io";
import { StyledButton } from "../../components/createPersonalProfile/RegistrationStyle";
import {
  getAllApartment,
  getAllOrg,
  getFees,
  getUnitsByUserId,
  unitFees,
} from "../../store/reducers/user.reducer";
import { upperCase } from "../../utils/fn";

function Settings() {
  const location = useLocation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.user);
  const { allOrganization } = useSelector((state) => state.user);
  const { apartment } = useSelector((state) => state.user);
  const { allApartment } = useSelector((state) => state.user);
  const { allUnit } = useSelector((state) => state.user);
  const inputRef = useRef(null);
  const [placeHolderVal, setPlaceHolderVal] = useState("");
  const [searchTerm, setSearchTerm] = useState(null);
  const [stateDatas, setStateDatas] = useState();
  const [allOrgArr, setAllOrgArr] = useState([]);
  const [allApartmentArr, setAllApartmentArr] = useState([]);
  const [allUnitArr, setAllUnitArr] = useState([]);
  const [allApaUnit, setAllApaUnit] = useState([]);
  const [finalMergedData, setFinalMergedData] = useState([]);
  const [newSubUnit, setNewSubUnit] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [selectedApa, setSelectedApa] = useState([]);
  const [allOrgProp, setAllOrgProp] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState({});
  const [loadingApi, setLoadingApi] = useState(true);
  const [loadingApiBtn, setLoadingApiBtn] = useState(false);
  const [selectDropDown, setSelectDropDown] = useState("");
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  // get Fees (APIs) and set value in object -> array
  useEffect(() => {
    dispatch(getFees()).then(({ payload }) => {
      if (payload && Object.keys(payload).length) {
        const arr = Object.values(payload);
        setAllOrgProp(arr);
        setLoadingApi(false);
      }
    });
  }, [loadingApiBtn]);

  const [allApartmentList, setAllApartmentList] = useState([]);
  const [allUnitList, setAllUnitList] = useState([]);

  // get all children all organization and set in array
  useEffect(() => {
    if (allOrgProp?.length) {
      const arr = [];
      allOrgProp.map((item) => {
        if (item.children) {
          arr.push(...item.children);
        }
      });
      setAllApartmentList(arr);
    }
  }, [allOrgProp]);

  //  get all children all apartment and set in array
  useEffect(() => {
    if (allApartmentList?.length) {
      const arr = [];
      allApartmentList.map((item) => {
        if (item.children) {
          arr.push(...item.children);
        }
      });
      setAllUnitList(arr);
    }
  }, [allApartmentList]);

  // create a component list
  useEffect(() => {
    if (allUnitList?.length) {
      const allApaUnit = allUnitList?.map((elem, idx) => ({
        ...elem,
        name: elem.unit_name,
        key: elem.id,
        // key: idx + 100,
        component: MenuItem,
        onClick: elem,
      }));
      setNewSubUnit(allApaUnit);
    }
  }, [allUnitList]);

  // create a component list
  useEffect(() => {
    if (newSubUnit?.length) {
      const allApaUnit = allApartmentList?.map((elem, idx) => ({
        ...elem,
        // key: idx + 10,
        key: elem.id,
        name: elem.unit_name,
        component: SubMenu,
        children: newSubUnit?.filter(function (item) {
          return item["apartment_id"] === elem.id;
        }),
      }));
      setAllApaUnit(allApaUnit);
    }
  }, [newSubUnit]);

  // create a component list
  useEffect(() => {
    if (allApaUnit?.length) {
      const launchOptimistic = allOrgProp?.map((elem, idx) => ({
        ...elem,
        // key: idx + 1,
        key: elem.id,
        name: elem?.org_name,
        component: SubMenu,
        children: allApaUnit?.filter(function (item) {
          return item["org_id"] === elem.id;
        }),
      }));
      setFinalMergedData(launchOptimistic);
    }
  }, [allApaUnit]);

  // auto input button (clicks)
  const onButtonClick = () => {
    inputRef.current.focus();
  };

  // set state value
  const onChange = (val) => {
    setSearchTerm(val);
  };

  // default tab value
  useEffect(() => {
    if (
      allOrganization &&
      Object.values(allOrganization).length &&
      !selectedOrg.length
    ) {
      setAllOrgArr(Object.values(allOrganization));
      setSelectedOrg([Object.values(allOrganization)[0]]);
    }
    if (
      (apartment && Object.values(apartment).length) ||
      (allApartment && Object.values(allApartment).length)
    ) {
      setAllApartmentArr(
        Object.values(
          Object.values(allApartment).length ? allApartment : apartment
        )
      );
    }
    if (allUnit && Object.values(allUnit).length) {
      setAllUnitArr(Object.values(allUnit));
    }
  }, [user, apartment, allUnit, allOrganization]);

  // useEffect(() => {
  //   if (allUnitArr?.length) {
  //     const allApaUnit = allUnitArr?.map((elem, idx) => ({
  //       ...elem,
  //       name: elem.unit_name,
  //       key: elem.id,
  //       // key: idx + 100,
  //       component: MenuItem,
  //       onClick: elem,
  //     }));
  //     setNewSubUnit(allApaUnit);
  //   }
  // }, [allUnitArr, user]);

  // useEffect(() => {
  //   if (newSubUnit?.length) {
  //     const allApaUnit = allApartmentArr?.map((elem, idx) => ({
  //       ...elem,
  //       // key: idx + 10,
  //       key: elem.id,
  //       name: elem.unit_name,
  //       component: SubMenu,
  //       children: newSubUnit?.filter(function (item) {
  //         return item["apartment_id"] === elem.id;
  //       }),
  //     }));
  //     setAllApaUnit(allApaUnit);
  //   }
  // }, [newSubUnit, allApartmentArr, user]);

  // useEffect(() => {
  //   if (allApaUnit?.length) {
  //     const launchOptimistic = allOrgArr?.map((elem, idx) => ({
  //       ...elem,
  //       // key: idx + 1,
  //       key: elem.id,
  //       name: elem.org_name,
  //       component: SubMenu,
  //       children: allApaUnit?.filter(function (item) {
  //         return item["org_id"] === elem.id;
  //       }),
  //     }));
  //     setFinalMergedData(launchOptimistic);
  //   }
  // }, [allApaUnit, user]);

  // create render menu for setting side bar
  const renderMenu = (menu, className = "") => {
    return menu.map(
      ({ name, key, component: Item, children, onClick }, idx) => {
        return (
          <>
            <Item
              key={key}
              className={className}
              title={children && name}
              level={idx + 1}
              // onClick={() => console.log(onClick, ">>>")}
              // onOpenChange={() => console.log(onClick, ">>>")}
              onTitleClick={({ key }) => {
                setSelectDropDown(key);
                setNameFun(key);
              }}
            >
              {children ? (
                renderMenu(children, `sub-children ${key}`)
              ) : onClick ? (
                <>
                  <Link
                    to={window.location.pathname}
                    onClick={() => setSelectedUnit(onClick)}
                  >
                    {name}
                  </Link>
                </>
              ) : (
                name
              )}
            </Item>
          </>
        );
      }
    );
  };

  // set name function
  const setNameFun = (id) => {
    var vals = [];
    setSelectedUnit({});
    for (var item of allOrgArr) {
      vals.push(item.id);
    }

    if (vals.includes(id)) {
      const forOrg = allOrgProp?.filter((elem, idx) => {
        return elem["id"] === id;
      });
      setSelectedOrg(forOrg);
    }

    const forApa = allApartmentList?.filter((elem, idx) => {
      return elem["id"] === id;
    });
    setSelectedApa(forApa);
  };

  // change/add new fees
  const onFinish = (value) => {
    if (
      selectedOrg?.length &&
      !Object.values(selectedUnit).length &&
      !selectedApa?.length
    ) {
      value.org_id = selectedOrg[0]?.id;
      const obj = { ...selectedOrg[0] };
      obj.fees = value;
      setSelectedOrg([obj]);
    }
    if (selectedApa?.length && !Object.values(selectedUnit).length) {
      value.apartment_id = selectedApa[0]?.id;
      const obj = { ...selectedApa[0] };
      obj.fees = value;
      setSelectedApa([obj]);
    }
    if (selectedUnit && Object.values(selectedUnit).length) {
      value.unit_id = selectedUnit.id;
      const obj = { ...selectedUnit };
      obj.fees = value;
      setSelectedUnit(obj);
    }

    setLoadingApiBtn(true);
    dispatch(unitFees(value)).then(({ payload }) => {
      setLoadingApiBtn(false);

      if (payload?.status) {
        message.success(payload.message);
      }
    });
  };

  // default set form value
  useEffect(() => {
    //  Updated Flow
    if (
      selectedOrg?.length &&
      !Object.values(selectedUnit).length &&
      !selectedApa?.length
    ) {
      form.setFieldsValue({
        taxFeeSwitch: selectedOrg[0].fees?.taxFeeSwitch,
        taxFee: selectedOrg[0].fees?.taxFee,
        parkingFeeSwitch: selectedOrg[0].fees?.parkingFeeSwitch,
        parkingFee: selectedOrg[0].fees?.parkingFee,
        lateFeeSwitch: selectedOrg[0].fees?.lateFeeSwitch,
        lateFee: selectedOrg[0].fees?.lateFee,
        avenewFeesSwitch: selectedOrg[0].fees?.avenewFeesSwitch,
        avenewFees: selectedOrg[0].fees?.avenewFees,
      });
    } else if (selectedApa?.length && !Object.values(selectedUnit).length) {
      form.setFieldsValue({
        taxFeeSwitch: selectedApa[0].fees?.taxFeeSwitch,
        taxFee: selectedApa[0].fees?.taxFee,
        parkingFeeSwitch: selectedApa[0].fees?.parkingFeeSwitch,
        parkingFee: selectedApa[0].fees?.parkingFee,
        lateFeeSwitch: selectedApa[0].fees?.lateFeeSwitch,
        lateFee: selectedApa[0].fees?.lateFee,
        avenewFeesSwitch: selectedApa[0].fees?.avenewFeesSwitch,
        avenewFees: selectedApa[0].fees?.avenewFees,
      });
    } else if (
      selectedUnit &&
      Object.values(selectedUnit).length &&
      selectedUnit?.fees
    ) {
      form.setFieldsValue({
        taxFeeSwitch: selectedUnit.fees?.taxFeeSwitch,
        taxFee: selectedUnit.fees?.taxFee,
        parkingFeeSwitch: selectedUnit.fees?.parkingFeeSwitch,
        parkingFee: selectedUnit.fees?.parkingFee,
        lateFeeSwitch: selectedUnit.fees?.lateFeeSwitch,
        lateFee: selectedUnit.fees?.lateFee,
        avenewFeesSwitch: selectedUnit.fees?.avenewFeesSwitch,
        avenewFees: selectedUnit.fees?.avenewFees,
      });
    } else {
      form.setFieldsValue({
        lateFeeSwitch: true,
        avenewFeesSwitch: true,
        taxFeeSwitch: true,
        parkingFeeSwitch: true,
        taxFee: null,
        parkingFee: null,
        lateFee: null,
        avenewFees: null,
      });
    }
    //  Current Flow
    // if (selectedUnit?.fees) {
    //   form.setFieldsValue({
    //     taxFeeSwitch: selectedUnit.fees.taxFeeSwitch,
    //     taxFee: selectedUnit.fees.taxFee,
    //     parkingFeeSwitch: selectedUnit.fees.parkingFeeSwitch,
    //     parkingFee: selectedUnit.fees.parkingFee,
    //     lateFeeSwitch: selectedUnit.fees.lateFeeSwitch,
    //     lateFee: selectedUnit.fees.lateFee,
    //     avenewFeesSwitch: selectedUnit.fees.avenewFeesSwitch,
    //     avenewFees: selectedUnit.fees.avenewFees,
    //   });
    // } else {
    //   form.setFieldsValue({
    //     lateFeeSwitch: true,
    //     avenewFeesSwitch: true,
    //     taxFeeSwitch: true,
    //     parkingFeeSwitch: true,
    //     taxFee: null,
    //     parkingFee: null,
    //     lateFee: null,
    //     avenewFees: null,
    //   });
    // }
  }, [selectedUnit, selectedApa, selectedOrg, allUnitArr]);

  return (
    <>
      <Container padding="50px 50px 0 330px">
        <SettingsWrapMain>
          <OrganizationWrap>
            <OrganizationBtnWrap>
              <span>Organization</span>
            </OrganizationBtnWrap>
          </OrganizationWrap>
          {loadingApi ? (
            <Container padding="0 50px 0px 330px">
              <div
                style={{
                  position: "relative",
                  minHeight: "calc(100vh - 80px)",
                }}
              >
                <Spin className="ant__spin" size="large" />
              </div>
            </Container>
          ) : (
            <Row>
              <Col xs={24} sm={24} md={8} lg={7} xl={7} xxl={7}>
                <SettingWrap>
                  <StyledHeaderSearch
                    // style={{ marginBottom: "40px" }}
                    bgcolor="#fff"
                    height={"40px"}
                    // maxWidth={maxWidth}
                  >
                    <FiSearch
                      className="header_search_icon"
                      onClick={onButtonClick}
                    />
                    <TreeSelect
                      treeData={stateDatas}
                      onSearch={onChange}
                      showSearch={true}
                      showArrow={false}
                      listHeight={628}
                      style={{ width: "100%" }}
                      treeDefaultExpandAll={true}
                      dropdownClassName="header_tree_select_dropdown"
                      ref={inputRef}
                      // onFocus={(e) =>
                      //   (e.target.placeholder = placeHolderVal ? "" : "Search...")
                      // }
                      onSelect={(val, node) => {
                        setPlaceHolderVal(val);
                        inputRef.current.blur();
                      }}
                      onBlur={(e) => (e.target.placeholder = "")}
                      placeholder="Search..."
                      allowClear
                    ></TreeSelect>
                  </StyledHeaderSearch>
                  <SidebarTopMenu
                    mode="inline"
                    // expandIcon={<IoIosArrowDown />}
                    // defaultSelectedKeys={["1"]}
                    // defaultOpenKeys={["sub1"]}
                    style={{
                      borderRight: 0,
                    }}
                    // items={data}
                  >
                    {renderMenu(finalMergedData)}
                  </SidebarTopMenu>
                </SettingWrap>
              </Col>
              <Col xs={24} sm={24} md={16} lg={17} xl={17} xxl={17}>
                <RightSideWrap>
                  <BreadcrumbWrap>
                    <h5>{`${
                      selectedOrg.length
                        ? upperCase(selectedOrg[0]?.org_name)
                        : ""
                    } ${selectedApa.length ? " >" : ""} ${
                      selectedApa.length
                        ? upperCase(selectedApa[0]?.unit_name)
                        : ""
                    } ${
                      selectedUnit && Object.values(selectedUnit).length
                        ? " >"
                        : ""
                    } ${
                      selectedUnit && Object.values(selectedUnit).length
                        ? upperCase(selectedUnit?.unit_name)
                        : ""
                    } `}</h5>
                  </BreadcrumbWrap>
                  <ChargesWrap>
                    <Form layout="vertical" form={form} onFinish={onFinish}>
                      <Row
                        justify="space-between"
                        style={{ alignItems: "center" }}
                      >
                        <Col xs={24} sm={14} md={16} lg={16} xl={14} xxl={12}>
                          <Row>
                            <Col
                              xs={24}
                              sm={24}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            >
                              <h2>Late Fee</h2>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={24}
                              sm={20}
                              md={12}
                              lg={18}
                              xl={12}
                              xxl={18}
                            >
                              <p>
                                Established fact that a reader will be
                                distracted content
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24} sm={10} md={8} lg={8} xl={10} xxl={10}>
                          <Row justify="space-between">
                            <Col
                              xs={24}
                              sm={18}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={18}
                            >
                              <Form.Item name="lateFee">
                                <Input
                                  type="number"
                                  step="any"
                                  min="0"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="Enter amount"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              style={{
                                justifyContent: "end",
                                textAlign: "end",
                                alignItems: "center",
                                display: "flex",
                              }}
                              xs={24}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                            >
                              <Form.Item
                                name="lateFeeSwitch"
                                valuePropName="checked"
                              >
                                <Switch defaultChecked />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Divider />
                      <Row
                        justify="space-between"
                        style={{ alignItems: "center" }}
                      >
                        <Col xs={24} sm={14} md={16} lg={16} xl={14} xxl={12}>
                          <Row>
                            <Col
                              xs={24}
                              sm={20}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={24}
                            >
                              <h2>Passthrough Avenew Fees</h2>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={24}
                              sm={20}
                              md={12}
                              lg={18}
                              xl={12}
                              xxl={18}
                            >
                              <p>
                                Established fact that a reader will be
                                distracted content
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24} sm={10} md={8} lg={8} xl={10} xxl={10}>
                          <Row justify="space-between">
                            <Col
                              xs={24}
                              sm={18}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={18}
                            >
                              <Form.Item name="avenewFees">
                                <Input
                                  type="number"
                                  step="any"
                                  min="0"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="Enter amount"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              style={{
                                justifyContent: "end",
                                textAlign: "end",
                                alignItems: "center",
                                display: "flex",
                              }}
                              xs={24}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                            >
                              <Form.Item
                                name="avenewFeesSwitch"
                                valuePropName="checked"
                              >
                                <Switch defaultChecked />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Divider />
                      <Row
                        justify="space-between"
                        style={{ alignItems: "center" }}
                      >
                        <Col xs={24} sm={14} md={16} lg={16} xl={14} xxl={12}>
                          <Row>
                            <Col
                              xs={24}
                              sm={24}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            >
                              <h2>Tax Fee</h2>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={24}
                              sm={20}
                              md={12}
                              lg={18}
                              xl={12}
                              xxl={18}
                            >
                              <p>
                                Established fact that a reader will be
                                distracted content
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24} sm={10} md={8} lg={8} xl={10} xxl={10}>
                          <Row justify="space-between">
                            <Col
                              xs={24}
                              sm={18}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={18}
                            >
                              <Form.Item name="taxFee">
                                <Input
                                  type="number"
                                  step="any"
                                  min="0"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="Enter percentage"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              style={{
                                justifyContent: "end",
                                textAlign: "end",
                                alignItems: "center",
                                display: "flex",
                              }}
                              xs={24}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                            >
                              <Form.Item
                                name="taxFeeSwitch"
                                valuePropName="checked"
                              >
                                <Switch defaultChecked />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Divider />
                      <Row
                        justify="space-between"
                        style={{ alignItems: "center" }}
                      >
                        <Col xs={24} sm={14} md={16} lg={16} xl={14} xxl={12}>
                          <Row>
                            <Col
                              xs={24}
                              sm={24}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={12}
                            >
                              <h2>Parking Fee</h2>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              xs={24}
                              sm={20}
                              md={12}
                              lg={18}
                              xl={12}
                              xxl={18}
                            >
                              <p>
                                Established fact that a reader will be
                                distracted content
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24} sm={10} md={8} lg={8} xl={10} xxl={10}>
                          <Row justify="space-between">
                            <Col
                              xs={24}
                              sm={18}
                              md={16}
                              lg={16}
                              xl={16}
                              xxl={18}
                            >
                              <Form.Item name="parkingFee">
                                <Input
                                  type="number"
                                  step="any"
                                  min="0"
                                  onWheel={(e) => e.target.blur()}
                                  placeholder="Enter amount"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              style={{
                                justifyContent: "end",
                                textAlign: "end",
                                alignItems: "center",
                                display: "flex",
                              }}
                              xs={24}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              xxl={6}
                            >
                              <Form.Item
                                name="parkingFeeSwitch"
                                valuePropName="checked"
                              >
                                <Switch defaultChecked />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "50px" }} justify="end">
                        <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={6}>
                          <Form.Item>
                            <StyledButton
                              loading={loadingApiBtn}
                              htmlType="submit"
                            >
                              save
                            </StyledButton>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </ChargesWrap>
                </RightSideWrap>
              </Col>
            </Row>
          )}
        </SettingsWrapMain>
      </Container>
    </>
  );
}

export default Settings;

const ChargesWrap = styled.div`
  .ant-form-vertical .ant-form-item {
    margin: 0;
  }
  .ant-divider-horizontal {
    background: #dddddd;
    margin: 30px 0;
  }
  .ant-picker,
  input {
    width: 100%;
    border: none;
    height: 40px;
    background: #eeeeee;
    border-radius: 5px;

    .ant-picker-input {
      border: none;
      flex-direction: row-reverse;
      .ant-picker-suffix {
        margin-right: 15px;
        font-size: 16px;
        color: #777777;
      }
    }
    .ant-form-item input {
      border: none;
    }
  }

  h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #2b353f;
    margin-bottom: 8px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
    color: #aaaaaa;
  }
`;

const BreadcrumbWrap = styled.div`
  margin-bottom: 40px;
`;

const RightSideWrap = styled.div`
  padding: 30px;
  h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #777777;
`;

const MenuItem = styled(Menu.Item)`
  overflow: initial !important;
  
  
  &:not(.ant-menu-item-only-child) {
    height: 50px !important;
    color: #666666;

    @media(max-width: 1550px){
    height: 40px !important;
    color: #666666;
    }
  }

  .ant-menu-title-content {
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    color: #777;
    a {
      line-height: 46px;
      font-weight: 400;
      font-size: 18px;
      color:{({theme}) => theme.colors.forth};
      font-weight: 400;
    font-size: 13px;
    line-height: 28px;
    color: #777;
      
    }
    @media (max-width: 1550px){
      font-size: 12px;
      line-height: 36px;
    

    a {
      line-height: 36px;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 1px;
      color:{({theme}) => theme.colors.forth};
    }
    }
  }

  &:hover {
    .ant-menu-title-content {
      a {
        color: #00366b;
      }
    }
  }

  &.ant-menu-item-selected {
    background: #dee8ff !important;
    overflow: initial !important;
    border-left: 5px solid #4160ac !important;
    &:after {
      border-right: none !important;
    }
    
    .ant-menu-title-content {
      a {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`;

const SidebarTopMenu = styled(Menu)`
  margin-bottom: 8px;
  .ant-menu-inline {
    border-right: none !important;
    background: transparent;
    background: rgb(0 0 0 / 3%);
  }
  .ant-menu-horizontal > .ant-menu-submenu::after {
    border-bottom: 2px solid transparent !important;
  }
  .-title-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  .ant-menu-submenu-title .-title-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 40px;
    /* or 286% */

    color: #000000;
  }
`;

const SettingWrap = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  ${"" /* padding: 20px; */}

  overflow-y: auto;
  height: 750px;
  white-space: nowrap;
  overflow-x: hidden;

  /* scroll bar */
  ::-webkit-scrollbar,
  .ant-modal-wrap::-webkit-scrollbar {
    width: 0.5em;

    @media (max-width: 767px) {
      width: 0 !important;
      display: none !important;
    }
  }

  ::-webkit-scrollbar-track,
  .ant-modal-wrap::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

    @media (max-width: 767px) {
      box-shadow: none;
    }
  }

  ::-webkit-scrollbar-thumb,
  .ant-modal-wrap::-webkit-scrollbar-thumb {
    background-color: rgb(97, 96, 96);
    background-color: #c4c4c4;
    outline: 1px solid #c4c4c4;
    border-radius: 10px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .ant-modal-wrap::-webkit-scrollbar-thumb {
    display: none;
  }

  @media (max-width: 1550px) {
    height: 600px;
  }
  @media (max-width: 770px) {
    height: auto;
  }
`;

const OrganizationBtnWrap = styled.div`
  width: 20%;
  padding: 20px 0;
  background: #dee8ff;
  border-bottom: 2px solid #4160ac;
  text-align: center;

  @media (max-width: 770px) {
    width: 100%;
  }
`;

const OrganizationWrap = styled.div`
  background: #f7f7f7;
  border-radius: 5px 5px 0px 0px;
  width: 100%;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #4160ac;
  }
`;

const SettingsWrapMain = styled.div`
  max-width: 1000px;
  margin: auto;

  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const StyledHeaderSearch = styled.div`
  display: flex;
  align-items: center;
  ${"" /* width: 100%; */}
  max-width: ${({ maxWidth }) => maxWidth || "433px"};
  height: ${({ height }) => height || "45px"};
  background: #cbcbcb;
  background: ${({ bgcolor }) => bgcolor || "#cbcbcb"};
  border-radius: 5px;
  padding-left: 18px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin: 20px;

  ${
    "" /* .ant-select-dropdown {
    position: fixed !important;
  } */
  }

  .ant-select-selector {
    height: auto;
    background-color: transparent;
    border: 0;
    box-shadow: none !important;
  }
  .ant-select {
    ${"" /* width: 80% !important; */}
  }

  .ant-select-clear {
    color: #777777 !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: none;
    font-size: 14px;
    line-height: 14px;
    color: #aaaaaa;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
  .ant-select-clear {
    opacity: 1;
    background: none;
    font-size: 16px;
    color: white;
  }

  .header_search_icon {
    font-size: 20px;
    cursor: pointer;
    color: #777777;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
    line-height: 14px;
    color: #aaaaaa;
  }

  input::placeholder {
    color: white;
    font-size: 14px;
    line-height: 14px;
  }

  @media (max-width: 1550px) {
    max-width: ${({ maxWidth }) => maxWidth || "354px"};
    height: 35px;
    .header_search_icon {
      font-size: 16px;
    }
    .ant-select-selection-placeholder {
      font-size: 14px;
      line-height: 14px;
      color: #aaaaaa;
    }
  }
`;
