import { Button, Col, Row, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineDone } from "react-icons/md";
import { TiFilter } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { EditSvg } from "../../assets/svgs";
import { EditIconWrap } from "../../components/apartments-view/Actions";
import { ApartmentTable } from "../../components/apartments-view/ApartmentsViewStyle";
import {
  getTenant,
  getTenantFees,
  invoicesByTenant,
} from "../../store/reducers/user.reducer";
import { Heading } from "../../ui/Heading/Heading";
import { Container } from "../../ui/Layout/Container";
import TenantsInvoicesModal from "../tenantsInvoicesDetails/TenantsInvoicesModal";
import CheckboxMenu from "../../components/tenants/CheckboxMenu";
import { dateConverter, toUSACurrency, upperCase } from "../../utils/fn";

function TenantLedger() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [tenantDetails, setTenantDetails] = useState({});
  const [tenantFees, setTenantFees] = useState({});
  const [getAllIncoice, setGetAllIncoice] = useState({});
  const [getAllIncoiceArr, setGetAllIncoiceArr] = useState([]);
  const [shortedData, setShortedData] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState({});

  // sort nvoice array (last is first)
  useEffect(() => {
    const val = getAllIncoiceArr.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.currentDayTime) - new Date(a.currentDayTime);
    });

    setShortedData(val);
  }, [getAllIncoiceArr]);

  // get tenant data from tenants id (store id in local storage)
  useEffect(() => {
    if (params.id) {
      localStorage.setItem("tenant_id", params.id);
      setLoading(true);
      dispatch(getTenant()).then(({ payload }) => {
        setTenantDetails(payload[0]);
        setLoading(false);
      });
    }
  }, [params.id]);

  // get tenant fees data from tenants id (store id in local storage)
  useEffect(() => {
    if (params.id) {
      localStorage.setItem("tenant_id", params.id);
      setLoading(true);
      dispatch(getTenantFees()).then(({ payload }) => {
        setTenantFees(payload);
      });
    }
  }, [params.id]);

  // edit value
  const editValue = (value) => {
    setSelectedInvoice(value);
    setModalState(true);
    setIsEdit(true);
  };

  // all table columns
  const columns = [
    {
      title: "Inovice id",
      dataIndex: "invoiceCode",
      render: (_, record) => <span>#{record.invoiceCode}</span>,
    },
    {
      title: "Bill Date",
      dataIndex: "invoiceCreatedDate",
      sorter: (a, b) =>
        new Date(a.invoiceCreatedDate) - new Date(b.invoiceCreatedDate),
      render: (_, record) => (
        <span>{dateConverter(record.invoiceCreatedDate)}</span>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
      // ellipsis: true,
      render: (_, record) => <span>{dateConverter(record?.dueDate)}</span>,
    },
    {
      title: "Amount",
      dataIndex: "payableAmount",
      sorter: (a, b) => a.payableAmount - b.payableAmount,
      render: (_, record) => (
        <span>{toUSACurrency(record?.payableAmount)}</span>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      render: (_, record) => <span>{toUSACurrency(record?.balance)}</span>,
    },
    {
      title: "Closed",
      dataIndex: "invoiceClose",
      render: (_, record) => (
        <>
          {record?.invoiceClose ? (
            <span>
              <MdOutlineDone />
            </span>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      align: "right",
      width: "8%",
      render: (_, record) => {
        return (
          <EditIconWrap
            onClick={(event) => {
              event.stopPropagation();
              editValue(record);
            }}
          >
            <EditSvg />
          </EditIconWrap>
        );
      },
    },
  ];

  // retun component (with title, dataIndex and render <span/>)
  function getMenuLink(key) {
    switch (key) {
      case "invoiceCode":
        return {
          title: "Inovice id",
          dataIndex: "invoiceCode",
          render: (_, record) => <span>#{record.invoiceCode}</span>,
        };
      case "invoiceCreatedDate":
        return {
          title: "Bill Date",
          dataIndex: "invoiceCreatedDate",
          sorter: (a, b) =>
            new Date(a.invoiceCreatedDate) - new Date(b.invoiceCreatedDate),
          render: (_, record) => (
            <span>{dateConverter(record.invoiceCreatedDate)}</span>
          ),
        };
      case "dueDate":
        return {
          title: "Due Date",
          dataIndex: "dueDate",
          sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
          // ellipsis: true,
          render: (_, record) => <span>{dateConverter(record.dueDate)}</span>,
        };
      case "payableAmount":
        return {
          title: "Amount",
          dataIndex: "payableAmount",
          sorter: (a, b) => a.payableAmount - b.payableAmount,
          render: (_, record) => (
            <span>{toUSACurrency(record.payableAmount)}</span>
          ),
        };
      case "balance":
        return {
          title: "Balance",
          dataIndex: "balance",
          render: (_, record) => <span>{toUSACurrency(record.balance)}</span>,
        };
      case "invoiceClose":
        return {
          title: "Closed",
          dataIndex: "invoiceClose",
          render: (_, record) => (
            <>
              {record?.invoiceClose ? (
                <span>
                  <MdOutlineDone />
                </span>
              ) : (
                ""
              )}
            </>
          ),
        };
      case "action":
        return {
          title: "",
          dataIndex: "action",
          key: "action",
          align: "right",
          width: "8%",
          render: (_, record) => {
            return (
              <EditIconWrap
                onClick={(event) => {
                  event.stopPropagation();
                  editValue(record);
                }}
              >
                <EditSvg />
              </EditIconWrap>
            );
          },
        };

      default:
        return null;
    }
  }

  const [tableLoading, setTableLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    size: "small",
  });

  // table pagination
  const fetchData = (params = {}) => {
    setTableLoading(true);
    setPagination({
      ...params.pagination,
      total: getAllIncoiceArr?.length, // 200 is mock data, you should read it from server
      // total: data.totalCount,
    });
    setTimeout(() => {
      setTableLoading(false);
    }, 500);
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, []);

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  // redirect to tenants invoice details
  const tenantDetailsPage = (value) => {
    navigate(`/tenants-invoices-details/${value.id}`);
  };

  // set a new invoice data in state
  const newInvoice = (value) => {
    if (value && Object.values(value).length) {
      const arr = Object.values(value);
      const newValue = [...arr];
      setGetAllIncoiceArr((val) => [...newValue, ...val]);
    }
  };

  // convert data object to array
  useEffect(() => {
    if (getAllIncoice && Object.values(getAllIncoice).length) {
      const arr = Object.values(getAllIncoice);
      setGetAllIncoiceArr(arr);
    }
  }, [getAllIncoice]);

  // get invoices by tenant
  useEffect(() => {
    dispatch(invoicesByTenant()).then(({ payload }) => {
      setGetAllIncoice(payload);
    });
  }, []);

  // update invoice data
  const updatedData = (data) => {
    setTableLoading(true);
    dispatch(invoicesByTenant()).then(({ payload }) => {
      setGetAllIncoice(payload);
      setTableLoading(false);
    });
  };

  // type case change/return
  function getMenu(key) {
    switch (key) {
      case "Inovice id":
        return "invoiceCode";
      case "Bill Date":
        return "invoiceCreatedDate";
      case "Due Date":
        return "dueDate";
      case "Amount":
        return "payableAmount";
      case "Balance":
        return "balance";
      case "Closed":
        return "invoiceClose";
      case "Action":
        return "action";
    }
  }

  // on check box click
  const onCheckboxChange = (selection) => {
    var arr = [];
    if (selection.length) {
      selection.map((value) => {
        // arr.push(getMenuLink(value));
        arr.push(getMenu(value));
      });
    }

    if (arr?.length) {
      var arr2 = [];
      const newFilter = columns?.map((val) =>
        arr.filter(function (item) {
          return item === val["dataIndex"];
        })
      );
      arr2.push(...newFilter);
      if (arr2?.length) {
        var arr3 = [];
        arr2.map((val) => {
          arr3.push(getMenuLink(...val));
        });
        var arr4 = [];
        if (arr3?.length) {
          arr3.map((value) => {
            if (value === null) {
            } else {
              arr4.push(value);
            }
          });
        }
        setColumans(arr4);
      }
    }
  };

  const [columans, setColumans] = useState(columns);

  return (
    <>
      {loading ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : (
        <TenantsWrap>
          <Row justify="space-between">
            <Col>
              <Row>
                <Col>
                  <Heading marginBottom="30px">
                    <Link to={`/tenants-ledger/${params.id}`}>
                      Tenants Invoices
                    </Link>
                  </Heading>
                </Col>
                <Col>
                  <p className="t__ledger">
                    {upperCase(tenantDetails?.firstName)}{" "}
                    {upperCase(tenantDetails?.lastName)}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row gutter={[10, 10]}>
                {/* <Col>
                  <AddNewApr bgColor={"#777777"} fw={"400"} displayView="none">
                    Post Charges
                  </AddNewApr>
                </Col> */}
                <Col>
                  <PostIncome
                    onClick={() => {
                      setModalState(true);
                      setIsEdit(false);
                    }}
                    bgColor={"#777777"}
                    fw={"400"}
                    displayView="none"
                  >
                    Create Invoice
                  </PostIncome>
                </Col>
                <Col>
                  <FilterButton
                    bgColor={"#777777"}
                    fw={"400"}
                    displayView="none"
                  >
                    {/* <TiFilter /> */}
                    <CheckboxMenu
                      isIcon={true}
                      options={[
                        "Inovice id",
                        "Bill Date",
                        "Due Date",
                        "Amount",
                        "Balance",
                        "Closed",
                        "Action",
                      ]}
                      value={[
                        "Inovice id",
                        "Bill Date",
                        "Due Date",
                        "Amount",
                        "Balance",
                        "Closed",
                        "Action",
                      ]}
                      onChange={onCheckboxChange}
                    />
                  </FilterButton>
                </Col>
              </Row>
            </Col>
          </Row>

          <ApartmentTable>
            <Table
              columns={columans}
              //   onRow={(r) => ({
              //     onClick: () => setSelectedUnit(r),
              //   })}
              dataSource={[...shortedData]}
              onChange={handleTableChange}
              pagination={pagination}
              loading={tableLoading}
              scroll={{ x: 1100 }}
              onRow={(r) => ({
                onClick: () => {
                  tenantDetailsPage(r);
                },
              })}
              // scroll={{ x: 1000 }}
              // scroll={{ y: 350 }}
            />
          </ApartmentTable>
          <TenantsInvoicesModal
            onCancel={(value) => setModalState(value)}
            modalState={modalState}
            tenantDetails={tenantDetails}
            newInvoice={newInvoice}
            selectedInvoice={selectedInvoice}
            tenantFees={tenantFees}
            isEdit={isEdit}
            updatedData={updatedData}

            // newAddedVal={newAddedVal}
          />
        </TenantsWrap>
      )}
    </>
  );
}

export default TenantLedger;

const FilterButton = styled(Button)`
  ${"" /* width: ${({ wh }) => wh || "100%"}; */}
  padding: 0 10px;
  height: ${({ hg }) => hg || "40px"};
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: #ffffff;
  text-transform: uppercase;
  font-size: 30px;
  color: #777777;
  border: none;

  :hover,
  :focus {
    color: #777777;
    border-color: #ffffff90;
    background: #ffffff90;
  }

  span {
    font-weight: ${({ fw }) => fw || "400"};
    font-size: ${({ fs }) => fs || "18px"};
  }

  @media (max-width: 1550px) {
    span {
      font-size: 14px;
    }
  }
  @media (max-width: 991.98px) {
    ${"" /* display: ${({ displayView }) => displayView || "block"}; */}
  }
`;
export const PostIncome = styled(Button)`
  ${"" /* width: ${({ wh }) => wh || "100%"}; */}
  padding: 0 15px;
  height: ${({ hg }) => hg || "40px"};
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: ${({ theme }) => theme.colors.third};
  color: #ffffff;
  text-transform: uppercase;

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.third}90;
    background: ${({ theme }) => theme.colors.third}90;
  }

  span {
    font-weight: ${({ fw }) => fw || "400"};
    font-size: ${({ fs }) => fs || "18px"};
  }

  @media (max-width: 1550px) {
    span {
      font-size: 14px;
    }
  }
  @media (max-width: 991.98px) {
    ${"" /* display: ${({ displayView }) => displayView || "block"}; */}
  }
`;
const AddNewApr = styled(Button)`
  ${"" /* width: ${({ wh }) => wh || "100%"}; */}
  padding: 0 15px;
  height: ${({ hg }) => hg || "40px"};
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: ${({ theme }) => theme.colors.secondary};
  color: #ffffff;
  text-transform: uppercase;

  :hover,
  :focus {
    color: #ffffff;
    border-color: ${({ theme }) => theme.colors.secondary}90;
    background: ${({ theme }) => theme.colors.secondary}90;
  }

  span {
    font-weight: ${({ fw }) => fw || "400"};
    font-size: ${({ fs }) => fs || "18px"};
  }

  @media (max-width: 1550px) {
    span {
      font-size: 14px;
    }
  }
  @media (max-width: 991.98px) {
    ${"" /* display: ${({ displayView }) => displayView || "block"}; */}
  }
`;

const TenantsWrap = styled.div`
  min-height: ${({ minH }) => minH || "calc(100vh - 80px)"};
  padding: ${({ padding }) => padding || "50px 50px 0 330px"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#eeeeee")};
  border-radius: ${({ brRadius }) => (brRadius ? brRadius : "0")};

  .t__ledger {
    font-weight: 700;
    font-size: 18px;
    color: #777777;
    margin-left: 10px;
  }

  @media (max-width: 1550px) {
    padding: ${({ padding }) => padding || "40px 40px 0 265px"};
    min-height: ${({ minH }) => minH || "calc(100vh - 65px)"};
  }

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "15px"};
  }

  ${
    "" /* .ant-table-tbody > tr.ant-table-placeholder {
    min-height: calc(100vh - 445px) !important;
  }

  .ant-table-body {
    min-height: calc(100vh - 445px) !important;
  }

  .ant-table-pagination.ant-pagination {
    margin: 30px 0;
  }
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  .ant-pagination {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 20px;
    padding-right: 25px;
  }
  .ant-pagination.mini .ant-pagination-item {
    margin-right: 20px;
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 20px;
  }
  .ant-pagination-item a {
    padding: 8px 6px;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background: ${({ theme }) => theme.colors.secondary};
    border: none;
  }
  .ant-pagination-item {
    border-radius: 50%;
  } */
  }
`;
