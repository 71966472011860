import React, {
  createRef,
  useState,
  forwardRef,
  useEffect,
  Fragment,
} from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Svg,
  Polygon,
  Rect,
  Link,
} from "@react-pdf/renderer";
// import Opensans from "../../assets/fonts/Product Sans Regular.ttf"
import Opensans from "../../../assets/fonts/Product Sans Regular.ttf";
import OpensansBold from "../../../assets/fonts/Product Sans Bold.ttf";
import OpensansMed from "../../../assets/fonts/Product Sans Regular.ttf";
import { AccountCreateLogo } from "../../../assets/svgs";
import {
  Capitalize,
  dateConverter,
  formatPhoneNumber,
  toUSACurrency,
} from "../../../utils/fn";

const NewInvoicePdf = (props, ref) => {
  const invRef = createRef();
  const { invoiceDetails, applicationProcessData, totalTax } = props;

  Font.register({
    family: "Open sans",
    format: "truetype",
    src: Opensans,
  });
  Font.register({
    family: "Open sans bold",
    format: "truetype",
    src: OpensansBold,
  });
  Font.register({
    family: "Open sans med",
    format: "truetype",
    src: OpensansMed,
  });

  const toUSACurrency = (num) => {
    const curr = num?.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return curr;
  };

  // split function after 15 words ... (Aven...)
  const slpitFunc = (value) => {
    if (value.length > 15) {
      return value.slice(0, 15) + "...";
    } else {
      return value;
    }
  };

  const feeType = (status) => {
    switch (status) {
      case "rate":
        return "Rent";
      case "lateFee":
        return "Late Fee";
      case "parkingFee":
        return "Parking Fee";
      case "avenewFees":
        return "Avenew Fee";
      case "otherFee":
        return "Other Fee";
      case "taxFee":
        return "Tax";
      default:
        return "";
    }
  };

  const ExampleSvg = () => (
    <Svg width="64" height="64" viewBox="0 0 64 64">
      <Polygon
        points="43.9, 43.8 40.4, 47.4 36.1, 49.9 31.2, 51 26.2, 50.6 21.6, 48.8 17.5, 45.9 15.4, 49.1 19.4, 52.1 24, 54.1 28.9, 55 33.8, 54.6 38.6, 53.1 42.9, 50.6 46.4, 47.1 46, 43.1"
        fill="#58D255"
      />
      <Polygon
        points="62.4, 1.6 58.3, 2.9 54.7, 6.4 51.2, 10 47.5, 10.9 43.3, 8.2 38.7, 6.3 33.8, 5.3 28.8, 5 23.9, 5.7 19.1, 7.1 14.7, 9.4 10.7, 12.4 7.2, 16 4.5, 20.2 2.5, 24.8 1.3, 29.6 1,  34.6 1.5, 39.6 2.9, 44.4 5.1, 48.9 8,  52.9 11.6, 56.4 15.7, 59.2 20.3, 61.3 25.1, 62.6 30.1, 63 35,  62.6 39.9, 61.3 44.4, 59.2 48.5, 56.3 52.1, 52.8 55,  48.7 57.1, 44.3 58.5, 39.4 59,  34.5 58.6, 29.5 57.5, 24.6 55.4, 20.1 51.7, 20.7 53.4, 25.3 54.7, 30.1 55,  35.1 54.3, 40.1 52.6, 44.8 50,  49 46.6, 52.7 42.6, 55.6 38,  57.7 33.1, 58.8 28.2, 58.9 23.2, 58.1 18.6, 56.2 14.4, 53.5 10.8, 50 8,  45.9 6.1, 41.3 5.1, 36.4 5.1, 31.4 6.1, 26.5 8.1, 21.9 10.9, 17.8 14.5, 14.4 18.7, 11.7 23.4, 9.9 28.3, 9.1 33.3, 9.2 38.2, 10.4 42.7, 12.5 45.7, 15.5 42.2, 19 38.6, 22.5 35.1, 26.1 31.6, 29.6 27.9, 29.4 24.2, 26.1 20.5, 22.7 17,  24.1 20.2, 27.8 23.9, 31.2 27.6, 34.5 31.8, 35 35.3, 31.5 38.9, 28 42.4, 24.4 45.9, 20.9 49.5, 17.3 53,  13.8 56.5, 10.3 60.1, 6.7 63,  2.9"
        fill="#58D255"
      />

      <Rect x="20" y="120" width="64" height="64" fill="#666666" />
    </Svg>
  );

  const ThirdrdPage = () => (
    <Document ref={ref}>
      <Page size="A4" ref={invRef}>
        <Image src="/images/avenewImg.png" style={styles.image} />
        <View style={styles.justifyCol}>
          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cell]}>#9ef972cb</Text>
            <Text style={[styles.tableText, styles.cell]}>
              {Capitalize(applicationProcessData?.user_data?.firstName)}{" "}
              {Capitalize(applicationProcessData?.user_data?.lastName)}
            </Text>
          </View>
          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cell]}>
              {applicationProcessData?.user_data?.email}
            </Text>
            <Text style={[styles.tableText, styles.cell]}>
              {formatPhoneNumber(
                applicationProcessData?.user_data?.phoneNumber
              )}
            </Text>
          </View>
        </View>

        <Text
          style={[
            styles.label_title_invoice,
            styles.border_bottom,
            styles.padding_para,
          ]}
        >
          User Profile
        </Text>

        <View style={[styles.tableUser, styles.margin_top]}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Full Name </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Birthday</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Security Number </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Address</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>City</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>State</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Zip Code</Text>
            </View>
          </View>

          {applicationProcessData?.userProfile?.members?.map((item) => (
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item?.memberName}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>08/01/2000</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>112313</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  Luxuria Trade Hub Suite 310
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Surat</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Gujarat</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>395007</Text>
              </View>
            </View>
          ))}
        </View>
        <Text
          style={[
            styles.label_title,
            styles.border_bottom,
            styles.padding_para,
          ]}
        >
          General Profile
        </Text>

        <View>
          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cellForName]}>
              First Name
            </Text>
            <Text style={[styles.fontText, styles.cellForName]}>
              Middle Name
            </Text>
            <Text style={[styles.fontText, styles.cellForName]}>Last Name</Text>
          </View>
          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cellForName]}>
              {
                applicationProcessData?.userProfile?.generalProfile
                  ?.firstLegalName
              }
            </Text>
            <Text style={[styles.tableText, styles.cellForName]}>
              {
                applicationProcessData?.userProfile?.generalProfile
                  ?.MiddleLegalName
              }
            </Text>
            <Text style={[styles.tableText, styles.cellForName]}>
              {
                applicationProcessData?.userProfile?.generalProfile
                  ?.lastLegalName
              }
            </Text>
          </View>

          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cellForName]}>Birthdate</Text>
            <Text style={[styles.fontText, styles.cellForName]}>Sex</Text>
            <Text style={[styles.fontText, styles.cellForName]}>
              Social Security Number
            </Text>
          </View>
          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cellForName]}>
              {applicationProcessData?.userProfile?.generalProfile?.birthdate
                ? dateConverter(
                    applicationProcessData?.userProfile?.generalProfile
                      ?.birthdate
                  )
                : "-"}
            </Text>
            <Text style={[styles.tableText, styles.cellForName]}>
              {applicationProcessData?.userProfile?.generalProfile?.sex}
            </Text>
            <Text style={[styles.tableText, styles.cellForName]}>
              {
                applicationProcessData?.userProfile?.generalProfile
                  ?.socialSecurityNumber
              }
            </Text>
          </View>

          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cell]}>Address</Text>
          </View>
          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cell]}>
              {applicationProcessData?.userProfile?.generalProfile?.address}
            </Text>
          </View>

          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cellForName]}>State</Text>
            <Text style={[styles.fontText, styles.cellForName]}>City</Text>
            <Text style={[styles.fontText, styles.cellForName]}>Zip code</Text>
          </View>
          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cellForName]}>
              {applicationProcessData?.userProfile?.generalProfile?.state}
            </Text>
            <Text style={[styles.tableText, styles.cellForName]}>
              {applicationProcessData?.userProfile?.generalProfile?.city}
            </Text>
            <Text style={[styles.tableText, styles.cellForName]}>
              {applicationProcessData?.userProfile?.generalProfile?.zipcode}
            </Text>
          </View>
        </View>

        <Text
          style={[
            styles.label_title,
            styles.border_bottom,
            styles.padding_para,
          ]}
        >
          ID Proof
        </Text>
        <View>
          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cell]}>ID type</Text>
          </View>
          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cell]}>
              {applicationProcessData?.userProfile?.idProof?.IDtype}
            </Text>
          </View>

          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cell]}>
              <Link
                src={
                  applicationProcessData?.userProfile?.idProof?.frontProof?.path
                }
                style={{
                  textDecoration: "underline",
                  color: "#4160ac",
                }}
              >
                Download Front side of the ID Proof
              </Link>
            </Text>
          </View>
          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cell]}>
              <Link
                src={
                  applicationProcessData?.userProfile?.idProof?.backProof?.path
                }
                style={{
                  textDecoration: "underline",
                  color: "#4160ac",
                }}
              >
                Download Back side of the ID Proof
              </Link>
            </Text>
          </View>
        </View>
      </Page>

      <Page size="A4">
        <Image src="/images/avenewImg.png" style={styles.image} />

        <Text
          style={[
            styles.label_title,
            styles.border_bottom,
            styles.padding_para,
          ]}
        >
          Income and Employment
        </Text>
        <View>
          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cell]}>
              Instant Bank verification
            </Text>
          </View>
          <View
            style={[styles.align_center, styles.row, styles.padding_para_table]}
          >
            <ExampleSvg />
          </View>
          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cell]}>
              Bank account verified
            </Text>
          </View>

          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cell]}>
              Manual Verification
            </Text>
          </View>

          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cell]}>Pay Stubs:</Text>
          </View>
          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.fontText, styles.cell]}>
              Recent two paystubs
            </Text>
          </View>

          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cell]}>
              <Link
                src={
                  applicationProcessData?.incomeAndEmployment
                    ?.manualVerification?.payStubs?.payStub1?.path
                }
                style={{
                  textDecoration: "underline",
                  color: "#4160ac",
                }}
              >
                Download Pay stub 1
              </Link>
            </Text>
          </View>
          <View style={[styles.row, styles.padding_para_table]}>
            <Text style={[styles.tableText, styles.cell]}>
              <Link
                src={
                  applicationProcessData?.incomeAndEmployment
                    ?.manualVerification?.payStubs?.payStub2?.path
                }
                style={{
                  textDecoration: "underline",
                  color: "#4160ac",
                }}
              >
                Download Pay stub 2
              </Link>
            </Text>
          </View>

          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.tableText, styles.cell]}>
              Employer Details
            </Text>
          </View>

          <View>
            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cell]}>Business Name</Text>
              <Text style={[styles.fontText, styles.cell]}>Address</Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cell]}>
                {
                  applicationProcessData?.incomeAndEmployment
                    ?.manualVerification?.employerDetails?.businessName
                }
              </Text>
              <Text style={[styles.tableText, styles.cell]}>
                {
                  applicationProcessData?.incomeAndEmployment
                    ?.manualVerification?.employerDetails?.address
                }
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cell]}>State</Text>
              <Text style={[styles.fontText, styles.cell]}>City</Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cell]}>
                {
                  applicationProcessData?.incomeAndEmployment
                    ?.manualVerification?.employerDetails?.state
                }
              </Text>
              <Text style={[styles.tableText, styles.cell]}>
                {
                  applicationProcessData?.incomeAndEmployment
                    ?.manualVerification?.employerDetails?.city
                }
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cell]}>Position</Text>
              <Text style={[styles.fontText, styles.cell]}>Zip code</Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cell]}>
                {
                  applicationProcessData?.incomeAndEmployment
                    ?.manualVerification?.employerDetails?.zipcode
                }
              </Text>
              <Text style={[styles.tableText, styles.cell]}>
                {
                  applicationProcessData?.incomeAndEmployment
                    ?.manualVerification?.employerDetails?.position
                }
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cell]}>Work Phone</Text>
              <Text style={[styles.fontText, styles.cell]}>
                Gross Monthly Income
              </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cell]}>
                {formatPhoneNumber(
                  applicationProcessData?.incomeAndEmployment
                    ?.manualVerification?.employerDetails?.workPhone
                )}
              </Text>
              <Text style={[styles.tableText, styles.cell]}>
                {toUSACurrency(
                  applicationProcessData?.incomeAndEmployment
                    ?.manualVerification?.employerDetails?.grossMonthlyIncome
                )}
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cell]}>Date Started</Text>
              <Text style={[styles.fontText, styles.cell]}>
                Supervisor’s Name
              </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cell]}>
                {applicationProcessData?.incomeAndEmployment?.manualVerification
                  ?.employerDetails?.dateStarted
                  ? dateConverter(
                      applicationProcessData?.incomeAndEmployment
                        ?.manualVerification?.employerDetails?.dateStarted
                    )
                  : "-"}
              </Text>
              <Text style={[styles.tableText, styles.cell]}>
                {
                  applicationProcessData?.incomeAndEmployment
                    ?.manualVerification?.employerDetails?.supervisorsName
                }
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cell]}>
                Supervisor’s Number
              </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cell]}>
                {formatPhoneNumber(
                  applicationProcessData?.incomeAndEmployment
                    ?.manualVerification?.employerDetails?.supervisorsNumber
                )}
              </Text>
            </View>
          </View>

          <View
            style={[styles.row, styles.padding_para_table, styles.margin_top]}
          >
            <Text style={[styles.tableText, styles.cell]}>
              Credit Score:{" "}
              {applicationProcessData?.credit_score_data?.length
                ? applicationProcessData?.credit_score_data[0]?.score
                : "-"}
            </Text>
          </View>
        </View>
      </Page>

      <Page size="A4">
        <Image src="/images/avenewImg.png" style={styles.image} />

        <Text
          style={[
            styles.label_title,
            styles.border_bottom,
            styles.padding_para,
          ]}
        >
          Rental History
        </Text>

        {applicationProcessData?.rentalHistory?.references?.map((item, id) => (
          <View key={id} style={[styles.tableReferenceTop, styles.margin_top]}>
            <View style={[styles.tableReferenceSm]}>
              <Text style={styles.tableCellReferenceTop}>
                {item?.propertyType}
              </Text>
            </View>
            <View style={[styles.tableReferenceBt]}>
              <Text style={styles.tableCellPropertyName}>
                {item?.propertyName}
              </Text>
            </View>

            <View style={styles.tableRowReference}>
              <View style={styles.tableColSm}>
                <Text style={styles.tableCellSm}>ADDRESS </Text>
              </View>
              <View style={styles.tableColLg}>
                <Text style={styles.tableCellLg}>{item?.address} </Text>
              </View>
              <View style={styles.tableColSm}>
                <Text style={styles.tableCellSm}>CURRENT RENT </Text>
              </View>
              <View style={styles.tableColLg}>
                <Text style={styles.tableCellLg}>
                  {toUSACurrency(item?.currentRent)}
                </Text>
              </View>
            </View>
            <View style={styles.tableRowReference}>
              <View style={styles.tableColSm}>
                <Text style={styles.tableCellSm}>CITY </Text>
              </View>
              <View style={styles.tableColLg}>
                <Text style={styles.tableCellLg}>{item?.city} </Text>
              </View>
              <View style={styles.tableColSm}>
                <Text style={styles.tableCellSm}>MANAGER’S NAME</Text>
              </View>
              <View style={styles.tableColLg}>
                <Text style={styles.tableCellLg}>{item?.managerName} </Text>
              </View>
            </View>
            <View style={styles.tableRowReference}>
              <View style={styles.tableColSm}>
                <Text style={styles.tableCellSm}>STATE </Text>
              </View>
              <View style={styles.tableColLg}>
                <Text style={styles.tableCellLg}>{item?.state} </Text>
              </View>
              <View style={styles.tableColSm}>
                <Text style={styles.tableCellSm}>PHONE </Text>
              </View>
              <View style={styles.tableColLg}>
                <Text style={styles.tableCellLg}>
                  {formatPhoneNumber(item?.phone)}{" "}
                </Text>
              </View>
            </View>
            <View style={styles.tableRowReference}>
              <View style={styles.tableColSm}>
                <Text style={styles.tableCellSm}>Zipcode </Text>
              </View>
              <View style={styles.tableColLg}>
                <Text style={styles.tableCellLg}>{item?.zipcode} </Text>
              </View>
              <View style={styles.tableColSm}>
                <Text style={styles.tableCellSm}>DATE MOVED IN</Text>
              </View>
              <View style={styles.tableColLg}>
                <Text style={styles.tableCellLg}>
                  {item?.dateMovedIn ? dateConverter(item?.dateMovedIn) : "-"}
                </Text>
              </View>
            </View>
          </View>
        ))}
      </Page>

      <Page size="A4">
        <Image src="/images/avenewImg.png" style={styles.image} />

        <Text
          style={[
            styles.label_title,
            styles.border_bottom,
            styles.padding_para,
          ]}
        >
          State Profile
        </Text>

        {applicationProcessData?.stateProfile?.map((item, id) => (
          <View key={id}>
            <View style={[styles.margin_top, styles.margin_left]}>
              <View style={[styles.tableReferenceSm]}>
                <Text style={styles.tableCellReferenceTop}>
                  {item?.stateName}
                </Text>
              </View>
            </View>

            <View style={[styles.justifyCol, styles.text_underline_bt]}>
              <View style={[styles.row, styles.padding_para_table]}>
                <Text
                  style={[styles.tableText, styles.cell, styles.text_underline]}
                >
                  Co-applicant personal details
                </Text>
              </View>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>
                First name
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Formal last name
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Co-applicant social security
              </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.fullName}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.lastName}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.spouseSocialSecurity}
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>
                Birthdate
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Height (Foot)
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Weight (lb)
              </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.birthdate
                  ? dateConverter(item?.spouseDetails?.birthdate)
                  : "-"}
              </Text>

              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.height}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.weight}
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>Sex</Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Eye color
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Hair color
              </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.sex}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.eyeColor}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.hairColor}
              </Text>
            </View>

            <View style={[styles.justifyCol, styles.text_underline_bt]}>
              <View style={[styles.row, styles.padding_para_table]}>
                <Text
                  style={[styles.tableText, styles.cell, styles.text_underline]}
                >
                  Co-applicant company details
                </Text>
              </View>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>
                U.S. citizen
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Present employer
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>Address</Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.USCitizen}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.presentEmployer}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.address}
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>State</Text>
              <Text style={[styles.fontText, styles.cellForName]}>City</Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Zip code
              </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.state}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.city}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.zipcode}
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>
                Work Phone
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Positions
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Gross monthly income
              </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {formatPhoneNumber(item?.spouseDetails?.workPhone)}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.positions}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {toUSACurrency(item?.spouseDetails?.monthlyIncome)}
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>
                Date you begen this job
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Supervisor’s name and phone
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}> </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.begenJob
                  ? dateConverter(item?.spouseDetails?.begenJob)
                  : "-"}
              </Text>

              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.spouseDetails?.supervisorNameAndPhone}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}> </Text>
            </View>

            <View style={[styles.justifyCol, styles.text_underline_bt]}>
              <View style={[styles.row, styles.padding_para_table]}>
                <Text
                  style={[styles.tableText, styles.cell, styles.text_underline]}
                >
                  Occupation
                </Text>
              </View>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>
                Work phone
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Position
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Gross Monthly income
              </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {formatPhoneNumber(item?.workDetails?.workPhone)}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.workDetails?.position}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {toUSACurrency(item?.workDetails?.monthlyIncome)}
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>
                Date you began this job
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Supervisor’s name
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Supervisor’s Phone
              </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.workDetails?.begenJob
                  ? dateConverter(item?.workDetails?.begenJob)
                  : "-"}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.workDetails?.supervisorName}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {formatPhoneNumber(item?.workDetails?.supervisorPhone)}
              </Text>
            </View>

            <View style={[styles.justifyCol, styles.text_underline_bt]}>
              <View style={[styles.row, styles.padding_para_table]}>
                <Text
                  style={[styles.tableText, styles.cell, styles.text_underline]}
                >
                  Credit History
                </Text>
              </View>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>
                Bank Name
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>State</Text>
              <Text style={[styles.fontText, styles.cellForName]}>City</Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.creditHistory?.bankName}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.creditHistory?.state}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.creditHistory?.city}
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>
                Zip code
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                List major credit cards
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Other non-work income
              </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.creditHistory?.zipcode}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.creditHistory?.majorCreditCards}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.creditHistory?.nonWorkIncome}
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>
                Past credit problems
              </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.creditHistory?.creditProblems}
              </Text>
            </View>

            <View style={[styles.justifyCol, styles.text_underline_bt]}>
              <View style={[styles.row, styles.padding_para_table]}>
                <Text
                  style={[styles.tableText, styles.cell, styles.text_underline]}
                >
                  Rental/Criminal History
                </Text>
              </View>
            </View>
            {item?.rentalOrCriminalHistory?.map((val, idx) => (
              <>
                <View
                  key={idx}
                  style={[
                    styles.row,
                    styles.padding_para_table,
                    styles.margin_top,
                  ]}
                >
                  <Text style={[styles.fontText, styles.cell]}>{val}</Text>
                </View>

                <View style={[styles.row, styles.padding_para_table]}>
                  <Text style={[styles.tableText, styles.cellForName]}>
                    Yes
                  </Text>
                </View>
              </>
            ))}

            <View style={[styles.justifyCol, styles.text_underline_bt]}>
              <View style={[styles.row, styles.padding_para_table]}>
                <Text
                  style={[styles.tableText, styles.cell, styles.text_underline]}
                >
                  Your vehicles
                </Text>
              </View>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cell]}>
                Make and color of vehicle
              </Text>
              <Text style={[styles.fontText, styles.cell]}>Year</Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cell]}>
                {item?.vehicleDetails?.vehicleColor}
              </Text>
              <Text style={[styles.tableText, styles.cell]}>
                {item?.vehicleDetails?.year}
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cell]}>License</Text>
              <Text style={[styles.fontText, styles.cell]}>State</Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cell]}>
                {item?.vehicleDetails?.license}
              </Text>
              <Text style={[styles.tableText, styles.cell]}>
                {item?.vehicleDetails?.state}
              </Text>
            </View>

            <View style={[styles.justifyCol, styles.text_underline_bt]}>
              <View style={[styles.row, styles.padding_para_table]}>
                <Text
                  style={[styles.tableText, styles.cell, styles.text_underline]}
                >
                  Additional details
                </Text>
              </View>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>
                Referred
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Name of locator or rental agency
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Name of individual locator or agent
              </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.whyYouRentedHere?.referred}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.whyYouRentedHere?.nameOfLocator}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.whyYouRentedHere?.nameOfIndividual}
              </Text>
            </View>

            <View
              style={[styles.row, styles.padding_para_table, styles.margin_top]}
            >
              <Text style={[styles.fontText, styles.cellForName]}>
                Name of friend or other person
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}>
                Did you find us on your own?
              </Text>
              <Text style={[styles.fontText, styles.cellForName]}> </Text>
            </View>
            <View style={[styles.row, styles.padding_para_table]}>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.whyYouRentedHere?.nameOfFriend}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.whyYouRentedHere?.findUs}
              </Text>
              <Text style={[styles.tableText, styles.cellForName]}>
                {item?.whyYouRentedHere?.findUsPlatform}
              </Text>
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );

  const styles = StyleSheet.create({
    page: {},
    align_center: {
      alignItems: "center",
      display: "flex",
    },
    label_title: {
      paddingTop: 40,
      fontFamily: "Open sans bold",
      fontSize: 12,
      lineHeight: 2,
    },
    label_title_invoice: {
      paddingTop: 5,
      fontFamily: "Open sans bold",
      fontSize: 12,
      lineHeight: 2,
      justifyContent: "end",
      display: "flex",
      right: 0,
    },
    margin_top: {
      marginTop: 10,
    },
    margin_left: {
      width: "30%",
      marginLeft: 30,
      marginBottom: 15,
      paddingBottom: 10,
      borderBottom: 1,
      borderColor: "grey",
    },
    padding_para: {
      marginLeft: 30,
      marginRight: 30,
    },
    padding_para_table: {
      marginLeft: 40,
      marginRight: 40,
    },
    border_bottom: {
      paddingBottom: 10,
      borderBottom: 1,
      borderColor: "grey",
    },
    marginTop20: {
      marginTop: 20,
    },
    label_content: {
      marginLeft: 70,
      marginRight: 70,
      paddingTop: 35,
      fontFamily: "Open sans",
    },
    d_block: {
      display: "block",
    },

    breakable: { width: "100%", height: 10 },
    unbreakable: { width: "100%", height: 100 },
    tableUser: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginLeft: 30,
      marginRight: 30,
    },
    tableReference: {
      width: "auto",
      border: "1px solid #dddddd",
      borderWidth: 1,
      backgroundColor: "#fafafa",
      marginLeft: 30,
      marginRight: 30,
      paddingTop: 20,
    },
    tableReferenceTop: {
      width: "auto",
      border: "1px solid #dddddd",
      borderWidth: 1,
      backgroundColor: "#fafafa",
      marginLeft: 30,
      marginRight: 30,
    },
    tableReferenceSm: {
      width: "auto",
      backgroundColor: "#ffffff",
      paddingTop: 10,
    },
    tableReferenceBt: {
      width: "auto",
      backgroundColor: "#ffffff",
    },
    tableRowReference: {
      flexDirection: "row",
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableColReference: {
      width: "50%",
    },
    tableColSm: {
      width: "20%",
      backgroundColor: "#ffffff",
      padding: 5,
      margin: 2,
    },
    tableColLg: {
      width: "30%",
      backgroundColor: "#ffffff",
      padding: 5,
      margin: 2,
    },
    tableCol: {
      width: "14.2%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCellReference: {
      marginTop: 5,
      marginLeft: 5,
      marginBottom: 2,
      fontSize: 12,
      color: "#777777",
    },
    tableCellReferenceTop: {
      marginLeft: 5,
      fontSize: 10,
      color: "#4160ac",
      fontWeight: 700,
      padding: 2,
    },
    tableCellPropertyName: {
      marginLeft: 5,
      marginBottom: 10,
      fontSize: 14,
      color: "#000000",
      fontWeight: 700,
      padding: 2,
    },
    tableCellSm: {
      marginTop: 5,
      marginLeft: 5,
      fontSize: 10,
      color: "#777777",
    },
    tableCellLg: {
      marginTop: 5,
      marginLeft: 5,
      fontSize: 10,
      color: "#000000",
    },
    tableCell: {
      margin: "auto",
      marginTop: 5,
      fontSize: 10,
    },
    table: {
      fontSize: 10,
      width: 550,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignContent: "stretch",
      flexWrap: "nowrap",
      alignItems: "stretch",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignContent: "stretch",
      flexWrap: "nowrap",
      alignItems: "stretch",
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 20,
    },
    cell: {
      width: 50,
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto",
      alignSelf: "stretch",
    },
    cellForName: {
      width: 33,
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto",
      alignSelf: "stretch",
    },
    headerText: {
      fontSize: 12,
      fontWeight: 1200,
      color: "#1a245c",
      margin: 2,
    },
    tableText: {
      // margin: 5,
      fontSize: 12,
      color: "#000000",
      fontFamily: "Open sans med",
    },
    text_underline: {
      marginTop: 10,
      marginBottom: 5,
      textDecoration: "underline",
    },
    text_underline_bt: {
      marginTop: 10,
      marginBottom: 5,
    },
    tableTextAmountTotal: {
      // margin: 5,
      fontSize: 16,
      color: "#000000",
      fontFamily: "Open sans med",
    },
    fontTextBottom: {
      fontFamily: "Open sans med",
      fontSize: 7,
      textAlign: "center",
      color: "#5C737F",
      paddingTop: 30,
      marginLeft: 15,
      marginRight: 15,
    },
    fontText: {
      color: "#5C737F",
      fontFamily: "Open sans med",
      fontSize: 10,
    },
    amountTotal: {
      color: "#5C737F",
      fontFamily: "Open sans med",
      fontSize: 16,
    },
    margintopfirst: {
      marginTop: 40,
    },
    margintopsecond: {
      marginTop: 300,
    },
    // image: {
    //   objectFit: "cover",
    //   height: "92px",
    // },
    image: {
      marginVertical: 20,
      marginHorizontal: 20,
      width: 100,
    },
    idImage: {
      marginVertical: 20,
      marginHorizontal: 20,
      width: "45%",
    },
    justifyCol: {
      display: "flex",
      justifyContent: "space-between",
    },
    lineHeight30: {
      paddingTop: "30px",
    },
    lineHeight10: {
      paddingTop: "10px",
    },
  });

  return <ThirdrdPage />;
};

export default forwardRef(NewInvoicePdf);
