import React, { createRef, forwardRef } from "react";
import { Container } from "../../ui/Layout/Container";
import { AvenewLogo } from "../../assets/svgs";
import styled from "styled-components";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Svg,
  Line,
} from "@react-pdf/renderer";

function InvoicePdf(props, ref) {
  const invRef = createRef();

  const styles = StyleSheet.create({
    page: {
      // flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      // flexGrow: 1,
    },
  });
  console.log(props, ">>>");

  const stylePdf = StyleSheet.create({
    page: {
      // flexDirection: "row",
      backgroundColor: "#ffffff",
    },

    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
      fontFamily: "Oswald",
    },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: "Oswald",
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: "justify",
      fontFamily: "Times-Roman",
    },
    image: {
      marginVertical: 50,
      marginHorizontal: 20,
      width: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  return (
    <>
      {/* <Container bgColor="#fff" padding="50px 50px 0 330px"> */}
      <Document ref={ref}>
        <Page ref={invRef} size="A4" style={stylePdf.page}>
          {/* <View style={styles.section}> */}
          <Image src="/images/avenewImg.png" style={stylePdf.image} />
          <View>
            <Line />
          </View>
          <View>
            <Text>Invoice</Text>
          </View>
          <View>
            <Text>Section #2</Text>
          </View>
        </Page>
      </Document>
      {/* </Container> */}
      {/* <Container bgColor="#fff" padding="50px 50px 0 330px">
        <InvoicePdfWrap ref={invoiceDownloadRef}>
          <AvenewLogo className="avenew__title" />

          <InvoiceHeader>
            <h1>Invoice</h1>
            <p>#afdsfsd</p>
          </InvoiceHeader>
        </InvoicePdfWrap>
      </Container> */}
    </>
  );
}

export default forwardRef(InvoicePdf);

const InvoicePdfWrap = styled.div`
  width: 100%;
  padding: 50px 20px;
`;
const InvoiceHeader = styled.div`
  width: 80%;
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  padding: 10px 30px;
  border: 1px solid #dddddd;

  h1 {
    font-size: 30px;
    line-height: 24px;
    margin-bottom: 30px;
    color: black;
    color: #323548;
    margin-bottom: 0;
  }

  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #f67c36;
    margin-bottom: 0;
  }
`;
