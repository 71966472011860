import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpAPI, httpAPIInvite } from "../../api/index";
import { message } from "antd";

const initialState = {
  user: null,
  accessToken: "",
  user_id: "",
  loading: false,
  getApplicantDetailsLoading: false,
  loadingApartment: false,
  isLoggedIn: false,
  error: null,
  emailVerification: false,
  modules: {},
  allOrganization: {},
  apartment: {},
  allApartment: {},
  allUnit: {},
  allNotificatios: {},
  allApplications: {},
};

export const login = createAsyncThunk("user/login", async (data, thunkAPI) => {
  try {
    const response = await httpAPI("/signIn", data);
    return { ...response.data };
  } catch (error) {
    return error;
  }
});

export const register = createAsyncThunk("signUp", async (data, thunkAPI) => {
  try {
    const response = await httpAPI("/signUp", data, "POST", true);
    return { ...response.data };
  } catch (error) {
    if (error) {
      return error;
    }
  }
});

export const verifySignUp = createAsyncThunk(
  "verifyOtp",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/verifyOtp", data, "POST", true);
      return { ...response.data };
    } catch (error) {
      if (error) {
        return error;
      }
    }
  }
);

export const forgotPasswordOtp = createAsyncThunk(
  "forgot/password/otp",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/forgot-password", data, "POST", true);
      return { ...response.data };
    } catch (error) {
      if (error) {
        return error;
      }
    }
  }
);

export const forgotPasswordWithOtp = createAsyncThunk(
  "confirm/password/otp",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(
        "/confirm-forgot-password",
        data,
        "POST",
        true
      );
      return { ...response.data };
    } catch (error) {
      if (error) {
        return error;
      }
    }
  }
);

export const verifyResendOtp = createAsyncThunk(
  "resendOtp",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/resendOtp", data, "POST", true);

      return { ...response.data };
    } catch (error) {
      if (error) {
        return error;
      }
    }
  }
);

export const portal = createAsyncThunk(
  "user/configDetails",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("userId");

      const response = await httpAPI(`/portals`, data, "GET");

      // const responses = await axios.get(
      //   `${process.env.ANT_URL}/client/?_id=${response.data?.data[0]?._id}`
      // );
      // let portalData = response.data?.data[0];
      // let modules = responses.data?.data[0].module;
      return { ...response.data?.data[0] };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const me = createAsyncThunk("user/me", async (data, thunkAPI) => {
  try {
    let id = localStorage.getItem("user_id");
    const response = await httpAPI(`/user/${id}`, data, "GET");
    return { ...response.data };
  } catch (error) {
    // message.error(error.Error || error.message);
    return thunkAPI.rejectWithValue(error);
  }
});
export const resetPassword = createAsyncThunk(
  "user/reset-password",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(`/reset-password?id=${id}`, data, "POST");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editProfile = createAsyncThunk("edit", async (data, thunkAPI) => {
  try {
    const response = await httpAPI(`/user`, data, "PUT");
    return { ...response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const createOrg = createAsyncThunk(
  "organization",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/organization`, data, "POST");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const editOrg = createAsyncThunk(
  "organization/edit",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/organization`, data, "PUT");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllOrg = createAsyncThunk("all/org", async (data, thunkAPI) => {
  try {
    let id = localStorage.getItem("user_id");
    const response = await httpAPI(`/organizations/user/${id}`, data, "GET");
    return { ...response.data };
  } catch (error) {
    // message.error(error.Error || error.message);
    return thunkAPI.rejectWithValue(error);
  }
});

export const getAllOrgId = createAsyncThunk(
  "all/org/new",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(`/apartments?user_id=${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllPropId = createAsyncThunk(
  "all/pro/new",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("org_id");
      const response = await httpAPI(`/apartments`, data, "POST");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOrg = createAsyncThunk("one/org", async (data, thunkAPI) => {
  try {
    let id = localStorage.getItem("org_id");
    const response = await httpAPI(`/organization/${id}`, data, "GET");
    return { ...response.data };
  } catch (error) {
    // message.error(error.Error || error.message);
    return thunkAPI.rejectWithValue(error);
  }
});

export const getTenant = createAsyncThunk(
  "one/tenant",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("tenant_id");
      const response = await httpAPI(`/tenant/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTenantTr = createAsyncThunk(
  "tenant/transections",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("tenant_id");
      const response = await httpAPIInvite(
        `/transactions/tenant/${id}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTenantFees = createAsyncThunk(
  "tenant/fee",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("tenant_id");
      const response = await httpAPIInvite(`/fees/tenant/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllApartment = createAsyncThunk(
  "all/Apartment",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(`/apartments/user/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllApartmentsPost = createAsyncThunk(
  "all/Apartment/Post",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/apartments`, data, "POST");
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUnitsByUserId = createAsyncThunk(
  "all/Unit",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(`/units/user/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getApartment = createAsyncThunk(
  "apartment",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("org_id");
      const response = await httpAPI(
        `/apartments/organization/${id}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getApartmentCard = createAsyncThunk(
  "apartment/card",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("org_id");
      const response = await httpAPI(
        `/apartments/organization/${id}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSingleApartment = createAsyncThunk(
  "SingleApartment",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("prop_id");
      const response = await httpAPI(`/apartment/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createApartment = createAsyncThunk(
  "create/apartment",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/apartment`, data, "POST");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateApartment = createAsyncThunk(
  "update/apartment",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/apartment`, data, "PUT");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteApartment = createAsyncThunk(
  "delete/apartment",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/apartment/${data.id}`, data, "DELETE");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteUnit = createAsyncThunk(
  "delete/unit",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/unit/${data.id}`, data, "DELETE");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createUnit = createAsyncThunk(
  "create/unit",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/unit`, data, "POST");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createTenants = createAsyncThunk(
  "create/tenant",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/tenant`, data, "POST");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteTenant = createAsyncThunk(
  "delete/tenant",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(
        `/tenants/delete`,
        { tenant_id: [data.id] },
        "POST"
      );
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteMultipleTenant = createAsyncThunk(
  "multidelete/tenant",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/tenants/delete`, data, "POST");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTenant = createAsyncThunk(
  "update/tenant",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/tenant`, data, "PUT");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const inviteTenants = createAsyncThunk(
  "invite/tenant",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPIInvite(`/invite-tenant`, data, "POST");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createInvoice = createAsyncThunk(
  "create/invoice",
  async (data, thunkAPI) => {
    try {
      // let id = localStorage.getItem("prop_id");

      const response = await httpAPIInvite(`/invoice`, data, "POST");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateInvoice = createAsyncThunk(
  "update/invoice",
  async (data, thunkAPI) => {
    try {
      // let id = localStorage.getItem("prop_id");

      const response = await httpAPIInvite(`/invoice`, data, "PUT");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const invoicesByTenant = createAsyncThunk(
  "get/invoice/tenant",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("tenant_id");
      const response = await httpAPIInvite(
        `/invoices/tenant/${id}?ScanIndexForward=true`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getFees = createAsyncThunk(
  "get/fee/all",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(`/fees/user/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const propertiesDetails = createAsyncThunk(
  "property/details/all",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(
        `/property-details/user/${id}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const unitFees = createAsyncThunk(
  "unit/fees",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPIInvite(`/fees`, data, "POST");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllChats = createAsyncThunk("chats", async (data, thunkAPI) => {
  try {
    let id = localStorage.getItem("user_id");
    const response = await httpAPIInvite(`/chats/admin/${id}`, data, "GET");
    return { ...response.data };
  } catch (error) {
    message.error(error.Error || error.message);
    return thunkAPI.rejectWithValue(error);
  }
});

export const getOrgChats = createAsyncThunk(
  "chats/organization",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      let orgId = localStorage.getItem("org_id");
      const response = await httpAPIInvite(
        `/chats/admin/${id}?org_id=${orgId}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getMessages = createAsyncThunk(
  "messages",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("chat_id");
      const response = await httpAPIInvite(`/messages/chat/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getMessagesUnit = createAsyncThunk(
  "messages/unit",
  async (data, thunkAPI) => {
    try {
      let adminUserId = localStorage.getItem("user_id");
      let prospectUserId = localStorage.getItem("prospect_user_id");
      const response = await httpAPIInvite(
        `/chat/${adminUserId}/${prospectUserId}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createMessage = createAsyncThunk(
  "/message",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPIInvite("/message", data, "POST");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const createChat = createAsyncThunk(
  "/create/chat",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPIInvite("/chat", data, "POST");
      return { ...response.data };
    } catch (error) {
      return error;
    }
  }
);

export const updateUnitApi = createAsyncThunk(
  "update/unit",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI(`/unit`, data, "PUT");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllUnits = createAsyncThunk(
  "allUnits",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("prop_id");
      const response = await httpAPI(`/units/apartment/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllTenants = createAsyncThunk(
  "all/tenants",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(
        `/tenants/user/${id}?isDeleted=false`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getDeletedTenants = createAsyncThunk(
  "deleted/tenants",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPI(
        `/tenants/user/${id}?isDeleted=true`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllTransactions = createAsyncThunk(
  "all/transactions",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPIInvite(
        `/transactions?user_id=${id}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateNotifications = createAsyncThunk(
  "update/notofiation",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPIInvite(`/notification`, data, "PUT");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateApplication = createAsyncThunk(
  "update/application",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPIInvite(`/application`, data, "PUT");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const propertyStatus = createAsyncThunk(
  "property/status",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPIInvite(`/property-status`, data, "PUT");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendInvoiceSms = createAsyncThunk(
  "invoice/sms",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPIInvite(`/send-payment-sms`, data, "PUT");
      return { ...response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllTransactionsByOrg = createAsyncThunk(
  "all/transactionsByOrg",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("org_id");
      const response = await httpAPIInvite(
        `/transactions?org_id=${id}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getNotifications = createAsyncThunk(
  "notifications",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPIInvite(
        `/notifications/admin/${id}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getApplications = createAsyncThunk(
  "/applications",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      const response = await httpAPIInvite(
        `/applications/admin/${id}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOrgApplications = createAsyncThunk(
  "/applications/org",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("user_id");
      let orgId = localStorage.getItem("org_id");
      const response = await httpAPIInvite(
        `/applications/admin/${id}?org_id=${orgId}`,
        data,
        "GET"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserApplication = createAsyncThunk(
  "user/application",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("application_id");
      const response = await httpAPIInvite(`/application/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getApplicantDetails = createAsyncThunk(
  "applicant/user",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("prospectUserId");
      const response = await httpAPI(`/profile/user/${id}`, data, "GET");
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProspectUser = createAsyncThunk(
  "user/prospect",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPIInvite(
        `/details/prospect-user`,
        data,
        "POST"
      );
      return { ...response.data };
    } catch (error) {
      // message.error(error.Error || error.message);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateApplicationList = createAsyncThunk(
  "update/Application/List",
  (data, thunkAPI) => {
    try {
      return { ...data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const counterSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.user = null;
      state.accessToken = "";
      state.user_id = "";
      state.allOrganization = {};
      state.loading = false;
      state.isLoggedIn = false;
      state.error = null;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, { payload }) => {
      if (!payload.error && !payload.Error) {
        state.loading = false;

        localStorage.setItem("idToken", payload.AuthenticationResult?.IdToken);
        localStorage.setItem(
          "accessToken",
          payload.AuthenticationResult?.AccessToken
        );
        localStorage.setItem("user_id", payload.user_id);
        localStorage.setItem("userOrgLength", payload.userOrgLength);
        state.isLoggedIn = true;
        state.user_id = payload.user_id;
        state.accessToken = payload.AuthenticationResult?.AccessToken;
      }
    },
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.isLoggedIn = false;
      localStorage.setItem("isLoggedIn", false);
    },
    [verifySignUp.fulfilled]: (state, { payload }) => {
      if (!payload.error && !payload.Error) {
        state.loading = false;

        localStorage.setItem(
          "idToken",
          payload.loginResult?.AuthenticationResult?.IdToken
        );
        localStorage.setItem(
          "accessToken",
          payload.loginResult?.AuthenticationResult?.AccessToken
        );
        localStorage.setItem("user_id", payload.id);
        localStorage.setItem(
          "userOrgLength",
          payload.loginResult?.userOrgLength
        );
        state.isLoggedIn = true;
        state.accessToken =
          payload.loginResult?.AuthenticationResult?.AccessToken;
      }
    },
    [verifySignUp.pending]: (state) => {
      state.loading = true;
    },
    [verifySignUp.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.isLoggedIn = false;
    },
    [me.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.user_id = payload.id;
      state.loading = false;

      // state.faviconImage = initialState.faviconImage;
      // state.landingPageLogo = initialState.landingPageLogo;
      // state.backgroundImage = initialState.backgroundImage;
      // state.homePageLogo = initialState.homePageLogo;
    },
    [me.pending]: (state) => {
      state.loading = true;
    },
    [me.rejected]: (state, { payload }) => {
      localStorage.clear();
      state.user = null;
      state.accessToken = "";
      state.user_id = "";
      state.loading = false;
      state.isLoggedIn = false;
      state.error = null;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      console.log("resetPassword rejected");
    },
    [getAllOrg.fulfilled]: (state, { payload }) => {
      state.allOrganization = payload;
    },
    [getAllOrg.rejected]: (state, { payload }) => {
      localStorage.clear();
    },
    [getAllOrgId.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [getAllOrgId.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getAllOrgId.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getApartment.fulfilled]: (state, { payload }) => {
      state.apartment = payload;
      state.loading = false;
    },
    [getApartment.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getApartment.rejected]: (state, { payload }) => {
      state.loading = false;
      localStorage.clear();
    },
    [getAllTenants.rejected]: (state, { payload }) => {
      localStorage.clear();
    },
    [getSingleApartment.fulfilled]: (state, { payload }) => {
      state.property = payload;
      state.loading = false;
    },
    [getSingleApartment.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getSingleApartment.rejected]: (state, { payload }) => {
      state.loading = false;
      localStorage.clear();
    },
    [getAllUnits.fulfilled]: (state, { payload }) => {
      state.units = payload;
      state.loading = false;
    },
    [getAllUnits.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getAllUnits.rejected]: (state, { payload }) => {
      state.loading = false;
      // localStorage.clear();
    },
    [getAllPropId.fulfilled]: (state, { payload }) => {
      state.loadingApartment = false;
    },
    [getAllPropId.pending]: (state, { payload }) => {
      state.loadingApartment = true;
    },
    [getAllPropId.rejected]: (state, { payload }) => {
      state.loadingApartment = false;
    },
    [getAllApartment.fulfilled]: (state, { payload }) => {
      state.allApartment = payload;
      state.loading = false;
    },
    [getAllApartment.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getAllApartment.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getAllApartmentsPost.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [getAllApartmentsPost.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getAllApartmentsPost.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getUnitsByUserId.fulfilled]: (state, { payload }) => {
      state.allUnit = payload;
      state.loading = false;
    },
    [getUnitsByUserId.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getUnitsByUserId.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [invoicesByTenant.fulfilled]: (state, { payload }) => {
      state.invoices = payload;
      state.loading = false;
    },
    [invoicesByTenant.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [invoicesByTenant.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.allNotificatios = payload;
      state.loading = false;
    },
    [getNotifications.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getNotifications.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getAllTransactions.rejected]: (state, { payload }) => {
      localStorage.clear();
    },
    [getAllTransactionsByOrg.rejected]: (state, { payload }) => {
      localStorage.clear();
    },
    [getApplicantDetails.fulfilled]: (state, { payload }) => {
      state.getApplicantDetailsLoading = false;
    },
    [getApplicantDetails.pending]: (state, { payload }) => {
      state.getApplicantDetailsLoading = true;
    },
    [getApplicantDetails.rejected]: (state, { payload }) => {
      state.getApplicantDetailsLoading = false;
    },
    [updateApplicationList.fulfilled]: (state, { payload }) => {
      state.allApplications = payload;
    },
    // [getAllOrg.pending]: (state) => {
    //   state.loading = true;
    // },
    // [getAllOrg.rejected]: (state, { payload }) => {
    //   console.log(payload, "me getAllOrg");
    // },
  },
});

// Action creators are generated for each case reducer function
export const { logout } = counterSlice.actions;

export default counterSlice.reducer;
