import {
  StyledFormItem,
  StyledForm,
  StyledForms,
  StyledButton,
} from "./RegistrationStyle";
import React, { useState, useEffect } from "react";
import { Form, Input, message } from "antd";
import { useDispatch } from "react-redux";
import { login } from "../../features/useSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import OtpModal from "./OtpModal";
import {
  register,
  verifyResendOtp,
  verifySignUp,
} from "../../store/reducers/user.reducer";
import { currentDateTime } from "../../utils/fn";

function RegisterFormPersonalDetails({ pageTitle, bgImage }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [signUpUserData, setSignUpUserData] = useState({});
  const [verifyOtp, setVerifyOtp] = useState("");
  const [emailValidation, setEmailValidation] = useState("");
  const [loadings, setloadings] = useState(false);
  const [loadingsOtp, setloadingsOtp] = useState(false);
  const [loadingReOtp, setLoadingReOtp] = useState(false);
  const [invalidCode, setInvalidCode] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [formVal, setFormVal] = useState({});
  const [asetNewOtp, setSetNewOtp] = useState("");

  // Pre-setting Bg image and pagetitle
  useEffect(() => {
    forceUpdate({});
    pageTitle("Create Your Personal Profile");
    bgImage("/images/createProfileBg.png");
  }, []);

  const otpValue = (value) => {
    setVerifyOtp(value);
  };

  useEffect(() => {
    setSignUpUserData({
      ...signUpUserData,
      otp: verifyOtp,
    });
    setSetNewOtp(verifyOtp);
  }, [verifyOtp]);

  // Dispatching action for OTP verify
  useEffect(() => {
    if (asetNewOtp) {
      setloadingsOtp(true);
      dispatch(verifySignUp(signUpUserData)).then(({ payload }) => {
        setInvalidCode(payload);
        setloadingsOtp(false);
      });
      // navigate("/registration/company-details");
      // if (verifyOtp === "123654") {
      //   setModalState(false);
      // }
    }
  }, [asetNewOtp]);

  // const onFinishOtp = async () => {
  //   setloadingsOtp(true);
  //   dispatch(verifySignUp(signUpUserData)).then(({ payload }) => {
  //     setInvalidCode(payload);
  //     setloadingsOtp(false);
  //   });
  //   // navigate("/registration/company-details");
  //   // if (verifyOtp === "123654") {
  //   //   setModalState(false);
  //   // }
  // };

  var formRef = React.createRef();

  // Submit function for form submission
  const onFinish = async (value) => {
    setEmailValidation("");
    value.email = value.email.toLowerCase();
    value.phoneNumber = `+1${value.phoneNumber}`;
    value.createdAt = currentDateTime;
    value.updatedAt = currentDateTime;
    setloadings(true);
    setFormVal(value);
    await dispatch(register(value)).then(({ payload }) => {
      setloadings(false);
      setEmailValidation(payload?.Error || payload?.error);

      if (payload?.Error || payload?.error) {
      } else {
        setModalState(true);
      }
    });

    setSignUpUserData({
      email: value.email,
      otp: "",
      password: value.password,

      user: {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        phonenumber: value.phoneNumber,
        createdAt: currentDateTime,
        updatedAt: currentDateTime,
      },
    });
  };

  useEffect(() => {
    if (emailValidation?.length && emailValidation?.includes("already exist")) {
      formRef.current.setFields([
        {
          name: "email",
          errors: ["Email already exists"],
        },
      ]);
    }
  }, [emailValidation, loadings]);

  // const onFinishOtp = async () => {
  //   setloadingsOtp(true);
  //   await dispatch(verifySignUp(signUpUserData)).then(({ payload }) => {
  //     setInvalidCode(payload);
  //     setloadingsOtp(false);
  //   });
  //   // navigate("/registration/company-details");
  //   // if (verifyOtp === "123654") {
  //   //   setModalState(false);
  //   // }
  // };

  // Submit function to Resend OTP
  const onFinishResendOtp = async () => {
    setLoadingReOtp(true);
    await dispatch(verifyResendOtp({ email: formVal.email })).then(
      ({ payload }) => {
        // setInvalidCode(payload);
        setLoadingReOtp(false);
        if (payload?.Error) {
        } else {
          message.success("OTP is resent to your Email");
        }
      }
    );
    // navigate("/registration/company-details")  ;
    // if (verifyOtp === "123654") {
    //   setModalState(false);
    // }
  };

  // Error handling alert message and successfull message alert
  useEffect(() => {
    if (invalidCode?.Error) {
      setErrorMessage("Invalid code");
    } else if (invalidCode?.id) {
      setModalState(false);
      message.success("User Created Successfully");
    }
  }, [invalidCode]);

  // message to validate form
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  return (
    <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
      <StyledForms>
        <Form
          layout="vertical"
          onFinish={onFinish}
          requiredMark={"optional"}
          validateMessages={validateMessages}
          ref={formRef}
        >
          <StyledFormItem smwh="100">
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true }]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </StyledFormItem>
          {/* Second col */}
          <StyledFormItem smwh="100">
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              className="input__affix"
              name="phoneNumber"
              label="Contact Number"
              rules={[
                {
                  required: true,
                },
                { max: 10, message: "Invalid Phone Number!" },
              ]}
            >
              <Input
                style={{ paddingLeft: "10px" }}
                prefix={`+1`}
                type="number"
                min={1}
                placeholder="Contact Number"
                onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                onWheel={(e) => e.target.blur()}
              />
            </Form.Item>
          </StyledFormItem>
          {/* Third col */}
          <StyledFormItem borderColor="none" smwh="100">
            <Form.Item
              name="password"
              label="Password"
              className="password-w-icon"
              rules={[
                {
                  required: true,
                },
                // {
                //   message: "password should contain minimum 8 characters",
                // },
                {
                  min: 8,
                  pattern: new RegExp(
                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                  ),
                  message:
                    "Use 8 and more characters with a mix of letters, numbers & symbols",
                },
              ]}
            >
              <Input.Password type="password" placeholder="Password" />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                { required: true, message: "Please Confirm your password." },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match."
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password type="password" placeholder="Confirm Password" />
            </Form.Item>
          </StyledFormItem>
          <Form.Item>
            <StyledButton htmlType="submit" loading={loadings}>
              Next
            </StyledButton>
          </Form.Item>
        </Form>
      </StyledForms>
      <OtpModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        // from={adminRoutes.TEAM}
        // onFinishOtp={onFinishOtp}
        onSubmit={() => setModalStates(true)}
        otpValue={otpValue}
        errorMessage={errorMessage}
        loadingsOtp={loadingsOtp}
        loadingReOtp={loadingReOtp}
        onFinishResendOtp={onFinishResendOtp}
      />
    </StyledForm>
  );
}

export default RegisterFormPersonalDetails;
