import React, { useEffect, useState } from "react";
import { TableBoardWrap } from "./DashboardStyle";
import { Container } from "../../ui/Layout/Container";
import { Row, Col, Table } from "antd";
import { useLocation } from "react-router";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { ContainerWrap } from "../../ui/Layout/ContainerWrap";

function TableBoard() {
  const { width } = useWindowSize();
  const location = useLocation();
  const [boxWidth, setBoxWidth] = useState(120);
  const [smallBoxWidth, setSmallBoxWidth] = useState(100);
  const [height, setHeight] = useState("285px");

  // Setting boxwidth, smallboxwidth, Height w.r.t width of the screen 
  useEffect(() => {
    if (width < 1550) {
      setBoxWidth(90);
      setSmallBoxWidth(85);
      setHeight("0px");
    } else {
      setBoxWidth(120);
      setSmallBoxWidth(100);
      setHeight("285px");
    }
  }, [width, location.pathname]);

  const columns = [
    {
      title: "",
      width: boxWidth,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },

    { title: "JAN 2021", width: smallBoxWidth, dataIndex: "JAN", key: "1" },
    { title: "FEB 2021", width: smallBoxWidth, dataIndex: "FEB", key: "2" },
    { title: "MAR 2021", width: smallBoxWidth, dataIndex: "MAR", key: "3" },
    { title: "APR 2021", width: smallBoxWidth, dataIndex: "APR", key: "4" },
    { title: "MAY 2021", width: smallBoxWidth, dataIndex: "MAY", key: "5" },
    { title: "JUN 2021", width: smallBoxWidth, dataIndex: "JUN", key: "6" },
    { title: "JUL 2021", width: smallBoxWidth, dataIndex: "JUL", key: "7" },
    { title: "AUG 2021", width: smallBoxWidth, dataIndex: "AUG", key: "8" },
    { title: "SEP 2021", width: smallBoxWidth, dataIndex: "SEP", key: "9" },
    { title: "OCT 2021", width: smallBoxWidth, dataIndex: "OCT", key: "10" },
    { title: "NOV 2021", width: smallBoxWidth, dataIndex: "NOV", key: "11" },
    { title: "DEC 2021", width: smallBoxWidth, dataIndex: "DEC", key: "12" },
  ];

  // Dummy Price history data
  const data = [
    {
      key: "1",
      name: "My Property",
      JAN: "70 %",
      FEB: "98 %",
      MAR: "78 %",
      APR: "86 %",
      MAY: "90 %",
      JUN: "68 %",
      JUL: "2 %",
      AUG: "70 %",
      SEP: "89 %",
      OCT: "98 %",
      NOV: "100 %",
      DEC: "85 %",
    },
    {
      key: "2",
      name: "Comp Set",
      JAN: "68 %",
      FEB: "86 %",
      MAR: "76 %",
      APR: "75 %",
      MAY: "73 %",
      JUN: "70 %",
      JUL: "98 %",
      AUG: "78 %",
      SEP: "86 %",
      OCT: "70 %",
      NOV: "90 %",
      DEC: "100 %",
    },
    {
      key: "3",
      name: "Difference",
      JAN: "2 %",
      FEB: "3 %",
      MAR: "3 %",
      APR: "12 %",
      MAY: "17 %",
      JUN: "19 %",
      JUL: "11 %",
      AUG: "23 %",
      SEP: "18 %",
      OCT: "29 %",
      NOV: "4 %",
      DEC: "18 %",
    },
    {
      key: "4",
      name: "Index",
      JAN: "51.4",
      FEB: "51.4",
      MAR: "55.9",
      APR: "85.6",
      MAY: "45.5",
      JUN: "51.9",
      JUL: "78.9",
      AUG: "99.6",
      SEP: "78.0",
      OCT: "77.9",
      NOV: "58.6",
      DEC: "42.2",
    },
  ];

  return (
    <TableBoardWrap>
      <ContainerWrap brRadius="5px" minH={height} bgColor="#ffffff">
        <Row justify="space-between">
          <Col span={24}>
            <Table
              bordered
              pagination={false}
              columns={columns}
              dataSource={data}
              scroll={{ x: 1100 }}
            />
          </Col>
        </Row>
      </ContainerWrap>
    </TableBoardWrap>
  );
}

export default TableBoard;
