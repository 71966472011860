import { Col, Divider, Row, Badge, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { Container } from "../../ui/Layout/Container";
import { Heading } from "../../ui/Heading/Heading";
import {
  NameStatusWrap,
  StatusWrap,
  ApplicationProccess,
  UnitDetailsApplication,
} from "./ApplicationsStyle";
import { AvenewImgSvg, LeaseTermSvg, SqFtSvg } from "../../assets/svgs";
import { UserAvatar } from "../../ui/Avatar";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Steps } from "antd";
import { ContainerWrap } from "../../ui/Layout/ContainerWrap";
import styled from "styled-components";
import Approval from "./Approval";
import SendLease from "./SendLease";
import LeaseSigned from "./LeaseSigned";
import DepositSubmitted from "./DepositSubmitted";
import { useDispatch } from "react-redux";
import {
  createChat,
  getUserApplication,
  getApplicantDetails,
} from "../../store/reducers/user.reducer";

import { IoIosBed } from "react-icons/io";
import { FaBath } from "react-icons/fa";
import UnitDetailModal from "./UnitDetailModal";
import { HiDownload } from "react-icons/hi";
import ProfilePdf from "./application-pdf/ProfilePdf";
import * as FileSaver from "file-saver";
import { pdf } from "@react-pdf/renderer";
import ApplicantDetails from "./ApplicantDetails";
import JSZip from "jszip";
import {
  Capitalize,
  dateConverter,
  formatDate,
  upperCase,
} from "../../utils/fn";
import { BsArrowLeft } from "react-icons/bs";

function Applications() {
  const { Step } = Steps;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedApplication, setSelectedApplication] = useState({});
  const [applicantData, setApplicantData] = useState({});
  const [selectedApplicationWithUnit, setSelectedApplicationWithUnit] =
    useState({});
  const [selectedApplicationsWithUnitArr, setselectedApplicationsWithUnitArr] =
    useState([]);
  const [allApplicationsArr, setAllApplicationsArr] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [searchParams] = useSearchParams();

  // To get unit details
  useEffect(() => {
    setIsloading(true);
    if (localStorage.getItem("application_id")) {
      dispatch(getUserApplication()).then(({ payload }) => {
        setSelectedApplicationWithUnit(payload[0]);
        setSelectedApplication(payload[0]);
      });
    } else {
    }
    setIsloading(false);
  }, [searchParams, localStorage.getItem("application_id")]);

  // Switching Bg color with respect to application status
  const statusBgColor = (status) => {
    switch (status) {
      case "Unseen":
        return "#FFEADE";
      case "Viewed":
        return "#FFF3CA";
      case "Approved":
        return "#D0FFDD";
      case "Denied":
        return "#d7dadd";
      default:
        return "#ffffff";
    }
  };

  // Switching Font color with respect to application status
  const statusFtColor = (status) => {
    switch (status) {
      case "Unseen":
        return "#F78341";
      case "Viewed":
        return "#FFC123";
      case "Approved":
        return "#56C274";
      case "Denied":
        return "#2B353F";
      default:
        return "#aaaaaa";
    }
  };

  // Selecting or to set tab upon change
  const onChange = (value) => {
    setCurrentTab(value);
  };

  // Setting current tab according to condition
  // useEffect(() => {
  //   if (Object.keys(selectedApplication)?.length) {
  //     if (
  //       selectedApplication.leaseAgreementText ||
  //       selectedApplication?.leaseAgreementDoc
  //     ) {
  //       setCurrentTab(3);
  //     } else if (selectedApplication.status) {
  //       setCurrentTab(2);
  //     } else {
  //       setCurrentTab(0);
  //     }
  //   }
  // }, [selectedApplication]);

  // Function to update applications
  const updatedApplication = (application) => {
    setSelectedApplication(application);

    let editObj = [...allApplicationsArr];
    let changedObj = editObj.filter((item) => {
      return item["id"] === application.id;
    });
    let eValue = { ...changedObj[0] };
    eValue["status"] = application.status;
    const newUpdatedArr = editObj.map((val) => {
      if (val.id === eValue.id) {
        return eValue;
      } else {
        return val;
      }
    });
    setAllApplicationsArr(newUpdatedArr);
  };

  // Function to redirect to message page w.r.t applicant
  const onMsgApplicant = (data) => {
    let conectChat = {};
    conectChat.prospectUserId = data.prospectUserId;
    conectChat.applicationChatFlag = true;
    conectChat.unit_id = data.unitId;
    conectChat.adminUserId = data.adminUserId;
    dispatch(createChat(conectChat)).then(({ payload }) => {
      if (payload.chat) {
        navigate({
          pathname: `/messages`,
          search: createSearchParams({
            chatId: payload.chat.id,
          }).toString(),
        });
        localStorage.setItem("chat_id", payload.chat.id);
        localStorage.setItem(
          "prospect_user_id",
          payload?.chat?.prospectUser?.id
        );
      } else if (payload.Error) {
        message.info("Tenant hasn't responded to your invitation yet.");
      }
    });
  };

  // To get applicant details
  useEffect(() => {
    if (localStorage.getItem("prospectUserId")) {
      dispatch(getApplicantDetails()).then(({ payload }) => {
        if (payload) {
          setApplicantData(payload);
        }
      });
    } else {
    }
  }, [searchParams, localStorage.getItem("prospectUserId")]);

  // Converting Object to Array
  useEffect(() => {
    if (
      selectedApplicationWithUnit &&
      Object.keys(selectedApplicationWithUnit).length
    ) {
      setselectedApplicationsWithUnitArr([selectedApplicationWithUnit]);
    } else {
      setselectedApplicationsWithUnitArr([]);
    }
  }, [selectedApplicationWithUnit]);

  // Get End-date by adding no of months to start-date
  function addMonths(date = new Date(), months) {
    const dateCopy = new Date(date);
    dateCopy.setMonth(dateCopy.getMonth() + months);
    return formatDate(dateCopy);
  }

  const unitPresent = Object.keys(selectedApplication?.unitDetail || {}).length;

  // download invoice (PFD format)
  // const downloadInvoice = async () => {
  //   const blob = await pdf(
  //     <ProfilePdf applicationProcessData={applicantData} />
  //   ).toBlob();
  //   FileSaver.saveAs(blob, `Profile Data`);
  // };

  const downloadInvoice = async () => {
    const zip = new JSZip();
    const pdfBlob = await pdf(
      <ProfilePdf applicationProcessData={applicantData} />
    ).toBlob();

    zip.file(
      `${applicantData?.user_data?.firstName} ${applicantData?.user_data?.lastName}.pdf`,
      pdfBlob
    );

    // Generate the zip file
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Save the zip file
    FileSaver.saveAs(
      zipBlob,
      `${Capitalize(applicantData?.user_data?.firstName)} ${Capitalize(
        applicantData?.user_data?.lastName
      )}.zip`
    );
  };

  return (
    <>
      {isLoading ? (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      ) : Object.keys(selectedApplication || {}).length ? (
        <ApplicationsWrap>
          {!unitPresent ? (
            ""
          ) : (
            <AppWrap>
              <WrapContent>
                <h2>You cannot process. Unit is deleted</h2>
                <p>
                  simply dummy text of the printing and typesetting industry.
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s,
                </p>
              </WrapContent>
            </AppWrap>
          )}
          {/* <FetureSoon /> */}
          <Row gutter={[30, 30]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Heading>
                <BsArrowLeft
                  style={{ marginRight: "20px" }}
                  onClick={() => navigate("/application")}
                />
                <h2>Application Details</h2>
              </Heading>
              <Badge.Ribbon
                text={
                  selectedApplication?.status === 0
                    ? "Unseen"
                    : selectedApplication?.status === 1
                    ? "Viewed"
                    : selectedApplication?.status === 2
                    ? "Approved"
                    : "Denied"
                }
                color={statusFtColor(
                  selectedApplication?.status === 0
                    ? "Unseen"
                    : selectedApplication?.status === 1
                    ? "Viewed"
                    : selectedApplication?.status === 2
                    ? "Approved"
                    : "Denied"
                )}
              >
                <ContainerWrap
                  minH="auto"
                  padding="0"
                  bgColor="#ffffff"
                  brRadius="10px"
                >
                  <NameStatusWrap>
                    <div className="avatar__wrap">
                      <UserAvatar
                        fullName={`${
                          selectedApplication?.userDetails?.firstName || ""
                        } ${selectedApplication?.userDetails?.lastName || ""}`}
                        className="user__icon"
                        src={
                          selectedApplication?.userDetails?.image
                            ? selectedApplication?.userDetails?.image
                            : ""
                        }
                      />
                      <span className="avatarName__wrap">
                        {upperCase(selectedApplication?.userDetails?.firstName)}{" "}
                        {upperCase(selectedApplication?.userDetails?.lastName)}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="share__icon"
                    >
                      {/* <HiDownload onClick={() => downloadInvoice()} />
                      <span
                        className="share__class"
                        onClick={() => downloadInvoice()}
                      >
                        Download user data
                      </span> */}
                      <StatusWrap
                        bgColor={statusBgColor(
                          selectedApplication?.status === 0
                            ? "Unseen"
                            : selectedApplication?.status === 1
                            ? "Viewed"
                            : selectedApplication?.status === 2
                            ? "Approved"
                            : "Denied"
                        )}
                        ftColor={statusFtColor(
                          selectedApplication?.status === 0
                            ? "Unseen"
                            : selectedApplication?.status === 1
                            ? "Viewed"
                            : selectedApplication?.status === 2
                            ? "Approved"
                            : "Denied"
                        )}
                      >
                        <span>
                          {selectedApplication?.status === 0
                            ? "Unseen"
                            : selectedApplication?.status === 1
                            ? "Viewed"
                            : selectedApplication?.status === 2
                            ? "Approved"
                            : "Denied"}
                        </span>
                      </StatusWrap>
                    </div>
                  </NameStatusWrap>
                  <UnitDetailsApplication
                    onClick={() => {
                      setModalState(true);
                    }}
                  >
                    <Row justify="space-between">
                      <Col>
                        <Row style={{ alignItems: "center" }}>
                          <Col className="svg__border">
                            {selectedApplicationWithUnit?.unitDetails?.images &&
                            selectedApplicationWithUnit?.unitDetails?.images
                              ?.length ? (
                              <>
                                {selectedApplicationWithUnit?.unitDetails?.images.map(
                                  (img, idx) => (
                                    <>
                                      {img.isCover === true ? (
                                        <img alt="example" src={img.path} />
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )
                                )}
                              </>
                            ) : (
                              <AvenewImgSvg
                                className="svg__border"
                                style={{
                                  background: "#eeeeee",
                                  height: "175px",
                                }}
                              />
                            )}
                          </Col>
                          <Col>
                            <p>
                              {
                                selectedApplicationWithUnit?.unitDetails
                                  ?.unit_name
                              }
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        style={{
                          alignItems: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Row
                          justify="space-between"
                          style={{
                            alignItems: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <Col className="card__bottom">
                            <p>
                              <LeaseTermSvg className="icon" />
                            </p>
                            <span>
                              {dateConverter(selectedApplication?.selectedDate)}
                              {"-"}
                              {addMonths(
                                selectedApplication?.selectedDate,
                                parseInt(selectedApplication?.leaseLength)
                              )}
                            </span>
                          </Col>
                          <Col className="card__bottom">
                            <p>
                              <IoIosBed className="icon" />
                            </p>
                            <span>
                              Bed :{" "}
                              {selectedApplicationWithUnit?.unitDetails?.bed}
                            </span>
                          </Col>
                          <Col className="card__bottom">
                            <p>
                              <FaBath />
                            </p>
                            <span>
                              Bath :{" "}
                              {selectedApplicationWithUnit?.unitDetails?.bath}
                            </span>
                          </Col>
                          <Col className="card__bottom">
                            <p>
                              <SqFtSvg />
                            </p>
                            <span>
                              Sq ft :{" "}
                              {selectedApplicationWithUnit?.unitDetails?.sqft}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </UnitDetailsApplication>
                  <ApplicationProccess>
                    <Steps
                      direction="horizontal"
                      size="small"
                      onChange={onChange}
                      current={currentTab}
                    >
                      {/* <Step
                        className="application__width"
                        title="Applicant Details"
                      /> */}
                      <Step
                        className="approval__width"
                        title="Appplicant Details"
                      />
                      <Step
                        disabled={
                          selectedApplication?.status === 2 ? false : true
                        }
                        className="lease__width"
                        title="Send Lease & Deposit Total"
                      />
                      <Step
                        disabled={
                          selectedApplication?.status === 2 ? false : true
                        }
                        className="signed__width"
                        title="Lease Confirmed"
                      />
                      <Step
                        disabled={
                          selectedApplication?.status === 2 ? false : true
                        }
                        className="application__width"
                        title="Deposit Submitted"
                      />
                    </Steps>
                  </ApplicationProccess>
                  <div style={{ padding: "0px 13px" }}>
                    <Divider style={{ margin: "0", background: "#dddddd" }} />
                  </div>
                  {
                    /* currentTab === 0 ? (
                    <ApplicantDetails applicantData={applicantData} />
                  ) :  */
                    currentTab === 0 ? (
                      <Approval
                        updatedApplication={updatedApplication}
                        selectedApplication={selectedApplication}
                        onMsgApplicant={onMsgApplicant}
                        applicantData={applicantData}
                        setCurrentTab={setCurrentTab}
                      />
                    ) : currentTab === 1 ? (
                      <SendLease
                        updatedApplication={updatedApplication}
                        selectedApplication={selectedApplication}
                        applicantData={applicantData}
                        onMsgApplicant={onMsgApplicant}
                      />
                    ) : currentTab === 2 ? (
                      <LeaseSigned
                        onMsgApplicant={onMsgApplicant}
                        applicantData={applicantData}
                        selectedApplication={selectedApplication}
                      />
                    ) : currentTab === 3 ? (
                      <DepositSubmitted
                        applicantData={applicantData}
                        selectedApplication={selectedApplication}
                      />
                    ) : (
                      ""
                    )
                  }
                </ContainerWrap>
              </Badge.Ribbon>
            </Col>
          </Row>
          <UnitDetailModal
            modalState={modalState}
            onCancel={(value) => setModalState(value)}
            selectedApplicationsWithUnitArr={selectedApplicationsWithUnitArr}
          />
        </ApplicationsWrap>
      ) : (
        <Container padding="0 50px 0px 330px">
          <div
            style={{
              position: "relative",
              minHeight: "calc(100vh - 80px)",
            }}
          >
            <Spin className="ant__spin" size="large" />
          </div>
        </Container>
      )}
    </>
  );
}

export default Applications;

export const ApplicationsWrap = styled.div`
  min-height: ${({ minH }) => minH || "calc(100vh - 80px)"};
  padding: ${({ padding }) => padding || "50px 50px 0px 330px"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#eeeeee")};
  border-radius: ${({ brRadius }) => (brRadius ? brRadius : "0")};

  .ant-ribbon {
    display: none;
  }
  .applicant__wrap {
    position: fixed;
    width: 19%;
  }

  @media (max-width: 1550px) {
    padding: ${({ padding }) => padding || "40px 40px 0 265px"};
    min-height: ${({ minH }) => minH || "calc(100vh - 65px)"};
  }
  @media (max-width: 1200px) {
    .applicant__wrap {
      position: static;
      width: auto;
    }
  }

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "15px"};
  }
  @media (max-width: 420px) {
    .ant-ribbon,
    .ant-ribbon-text {
      display: block;
      background-color: {(status)=> statusBgColor};
      color: {(status)=> statusFtColor};
    }
  }
`;

const AppWrap = styled.div`
  width: 100%;
  position: fixed;
  top: 80px;
  left: 280px;
  right: 0;
  bottom: 0;
  z-index: 1000;
  height: 100%;
  background: rgba(235, 235, 235, 0.2);
  backdrop-filter: blur(5px);

  @media (max-width: 1550px) {
    top: 65px;
    left: 225px;
  }
`;

const WrapContent = styled.div`
  background: #ffffff;
  padding: 65px 35px 50px 35px;
  width: 37%;
  text-align: center;
  margin: auto;
  position: absolute;
  top: 40%;
  left: 40%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #2b353f;
    width: 74%;
    margin: auto;
    margin-bottom: 25px;
  }
  p {
    margin: auto;
    width: 80%;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #aaaaaa;
  }

  @media (max-width: 1550px) {
    width: 35%;

    h2 {
      font-size: 32px;
      line-height: 40px;
      width: 59%;
      margin: auto;
      margin-bottom: 25px;
    }
    p {
      margin: auto;
      width: 95%;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      color: #aaaaaa;
    }
  }
`;
