import { Spin } from "antd";
import React from "react";
import styled from "styled-components";

function PhoneSearchCompo() {
  return (
    <DashboardWrap>
      <WrapContant>
        <div
          style={{
            position: "relative",
            minHeight: "calc(100vh - 80px)",
          }}
        >
          <Spin className="ant__spin" size="large" />
        </div>
      </WrapContant>
    </DashboardWrap>
  );
}

export default PhoneSearchCompo;

const WrapContant = styled.div`
  text-align: center;
  margin: auto;
  position: absolute;
  top: 47%;
  left: 47%;
`;

const DashboardWrap = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  height: 100%;
  background: rgba(235, 235, 235, 0.2);
  backdrop-filter: blur(5px);
`;
