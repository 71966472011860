import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  message,
  Checkbox,
  Row,
  Col,
  Select,
  Divider,
  Radio,
} from "antd";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
  StyledTitle,
} from "../createPersonalProfile/RegistrationStyle";
import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";

function UserSettings({
  pageTitle,
  isModal,
  onCancel,
  apartValue,
  orgData,
  onFormChange = console.log,
}) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const CheckboxGroup = Checkbox.Group;
  const { user } = useSelector((state) => state.user);
  const { allOrganization } = useSelector((state) => state.user);
  const { apartment } = useSelector((state) => state.user);
  const { allApartment } = useSelector((state) => state.user);
  const { allUnit } = useSelector((state) => state.user);
  const [state, setState] = useState([]);
  const [checkedList, setCheckedList] = useState("");
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [viewModal2, setViewModal2] = useState(false);
  const [orgName, setOrgName] = useState([]);
  const [units, setUnits] = useState([]);

  // Push Object organization details value to array
  useEffect(() => {
    if (allOrganization && Object.values(allOrganization)) {
      setOrgName(Object.values(allOrganization));
    }
    if (allApartment && Object.values(allApartment)) {
      setUnits(Object.values(allApartment));
    }
  }, [allOrganization, allApartment]);

  // const plainOptions = [
  //   "Appartment 1",
  //   "Appartment 2",
  //   "Appartment 3",
  //   "Appartment 4",
  //   "Appartment 5",
  // ];
  // const plainOptions1 = [
  //   "Appartment 6",
  //   "Appartment 7",
  //   "Appartment 8",
  //   "Appartment 9",
  //   "Appartment 10",
  // ];
  // const plainOptions2 = [
  //   "Appartment 11",
  //   "Appartment 12",
  //   "Appartment 13",
  //   "Appartment 14",
  //   "Appartment 15",
  // ];
  // const allCheck = [
  //   "Appartment 1",
  //   "Appartment 2",
  //   "Appartment 3",
  //   "Appartment 4",
  //   "Appartment 5",
  //   "Appartment 6",
  //   "Appartment 7",
  //   "Appartment 8",
  //   "Appartment 9",
  //   "Appartment 10",
  //   "Appartment 11",
  //   "Appartment 12",
  //   "Appartment 13",
  //   "Appartment 14",
  //   "Appartment 15",
  // ];

  // const onChange = (list) => {
  //   setCheckedList(list);
  //   setIndeterminate(!!list.length && list.length < plainOptions.length);
  //   setCheckAll(list.length === plainOptions.length);
  // };

  // const onCheckAll = (e) => {
  //   setCheckedList(e.target.checked ? allCheck : []);
  //   setIndeterminate(false);
  //   setCheckAll(e.target.checked);
  // };
  // const onCheckAllChange = (e) => {
  //   setCheckedList(e.target.checked ? plainOptions : []);
  //   setIndeterminate(false);
  // };
  // const onCheckAllChange1 = (e) => {
  //   setCheckedList(e.target.checked ? plainOptions1 : []);
  //   setIndeterminate(false);
  // };
  // const onCheckAllChange2 = (e) => {
  //   setCheckedList(e.target.checked ? plainOptions2 : []);
  //   setIndeterminate(false);
  // };

  // Submit for onclick event
  const onFinish = (value) => {
    form.resetFields();
    setState([]);
    message.success("User Invited Successfully");
    onFormChange(false);
  };

  // Message to validate form
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    if (checkedList) {
      form.setFieldsValue({
        inviteORG: checkedList,
      });
    }
  }, [form, checkedList]);

  const onCheckAllChangeNew = (e) => {
    // setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const radioStyle = {
    paddingBottom: "30px",
    // fontFamily: "Product Sans",
    // fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#2B353F",
  };

  return (
    <UserSettingWrap>
      <StyledForm wh={isModal ? "100" : "50"} smwh="100" xswh="100" xxswh="100">
        <StyledForms>
          {isModal && <StyledTitle>Invite User</StyledTitle>}
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            <Radio.Group defaultValue="Manager" style={radioStyle}>
              <Radio value="Manager">Manager</Radio>
              <Radio value="User">User</Radio>
            </Radio.Group>
            <StyledFormItem smwh="100">
              <Form.Item
                name="firstName"    
                // label="First Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                name="lastName"
                // label="Last Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </StyledFormItem>
            <StyledFormItem wh="100" smwh="100">
              <Form.Item
                name="email"
                // label="Email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </StyledFormItem>
            <Form.Item>
              {orgName?.map((org, index) => (
                <Row key={index}>
                  <Col
                    xl={{ span: 24 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    style={{
                      paddingBottom: "10px",
                      justifyContent: "space-between",
                    }}
                    onMouseOver={() => setViewModal(true)}
                    onMouseLeave={() => setViewModal(false)}
                    className="hover-to-show-link"
                  >
                    <SelectWrap>
                      <Checkbox value={checkedList}>{org.org_name}</Checkbox>

                      {/* <Select
                        defaultValue="Admin"
                        bgColor="#eeeeee"
                        suffixIcon={<IoIosArrowDown />}
                      >
                        <Option value="Admin">Admin</Option>
                        <Option value="User">User</Option>
                      </Select> */}
                    </SelectWrap>

                    <DropdownWrap
                      dv={viewModal ? "block" : "none"}
                      className="hover-to-show"
                    >
                      {units?.map((ele, index) => (
                        <SelectWrap
                          key={index}
                          padding={"2px 0"}
                          className="hover__visible"
                        >
                          <Checkbox
                            value={checkedList}
                            style={{ paddingLeft: "10px" }}
                          >
                            {ele.unit_name}
                          </Checkbox>
                          {/* <Select
                            defaultValue="Admin"
                            // onChange={}
                            suffixIcon={<IoIosArrowDown />}
                            bgColor="#eeeeee"
                          >
                            <Option value="Admin">Admin</Option>
                            <Option value="User">User</Option>
                          </Select> */}
                        </SelectWrap>
                      ))}
                    </DropdownWrap>
                  </Col>
                </Row>
              ))}
              {/* <Row>
                <Col
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{
                    paddingBottom: "10px",
                    justifyContent: "space-between",
                  }}
                  onMouseOver={() => setViewModal2(true)}
                  onMouseLeave={() => setViewModal2(false)}
                  className="hover-to-show-link"
                >
                  <SelectWrap>
                    <Checkbox value={checkedList}>XYZ organization</Checkbox>
                    <Select
                      defaultValue="Admin"
                      // onChange={}
                      suffixIcon={<IoIosArrowDown />}
                      bgColor="#eeeeee"
                    >
                      <Option value="Admin">Admin</Option>
                      <Option value="User">User</Option>
                    </Select>
                  </SelectWrap>

                  <DropdownWrap
                    className="hover-to-show"
                    dv={viewModal2 ? "block" : "none"}
                  >
                    {plainOptions.map((appartment, index) => (
                      <SelectWrap key={index} padding={"2px 0"}>
                        <Checkbox
                          value={checkedList}
                          style={{ paddingLeft: "10px" }}
                        >
                          {appartment}
                        </Checkbox>
                        <Select
                          defaultValue="Admin"
                          // onChange={}
                          suffixIcon={<IoIosArrowDown />}
                          bgColor="#eeeeee"
                        >
                          <Option value="Admin">Admin</Option>
                          <Option value="User">User</Option>
                        </Select>
                      </SelectWrap>
                    ))}
                  </DropdownWrap>
                </Col>
              </Row> */}

              {/* <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                Check all
              </Checkbox>
              <Divider />
              <CheckboxGroup
                options={plainOptions}
                value={checkedList}
                onChange={onChange}
              /> */}

              {/* <Row>
                <Col
                  xl={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  style={{
                    paddingBottom: "10px",
                    justifyContent: "space-between",
                  }}
                  onMouseOver={() => setViewModal2(true)}
                  onMouseLeave={() => setViewModal2(false)}
                  className="hover-to-show-link"
                >
                  <SelectWrap>
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChangeNew}
                      checked={checkAll}
                    >
                      XYZ organization New
                    </Checkbox>

                    <Select
                      defaultValue="Admin"
                      // onChange={}
                      suffixIcon={<IoIosArrowDown />}
                      bgColor="#eeeeee"
                    >
                      <Option value="Admin">Admin</Option>
                      <Option value="User">User</Option>
                    </Select>
                  </SelectWrap>

                  <DropdownWrap
                    className="hover-to-show"
                    dv={viewModal2 ? "block" : "none"}
                    value={checkedList}
                    onChange={onChange}
                  >
                    <DropdownWrap options={plainOptions}>
                      {plainOptions.map((appartment, index) => (
                        <SelectWrap key={index} padding={"2px 0"}>
                          <Checkbox
                            value={checkedList}
                            style={{ paddingLeft: "10px" }}
                          >
                            {appartment}
                          </Checkbox>
                          <Select
                            defaultValue="Admin"
                            // onChange={}
                            suffixIcon={<IoIosArrowDown />}
                            bgColor="#eeeeee"
                          >
                            <Option value="Admin">Admin</Option>
                            <Option value="User">User</Option>
                          </Select>
                        </SelectWrap>
                      ))}
                    </DropdownWrap>
                  </DropdownWrap>
                </Col>
              </Row> */}
              {/* <Row>
                <Col>
                  <span>MORE</span>
                </Col>
              </Row> */}
            </Form.Item>
            <Form.Item>
              <StyledButton htmlType="submit">SEND INVITE</StyledButton>
            </Form.Item>
          </Form>
        </StyledForms>
      </StyledForm>
    </UserSettingWrap>
  );
}

export default UserSettings;

const UserSettingWrap = styled.div`
  .hover-to-show {
    overflow: hidden;
    max-height: 0px;
    -o-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .hover-to-show-link:hover .hover-to-show {
    max-height: 500px;
  }
`;

const SelectWrap = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding || "10px 0"};
  justify-content: space-between;
  .hover__visible {
    display: none;
  }
  .ant-select {
    width: 25%;
  }
  .ant-select-arrow {
    font-size: 18px;
    color: #777;
  }
  .ant-select-selection-placeholder {
    color: #777777 !important;
  }
  .ant-checkbox-wrapper {
    color: ${({ theme }) => theme.colors.forth};
  }
  :hover {
    border-color: #aaaaaa !important;
  }

  .ant-select-selector {
    position: relative;
    background-color: ${({ bgColor }) => bgColor || "#fff"} !important;
    border: none !important;
    border-radius: 5px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 25px !important;
    align-items: center;
  }
  .ant-select:hover {
    box-shadow: 0 0 0 2px rgb(0, 0, 0, 0.1) !important;
  }
  ,
  .ant-select-selector {
    border-color: #aaaaaa !important;
  }
  .ant-select-focused {
    box-shadow: 0 0 0 2px rgb(0, 0, 0, 0.1) !important;
  }
`;
const DropdownWrap = styled.div`
  ${"" /* display: ${({ dv }) => dv || "none"}; */}
  overflow: hidden;
  max-height: 0px;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
`;
