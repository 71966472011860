import React, { useEffect, useState } from "react";
import { Avatar, Col, message, Row } from "antd";
import styled from "styled-components";
import { useLocation } from "react-router";
import { UserAvatar } from "../../ui/Avatar";
import { Container } from "../../ui/Layout/Container";
import { AddNewApr } from "../listings/Listingstyle";
import MyAccountLayout from "./MyAccountLayout";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import { onEdit } from "../../features/useSlice";
import { editProfile, me } from "../../store/reducers/user.reducer";
import { FileUploadAvtar } from "../../ui/Form/FileUploadAvtar";
import { useBeforeunload } from "react-beforeunload";

function MyAccount() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [isEditTab, setIsEditTab] = useState(false);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [state, setState] = useState({});

  // on tab switch (personal details -> organization details)
  const onTabChangeFun = () => {
    setIsEditTab(false);
  };

  // To set editTab open or true
  const isEdit = (value) => {
    setIsEditTab(!isEditTab);
  };

  // To set scroll to zero
  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(me());
  }, []);

  // Css property for avatar
  const avatarProps = {
    size: 137,
    background: "#ffffff",
    border: "5px solid",
    // src: "https://joeschmoe.io/api/v1/random",
    fullName: "Mark Jecno",
  };

  // const editClickHandler = () => {
  //   setIsEdit(true);
  // };

  // Function before upload
  const beforeUpload = async (file) => {
    const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
      return false;
    }

    // To convert base size of file
    const convertToBase64 = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
      });
    };

    const convertImg = await convertToBase64(file);

    setState((s) => ({
      ...s,
      image: convertImg,
      path: URL.createObjectURL(file),
      name: file.name,
    }));

    // getBase64(file, (base64) =>
    //   setState((s) => ({
    //     ...s,
    //     image_src: base64,
    //     image: file,
    //   }))
    // );
    return false;
  };

  // To save data before automatic page reload
  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    setState((s) => ({
      ...s,
      path: user?.image?.path ? user?.image?.path : "",
    }));
  }, [user]);

  return (
    <MyAccountMainWraper>
      <MyAccountMainWrap>
        <RectangleIMG>
          <img src="/images/RectangleIMG.png" />
          {/* <RectangleIMG></RectangleIMG> */}
        </RectangleIMG>
        <MyAccountWrap>
          {isEditTab ? (
            <MyAccountWrapLeft>
              <FileUploadAvtar
                uploadSize={"108px"}
                cameraIcon={"14px"}
                name="avatar"
                className="avatar-uploader"
                showUploadList={false}
                size="106"
                beforeUpload={beforeUpload}
                userIcon={"78px"}
                offset={[-6, 100]}
                image_src={state?.path}
                username={`${user?.firstName || ""} ${user?.lastName || ""}`}
                badgeSize={"35px"}
              />
            </MyAccountWrapLeft>
          ) : (
            <MyAccountWrapLeft>
              <UserAvatar
                className="userName__icon"
                size="100"
                src={state?.path}
                fullName={`${user?.firstName || ""} ${user?.lastName || ""}`}
              />
            </MyAccountWrapLeft>
          )}
          <MyAccountWrapRight>
            <Row justify="space-between">
              <Col xs={18} sm={16} md={16} lg={12} xl={10} xxl={10}>
                <h2>
                  {user?.firstName.charAt(0).toUpperCase() +
                    user?.firstName.slice(1)}{" "}
                  {user?.lastName.charAt(0).toUpperCase() +
                    user?.lastName.slice(1)}
                </h2>
              </Col>

              {/* <Col>
                <AddNewApr hg="40px" onClick={isEdit}>
                  Edit
                </AddNewApr>
              </Col> */}
            </Row>
          </MyAccountWrapRight>
        </MyAccountWrap>
        <MyAccountLayout
          isEdit={isEdit}
          imgState={state}
          onTabChangeFun={onTabChangeFun}
          isEditTab={isEditTab}
        />
      </MyAccountMainWrap>
    </MyAccountMainWraper>
  );
}

export default MyAccount;

export const StyledUploadEditAvtarWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  ${"" /* top: -70px; */}

  @media (max-width: 575px) {
    justify-content: center;
    left: 0;
    right: 0;
  }
`;

export const MyAccountMainWraper = styled.div`
  min-height: ${({ minH }) => minH || "calc(100vh - 80px)"};
  padding: ${({ padding }) => padding || "50px 50px 0 330px"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#eeeeee")};
  border-radius: ${({ brRadius }) => (brRadius ? brRadius : "0")};

  @media (max-width: 1550px) {
    padding: ${({ padding }) => padding || "40px 40px 0 265px"};
    min-height: ${({ minH }) => minH || "calc(100vh - 65px)"};
  }

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "15px"};
  }
`;

export const MyAccountMainWrap = styled.div`
  padding: 0 170px;

  @media (max-width: 1650px) {
    padding: 0 130px;
  }

  @media (max-width: 1550px) {
    padding: 0 110px;
  }
  @media (max-width: 1450px) {
    padding: 0 80px;
  }

  @media (max-width: 1250px) {
    padding: 0 50px;
  }

  @media (max-width: 992px) {
    padding: 0 15px 0 15px;
  }
  @media (max-width: 426px) {
    padding: 0;
  }
`;
export const RectangleIMG = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  img {
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`;
export const MyAccountWrap = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  ${"" /* border-radius: 0 0 5px 5px; */}
  display: flex;
  @media (max-width: 575px) {
    flex-direction: column;
  }
`;
export const MyAccountWrapLeft = styled.div`
  margin-top: -70px;
  margin-left: 45px;
  .ant-avatar {
    width: 137px;
    height: 137px;
  }

  @media (max-width: 1550px) {
    margin-top: -55px;
    .ant-avatar {
      width: 110px;
      height: 110px;
    }
  }
  @media (max-width: 991.98px) {
    margin-top: -55px;
    .ant-avatar {
      width: 90px;
      height: 90px;
    }
  }
  @media (max-width: 426px) {
    margin-top: -30px;
    margin-left: 25px;

    .ant-avatar {
      width: 60px;
      height: 60px;
    }
  }
`;

export const MyAccountWrapRight = styled.div`
  padding: 30px;
  width: 100%;
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: ${({ theme }) => theme.colors.forth};
  }

  @media (max-width: 1550px) {
    padding: 24px;

    h2 {
      font-size: 20px;
      line-height: 23px;
    }
  }
  @media (max-width: 420px) {
    padding: 10px;
  }
`;
