import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import FaqsSidebar from "./FaqsSidebar";

function FaqsLayout() {
  const [minHeight, setMinHeight] = useState("calc(100vh - 143px)");
  const [zoom, setZoom] = useState("100%");

  const { width } = useWindowSize();
  const location = useLocation();

  // Setting heigth and zoom w.r.t screen width 
  useEffect(() => {
    if (width < 1550 && width > 991) {
      setMinHeight("calc(100vh - 320px)");
      setZoom("80%");
    } else {
      setMinHeight("calc(100vh - 143px)");
      setZoom("100%");
    }
  }, [width, location.pathname]);
  return (
    <FaqsLayoutWrap minHeight={minHeight}>
      <FaqsSidebar />
    </FaqsLayoutWrap>
  );
}

export default FaqsLayout;

export const FaqsLayoutWrap = styled.div`
  background: #ffffff;
  min-height: ${({ minHeight }) => minHeight || "440px"};
`;
