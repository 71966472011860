import React from "react";

const leaseTermSvg = ({
  wsize = "16",
  hsize = "21",
  bgColor = "#848687",
  color = " #848687",
}) => {
  return (
    <svg
      width={wsize}
      height={hsize}
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33333 5.57812V0H1C0.445833 0 0 0.438867 0 0.984375V20.0156C0 20.5611 0.445833 21 1 21H15C15.5542 21 16 20.5611 16 20.0156V6.5625H10.3333C9.78333 6.5625 9.33333 6.11953 9.33333 5.57812ZM4.72917 1.59141L7.58333 4.12207H6.72917V6.36973H5.30417V4.68398H4.15833V6.36973H2.72917V4.12207H1.875L4.72917 1.59141ZM10.7 15.75H12.6667C13.0333 15.75 13.3333 16.0453 13.3333 16.4062C13.3333 16.7672 13.0333 17.0625 12.6667 17.0625H10.7C10.0125 17.0625 9.39583 16.6893 9.09167 16.0822C8.97083 15.8402 8.75417 15.8156 8.66667 15.8156C8.57917 15.8156 8.36667 15.8402 8.25 16.0699L7.92917 16.6975C7.81667 16.9189 7.5875 17.0584 7.33333 17.0584C7.31667 17.0584 7.3 17.0584 7.2875 17.0584C7.02083 17.0379 6.7875 16.8615 6.70417 16.6113L6 14.5441L5.55833 15.8525C5.3125 16.5785 4.625 17.0625 3.85 17.0625H3.33333C2.96667 17.0625 2.66667 16.7672 2.66667 16.4062C2.66667 16.0453 2.96667 15.75 3.33333 15.75H3.85C4.05 15.75 4.22917 15.6229 4.29167 15.4342L5.05 13.1947C5.1875 12.7928 5.56667 12.5221 6 12.5221C6.42917 12.5221 6.8125 12.7928 6.95 13.1947L7.52917 14.901C8.35417 14.2365 9.77917 14.5031 10.2792 15.4834C10.3625 15.6516 10.525 15.75 10.7 15.75Z"
        fill="#848687"
      />
    </svg>
  );
};

export default leaseTermSvg;
