import React, { useState } from "react";
import { CurrentStagWrap } from "./ApplicationsStyle";
import styled from "styled-components";
import { Col, Row, Skeleton } from "antd";
import {
  Capitalize,
  dateConverter,
  formatPhoneNumber,
  formatSsn,
} from "../../utils/fn";
import { useEffect } from "react";
import { ImagePreview } from "../ImagePreview";
import { BsShieldCheck } from "react-icons/bs";
import ProfilePdf from "./application-pdf/ProfilePdf";
import FileSaver from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { HiDownload } from "react-icons/hi";
import { useSelector } from "react-redux";

function ApplicantDetails({ applicantData }) {
  const [stateFront, setStateFront] = useState([]);
  const [stateBack, setStateBack] = useState([]);
  const { getApplicantDetailsLoading } = useSelector((state) => state.user);

  useEffect(() => {
    if (
      applicantData?.userProfile &&
      applicantData?.userProfile?.idProof?.IDtype
    ) {
      setStateFront([applicantData?.userProfile?.idProof?.frontProof]);
      setStateBack([applicantData?.userProfile?.idProof?.backProof]);
    }
  }, [applicantData]);

  // download invoice (PFD format)
  const downloadInvoice = async () => {
    const blob = await pdf(
      <ProfilePdf applicationProcessData={applicantData} />
    ).toBlob();
    FileSaver.saveAs(blob, `Profile Data`);
  };

  return (
    <>
      <CurrentStagWrap>{/* <h2>Applicant Details</h2> */}</CurrentStagWrap>
      <ApplicantDetailsWrap>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Row gutter={[30, 30]} justify="space-between">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <span>First Name</span>
                {applicantData?.user_data?.firstName ? (
                  <h2>{Capitalize(applicantData?.user_data?.firstName)}</h2>
                ) : (
                  <h2>-</h2>
                )}
                {getApplicantDetailsLoading ? (
                  <Skeleton.Input
                    active
                    style={{
                      width: "100%",
                      height: 20,
                      marginBottom: 20,
                    }}
                  ></Skeleton.Input>
                ) : (
                  ""
                )}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <span>Last Name</span>
                {applicantData?.user_data?.lastName ? (
                  <h2>{Capitalize(applicantData?.user_data?.lastName)}</h2>
                ) : (
                  <h2>-</h2>
                )}
                {getApplicantDetailsLoading ? (
                  <Skeleton.Input
                    active
                    style={{
                      width: "100%",
                      height: 20,
                      marginBottom: 20,
                    }}
                  ></Skeleton.Input>
                ) : (
                  ""
                )}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <span>Contact Number</span>
                {applicantData?.user_data?.phoneNumber ? (
                  <h2>
                    {formatPhoneNumber(applicantData?.user_data?.phoneNumber)}
                  </h2>
                ) : (
                  <h2>-</h2>
                )}

                {getApplicantDetailsLoading ? (
                  <Skeleton.Input
                    active
                    style={{
                      width: "100%",
                      height: 20,
                      marginBottom: 20,
                    }}
                  ></Skeleton.Input>
                ) : (
                  ""
                )}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <span>Email</span>
                {applicantData?.user_data?.email ? (
                  <h2>{applicantData?.user_data?.email}</h2>
                ) : (
                  <h2>-</h2>
                )}
                {getApplicantDetailsLoading ? (
                  <Skeleton.Input
                    active
                    style={{
                      width: "100%",
                      height: 20,
                      marginBottom: 20,
                    }}
                  ></Skeleton.Input>
                ) : (
                  ""
                )}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <span>Birthdate</span>
                {applicantData?.userProfile?.generalProfile?.birthdate ? (
                  <h2>
                    {dateConverter(
                      applicantData?.userProfile?.generalProfile?.birthdate
                    )}
                  </h2>
                ) : (
                  <h2>-</h2>
                )}
                {getApplicantDetailsLoading ? (
                  <Skeleton.Input
                    active
                    style={{
                      width: "100%",
                      height: 20,
                      marginBottom: 20,
                    }}
                  ></Skeleton.Input>
                ) : (
                  ""
                )}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <span>Address</span>
                {applicantData?.userProfile?.generalProfile?.address ? (
                  <h2>
                    {applicantData?.userProfile?.generalProfile?.address},{" "}
                    {applicantData?.userProfile?.generalProfile?.city},{" "}
                    {applicantData?.userProfile?.generalProfile?.state},{" "}
                    {applicantData?.userProfile?.generalProfile?.zipcode}.
                  </h2>
                ) : (
                  <h2>-</h2>
                )}
                {getApplicantDetailsLoading ? (
                  <Skeleton.Input
                    active
                    style={{
                      width: "100%",
                      height: 20,
                      marginBottom: 20,
                    }}
                  ></Skeleton.Input>
                ) : (
                  ""
                )}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                {applicantData?.userProfile?.generalProfile
                  ?.socialSecurityNumber ? (
                  <>
                    <span>Social Security Number</span>
                    <h2>
                      {formatSsn(
                        applicantData?.userProfile?.generalProfile
                          ?.socialSecurityNumber
                      )}
                    </h2>
                  </>
                ) : (
                  <>
                    <span>Social Security Number</span>
                    <h2>-</h2>
                  </>
                )}
                {getApplicantDetailsLoading ? (
                  <Skeleton.Input
                    active
                    style={{
                      width: "100%",
                      height: 20,
                      marginBottom: 20,
                    }}
                  ></Skeleton.Input>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            {/* <Row justify="space-between">
              <Col>
                <span>ID Proof</span>
                {applicantData?.userProfile?.idProof?.IDtype ? (
                  <h2>
                    {Capitalize(applicantData?.userProfile?.idProof?.IDtype)}
                  </h2>
                ) : (
                  <h2>-</h2>
                )}

                {getApplicantDetailsLoading ? (
                  <Skeleton.Input
                    active
                    style={{
                      width: "100%",
                      height: 20,
                      marginBottom: 20,
                    }}
                  ></Skeleton.Input>
                ) : (
                  ""
                )}
              </Col>
              {applicantData?.is_verified ? (
                <Col style={{ marginRight: "20px" }}>
                  <HiDownload
                    className="share__class"
                    onClick={() => downloadInvoice()}
                  />
                  <span
                    className="share__class"
                    onClick={() => downloadInvoice()}
                  >
                    Download user data
                  </span>
                </Col>
              ) : (
                ""
              )}
            </Row> */}
            {/* <Row gutter={[10, 10]}>
              <Col>
                <span>Front</span>
                <ShowUplaodImg isPhoto={stateFront?.length}>
                  {stateFront?.map((img, idx) => (
                    <ImagePreview
                      style={{
                        width: "100%",
                        padding: "20px",
                        minHeight: "200px",
                        maxHeight: "200px",
                      }}
                      key={idx}
                      src={img.path ? img.path : ""}
                      downloadImgUrl={img.path ? img.path : ""}
                      height={"auto"}
                      width={"auto"}
                      isCoverDisProp={false}
                      isDeleteDisProp={false}
                      isDownload={true}
                    />
                  ))}
                </ShowUplaodImg>
              </Col>
              <Col>
                <span>Back</span>
                <ShowUplaodImg isPhoto={stateBack?.length}>
                  {stateBack?.map((img, idx) => (
                    <ImagePreview
                      style={{
                        width: "100%",
                        padding: "20px",
                        minHeight: "200px",
                        maxHeight: "200px",
                      }}
                      key={idx}
                      src={img.path ? img.path : ""}
                      downloadImgUrl={img.path ? img.path : ""}
                      height={"auto"}
                      width={"auto"}
                      isCoverDisProp={false}
                      isDeleteDisProp={false}
                      isDownload={true}
                    />
                  ))}
                </ShowUplaodImg>
              </Col>
            </Row> */}

            {/* {applicantData?.is_verified ? (
              <Row>
                <Col className="applicant__approved">
                  <h3>
                    <BsShieldCheck />
                  </h3>
                  <h3>This applicant is approved by Avenew team.</h3>
                </Col>
              </Row>
            ) : (
              ""
            )} */}
          </Col>
        </Row>
      </ApplicantDetailsWrap>
    </>
  );
}

export default ApplicantDetails;

export const ShowUplaodImg = styled.div`
  display: ${({ isPhoto }) => (!isPhoto ? "none" : "flex")};
  width: fit-content;
  height: auto;
  border: 1px dashed #777777;

  img {
    width: 100%;
  }

  @media (max-width: 769px) {
    width: auto;
    height: auto;
  }
`;

export const ApplicantDetailsWrap = styled.div`
  padding: 15px;

  .share__class {
    color: #4160ac;
    color: #4160ac;
    font-size: 18px;
    margin-left: 10px;
    cursor: pointer;
  }

  .applicant__approved {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 40px 0;
  }
  .applicant__approved > h3 > svg {
    display: flex;
    color: green;
  }

  h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    color: #2b353f;
    margin-bottom: 0;
    color: green;
  }
`;
