import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col } from "antd";
import styled from "styled-components";
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledForms,
} from "../createPersonalProfile/RegistrationStyle";
import { useDispatch, useSelector } from "react-redux";
import { personalDetails } from "../../features/personalDetailsSlice";
import { onEdit } from "../../features/useSlice";
import { editProfile, me } from "../../store/reducers/user.reducer";
import { AddNewApr } from "../listings/Listingstyle";
import { currentDateTime } from "../../utils/fn";

function EditPersonalDetails({ isEdit, imgState = {} }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.user);
  const [isloading, setIsloading] = useState(false);

  // set field value upon edit
  useEffect(() => {
    if (isEdit && user) {
      form.setFieldsValue({
        firstName: user?.firstName,
        lastName: user?.lastName,
        phonenumber: user?.phonenumber?.slice(2),
        email: user?.email,
      });
    }
  }, [isEdit, user]);

  // Submit onclick function
  const onFinish = (value) => {
    // dispatch(personalDetails(value));
    // dispatch(onEdit(""));
    value.id = user.id;
    value.phonenumber = `+1${value.phonenumber}`;
    value.email = value.email.toLowerCase();
    value.firstName = value.firstName.toLowerCase();
    value.lastName = value.lastName.toLowerCase();
    value.createdAt = user.createdAt;
    value.updatedAt = currentDateTime;
    if (Object.keys(imgState)?.length) {
      value.image = imgState;
    }
    setIsloading(true);
    dispatch(editProfile(value)).then(() => {
      dispatch(me()).then(() => {
        isEdit();
        setIsloading(false);
      });
    });
  };

  const onFieldsChange = (changedFields) => {
    // console.log(changedFields, ">>>>");
  };

  // Message to validate form
  const validateMessages = {
    required: "${label} is required.",
    types: {
      email: "${label} is not a valid email.",
      number: "${label} is not a valid number.",
    },
  };

  return (
    <EditPersonalDetailsWrap>
      <StyledForm wh="100" smwh="100" xswh="100" xxswh="100">
        <StyledForms>
          <Form
            layout="vertical"
            onFinish={onFinish}
            requiredMark={"optional"}
            validateMessages={validateMessages}
            onFieldsChange={onFieldsChange}
            form={form}
          >
            <StyledFormItem
              smwh="100"
              flexDirection="column"
              flexDirectionSmwh="100"
            >
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "First Name required",
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Last Name required",
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </StyledFormItem>
            <StyledFormItem
              smwh="100"
              flexDirection="column"
              flexDirectionSmwh="100"
            >
              <Form.Item
                className="input__affix"
                name="phonenumber"
                label="Contact Number"
                rules={[
                  {
                    required: true,
                  },
                  { max: 10, message: "Invalid Contact Number!" },
                  { min: 10, message: "Invalid Contact Number!" },
                ]}
              >
                <Input
                  style={{ paddingLeft: "10px" }}
                  type="number"
                  min="0"
                  className="profile_edit"
                  onWheel={(e) => e.target.blur()}
                  prefix={`+1`}
                  placeholder="Contact Number"
                  onKeyDown={(e) => (e.key === "." ? e.preventDefault() : "")}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input
                  disabled={true}
                  placeholder="Email"
                  style={{ border: "none", backgroundColor: "#ffffff" }}
                />
              </Form.Item>
            </StyledFormItem>

            <Form.Item>
              <Row
                gutter={[20, 20]}
                justify="end"
                style={{ alignItems: "center" }}
              >
                <Col>
                  <h3 className="close__class" hg="40px" onClick={isEdit}>
                    Cancel
                  </h3>
                </Col>
                <Col>
                  <StyledButton htmlType="submit" loading={isloading}>
                    SAVE
                  </StyledButton>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </StyledForms>
      </StyledForm>
    </EditPersonalDetailsWrap>
  );
}

export default EditPersonalDetails;

export const EditPersonalDetailsWrap = styled.div`
  ${"" /* padding: 5px 30px 0 60px; */}

  .close__class {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #848687;
    margin-bottom: 0;
    margin-top: 0;
  }

  .ant-form-item {
    input {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
    }
  }
  @media (max-width: 1550px) {
    padding: 0;

    .ant-form-item {
      input {
        font-size: 16px !important;
        line-height: 19px !important;
      }
    }
    .ant-form-item input {
      height: 40px !important;
    }
  }
  @media (max-width: 991.98px) {
    padding: 0 20px;
  }
  @media (max-width: 420px) {
    padding: 0 10px;
  }
`;
