import { Col, Popover, Row, Skeleton, Steps } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { updateApplication } from "../../store/reducers/user.reducer";
import {
  ButtonWrap,
  CurrentStagWrap,
  StatusButton,
  StepWrap,
  StepWrapRight,
} from "./ApplicationsStyle";
import {
  Capitalize,
  dateConverter,
  formatPhoneNumber,
  formatSsn,
} from "../../utils/fn";
import { HiDownload } from "react-icons/hi";
import ProfilePdf from "./application-pdf/ProfilePdf";
import FileSaver from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { ApplicantDetailsWrap } from "./ApplicantDetails";
import JSZip from "jszip";

function Approval({
  selectedApplication,
  updatedApplication,
  onMsgApplicant,
  setCurrentTab,
  applicantData,
}) {
  const { Step } = Steps;
  const dispatch = useDispatch();
  const [approveLoading, setApproveLoading] = useState(false);
  const [denyLoading, setDenyLoading] = useState(false);
  const [approve, setapprove] = useState(false);
  const { getApplicantDetailsLoading } = useSelector((state) => state.user);
  // Switching Bg color with respect to application status
  const statusBgColor = (status) => {
    switch (status) {
      case 0:
        return "wait";
      case 1:
        return "finish";
      case true:
        return "finish";
      case 2:
        return "error";
      case false:
        return "error";
      default:
        return "wait";
    }
  };

  const statusForDes = (status) => {
    switch (status) {
      case 0:
        return "Approval Pending";
      case 1:
        return "Verified";
      case true:
        return "Verified";
      case 2:
        return "Rejected";
      case false:
        return "Rejected";
      default:
        return "Approval Pending";
    }
  };

  // Function to approve the applications
  const approveApplication = () => {
    setApproveLoading(true);
    const value = {};
    value.status = 2;
    value.adminUserId = selectedApplication.adminUserId;
    // value.backgroundCheckVerified = selectedApplication.backgroundCheckVerified;
    // value.creditScoreVerified = selectedApplication.creditScoreVerified;
    value.id = selectedApplication.id;
    value.org_id = selectedApplication.org_id;
    // value.incomeVerified = selectedApplication.incomeVerified;
    value.leaseLength = selectedApplication.leaseLength;
    // value.previousRental = selectedApplication.previousRental;
    value.prospectUserId = selectedApplication.prospectUserId;
    value.selectedDate = selectedApplication.selectedDate;
    value.unitId = selectedApplication.unitId;
    value.serialNumber = selectedApplication.serialNumber;
    dispatch(updateApplication(value)).then((res) => {
      updatedApplication(res.payload);
      setApproveLoading(false);
      setapprove(true);
    });
  };

  // Function to deny application
  const denyApplication = () => {
    setDenyLoading(true);
    const value = {};
    value.status = 3;
    value.adminUserId = selectedApplication.adminUserId;
    // value.backgroundCheckVerified = selectedApplication.backgroundCheckVerified;
    // value.creditScoreVerified = selectedApplication.creditScoreVerified;
    value.id = selectedApplication.id;
    // value.incomeVerified = selectedApplication.incomeVerified;
    value.org_id = selectedApplication.org_id;
    value.leaseLength = selectedApplication.leaseLength;
    // value.previousRental = selectedApplication.previousRental;
    value.prospectUserId = selectedApplication.prospectUserId;
    value.selectedDate = selectedApplication.selectedDate;
    value.unitId = selectedApplication.unitId;
    value.serialNumber = selectedApplication.serialNumber;
    dispatch(updateApplication(value)).then((res) => {
      updatedApplication(res.payload);
      setDenyLoading(false);
      setapprove(false);
    });
  };

  const downloadInvoice = async () => {
    const zip = new JSZip();
    const pdfBlob = await pdf(
      <ProfilePdf applicationProcessData={applicantData} />
    ).toBlob();

    zip.file(
      `${applicantData?.user_data?.firstName} ${applicantData?.user_data?.lastName}.pdf`,
      pdfBlob
    );

    // Generate the zip file
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Save the zip file
    FileSaver.saveAs(
      zipBlob,
      `${Capitalize(applicantData?.user_data?.firstName)} ${Capitalize(
        applicantData?.user_data?.lastName
      )}.zip`
    );
  };

  return (
    <ApprovalWrap>
      {/* <CurrentStagWrap><h2>Step : 2 Approval</h2></CurrentStagWrap> */}
      <Row gutter={[10, 10]} style={{ paddingTop: "50px" }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <ApplicantDetailsWrap>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Row gutter={[20, 20]} justify="space-between">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <span>First Name</span>
                    {applicantData?.user_data?.firstName ? (
                      <h2>{Capitalize(applicantData?.user_data?.firstName)}</h2>
                    ) : (
                      <h2>-</h2>
                    )}
                    {getApplicantDetailsLoading ? (
                      <Skeleton.Input
                        active
                        style={{
                          width: "100%",
                          height: 20,
                          marginBottom: 20,
                        }}
                      ></Skeleton.Input>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <span>Last Name</span>
                    {applicantData?.user_data?.lastName ? (
                      <h2>{Capitalize(applicantData?.user_data?.lastName)}</h2>
                    ) : (
                      <h2>-</h2>
                    )}
                    {getApplicantDetailsLoading ? (
                      <Skeleton.Input
                        active
                        style={{
                          width: "100%",
                          height: 20,
                          marginBottom: 20,
                        }}
                      ></Skeleton.Input>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row gutter={[20, 20]} justify="space-between">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <span>Contact Number</span>
                    {applicantData?.user_data?.phoneNumber ? (
                      <h2>
                        {formatPhoneNumber(
                          applicantData?.user_data?.phoneNumber
                        )}
                      </h2>
                    ) : (
                      <h2>-</h2>
                    )}

                    {getApplicantDetailsLoading ? (
                      <Skeleton.Input
                        active
                        style={{
                          width: "100%",
                          height: 20,
                          marginBottom: 20,
                        }}
                      ></Skeleton.Input>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <span>Email</span>
                    {applicantData?.user_data?.email ? (
                      <h2>{applicantData?.user_data?.email}</h2>
                    ) : (
                      <h2>-</h2>
                    )}
                    {getApplicantDetailsLoading ? (
                      <Skeleton.Input
                        active
                        style={{
                          width: "100%",
                          height: 20,
                          marginBottom: 20,
                        }}
                      ></Skeleton.Input>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>

                <Row gutter={[20, 20]} justify="space-between">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <Row>
                      <Col span={24}>
                        <span>Birthdate</span>
                        {applicantData?.userProfile?.generalProfile
                          ?.birthdate ? (
                          <h2>
                            {dateConverter(
                              applicantData?.userProfile?.generalProfile
                                ?.birthdate
                            )}
                          </h2>
                        ) : (
                          <h2>-</h2>
                        )}
                        {getApplicantDetailsLoading ? (
                          <Skeleton.Input
                            active
                            style={{
                              width: "100%",
                              height: 20,
                              marginBottom: 20,
                            }}
                          ></Skeleton.Input>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col span={24}>
                        {applicantData?.userProfile?.generalProfile
                          ?.socialSecurityNumber ? (
                          <>
                            <span>Social Security Number</span>
                            <h2>
                              {formatSsn(
                                applicantData?.userProfile?.generalProfile
                                  ?.socialSecurityNumber
                              )}
                            </h2>
                          </>
                        ) : (
                          <>
                            <span>Social Security Number</span>
                            <h2>-</h2>
                          </>
                        )}
                        {getApplicantDetailsLoading ? (
                          <Skeleton.Input
                            active
                            style={{
                              width: "100%",
                              height: 20,
                              marginBottom: 20,
                            }}
                          ></Skeleton.Input>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    {applicantData?.userProfile?.generalProfile?.address ? (
                      <>
                        <Row>
                          <Col span={24}>
                            <span>Address</span>
                            <h2>
                              {
                                applicantData?.userProfile?.generalProfile
                                  ?.address
                              }
                            </h2>
                          </Col>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 24 }}
                            xl={{ span: 24 }}
                            xxl={{ span: 24 }}
                          >
                            <h2>
                              {applicantData?.userProfile?.generalProfile?.city}
                              ,{" "}
                              {
                                applicantData?.userProfile?.generalProfile
                                  ?.state
                              }
                              ,{" "}
                              {
                                applicantData?.userProfile?.generalProfile
                                  ?.zipcode
                              }
                            </h2>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <span>Address</span>
                        <h2>-</h2>
                      </>
                    )}
                    {getApplicantDetailsLoading ? (
                      <Skeleton.Input
                        active
                        style={{
                          width: "100%",
                          height: 20,
                          marginBottom: 20,
                        }}
                      ></Skeleton.Input>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </ApplicantDetailsWrap>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 10, offset: 2 }}
          xxl={{ span: 10, offset: 2 }}
        >
          <StepWrap>
            <Steps status="error" direction="vertical" size="small">
              {/* <Step
                status={statusBgColor(applicantData?.is_credit_score_verified)}
                title="Credit Score"
                description={statusForDes(
                  applicantData?.is_credit_score_verified
                )}
              /> */}
              <Step
                status={statusBgColor(applicantData?.is_paystub2_verified)}
                title="Paystub"
                description={statusForDes(
                  applicantData?.is_paystub1_verified === 2 &&
                    applicantData?.is_paystub2_verified === 2
                    ? 2
                    : applicantData?.is_paystub1_verified === 1 ||
                      applicantData?.is_paystub2_verified === 1
                    ? 1
                    : 0
                )}
              />
              <Step
                status={statusBgColor(applicantData?.is_employment_verified)}
                title="Employment"
                description={statusForDes(
                  applicantData?.is_employment_verified
                )}
              />
              <Step
                status={statusBgColor(
                  applicantData?.is_rental_history_verified
                )}
                title="Previous Rental Information"
                description={statusForDes(
                  applicantData?.is_rental_history_verified
                )}
              />
            </Steps>
          </StepWrap>
        </Col>
        {/* <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <StepWrapRight>
            <h1>
              Thank you for utilizing Avenew. To approve the tenant, simply
              click the button below. You can download all the necessary
              information and send this tenant your application and agreement
              using your preferred method.
            </h1>
            <Row>
              <Col>
                <h3>Information about the tenant:</h3>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <span>Email Id</span>
                <h2>{Capitalize(applicantData?.user_data?.email)}</h2>
              </Col>
              <Col>
                <span>Legal full name</span>
                <h2>
                  {Capitalize(
                    applicantData?.userProfile?.generalProfile?.firstLegalName
                  )}{" "}
                  {Capitalize(
                    applicantData?.userProfile?.generalProfile?.MiddleLegalName
                  )}{" "}
                  {Capitalize(
                    applicantData?.userProfile?.generalProfile?.lastLegalName
                  )}
                </h2>
              </Col>
            </Row>
            <Row>
              {applicantData?.is_verified ? (
                <Col>
                  <HiDownload
                    className="share__class icon__class"
                    onClick={() => downloadInvoice()}
                  />
                  <span
                    className="share__class"
                    onClick={() => downloadInvoice()}
                  >
                    Download user data
                  </span>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </StepWrapRight>
        </Col> */}
      </Row>

      <ButtonWrap>
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
            <StatusButton
              onClick={() => downloadInvoice()}
              className="status__button"
              bgColor={"#2B353F"}
              loading={denyLoading}
            >
              <>
                <span className="svg__download">
                  <HiDownload />
                </span>
                <span>Documents</span>
              </>
            </StatusButton>
          </Col>
          <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
            <StatusButton
              className="status__button"
              bgColor={"#4160AC"}
              onClick={() => onMsgApplicant(selectedApplication)}
            >
              <span>Message Applicant</span>
            </StatusButton>
          </Col>
          {selectedApplication?.status === 3 ||
          selectedApplication?.status === 1 ||
          selectedApplication?.status === 0 ? (
            <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
              <StatusButton
                onClick={() => approveApplication()}
                className="status__button"
                bgColor={"#F67C36"}
                loading={approveLoading}
              >
                <span>Approve Application</span>
              </StatusButton>
            </Col>
          ) : (
            <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
              <StatusButton
                onClick={() => setCurrentTab(1)}
                className="status__button"
                bgColor={"#F67C36"}
                // loading={approveLoading}
              >
                <span>Next</span>
              </StatusButton>
            </Col>
          )}

          <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
            <StatusButton
              onClick={() => denyApplication()}
              className="status__button"
              bgColor={"#2B353F"}
              loading={denyLoading}
            >
              <span>Deny Application</span>
            </StatusButton>
          </Col>
        </Row>
      </ButtonWrap>
    </ApprovalWrap>
  );
}

export default Approval;

const ApprovalWrap = styled.div`
  .ant-steps-item-tail {
    display: none !important;
  }
`;
