import React, { useEffect, useState, Icon } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Pagination,
  Space,
  Popover,
  Input,
  message,
  Popconfirm,
  Select,
} from "antd";
import { FcInfo } from "react-icons/fc";
import { Heading } from "../../ui/Heading/Heading";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import HeaderSearch from "../layout/header/HeaderSearch";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import CheckboxMenu from "./CheckboxMenu";
import { MessagesSvg } from "../../assets/svgs";
import { BiDetail, BiSearch, BiSend } from "react-icons/bi";
import {
  createChat,
  deleteMultipleTenant,
  deleteTenant,
  getAllTenants,
  getDeletedTenants,
  inviteTenants,
} from "../../store/reducers/user.reducer";
import { useDispatch } from "react-redux";
import { DelBtn, TableOneWrap } from "./TableOne";
import styled from "styled-components";
import { AddNewApr } from "../listings/Listingstyle";
import AddTenantsModal from "../addTenantsModal/AddTenantsModal";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  FloatButton,
  SecondaryButton,
} from "../apartments-view/ApartmentsViewStyle";
import { HiPlus } from "react-icons/hi";
import { ContainerWrap } from "../../ui/Layout/ContainerWrap";
import { UserAvatar } from "../../ui/Avatar";
import LoadingTopPage from "../feture-soon/LoadingTopPage";
import { EditIconWrap } from "../apartments-view/Actions";
import { FaTrash } from "react-icons/fa";
import { RiArrowGoBackLine } from "react-icons/ri";
import { map, includes, sortBy, uniqBy, each, result, get } from "lodash";
import { MdCancel } from "react-icons/md";
import { formatPhoneNumber, toUSACurrency, upperCase } from "../../utils/fn";

function Tenants() {
  const { Option } = Select;
  const [tenantStatus, setTenantStatus] = useState("Current Tenants");
  const [allTenantsObj, setAllTenantsObj] = useState({});
  const [allTenantsArr, setAllTenantsArr] = useState([]);
  const [orgTenants, setOrgTenants] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [allTenantsArrOne, setAllTenantsArrOne] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [modalState, setModalState] = useState(false);
  const [deleteChange, setDeleteChange] = useState("");
  const [searchBarText, setSearchBarText] = useState("");
  const [currentSelectTab, setCurrentSelectTab] = useState("Current Tenants");
  const [tableXAxis, setTableXAxis] = useState(2100);
  const [isClickMessage, setIsClickMessage] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const Search = Input.Search;

  const onSearch = (e) => {
    setSearchBarText(e.target.value);
    const reg = new RegExp(e.target.value, "gi");
    const filteredData = map(orgTenants, (record) => {
      const lastNameMatch = get(record, "lastName").match(reg);
      const firstNameMatch = get(record, "firstName").match(reg);
      const apartment_nameMatch = get(record, "apartment_name").match(reg);
      const addressMatch = get(record, "address").match(reg);
      const apaphonenumberMatch = get(record, "apaphonenumber").match(reg);
      const phoneNumberMatch = get(record, "phoneNumber").match(reg);
      const unitTypeMatch = get(record, "unitType").match(reg);
      const invitaionRequestMatch = get(record, "invitaionRequest").match(reg);
      const org_nameMatch = get(record, "org_name").match(reg);
      const unit_nameMatch = get(record, "unit_name").match(reg);
      if (
        !lastNameMatch &&
        !addressMatch &&
        !firstNameMatch &&
        !apaphonenumberMatch &&
        !invitaionRequestMatch &&
        !org_nameMatch &&
        !phoneNumberMatch &&
        !unitTypeMatch &&
        !unit_nameMatch &&
        !apartment_nameMatch
      ) {
        return null;
      }
      return record;
    }).filter((record) => !!record);

    setSearchData(e.target.value ? filteredData : orgTenants);

    // this.setState({
    //   searchText: e.target.value,
    //   filtered: !!e.target.value,
    //   data: e.target.value ? filteredData : orgTenants,
    // });
  };

  const [dayLeft, setDayLeft] = useState(0);
  const dispatch = useDispatch();

  // To format date
  function formatDate(date = new Date()) {
    let { day, month, year } = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    })
      .formatToParts(date ? date : new Date())
      .reduce((acc, part) => {
        if (part.type != "literal") {
          acc[part.type] = part.value;
        }
        return acc;
      }, Object.create(null));
    return `${day}-${month}-${year}`;
  }

  // To convert Date
  const dateConverter = (dateValue, month = 0) => {
    var date = new Date(dateValue);
    var newDate = new Date(date.setMonth(date?.getMonth() + month));
    var newDay = new Date(newDate.setDate(newDate?.getDate() - 1));
    return formatDate(newDay);
  };

  // Counter to count days left
  const dayLeftCounter = (dateValue, month) => {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    var date = new Date(dateValue);
    var newDate = new Date(date.setMonth(date?.getMonth() + month));
    var newDay = new Date(newDate.setDate(newDate?.getDate()));

    let date_ini = new Date();
    let date_end = new Date(formatDate(newDay));
    let diff = date_end.getTime() - date_ini.getTime();
    const val = Math.floor(diff / day);

    if (Math.sign(val) === -1) {
      return "Lease Expired";
    } else {
      return `${val} Left`;
    }
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = React.useRef(null);

  // Search handle for search functionality
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  // To reset search field
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // icon={<BiSearch />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <BiSearch
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <BiSearch
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // const balanceColor = (value) => {
  //   if (Number(value.rate) - Number(value.balance) < 0) {
  //     return (
  //       <td style={{ color: "green" }}>
  //         {toUSACurrency(Math.abs(Number(value.rate) - Number(value.balance)))}
  //       </td>
  //     );
  //   } else if (Number(value.rate) - Number(value.balance) === 0) {
  //     return (
  //       <td style={{ color: "rgb(33, 150, 243)" }}>
  //         {toUSACurrency(Number(value.rate) - Number(value.balance))}
  //       </td>
  //     );
  //   } else {
  //     return (
  //       <td style={{ color: "#e73030" }}>
  //         {toUSACurrency(Number(value.rate) - Number(value.balance))}
  //       </td>
  //     );
  //   }
  // };

  // To render color with due date
  const dueDateColor = (value) => {
    if (Number(value.rate) - Number(value.balance) < 0) {
      return (
        <p style={{ color: "green" }}>
          Balance :{" "}
          {/* {toUSACurrency(
            Math.abs(Number(value.rate) - Number(value.balance))
          )} */}
        </p>
      );
    } else if (Number(value.rate) - Number(value.balance) === 0) {
      return (
        <p style={{ color: "rgb(33, 150, 243)" }}>
          Balance :{" "}
          {/* {toUSACurrency(Number(value.rate) - Number(value.balance))} */}
        </p>
      );
    } else {
      return (
        <p style={{ color: "#F67C36 " }}>
          Balance :{" "}
          {/* {toUSACurrency(Number(value.rate) - Number(value.balance))} */}
        </p>
      );
    }
  };

  // To render/switch color w.r.t status
  const invitaionstatus = (key) => {
    switch (key) {
      case "sent":
        return "black";
      case "approved":
        return "#3650f6";
      case "denied":
        return "red";
      case "cancel":
        return "orange";
    }
  };

  const chatCreate = (value) => {
    if (value.id) {
      setIsClickMessage(true);
      const data = {};
      data.tenant_id = value.id;
      data.unit_id = value.unit_id;
      data.unit_name = value.unit_name;
      dispatch(createChat(data)).then(({ payload }) => {
        setIsClickMessage(false);

        if (payload.chat) {
          navigate({
            pathname: `/messages`,
            search: createSearchParams({
              chatId: payload.chat.id,
            }).toString(),
          });
        } else if (payload.Error) {
          message.info("Tenant hasn't responded to your invitation yet.");
        }
      });
    }
  };

  const columns = [
    {
      title: "Tenant Name",
      dataIndex: "tenantName",
      width: 170,
      // ...getColumnSearchProps("tenantName"),
      render: (_, record) => (
        <div>
          <td>
            {upperCase(record.firstName)} {upperCase(record.lastName)}
          </td>
        </div>
      ),
    },
    {
      title: "Contact Number",
      dataIndex: "phoneNumber",
      width: 170,
      render: (_, record) => (
        <span>{formatPhoneNumber(record.phoneNumber)}</span>
      ),
    },
    {
      title: "Unit",
      dataIndex: "unitNumber",
      width: 250,
      render: (_, record) => (
        <span>
          {slpitFunc(record.unit_name, 10)},{" "}
          {slpitFunc(record.apartment_name, 20)}
        </span>
      ),
    },
    {
      title: "Unit Type",
      dataIndex: "unitType",
      width: 180,
    },
    {
      title: "Lease Term",
      dataIndex: "leaseTerm",
    },
    {
      title: "Lease Start",
      dataIndex: "leaseStart",
    },
    {
      title: "Lease End",
      dataIndex: "leaseEnd",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      width: "8%",
      render: (_, record) => (
        <div>
          <Space size="middle">
            <td>
              {record?.leaseStart && record?.leaseTerm
                ? dateConverter(
                    record?.leaseStart,
                    Number(record?.leaseTerm?.substring(0, 2))
                  )
                : ""}
            </td>
            {record.leaseStart ? (
              <Popover
                content={
                  <div>
                    <p style={{ color: "#f65936 " }}>
                      Due Day :{" "}
                      {record.leaseStart && record?.leaseTerm
                        ? dayLeftCounter(
                            record?.leaseStart,
                            Number(record?.leaseTerm?.substring(0, 2))
                          )
                        : ""}
                    </p>
                    {dueDateColor(record)}
                  </div>
                }
                title="DUE DAY"
              >
                <FcInfo />
              </Popover>
            ) : (
              ""
            )}{" "}
          </Space>
        </div>
      ),
    },
    {
      title: "Rent",
      dataIndex: "rate",
      sorter: (a, b) => Number(a.rate) - Number(b.rate),
      render: (_, record) => (
        <td>{toUSACurrency(Number(record?.rate ? record?.rate : 0))}</td>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      render: (_, record) => (
        <Space
          size="middle"
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={(event) => {
            navigate(`/tenants-ledger/${record.id}`);
            event.stopPropagation();
          }}
        >
          {toUSACurrency(Number(record.rate ? record.rate : "0"))}
          <Popover
            content={
              <div>
                <p>
                  Rate :{" "}
                  {toUSACurrency(Number(record.rate ? record.rate : "0"))}
                </p>
                <p style={{ color: "#5aa535 " }}>
                  Paid Amount : {toUSACurrency(Number(record.paidAmount))}
                </p>
                <p style={{ color: "#F67C36 " }}>
                  Balance :{" "}
                  {toUSACurrency(
                    Number(record.rate ? record.rate : "0") -
                      Number(record.paidAmount ? record.paidAmount : "0")
                  )}
                </p>
              </div>
            }
            title="Balance"
          >
            <FcInfo />
          </Popover>
        </Space>
      ),
    },
    {
      title: "Invitation",
      dataIndex: "invitaionRequest",
      width: 100,
      render: (_, record) => (
        <span
          className="send__invitation"
          style={{ color: invitaionstatus(record.invitaionRequest) }}
        >
          {upperCase(
            record.invitaionRequest === "cancel"
              ? "viewed"
              : record.invitaionRequest
          )}
        </span>
      ),
    },
    {
      title: "Message",
      dataIndex: "sendmessage",
      width: 100,

      render: (_, record) => (
        <Space
          className="send__invitation"
          size="middle"
          onClick={(event) => {
            event.stopPropagation();
            chatCreate(record);
          }}
        >
          <Link to="/tenants">
            <MessagesSvg />
          </Link>
          {/* <Link to="/tenants">Send Message</Link> */}
          {/* <Link to="/tenants">
            <MdOutlineArrowForwardIos />
          </Link> */}
        </Space>
      ),
    },
    {
      title: "Send Invitation",
      dataIndex: "sendInvitation",
      width: 130,
      render: (_, record) => (
        <Space
          className="send__invitation"
          size="middle"
          onClick={(event) => {
            event.stopPropagation();
            resendInvitaion(record);
          }}
        >
          {record.invitaionRequest === "approved" ? (
            ""
          ) : (
            <BiSend className="send__icon" />
          )}
        </Space>
      ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   align: "right",
    //   width: "4%",
    //   render: (_, record) => {
    //     return (
    //       <Popconfirm
    //         key={"del-c"}
    //         title="Are you sure?"
    //         onConfirm={(e) => {
    //           e.stopPropagation();
    //           onDelete(record, allTenantsArr);
    //         }}
    //         onCancel={(e) => {
    //           e.stopPropagation();
    //         }}
    //         okText="Yes"
    //         cancelText="No"
    //         icon={<QuestionCircleOutlined style={{ color: "red" }} />}
    //       >
    //         <EditIconWrap
    //           pr="0"
    //           onClick={(event) => {
    //             event.stopPropagation();
    //           }}
    //         >
    //           <FaTrash />
    //         </EditIconWrap>
    //       </Popconfirm>
    //     );
    //   },
    // },
  ];

  const [columans, setColumans] = useState(columns);

  // Delete handler for tenant delete function
  const onDelete = (value, allTenantsArrs) => {
    setDeleteChange(value);
  };

  useEffect(() => {
    if (orgTenants?.length && deleteChange) {
      setLoading(true);
      let arr = [];
      const unit = [...orgTenants];
      const newUnit = unit.map((val) => {
        if (val.id === deleteChange.id) {
        } else {
          arr.push(val);
        }
        if (arr?.length === 0) {
          setOrgTenants([]);
          setSearchData([]);
        } else {
          setAllTenantsArr(arr);
        }
      });
      dispatch(deleteTenant({ id: deleteChange.id })).then((res) => {
        setLoading(false);
      });
    }
  }, [deleteChange]);

  // To navigate to tenant details page
  const tenantDetailsPage = (value) => {
    if (width < 1600) {
      navigate(`/tenant-details/${value.id}`);
    }
  };

  const emitEmpty = () => {
    setSearchBarText("");
    setSearchData(orgTenants);
  };

  const suffix = <MdCancel onClick={() => emitEmpty()} />;

  const { width } = useWindowSize();
  const location = useLocation();
  const [yScroll, setyScroll] = useState(250);

  // To set scroll w.r.t screen width
  useEffect(() => {
    if (width < 1550) {
      setyScroll(250);
    } else {
      setyScroll(350);
    }
  }, [width, location.pathname]);

  useEffect(() => {
    if (allTenantsArrOne?.id) {
      localStorage.setItem("tenant_id", allTenantsArrOne.id);
    } else {
      localStorage.removeItem("tenant_id");
    }
  }, [allTenantsArrOne]);

  useEffect(() => {
    if (allTenantsArr?.length && !localStorage.getItem("viewAll")) {
      const availableTenants = allTenantsArr.filter((item) => {
        return item["org_id"] == localStorage.getItem("toSave");
      });
      setOrgTenants(availableTenants);
      setSearchData(availableTenants);
    } else if (localStorage.getItem("viewAll")) {
      setOrgTenants(allTenantsArr);
      setSearchData(allTenantsArr);
    } else {
      setOrgTenants([]);
      setSearchData([]);
    }
  }, [
    allTenantsArr,
    localStorage,
    localStorage.getItem("viewAll"),
    localStorage.getItem("toSave"),
  ]);

  useEffect(() => {
    if (orgTenants?.length) {
      setAllTenantsArrOne(orgTenants[0]);
    } else {
      setAllTenantsArrOne({});
    }
  }, [
    orgTenants,
    localStorage.getItem("viewAll"),
    localStorage.getItem("toSave"),
  ]);
  useEffect(() => {
    dispatch(getAllTenants()).then(({ payload }) => {
      setAllTenantsObj(payload);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (allTenantsObj && Object.keys(allTenantsObj).length) {
      const arr = Object.values(allTenantsObj);
      setAllTenantsArr(arr);
    } else {
      setAllTenantsArr([]);
    }
  }, [allTenantsObj]);

  const newAddedVal = (value) => {
    setAllTenantsArr((val) => [...val, value]);
  };

  function getMenuLink(key) {
    switch (key) {
      case "tenantName":
        return {
          title: "Tenant Name",
          dataIndex: "tenantName",
          width: 170,
          // ...getColumnSearchProps("tenantName"),
          render: (_, record) => (
            <div>
              <td>
                {upperCase(record.firstName)} {upperCase(record.lastName)}
              </td>
            </div>
          ),
        };
      case "phoneNumber":
        return {
          title: "Contact Number",
          dataIndex: "phoneNumber",
          width: 170,
          render: (_, record) => (
            <span>{formatPhoneNumber(record.phoneNumber)}</span>
          ),
        };
      case "unitNumber":
        return {
          title: "Unit",
          dataIndex: "unitNumber",
          width: 250,
          render: (_, record) => (
            <span>
              {record.unit_name}, {record.apartment_name}
            </span>
          ),
        };
      case "unitType":
        return {
          title: "Unit Type",
          dataIndex: "unitType",
          width: 180,
        };
      case "leaseTerm":
        return {
          title: "Lease Term",
          dataIndex: "leaseTerm",
        };
      case "leaseStart":
        return {
          title: "Lease Start",
          dataIndex: "leaseStart",
        };
      case "leaseEnd":
        return {
          title: "Lease End",
          dataIndex: "leaseEnd",
        };
      case "dueDate":
        return {
          title: "Due Date",
          dataIndex: "dueDate",
          width: 150,
          render: (_, record) => (
            <div>
              <Space size="middle">
                <td>
                  {record?.leaseStart && record?.leaseTerm
                    ? dateConverter(
                        record?.leaseStart,
                        Number(record?.leaseTerm?.substring(0, 2))
                      )
                    : ""}
                </td>
                <Popover
                  content={
                    <div>
                      <p style={{ color: "#f65936 " }}>
                        Due Day :{" "}
                        {record.leaseStart && record?.leaseTerm
                          ? dayLeftCounter(
                              record?.leaseStart,
                              Number(record?.leaseTerm?.substring(0, 2))
                            )
                          : ""}
                      </p>
                      {dueDateColor(record)}
                    </div>
                  }
                  title="DUE DAY"
                >
                  <FcInfo />
                </Popover>
              </Space>
            </div>
          ),
        };
      case "rate":
        return {
          title: "Rent",
          dataIndex: "rate",
          sorter: (a, b) => Number(a.rate) - Number(b.rate),
          render: (_, record) => <td>{toUSACurrency(Number(record.rate))}</td>,
        };
      case "balance":
        return {
          title: "Balance",
          dataIndex: "balance",
          render: (_, record) => (
            <Space
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              size="middle"
            >
              {toUSACurrency(Number(record.rate))}
              <Popover
                content={
                  <div>
                    <p>Rate : {toUSACurrency(Number(record.rate))}</p>
                    <p style={{ color: "#F67C36 " }}>
                      Balance : {toUSACurrency(Number(record.rate))}
                    </p>
                  </div>
                }
                title="Balance"
              >
                <FcInfo />
              </Popover>
            </Space>
          ),
        };
      case "invitaionRequest":
        return {
          title: "Invitation",
          dataIndex: "invitaionRequest",
          width: 100,
          render: (_, record) => (
            <span
              className="send__invitation"
              style={{ color: invitaionstatus(record.invitaionRequest) }}
            >
              {upperCase(
                record.invitaionRequest === "cancel"
                  ? "viewed"
                  : record.invitaionRequest
              )}
            </span>
          ),
        };
      case "sendmessage":
        return {
          title: "Message",
          dataIndex: "sendmessage",
          width: 100,

          render: (_, record) => (
            <Space
              className="send__invitation"
              size="middle"
              onClick={(event) => {
                event.stopPropagation();
                chatCreate(record);
              }}
            >
              <Link to="/tenants">
                <MessagesSvg />
              </Link>
              {/* <Link to="/tenants">Send Message</Link> */}
              {/* <Link to="/tenants">
                <MdOutlineArrowForwardIos />
              </Link> */}
            </Space>
          ),
        };
      case "sendInvitation":
        return {
          title: "Send Invitation",
          dataIndex: "sendInvitation",
          width: 130,
          render: (_, record) => (
            <Space
              className="send__invitation"
              size="middle"
              onClick={(event) => {
                event.stopPropagation();
                resendInvitaion(record);
              }}
            >
              {record.invitaionRequest === "approved" ? (
                ""
              ) : (
                <BiSend className="send__icon" />
              )}
            </Space>
          ),
        };
      // case "action":
      //   return {
      //     title: "Action",
      //     dataIndex: "action",
      //     key: "action",
      //     align: "right",
      //     width: "4%",
      //     render: (_, record) => {
      //       return (
      //         <Popconfirm
      //           key={"del-c"}
      //           title="Are you sure?"
      //           onConfirm={(e) => {
      //             e.stopPropagation();
      //             onDelete(record, allTenantsArr);
      //           }}
      //           onCancel={(e) => {
      //             e.stopPropagation();
      //           }}
      //           okText="Yes"
      //           cancelText="No"
      //           icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      //         >
      //           <EditIconWrap
      //             pr="0"
      //             onClick={(event) => {
      //               event.stopPropagation();
      //             }}
      //           >
      //             <FaTrash />
      //           </EditIconWrap>
      //         </Popconfirm>
      //       );
      //     },
      //   };
      default:
        return null;
    }
  }

  function getMenu(key) {
    switch (key) {
      case "Tenant Name":
        return "tenantName";
      case "Contact Number":
        return "phoneNumber";
      case "Unit":
        return "unitNumber";
      case "Unit Type":
        return "unitType";
      case "Lease Term":
        return "leaseTerm";
      case "Lease Start":
        return "leaseStart";
      case "Lease End":
        return "leaseEnd";
      case "Due Date":
        return "dueDate";
      case "Rent":
        return "rate";
      case "Balance":
        return "balance";
      case "Send Message":
        return "sendmessage";
      case "Invitation":
        return "invitaionRequest";
      case "Send Invitation":
        return "sendInvitation";
      // case "Action":
      //   return "action";
    }
  }

  const onCheckboxChange = (selection) => {
    const tableAxis = (selection?.length * 2000) / 14;
    setTableXAxis(tableAxis);

    var arr = [];
    if (selection?.length) {
      selection.map((value) => {
        // arr.push(getMenuLink(value));
        arr.push(getMenu(value));
      });
    }
    if (arr?.length) {
      var arr2 = [];
      const newFilter = columns?.map((val) =>
        arr.filter(function (item) {
          return item === val["dataIndex"];
        })
      );
      arr2.push(...newFilter);
      if (arr2?.length) {
        var arr3 = [];
        arr2.map((val) => {
          arr3.push(getMenuLink(...val));
        });
        var arr4 = [];
        if (arr3?.length) {
          arr3.map((value) => {
            if (value === null) {
            } else {
              arr4.push(value);
            }
          });
        }
        setColumans(arr4);
      }
    }
  };
  const [tableLoading, setTableLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    size: "small",
  });

  // To fetch data for table
  const fetchData = (params = {}) => {
    setTableLoading(true);
    setPagination({
      ...params.pagination,
      total: orgTenants.length, // 200 is mock data, you should read it from server
      // total: data.totalCount,
    });
    setTimeout(() => {
      setTableLoading(false);
    }, 500);

    //   });
  };

  // Onchange function for table change/pagination
  const handleTableChange = (newPagination, filters, sorter) => {
    fetchData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination: newPagination,
      ...filters,
    });
  };

  useEffect(() => {
    fetchData({
      pagination,
    });
  }, [orgTenants]);

  // Slpit function when the string length is more then 15
  const slpitFunc = (value, max = 15) => {
    if (value.length > max) {
      return value.slice(0, max) + "...";
    } else {
      return value;
    }
  };

  // Function to send invitation again
  const resendInvitaion = (tenant) => {
    setLoading(true);
    // tenant.invitationResent = true;
    tenant.invitaionRequest = "sent";
    tenant.tenant_id = tenant?.id;

    dispatch(inviteTenants(tenant)).then((res) => {
      message.success("Invitation has been sent");
      setLoading(false);
    });
  };

  // To dispatch action for delete and current tenants
  const selectChange = (value) => {
    setShowDel(false);
    setSelectedId([]);
    setTenantStatus(value);
    setCurrentSelectTab(value);
    setLoading(true);
    if (value === "Deleted Tenants") {
      dispatch(getDeletedTenants()).then(({ payload }) => {
        setLoading(false);
        setAllTenantsObj(payload);
      });
    } else if (value === "Current Tenants") {
      dispatch(getAllTenants()).then(({ payload }) => {
        setLoading(false);
        setAllTenantsObj(payload);
      });
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows && Object.keys(selectedRows).length) {
        const arr = Object.values(selectedRows);
        const ids = arr.map((val) => val.id);
        setSelectedId(ids);
      } else {
        setSelectedId([]);
      }
      setShowDel(true);
    },

    // getCheckboxProps: (record) => ({
    //   disabled: record.name === "Disabled User",
    //   name: record.name,
    // }),
  };

  const deleteHandler = () => {
    if (selectedId?.length) {
      if (currentSelectTab === "Current Tenants") {
        setLoading(true);
        dispatch(deleteMultipleTenant({ tenant_id: selectedId })).then(
          (res) => {
            if (selectedId?.length >= 2) {
              message.success("Tenants has been deleted successfully");
            } else {
              message.success("Tenant has been deleted successfully");
            }
            setLoading(false);
            let arr = [];
            searchData?.map((item) => {
              if (selectedId?.includes(item.id)) {
              } else {
                arr.push(item);
              }
            });
            setSearchData(arr);
          }
        );

        setShowDel(false);
        setSelectedId([]);
      } else if (currentSelectTab === "Deleted Tenants") {
        let restoreData = {};
        restoreData.restore_tenant = true;
        restoreData.tenant_id = selectedId;

        setLoading(true);
        dispatch(deleteMultipleTenant(restoreData)).then((res) => {
          if (selectedId?.length >= 2) {
            message.success("Tenants has been restored successfully");
          } else {
            message.success("Tenant has been restored successfully");
          }
          setLoading(false);
          let arr = [];
          searchData?.map((item) => {
            if (selectedId?.includes(item.id)) {
            } else {
              arr.push(item);
            }
          });
          setSearchData(arr);
        });

        setShowDel(false);
        setSelectedId([]);
      }
    }
  };

  return (
    <>
      <TenantsWrap>
        {isClickMessage ? <LoadingTopPage /> : ""}

        <Row justify="space-between">
          <Col
            xl={{ span: 6 }}
            md={{ span: 4 }}
            sm={{ span: 5 }}
            xs={{ span: 12 }}
          >
            <Heading marginBottom="30px">
              <Link to="/tenants">Tenants</Link>
            </Heading>
          </Col>
          <Col
            xl={{ span: 18 }}
            md={{ span: 20 }}
            sm={{ span: 19 }}
            xs={{ span: 12 }}
          >
            <Row justify="end" gutter={[10, 10]}>
              <Col>
                <SelectWrap
                  placeholder="Select Propety"
                  onChange={selectChange}
                  defaultValue="Current Tenants"
                  // allowClear
                >
                  <Option value="Current Tenants">Current Tenants</Option>
                  <Option value="Deleted Tenants">Deleted Tenants</Option>
                </SelectWrap>
              </Col>
              <Col style={{ display: "flex" }}>
                <AddNewApr
                  bgColor={"#777777"}
                  fw={"400"}
                  displayView="none"
                  onClick={() => setModalState(true)}
                >
                  Add New Tenants
                </AddNewApr>
              </Col>
            </Row>
          </Col>
        </Row>
        <AddTenantsModal
          onCancel={(value) => setModalState(value)}
          modalState={modalState}
          newAddedVal={newAddedVal}
          // from={adminRoutes.TEAM}
        />

        {/* <TenantsCard>
          <Row gutter={[15, 15]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <TenantsCardWrap>
                <Row justify="space-between">
                  <Col>
                    <p>Lease Up in 90 Days</p>
                  </Col>
                  <Col>
                    <h5>
                      <TiFilter className="tifilter__icon" />
                      Tenants
                    </h5>
                  </Col>
                  <Col span={24}>
                    <h2>08</h2>
                  </Col>
                </Row>
              </TenantsCardWrap>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <TenantsCardWrap>
                <Row justify="space-between">
                  <Col>
                    <p>Below Est. Market Rate</p>
                  </Col>
                  <Col>
                    <h5>
                      <TiFilter className="tifilter__icon" />
                      Tenants
                    </h5>
                  </Col>
                  <Col span={24}>
                    <h2>12</h2>
                  </Col>
                </Row>
              </TenantsCardWrap>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <TenantsCardWrap>
                <Row justify="space-between">
                  <Col>
                    <p>Above Est. Market Rate</p>
                  </Col>
                  <Col>
                    <h5>
                      <TiFilter className="tifilter__icon" />
                      Tenants
                    </h5>
                  </Col>
                  <Col span={24}>
                    <h2>04</h2>
                  </Col>
                </Row>
              </TenantsCardWrap>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <TenantsCardWrap>
                <Row justify="space-between">
                  <Col>
                    <p>Occupancy Rate</p>
                  </Col>
                  <Col>
                    <h5>
                      <TiFilter className="tifilter__icon" />
                      Tenants
                    </h5>
                  </Col>
                  <Col span={24}>
                    <h2>87%</h2>
                  </Col>
                </Row>
              </TenantsCardWrap>
            </Col>
          </Row>
        </TenantsCard> */}
        <TableOneWrap>
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={18}>
              <ContainerWrap
                paddings="10px"
                padding="30px 30px 0 30px"
                minH="auto"
                brRadius="5px"
                bgColor="#ffffff"
              >
                <Row justify="space-between">
                  <Col
                    xl={{ span: 9 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    xs={{ span: 24 }}
                  >
                    <Row justify="space-between">
                      <Col
                        xl={{ span: 24 }}
                        md={{ span: 24 }}
                        sm={{ span: 24 }}
                        xs={{ span: 12 }}
                      >
                        <Heading fontSize="20px">
                          <Link to="/tenants">{tenantStatus}</Link>
                        </Heading>
                      </Col>
                      <Col
                        xl={{ span: 0 }}
                        md={{ span: 0 }}
                        sm={{ span: 0 }}
                        xs={{ span: 6 }}
                      >
                        {showDel && selectedId?.length >= 1 ? (
                          <DelBtn onClick={deleteHandler}>
                            {currentSelectTab === "Current Tenants" ? (
                              <>
                                <FaTrash />
                                <p>Delete</p>
                              </>
                            ) : currentSelectTab === "Deleted Tenants" ? (
                              <>
                                <RiArrowGoBackLine />
                                <p>Restore</p>
                              </>
                            ) : (
                              ""
                            )}
                          </DelBtn>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    xl={{ span: 11 }}
                    md={{ span: 15 }}
                    sm={{ span: 15 }}
                    xs={{ span: 24 }}
                  >
                    <Row justify="space-between">
                      <Col
                        xl={{ span: 4 }}
                        md={{ span: 4 }}
                        sm={{ span: 4 }}
                        xs={{ span: 0 }}
                        style={{
                          justifyContent: "end",
                        }}
                      >
                        {showDel && selectedId?.length >= 1 ? (
                          <DelBtn onClick={deleteHandler}>
                            {currentSelectTab === "Current Tenants" ? (
                              <>
                                <FaTrash />
                                <p>Delete</p>
                              </>
                            ) : currentSelectTab === "Deleted Tenants" ? (
                              <>
                                <RiArrowGoBackLine />
                                <p>Restore</p>
                              </>
                            ) : (
                              ""
                            )}
                          </DelBtn>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col
                        xl={{ span: 19 }}
                        md={{ span: 20 }}
                        sm={{ span: 20 }}
                        xs={{ span: 20 }}
                        style={{ display: "flex" }}
                        className="search__filter"
                      >
                        <Search
                          ref={(ele) => searchBarText === ele}
                          suffix={suffix}
                          onChange={onSearch}
                          placeholder="Search..."
                          value={searchBarText}
                          onPressEnter={onSearch}
                        />
                        {/* <HeaderSearch height="40px" bgcolor="#eeeeee" /> */}
                        <CheckboxMenu
                          options={[
                            "Tenant Name",
                            "Unit",
                            "Contact Number",
                            "Unit Type",
                            "Lease Term",
                            "Lease Start",
                            "Lease End",
                            "Due Date",
                            "Rent",
                            "Balance",
                            "Send Message",
                            "Invitation",
                            "Send Invitation",
                            // "Action",
                          ]}
                          value={[
                            "Tenant Name",
                            "Unit",
                            "Contact Number",
                            "Unit Type",
                            "Lease Term",
                            "Lease Start",
                            "Lease End",
                            "Due Date",
                            "Rent",
                            "Balance",
                            "Send Message",
                            "Invitation",
                            "Send Invitation",
                            // "Action",
                          ]}
                          defaultValue={["Tenant Name", "Contact Number"]}
                          onChange={onCheckboxChange}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <TableWrap checkOpacity={selectedId?.length ? 1 : 0}>
                      <Table
                        columns={columans}
                        dataSource={[...searchData]}
                        onChange={handleTableChange}
                        size="middle"
                        scroll={{ x: tableXAxis, y: yScroll }}
                        loading={loading}
                        pagination={pagination}
                        rowSelection={{
                          type: "checkbox",
                          ...rowSelection,
                        }}
                        rowKey={(record) => record.id}
                        onRow={(r) => ({
                          onClick: () => {
                            setAllTenantsArrOne(r);
                            tenantDetailsPage(r);
                          },
                        })}
                      />
                    </TableWrap>
                  </Col>
                </Row>
              </ContainerWrap>
            </Col>
            <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={6}>
              <RightSideWrap>
                <RightSide>
                  <NameWrap>
                    <UserAvatar
                      className="userName__icon"
                      fullName={"Mark Jecno"}
                      // fullName={`${firstName || ""} ${lastName || ""}`}
                      // src={
                      //   item?.user?.avatar?.path
                      //     ? imagePath(item?.user?.avatar?.path)
                      //     : ""
                      // }
                      src={"/images/tenants.png"}
                    />
                  </NameWrap>
                  <MyAccountWrapRight>
                    <Row justify="space-between">
                      <Col>
                        <h2>
                          {upperCase(allTenantsArrOne?.firstName)}{" "}
                          {upperCase(allTenantsArrOne?.lastName)}
                        </h2>
                        <p>
                          {formatPhoneNumber(allTenantsArrOne?.phoneNumber)}
                        </p>
                      </Col>
                    </Row>
                  </MyAccountWrapRight>
                </RightSide>
                <TenantsDetails>
                  {allTenantsArrOne?.org_name ? (
                    <Row>
                      <Col span={12}>
                        <span>Organization Name</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.org_name}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {allTenantsArrOne?.apartment_name ? (
                    <Row>
                      <Col span={12}>
                        <span>Property Name</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.apartment_name}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {allTenantsArrOne?.unit_name ? (
                    <Row>
                      <Col span={12}>
                        <span>Unit Name</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.unit_name}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {allTenantsArrOne?.unitNumber ? (
                    <Row>
                      <Col span={12}>
                        <span>Unit Number</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.unitNumber}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.unitType ? (
                    <Row>
                      <Col span={12}>
                        <span>Unit Type</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.unitType}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.leaseTerm ? (
                    <Row>
                      <Col span={12}>
                        <span>Lease Term</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.leaseTerm}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.leaseStart ? (
                    <Row>
                      <Col span={12}>
                        <span>Lease Start</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.leaseStart}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.leaseEnd ? (
                    <Row>
                      <Col span={12}>
                        <span>Lease End</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.leaseEnd}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.dueDate ? (
                    <Row>
                      <Col span={12}>
                        <span>Due Date</span>
                      </Col>
                      <Col span={12}>
                        <p>{allTenantsArrOne?.dueDate}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.rate ? (
                    <Row>
                      <Col span={12}>
                        <span>Rent</span>
                      </Col>
                      <Col span={12}>
                        <p>{toUSACurrency(Number(allTenantsArrOne?.rate))}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.balance ? (
                    <Row>
                      <Col span={12}>
                        <span>Balance</span>
                      </Col>
                      <Col span={12}>
                        <p>
                          {toUSACurrency(Number(allTenantsArrOne?.balance))}
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  {allTenantsArrOne?.email ? (
                    <Row>
                      <Col span={12}>
                        <span>Tenant Email</span>
                      </Col>
                      <Col span={12}>
                        <p>{slpitFunc(allTenantsArrOne?.email, 15)}</p>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </TenantsDetails>
              </RightSideWrap>
            </Col>
          </Row>
        </TableOneWrap>
        <FloatButton>
          <SecondaryButton
            shape="circle"
            icon={<HiPlus className="plus_icon" />}
            onClick={() => setModalState(true)}
          />
        </FloatButton>
      </TenantsWrap>
    </>
  );
}

export default Tenants;

export const TableWrap = styled.div`
  .send__invitation .ant-space-item {
    margin: auto;
  }
  .ant-space-align-center {
    align-items: center;
    width: 100%;
  }
  .send__icon {
    font-size: 18px;
    color: #f67c36;
  }
  .ant-checkbox {
    opacity: ${({ checkOpacity }) => checkOpacity || 0};
  }
  .ant-checkbox:hover,
  .ant-checkbox:checked {
    opacity: 1;
  }

  .ant-table-container .ant-table-body table tr:hover {
    .ant-checkbox {
      opacity: 1;
    }
  }
`;
export const TenantsDetails = styled.div`
  padding-top: 50px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #94999e;
  }
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 40px;
    color: #374049;
    margin-bottom: 0;
  }

  @media (max-width: 1550px) {
    padding-top: 0;
  }
`;

export const MyAccountWrapRight = styled.div`
  padding: 20px 0 20px 30px;
  width: 100%;
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: ${({ theme }) => theme.colors.forth};
  }
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.third};
  }

  @media (max-width: 1550px) {
    padding: 24px 0px;

    h2 {
      font-size: 20px;
      line-height: 23px;
    }
  }
  @media (max-width: 420px) {
    padding: 10px;
  }
`;

export const NameWrap = styled.div`
  .ant-avatar {
    width: 100px;
    height: 100px;
  }
`;
export const RightSideWrap = styled.div`
  ${"" /* padding: 30px; */}
  padding: ${({ padding }) => padding || "30px"};

  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  ${"" /* min-height: calc(100vh - 220px); */}
  min-height: ${({ minH }) => minH || "calc(100vh - 220px)"};
`;
export const RightSide = styled.div`
  display: flex;
  @media (max-width: 1550px) {
    display: flex;
    flex-direction: column;
    flex-direction: ${({ flexDirection }) => flexDirection || "column"};
  }
`;

const SelectWrap = styled(Select)`
  width: 170px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 40px !important;
  .ant-select-selection-placeholder {
    color: #2b353f !important;
  }
  :hover {
    border-color: #aaaaaa !important;
  }

  .ant-select-selector {
    position: relative;
    background-color: ${({ bgColor }) => bgColor || "#fff"} !important;
    border: none !important;
    border-radius: 5px !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 40px !important;
    align-items: center;
  }
  .ant-select:hover {
    box-shadow: 0 0 0 2px rgb(0, 0, 0, 0.1) !important;
  }
  ,
  .ant-select-selector {
    border-color: #aaaaaa !important;
  }
  .ant-select-focused {
    box-shadow: 0 0 0 2px rgb(0, 0, 0, 0.1) !important;
  }

  @media (max-width: 1550px) {
    height: 40px !important;
    font-size: 13px !important;

    .ant-select-selection-placeholder {
      font-size: 13px;
    }

    .ant-select-selector {
      height: 40px !important;
    }
  }
`;

export const TenantsWrap = styled.div`
  min-height: ${({ minH }) => minH || "calc(100vh - 80px)"};
  padding: ${({ padding }) => padding || "50px 50px 0 330px"};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : "#eeeeee")};
  border-radius: ${({ brRadius }) => (brRadius ? brRadius : "0")};

  @media (max-width: 1550px) {
    padding: ${({ padding }) => padding || "40px 40px 0 265px"};
    min-height: ${({ minH }) => minH || "calc(100vh - 65px)"};
  }

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "15px"};
  }

  .ant-table-tbody > tr.ant-table-placeholder {
    min-height: calc(100vh - 445px) !important;
  }

  .ant-table-body {
    min-height: calc(100vh - 445px) !important;
  }

  .ant-table-pagination.ant-pagination {
    margin: 30px 0;
  }
  .ant-pagination.mini .ant-pagination-options {
    display: none;
  }
  .ant-pagination {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 20px;
    padding-right: 25px;
  }
  .ant-pagination.mini .ant-pagination-item {
    margin-right: 20px;
    min-width: 40px;
    height: 40px;
  }
  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 20px;
  }
  .ant-pagination-item a {
    padding: 8px 6px;
  }
  .ant-pagination-item-active a {
    color: #ffffff;
  }
  .ant-pagination-item-active {
    background: ${({ theme }) => theme.colors.secondary};
    border: none;
  }
  .ant-pagination-item {
    border-radius: 50%;
  }
`;
